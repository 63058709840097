import queryString from "query-string";
import React from "react";
import { useSelector } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import DownloadLink from "../../components/DownloadLink";
import SearchInput from "../../components/forms/SearchInput";
import Pager from "../../components/Pager";
import ProductItem from "../../components/products/Item";
import QualityAssurance from "../../components/QualityAssurance";
import Wave from "../../components/style/Wave";
import DistributorSelectors from "../../selectors/distributor.selectors";
import ProductsSelectors from "../../selectors/products.selectors";
import { store } from "../../store/configure.store";
import ShopSlice from "../../store/slices/shop.slice";
import { usePager } from "../../utils/layout.utils";
import Filters from "./Filters";
import "./shop.scss";

const SearchDropdown = () => {
    const results = useSelector((state: any) => ProductsSelectors.searchResults(state));

    const setCategoryFilter = (id) => {
        store.dispatch(ShopSlice.actions.resetFilters());
        store.dispatch(ShopSlice.actions.setProductCategoryFilter(id));
    };

    const setSubCategoryFilter = (id) => {
        store.dispatch(ShopSlice.actions.resetFilters());
        store.dispatch(ShopSlice.actions.setProductSubCategoryFilter(id));
    };

    return (
        <div className="search-dropdown">
            {results.map((category) => (
                <div key={`category${category.id}`} className="category">
                    <HashLink to="#products" onClick={() => setCategoryFilter(category.id)}>
                        {category.name}
                    </HashLink>
                    {category.productSubCategories.map((subCategory) => (
                        <div key={`sub-category${subCategory.id}`} className="sub-category">
                            <HashLink
                                to="#products"
                                onClick={() => setSubCategoryFilter(subCategory.id)}
                            >
                                {subCategory.name}
                            </HashLink>
                            {subCategory.products.map((product) => (
                                <Link
                                    key={`product${product.id}`}
                                    to={`/product/${product.id}`}
                                    className="product"
                                >
                                    {product.name}
                                </Link>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default () => {
    const location = useLocation();
    const { orderToEdit } = queryString.parse(location.search);

    const products = useSelector((state: any) => ProductsSelectors.applyFilters(state));

    const { page, setPage, pageCount, displayedItems: displayedProducts } = usePager(products, 12);

    const existingOrder = useSelector(DistributorSelectors.orderById(orderToEdit));

    if (existingOrder && !existingOrder.statusData.editable) {
        return <Redirect to={"/profile/order/" + existingOrder.id} />;
    } else {
        return (
            <div id="shop">
                <div className="title-wrapper">
                    <div className="title-row">
                        <h1>My product portfolio</h1>
                        <SearchInput
                            color="primary"
                            placeholder="Search for a product"
                            onChange={(x) => {
                                store.dispatch(ShopSlice.actions.setSearchFilter(x));
                            }}
                            dropdownComponent={SearchDropdown}
                        />
                    </div>
                    <div className="download-catalog">
                        <p className="label">
                            To see the complete DKT WomanCare Global portfolio, click the button
                            below. If you are interested in a product that is not featured in your
                            portfolio, please contact your sales representative.
                        </p>
                        <DownloadLink
                            url="/assets/documents/product_catalogue-en.pdf"
                            label="Download our full product catalaogue"
                        />
                    </div>
                    <Wave color="light" />
                </div>
                <div className="products">
                    <a id="products"></a>
                    <Filters />
                    <div className="list">
                        <div className="items">
                            {displayedProducts.map((product, index) => (
                                <ProductItem
                                    key={index}
                                    product={product}
                                    orderToEdit={existingOrder ? existingOrder.id : null}
                                    hideDetails
                                />
                            ))}
                            {Array(10)
                                .fill(0)
                                .map((_, index) => (
                                    <div
                                        key={index}
                                        className="product-item"
                                        role="presentation"
                                    ></div>
                                ))}
                        </div>
                        <Pager
                            pageCount={pageCount}
                            currentPage={page}
                            onSetPage={(x) => setPage(x)}
                            link="#products"
                        />
                    </div>
                    <Wave color="white" />
                </div>
                <QualityAssurance />
            </div>
        );
    }
};
