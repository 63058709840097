import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: null,
    success: null,
};

const AppSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setError: (state, { payload }) => ({
            ...state,
            error: payload,
        }),
        setSuccess: (state, { payload }) => ({
            ...state,
            success: payload,
        }),
        reset: () => initialState,
    },
});

export default AppSlice;
