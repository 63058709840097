import React from "react";
import { Columns } from "../../pages/order/Cart";
import { getById } from "../../services/products.service";
import { useAtomicQuery } from "../../utils/query.utils";
import { getFile } from "../../utils/s3.utils";

const CartBonusItem = ({ cartBonusProduct: { productId, quantity }, countryName }) => {
    const [product] = useAtomicQuery(() => getById(productId));

    if (!product) {
        return null;
    }

    return (
        <tr className="cart-bonus-item" key={product.id}>
            <td data-label={Columns[0]}>
                <div className="product-block">
                    <div className="image-wrapper">
                        <img className="image" src={getFile(product.image)} alt="Product" />
                    </div>
                    <div className="title">
                        <div className="name">{product.name}</div>
                        <div className="reference">{product.reference}</div>
                    </div>
                </div>
            </td>
            <td data-label={Columns[1]}>{countryName ?? "-"}</td>
            <td data-label={Columns[2]}>$0.00</td>
            <td data-label={Columns[3]}>{quantity}</td>
            <td data-label={Columns[4]}>$0.00</td>
        </tr>
    );
};

export default CartBonusItem;
