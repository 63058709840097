const formatNumber = (n, useGrouping?, decimals = 0) => {
    let parsed = parseFloat(n);
    if (!Number.isFinite(parsed)) {
        console.error("Erreur lors de l'affichage du nombre", n);
        return "ERROR";
    }
    let formatted = parsed.toFixed(decimals);
    if (useGrouping) {
        formatted = formatted.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    }
    return formatted;
};

const formatPrice = (price) => {
    return `$${formatNumber(price, true, 2)}`;
};

const formatPercentage = (percentage, decimals?) => {
    return `${formatNumber(percentage, true, decimals)}%`;
};

export { formatNumber, formatPrice, formatPercentage };
