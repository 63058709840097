import React from "react";
import { Link } from "react-router-dom";
import "./backLink.scss";
import Caret from "./icons/Caret";

export default (props: { to?: string; label: string; color?: string; onClick?: () => void }) => (
    <Link to={props.to || "/"} className="back-link" onClick={props?.onClick}>
        <Caret type="left" color={props.color || "primary"} size={12} />
        <div className={`label ${props.color || "primary"}`}>{props.label}</div>
    </Link>
);
