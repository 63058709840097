import { useEffect, useState } from "react";

const useResize = (ref) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (ref.current) {
            setWidth(ref.current ? ref.current.offsetWidth : 0);
            setHeight(ref.current ? ref.current.offsetHeight : 0);
        }

        const onResize = () => {
            setWidth(ref.current ? ref.current.offsetWidth : 0);
            setHeight(ref.current ? ref.current.offsetHeight : 0);
        };

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [ref]);

    return { width, height };
};

const usePager = (items, itemsByPage) => {
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(Math.ceil(items?.length / itemsByPage));

    useEffect(() => {
        setPage(0);
        setPageCount(Math.ceil(items?.length / itemsByPage));
    }, [items?.length, itemsByPage]);

    return {
        page,
        setPage,
        pageCount,
        displayedItems: items?.slice(page * itemsByPage, page * itemsByPage + itemsByPage),
    };
};

export { useResize, usePager };
