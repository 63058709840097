import "./calendar.scss";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

import { CalenderTable } from "./CalenderTable";
import { getEventsByDate } from "../../services/events.service";
import { Loader } from "../style/Loader";

const Calender = () => {
    const [monthOffset, setMonthOffset] = useState(0);
    const [events, setEvents] = useState([]);
    const [[year, monthNumber, monthName], setDate] = useState(
        moment().format("YYYY MM MMMM").split(" ")
    );
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        getEvents();
    }, [monthNumber]);

    const getEvents = async () => {
        const evnts = await getEventsByDate(year, monthNumber);
        setLoading(false);
        setEvents(evnts);
    };

    const onOffsetChange = (offset: number) => {
        setLoading(true);
        setEvents([]);
        const newOffset = monthOffset + offset;
        setMonthOffset(newOffset);
        setDate(moment().add(newOffset, "M").format("YYYY MM MMMM").split(" "));
    };

    const incOffset = () => onOffsetChange(1);
    const decOffset = () => onOffsetChange(-1);

    const LeftArrow = () => {
        return (
            <div style={{ cursor: "pointer" }} onClick={decOffset}>
                <AiOutlineLeft color="white" />
            </div>
        );
    };

    const RightArrow = () => {
        return (
            <div style={{ cursor: "pointer" }} onClick={incOffset}>
                <AiOutlineRight color="white" />
            </div>
        );
    };

    return (
        <div className="calendar-container">
            <div className="wave-container">
                <svg
                    viewBox="0 0 540 120"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    className="wave stack"
                >
                    <path d="M0,120Q80,50,200,100T540,60L540,0L0,0L0,120" fill="#479bc4" />
                </svg>
                <div className="stack stack-top row">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <React.Fragment>
                            <LeftArrow />
                            <span className="calendar-title">{monthName + " " + year}</span>
                            <RightArrow />
                        </React.Fragment>
                    )}
                </div>
            </div>
            <CalenderTable year={year} month={monthNumber} events={events} />
        </div>
    );
};

export default Calender;
