import { get } from "./utils.service";

export const getAll = async () => {
    try {
        const res = await get(`/countries`);
        return await res.json();
    } catch (e) {
        return null;
    }
};
