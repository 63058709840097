import React, { useState } from "react";

import SearchIcon from "../icons/Search";
import "./searchInput.scss";

type SearchInputProps = {
    color?: string;
    onChange?: (text: string) => void;
    onBlur?: () => void;
    placeholder?: string;
    value?: string;
    style?: Object;
    dropdownComponent?: any;
};

export default (props: SearchInputProps) => {
    const [showDropdown, setShowDropdown] = useState(false);

    return (
        <div
            className={`search-input ${props.color || ""}`}
            onMouseLeave={() => {
                if (props.onBlur) {
                    props.onBlur();
                }
                setShowDropdown(false);
            }}
            style={props?.style}
        >
            <input
                type="text"
                value={props.value}
                onChange={(e) => {
                    props.onChange(e.target.value);
                    setShowDropdown(true);
                }}
                onClick={() => setShowDropdown(true)}
                placeholder={props.placeholder}
            />
            <SearchIcon color={props.color} />
            {props.dropdownComponent && showDropdown ? (
                <div className="dropdown">
                    <props.dropdownComponent />
                </div>
            ) : null}
        </div>
    );
};
