import React from "react";

import "./newsCard.scss";

const NewsCard = (props: {
	id: number;
    date: Date|string;
	title: string;
	excerpt: string;
}) => {
	return (
		<article className="news-card">
			<div className="news-card-date">
				{ new Date(props.date).toLocaleDateString('US') }
			</div>
			<h1 className="news-card-title">
				{ props.title }
			</h1>
			<p className="news-card-excerpt">
				{ props.excerpt }
			</p>

			<a className="read-news-link" href={`/news/${props.id}`}>Read</a>
		</article>
	);
};

export default NewsCard;