import React from "react";

import "./confirmationModal.scss";
import Button from "./forms/Button";
import Modal from "./Modal";

export default (props: { message: string; onValidate: () => void; onCancel: () => void }) => (
    <Modal className="confirmation-modal" onClose={props.onCancel} closeOnBackgroundClick>
        <div className="content">{props.message}</div>
        <div className="buttons">
            <Button text="Confirm" onClick={props.onValidate} />
            <Button text="Cancel" onClick={props.onCancel} color="white" hasBorder />
        </div>
    </Modal>
);
