import { get, post } from "./utils.service";

export const getAll = async () => {
    try {
        const res = await get(`/products/all`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const getById = async (id: number) => {
    try {
        const res = await get(`/products/${id}`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const getByMBDreferenceCode = async (MBDreferenceCode: string) => {
    try {
        const res = await get(`/products/mbdReferenceCode/${MBDreferenceCode}`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const getForCurrentDistributor = async () => {
    try {
        const res = await get(`/products`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const getProductCategories = async () => {
    try {
        const res = await get(`/products-categories`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const getProductSubCategories = async () => {
    try {
        const res = await get(`/products-sub-categories`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const requestProduct = async (productId) => {
    try {
        const res = await post(`/products/request/${productId}`, {});
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const requestLabel = async (productId, countryId) => {
    try {
        const res = await post(`/products/request-label/${productId}`, { countryId });
        return await res.json();
    } catch (e) {
        return null;
    }
};
