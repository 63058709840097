import React from "react";

import Colors from "../../themes/colors.theme";

export default ({ color }) => {
    const colorCode = Colors[color || "secondary"];

    return (
        <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
            <defs>
                <filter id="filter-1">
                    <feColorMatrix
                        in="SourceGraphic"
                        type="matrix"
                        values="0 0 0 0 0.556863 0 0 0 0 0.345098 0 0 0 0 0.752941 0 0 0 1.000000 0"
                    ></feColorMatrix>
                </filter>
            </defs>
            <g
                id="01.-Atoms-/-02.-Icons-/-Modify"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <g id="Regular" transform="translate(7.000000, 7.000000)">
                    <g>
                        <path
                            d="M24.2395833,6.331 L8.81183333,21.75875 L4.24016667,17.1870833 L19.5455,1.88175 C20.6695765,0.709819541 22.4711524,0.510049851 23.8246667,1.40725 C24.6012472,1.95841561 25.0954087,2.82385608 25.1753735,3.77278525 C25.2553382,4.72171442 24.9129757,5.65764745 24.2395833,6.331 Z"
                            id="Shape"
                            stroke={colorCode}
                            strokeWidth="1.5"
                        ></path>
                        <path
                            d="M19.669,1.75933333 C18.4063192,0.497506017 16.3600142,0.497506017 15.0973333,1.75933333 L11.6685833,5.18808333"
                            id="Shape"
                            stroke={colorCode}
                            strokeWidth="1.5"
                        ></path>
                        <line
                            x1="15.66825"
                            y1="5.759"
                            x2="20.2399167"
                            y2="10.3306667"
                            id="Shape"
                            stroke={colorCode}
                            strokeWidth="1.5"
                        ></line>
                        <polygon
                            id="Shape"
                            stroke={colorCode}
                            strokeWidth="1.5"
                            points="0.811416667 25.1875 4.24016667 17.1881667 8.81183333 21.75875"
                        ></polygon>
                    </g>
                </g>
            </g>
        </svg>
    );
};
