import React from "react";
import Button from "../../components/forms/Button";
import { useLogin } from "../../utils/auth.utils";
import "./notFound.scss";

const NotFound = () => {
    const { isLogin } = useLogin();

    return (
        <div id="not-found">
            <h1>Page not found</h1>
            <Button to={isLogin ? "/home" : "/"} text="Back to home" color="dark" />
        </div>
    );
};

export default NotFound;
