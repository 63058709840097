import React from "react";
import { useHistory } from "react-router-dom";
import { getFile } from "../../utils/s3.utils";
import LinkButton from "../LinkButton";
import "./resourceItem.scss";

export default (props: { item: any; onClickLink: string }) => {
    const history = useHistory();

    const redirectToDetails = () => {
        history.push(props.onClickLink);
    };

    return (
        <div onClick={redirectToDetails} id="productResourceItem">
            <div className="image-content">
                {props.item.image ? (
                    <img className="image" src={getFile(props.item.image)} alt="Product resource" />
                ) : null}
            </div>
            <div className="text-content">
                <div className="name">{props.item.title}</div>
                <div className="excerpt">{props.item.introduction}</div>
                <LinkButton label="See more" color="primary" />
            </div>
        </div>
    );
};
