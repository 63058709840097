import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    registrations: [],
    shippingAddresses: [],
    billingAddresses: [],
    forecasts: [],
    documents: [],
    users: [],
    orders: [],
    notifications: [],
    loyalty: {
        careCoinsBalance: 0,
    },
};

const DistributorSlice = createSlice({
    name: "distributor",
    initialState,
    reducers: {
        setDistributorData: (state, { payload }) => ({
            ...state,
            ...payload,
        }),
        setRegistrations: (state, { payload }) => ({
            ...state,
            registrations: payload,
        }),
        setOrders: (state, { payload }) => ({
            ...state,
            orders: payload,
        }),
        addShippingAddress: (state, { payload }) => ({
            ...state,
            shippingAddresses: [...state.shippingAddresses, payload],
        }),
        deleteShippingAddress: (state, { payload }) => ({
            ...state,
            shippingAddresses: state.shippingAddresses.filter((x) => x.id !== payload.id),
        }),
        setShippingAddress: (state, { payload }) => ({
            ...state,
            shippingAddresses: state.shippingAddresses.map((x) =>
                x.id === payload.id
                    ? {
                          ...x,
                          ...payload,
                      }
                    : x
            ),
        }),
        setPrimaryShippingAddress: (state, { payload }) => ({
            ...state,
            shippingAddresses: state.shippingAddresses.map((x) =>
                x.id === payload.id
                    ? x
                    : {
                          ...x,
                          isPrimary: false,
                      }
            ),
        }),
        addBillingAddress: (state, { payload }) => ({
            ...state,
            billingAddresses: [...state.billingAddresses, payload],
        }),
        deleteBillingAddress: (state, { payload }) => ({
            ...state,
            billingAddresses: state.billingAddresses.filter((x) => x.id !== payload.id),
        }),
        setBillingAddress: (state, { payload }) => ({
            ...state,
            billingAddresses: state.billingAddresses.map((x) =>
                x.id === payload.id
                    ? {
                          ...x,
                          ...payload,
                      }
                    : x
            ),
        }),
        setPrimaryBillingAddress: (state, { payload }) => ({
            ...state,
            billingAddresses: state.billingAddresses.map((x) =>
                x.id === payload.id
                    ? x
                    : {
                          ...x,
                          isPrimary: false,
                      }
            ),
        }),

        setOrderAttribute: (state, { payload }) => ({
            ...state,
            orders: state.orders.map((x) =>
                x.id === +payload.id
                    ? {
                          ...x,
                          ...payload.fields,
                      }
                    : x
            ),
        }),
        addOrderItem: (state, { payload }) => ({
            ...state,
            orders: state.orders.map((x) =>
                x.id === +payload.id
                    ? {
                          ...x,
                          orderItems: [...x.orderItems, payload.orderItem],
                      }
                    : x
            ),
        }),
        setOrderItem: (state, { payload }) => ({
            ...state,
            orders: state.orders.map((x) =>
                x.id === +payload.id
                    ? {
                          ...x,
                          orderItems: x.orderItems.map((y) =>
                              y.id === payload.orderItem.id
                                  ? {
                                        ...y,
                                        ...payload.orderItem,
                                    }
                                  : y
                          ),
                      }
                    : x
            ),
        }),
        setOrderItems: (state, { payload }) => ({
            ...state,
            orders: state.orders.map((x) =>
                x.id === +payload.id
                    ? {
                          ...x,
                          orderItems: payload.orderItems,
                      }
                    : x
            ),
        }),
        addOrderDocument: (state, { payload }) => ({
            ...state,
            orders: state.orders.map((x) =>
                x.id === +payload.id
                    ? {
                          ...x,
                          orderDocuments: [...x.orderDocuments, payload.file],
                      }
                    : x
            ),
        }),
        addForecast: (state, { payload }) => ({
            ...state,
            forecasts: [...state.forecasts, payload],
        }),
        setForecast: (state, { payload }) => ({
            ...state,
            forecasts: state.forecasts.map((x) =>
                x.id === payload.id
                    ? {
                          ...x,
                          ...payload,
                      }
                    : x
            ),
        }),
        deleteForecast: (state, { payload }) => ({
            ...state,
            forecasts: state.forecasts.filter((x) => x.id !== payload.id),
        }),
        addDocument: (state, { payload }) => ({
            ...state,
            documents: [...state.documents, payload],
        }),
        setDocument: (state, { payload }) => ({
            ...state,
            documents: state.documents.map((x) =>
                x.id === payload.id
                    ? {
                          ...x,
                          ...payload,
                      }
                    : x
            ),
        }),
        addUser: (state, { payload }) => ({
            ...state,
            users: [...state.users, payload],
        }),
        setUser: (state, { payload }) => ({
            ...state,
            users: state.users.map((x) =>
                x.id === payload.id
                    ? {
                          ...x,
                          ...payload,
                      }
                    : x
            ),
        }),
        setNotifications: (state, { payload }) => ({
            ...state,
            notifications: payload,
        }),
        setNotificationsRead: (state, { payload }) => ({
            ...state,
            notifications: state.notifications.map((notification) =>
                payload.notificationsIds.includes(notification.id)
                    ? { ...notification, read: true }
                    : notification
            ),
        }),
        setLoyaltyData: (state, { payload }) => ({
            ...state,
            loyalty: {
                ...state.loyalty,
                ...payload,
            },
        }),
        addCareCoinsBalance: (state, { payload }) => ({
            ...state,
            loyalty: {
                ...state.loyalty,
                careCoinsBalance: Math.round(state.loyalty.careCoinsBalance + payload),
            },
        }),
        reset: () => initialState,
    },
});

export default DistributorSlice;
