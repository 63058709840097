import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import IconLink from "../../components/IconLink";
import Wave from "../../components/style/Wave";
import MeSelectors from "../../selectors/me.selectors";
import { getFile } from "../../utils/s3.utils";
import "./contacts.scss";

const Contact = (props: {
    preferred?: boolean;
    title: string;
    linkType?: "edit" | "more_info";
    linkTo?: string;
    children: any;
}) => (
    <div className={`contact ${props.preferred ? "preferred" : ""}`}>
        <div className="title dash-title">{props.title}</div>
        <div className="content">{props.children}</div>
        {props.linkType ? (
            <div className={`link ${props.linkType.replace("_", "-")}`}>
                {props?.linkType === "edit" && (
                    <IconLink to={props.linkTo || "#"} icon="edit" label="Edit" color="secondary" />
                )}
                {props?.linkType === "more_info" && (
                    <Link to={props.linkTo || "#"} className="primary">
                        More info
                    </Link>
                )}
            </div>
        ) : null}
    </div>
);

export default () => {
    const me = useSelector((state: any) => MeSelectors.formattedMe(state));
    const distributor = useSelector((state: any) => state.distributor);
    const displayedUsers = distributor.users
        .slice()
        .sort((a, b) => (a.creationDate < b.creationDate ? 1 : -1))
        .slice(0, 2);
    const shippingAddress = distributor.shippingAddresses.find((x) => x.isPrimary);

    return (
        <div className="contacts">
            <Contact title="Your prefered contact" preferred>
                {me.salesRepresentative ? (
                    <>
                        <img
                            src={
                                me.salesRepresentative.picture
                                    ? getFile(me.salesRepresentative.picture)
                                    : "/assets/images/sample.jpg"
                            }
                            alt="Sales representative"
                            className="picture"
                        />
                        <p>
                            {me.salesRepresentative.firstname} {me.salesRepresentative.lastname}
                            <br />
                            <a href={`mailto:${me.salesRepresentative.email}`}>
                                {me.salesRepresentative.email}
                            </a>
                            <br />
                            <a href={`tel:${me.salesRepresentative.phone}`}>
                                {me.salesRepresentative.phone}
                            </a>
                        </p>
                    </>
                ) : (
                    <>
                        <img
                            src="/assets/images/commercial-director.png"
                            alt="Commercial director"
                        />
                        <p>
                            Rodrigo Portugues
                            <br />
                            <a href="mailto:rodrigo@womancaredkt.org">rodrigo@womancaredkt.org</a>
                            <br />
                            <a href="tel:+66525638278">+66 5 25 63 82 78</a>
                        </p>
                    </>
                )}
            </Contact>
            <Contact title="Your informations" linkType="edit" linkTo="/profile/me">
                <p>
                    <span className="subtitle">{me.fullName}</span>
                    <br />
                    {distributor.organizationName}
                    <br />
                    {me.job}
                </p>
                <p>
                    {me.email}
                    <br />
                    {me.phone}
                    {me.phone2 ? (
                        <>
                            <br />
                            {me.phone2}
                        </>
                    ) : null}
                </p>
            </Contact>
            {displayedUsers ? (
                <Contact title="Your organization" linkType="more_info" linkTo="/profile/users">
                    {displayedUsers?.map((user, index) => {
                        return (
                            <p key={index}>
                                <span className="subtitle">
                                    {user.firstname + " " + user.lastname + " - " + user.role}
                                </span>
                                <br />
                                {user.email}
                                <br />
                                {user.phone}
                            </p>
                        );
                    })}
                </Contact>
            ) : null}
            {shippingAddress ? (
                <Contact
                    title="Your primary shipping address"
                    linkType="edit"
                    linkTo="/profile/shipping-addresses"
                >
                    <p>
                        <span className="subtitle">{shippingAddress.name}</span>
                        <br />
                        {shippingAddress.address}
                        <br />
                        {`${shippingAddress.zipCode}  ${shippingAddress.city}, ${shippingAddress.country.name}`}
                        <br />
                        <br />

                        {shippingAddress.email}
                        <br />
                        {shippingAddress.phone}
                    </p>
                </Contact>
            ) : null}
            <Wave color="light" reversed />
        </div>
    );
};
