import { post, get } from "./utils.service";

export const signin = async (email, password) => {
    const res = await post(`/auth/signin`, { email, password });
    return await res.json();
};

export const signup = async (user: Object) => {
    const res = await post(`/auth/signup`, user);
    return await res.json();
};

export const isDistributorAccountFree = async (props: {
    username?: string;
    email?: string;
    companyEmail?: string;
}) => {
    const res = await get(`/auth/distributor-free`, props);
    return await res.json();
};

export const forgottenPassword = async email => {
    const res = await post(`/auth/forgotten-password`, { email });
    return await res.json();
};
