const combineFilters = (...filterFunctions) => data => {
    let newData = data;
    for (const filterFunction of filterFunctions) {
        if (filterFunction) {
            newData = filterFunction(newData);
        }
    }
    return newData;
};

const atomize = list =>
    list.reduce((acc, value) => (acc.includes(value) ? acc : [...acc, value]), []);

const atomizeById = list =>
    list.reduce((acc, value) => (acc.some(x => value.id === +x.id) ? acc : [...acc, value]), []);

const atomizeByCondition = (list, condition) =>
    list.reduce((acc, value) => (acc.some(x => condition(x, value)) ? acc : [...acc, value]), []);

const select = (object, keys) => keys.reduce((acc, key) => ({ ...acc, [key]: object[key] }), {});

const mapAndSelect = (objects, keys) => objects.map(object => select(object, keys));

const sum = values => values.reduce((acc, value) => acc + value, 0);

const sumByKey = (items, key) => items.reduce((acc, item) => acc + item[key], 0);

const except = (object, keys) => {
    let newObject = { ...object };
    for (const key of keys) {
        delete newObject[key];
    }
    return newObject;
};

export {
    combineFilters,
    atomize,
    atomizeById,
    atomizeByCondition,
    select,
    mapAndSelect,
    sum,
    sumByKey,
    except,
};
