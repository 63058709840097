import { get } from "./utils.service";

export const getVideosAndTutorials = async () => {
    try {
        const res = await get(`/resource-materials/videos-and-tutorials`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const getMarketingMaterials = async () => {
    try {
        const res = await get(`/resource-materials/marketing-materials`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const getPosAndPop = async () => {
    try {
        const res = await get(`/resource-materials/pos-and-pop`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const getMarketData = async () => {
    try {
        const res = await get(`/resource-materials/market-data`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const getBestPractices = async () => {
    try {
        const res = await get(`/resource-materials/best-practices`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const getWCAcademy = async () => {
    try {
        const res = await get(`/resource-materials/womancare-academy`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const getAll = async () => {
    try {
        const res = await get(`/resource-materials/`);
        return await res.json();
    } catch (e) {
        return null;
    }
};