import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../components/forms/Button";
import CareCoinsModal from "../../components/order/CareCoinsModal";
import CartBonusItem from "../../components/order/CartBonusItem";
import OrderStepper from "../../components/order/Stepper";
import OrderSelectors from "../../selectors/order.selectors";
import { saveOrder } from "../../services/orders.service";
import { RootState, store } from "../../store/configure.store";
import OrderSlice from "../../store/slices/order.slice";
import { formatNumber, formatPrice } from "../../utils/numeric.utils";
import { getFile } from "../../utils/s3.utils";
import "./validation.scss";

const Address = (props: { title: string; address: any }) => {
    return (
        <div className="block">
            <div className="title">{props.title}</div>
            <div className="content">
                <div>{props.address.name}</div>
                {props.address.address ||
                props.address.zipCode ||
                props.address.city ||
                props.address.country ? (
                    <>
                        {props.address.address ? (
                            <>
                                {props.address.address}
                                <br />
                            </>
                        ) : null}
                        {props.address.zipCode || props.address.city ? (
                            <>
                                {props.address.zipCode || ""} {props.address.city || ""}
                            </>
                        ) : null}
                        <br />
                        {props.address.country ? props.address.country.name : null}
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default () => {
    const history = useHistory();

    const order = useSelector((state: RootState) => state.order);

    const orderData = useSelector((state: RootState) => OrderSelectors.orderProducts(state));

    const loyaltyData = useSelector((state: RootState) => state.distributor.loyalty);

    const [showCareCoinsModal, setShowCareCoinsModal] = useState(false);

    const setCareCoinsToUse = (careCoinsToUse: number) => {
        store.dispatch(OrderSlice.actions.setCareCoinsToUse(careCoinsToUse));
    };

    const [isLoading, setIsLoading] = useState(false);

    const goToNext = async () => {
        setIsLoading(true);

        const registrations = orderData.products.map((product) => {
            return {
                registrationId: product.registration.id,
                packagingId: product.packagingId,
                quantity: product.quantity,
            };
        });

        const res = await saveOrder({
            registrations,
            billingAddressId: order.billingAddress.id,
            shippingAddressId: order.shippingAddress.id,
            shipping: order.shipping,
            contact: order.contact,
            specialRequest: order.specialRequest,
            careCoinsUsed: order.careCoinsToUse,
            certificates: order.certificates,
        });

        if (res.success) {
            store.dispatch(OrderSlice.actions.resetOrderData());
            history.push("/order/confirmation");
        }
        setIsLoading(false);
    };

    const Columns = ["Product", "Shipping to", "Per unit price", "Quantity", "Total"];

    return (
        <div id="order-validation">
            <OrderStepper currentStep={4} />
            <h1>Validation</h1>
            <div className="content">
                <div className="products">
                    <table>
                        <thead>
                            <tr>
                                {Columns.map((column, index) => (
                                    <th key={index}>{column}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {orderData.products.map((product) => (
                                <Fragment key={product.id}>
                                    <tr>
                                        <td data-label={Columns[0]}>
                                            <div className="product-block">
                                                <div className="image-wrapper">
                                                    <img
                                                        className="image"
                                                        src={getFile(product.image)}
                                                        alt="Product"
                                                    />
                                                </div>
                                                <div className="title">
                                                    <div className="name">{product.name}</div>
                                                    <div className="reference">
                                                        {product.reference}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td data-label={Columns[1]}>
                                            {product.registration.country.name}
                                        </td>
                                        <td data-label={Columns[2]}>
                                            {formatPrice(product.effectivePrice)}
                                        </td>
                                        <td data-label={Columns[3]}>
                                            {formatNumber(product.quantity, true)}
                                        </td>
                                        <td data-label={Columns[4]}>
                                            {formatPrice(product.totalEffectivePrice)}
                                        </td>
                                    </tr>
                                </Fragment>
                            ))}
                            {order.cartBonus
                                .filter(
                                    (cartBonusProduct) =>
                                        cartBonusProduct.sectionId === orderData.sectionId
                                )
                                .map((cartBonusProduct, i) => (
                                    <CartBonusItem
                                        key={i}
                                        cartBonusProduct={cartBonusProduct}
                                        countryName={orderData.country?.name}
                                    />
                                ))}

                            <tr>
                                <td className="certificates" colSpan={5}>
                                    <div className="content">
                                        <div className="label">Certificates:</div>
                                        <div className="list">{order.certificates}</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="validation">
                        <div className="totals">
                            <div className="row subtotal">
                                <div className="label">Sub-total (excl. VAT)</div>
                                <div className="value">
                                    {formatPrice(orderData.nonPromoCodeDiscountedTotal)}
                                </div>
                            </div>

                            {order.careCoinsToUse ? (
                                <>
                                    <div className="row subtotal">
                                        <div className="label">CareCoins to use</div>
                                        <div className="value">
                                            {formatNumber(order.careCoinsToUse, true)}
                                        </div>
                                    </div>
                                    <div className="row subtotal">
                                        <div className="label">CareCoins discount</div>
                                        <div className="value">
                                            - {formatPrice(orderData.careCoinsDiscount)}
                                        </div>
                                    </div>
                                    <div className="row subtotal">
                                        <div className="label">CareCoins remaining after order</div>
                                        <div className="value">
                                            {formatNumber(orderData.careCoinsAfterOrder, true)}
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            <div className="row total">
                                <div className="label">Total (excl. VAT)</div>
                                <div className="value">{formatPrice(orderData.total)}*</div>
                            </div>
                            <div className="buttons">
                                <Button
                                    text="Use my CareCoins"
                                    onClick={() => setShowCareCoinsModal(true)}
                                    color="transparent"
                                    hasBorder
                                />
                                <Button
                                    text="Request a Freight quote"
                                    onClick={goToNext}
                                    color="primary"
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="info">
                                *Shipping isn’t included in this total price.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="summary">
                    {order.specialRequest ? (
                        <div className="block">
                            <div className="title">Special request</div>
                            <div className="content">Regarding: {order.specialRequest}</div>
                        </div>
                    ) : null}
                    <Address title="Shipping address" address={order.shippingAddress} />
                    {order.contact && (order.contact.name || order.contact.phone) ? (
                        <div className="block">
                            <div className="title">Contact for delivery</div>
                            <div className="content">
                                {order.contact.name ? <div>{order.contact.name}</div> : null}
                                {order.contact.phone ? <div>{order.contact.phone}</div> : null}
                            </div>
                        </div>
                    ) : null}
                    <Address title="Billing address" address={order.billingAddress} />
                    <div className="block">
                        <div className="title">Shipping method</div>
                        <div className="content">
                            {`${order.shipping.method}${order.shipping.transportMode}`}
                        </div>
                    </div>
                </div>
            </div>
            <CareCoinsModal
                visible={showCareCoinsModal}
                onValidate={(x) => setCareCoinsToUse(x)}
                onClose={() => setShowCareCoinsModal(false)}
                orderTotal={orderData.total}
                nonCareCoinsDiscountedOrderTotal={orderData.nonCareCoinsDiscountedTotal}
                availableCareCoins={loyaltyData.careCoinsBalance}
                careCoinsToUse={order.careCoinsToUse}
                careCoinsDiscount={orderData.careCoinsDiscount}
            />
        </div>
    );
};
