import React, { useEffect, useState } from "react";
import { useOutsideAlerter } from "../../pages/user/Documents";
import Colors from "../../themes/colors.theme";
import Caret from "../icons/Caret";

import "./dropdownCheckbox.scss";

type DropdownCheckBoxItemValue = string | number;

type DropdownCheckboxItem = {
    value: DropdownCheckBoxItemValue;
    label: string;
};

type DropdownCheckboxProps = {
    title?: string;
    tooltip?: string;
    label: string;
    items: DropdownCheckboxItem[];
    value?: DropdownCheckBoxItemValue[];
    onChange: (value: DropdownCheckBoxItemValue[]) => void;
    color?: string;
};

export default (props: DropdownCheckboxProps) => {
    const [showOptions, setShowOptions] = useState(false);
    const [selectedItems, setSelectedItems] = useState(props.value || []);
    const wrapperRef = React.useRef(null);
    useOutsideAlerter(wrapperRef, () => setShowOptions(false));

    const addItem = (item) => {
        let newValue = [...selectedItems];
        if (selectedItems && selectedItems.includes(item.value)) {
            newValue = newValue.filter((x) => x != item.value);
        } else {
            newValue.push(item.value);
        }
        setSelectedItems(newValue);
        props.onChange(newValue);
    };

    useEffect(() => {
        setSelectedItems(props.value);
    }, [props.value]);

    const color = props.color ? Colors[props.color] : Colors.dark;

    return (
        <div className="dropdown-checkbox" ref={wrapperRef}>
            <div
                className="input"
                onClick={() => setShowOptions((prev) => !prev)}
                onBlur={() => setShowOptions(false)}
            >
                {props.title ? (
                    <div
                        className="title"
                        style={{
                            color,
                        }}
                    >
                        {props.title}
                    </div>
                ) : null}
                <div
                    className="label"
                    style={{
                        color,
                    }}
                >
                    {props.label}

                    <Caret type="down" color={props.color} size={12} />
                </div>
            </div>
            {showOptions &&
                (props.items.length > 0 ? (
                    <div className="items">
                        {props.items?.map((item, index) => (
                            <label className={`item ${props.color || ""}`} key={index}>
                                <div className="label">{item.label}</div>

                                <input
                                    type="checkbox"
                                    checked={selectedItems.includes(item.value)}
                                    onChange={() => addItem(item)}
                                />
                            </label>
                        ))}
                    </div>
                ) : (
                    <div className="items">
                        <label className={`item ${props.color || ""}`}>
                            <div className="label">No {props.title}</div>
                        </label>
                    </div>
                ))}
        </div>
    );
};
