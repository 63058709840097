import React, { useState } from "react";
import "./numberInput.scss";

type NumberInputProps = {
    onChange: (newValue: number) => void;
    onBlur?: () => void;
    label?: string;
    placeholder?: string;
    required?: boolean;
    min?: number;
    max?: number;
    step?: number;
    value?: number;
    color?: string;
    errorMsg?: string;
    successMsg?: string;
};

const NumberInput = (props: NumberInputProps) => {
    const [internalError, setInternalError] = useState(null);

    const onChange = (e) => {
        setInternalError(null);
        const text = e.target.value;
        if (!text) {
            props.onChange(0);
        } else {
            let value = parseInt(text);
            if (Number.isFinite(value)) {
                if (props.min !== undefined) {
                    value = Math.max(value, props.min);
                }
                if (props.max !== undefined) {
                    value = Math.min(value, props.max);
                }
                props.onChange(value);
            } else {
                setInternalError("Please enter a valid number");
            }
        }
    };

    return (
        <div className={`number-input ${props.color || ""}`}>
            {props.label || props.required ? (
                <div className="label-wrapper">
                    <div className="label">
                        {props.label || ""}
                        {props.required ? "*" : ""}
                    </div>
                </div>
            ) : null}
            <input
                className="input"
                type="number"
                min={props.min}
                max={props.max}
                step={props.step}
                value={props.value ?? ""}
                onChange={onChange}
                onBlur={props.onBlur}
                placeholder={props?.placeholder}
            />
            {internalError && <div className="error-text">{internalError}</div>}
            {props.errorMsg && <div className="error-text">{props.errorMsg}</div>}
            {props.successMsg && <div className="success-text">{props.successMsg}</div>}
        </div>
    );
};

export default NumberInput;
