import { sumByKey } from "../selectors/utils.selectors";

export const CareCoinValue = 0.1; // $

export const computeOrderPromoCodeDiscountOnTotal = (
    nonPromoCodeDiscountedTotal,
    promoCode,
    includeShipping?,
    freight?
) => {
    let discountOnTotal = null;
    let effectiveDiscountOnTotal = 0;

    if (promoCode && promoCode.products.length === 0) {
        if (promoCode.type === "DISCOUNT" && promoCode.discount) {
            discountOnTotal = promoCode.discount;
            effectiveDiscountOnTotal =
                Math.round(nonPromoCodeDiscountedTotal * discountOnTotal) / 100;
        } else if (promoCode.type === "CASH_BACK" && promoCode.cashBackAmount) {
            effectiveDiscountOnTotal = promoCode.cashBackAmount;
            discountOnTotal = (effectiveDiscountOnTotal / nonPromoCodeDiscountedTotal) * 100;
        } else if (
            includeShipping &&
            promoCode.type === "SHIPPING_DISCOUNT" &&
            promoCode.shippingDiscount
        ) {
            discountOnTotal = null;
            effectiveDiscountOnTotal = Math.round(freight * promoCode.shippingDiscount) / 100;
        }
    }

    return { discountOnTotal, effectiveDiscountOnTotal };
};

export const applyCareCoinsUseOnOrderTotal = (total: number, careCoinsUsed: number) =>
    Math.round((total - careCoinsUsed * CareCoinValue) * 100) / 100;

// discount : [0;100] (%)
export const computeOrderTotal = (
    products: { totalEffectivePrice: number }[],
    freight: number,
    discount?: number,
    careCoinsUsed?: number,
    includeShipping?: boolean,
    shippingDiscount?: number
) => {
    let total = sumByKey(products, "totalEffectivePrice");

    if (discount) {
        const effectiveDiscount = Math.round(total * discount) / 100;
        total -= effectiveDiscount;
    }

    if (includeShipping) {
        total += Math.round(freight * (1 - (shippingDiscount ?? 0) / 100));
    }


    return careCoinsUsed !== undefined
        ? applyCareCoinsUseOnOrderTotal(total, careCoinsUsed)
        : total;
};
