import React from "react";
import Wave from "../../components/style/Wave";

import "./socialCard.scss";

const SocialCard = () => {
	return (
		<div className="social-card">
			<div className="header">
				<div className="background"></div>
				<Wave color="white" />
			</div>
			<div className="social">
				<span>Follow us</span>
				<div className="links">
					<a href="https://www.facebook.com/DKTWomanCare" target="_blank" rel="noopener noreferrer">
						<img src="/assets/icons/social/facebook.svg" alt="Facebook" />
					</a>
					<a href="https://twitter.com/DKTWomanCare" target="_blank" rel="noopener noreferrer">
						<img src="/assets/icons/social/twitter.svg" alt="Twitter" />
					</a>
					<a
						href="https://www.linkedin.com/company/dktwomancareglobal"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src="/assets/icons/social/linkedin.svg" alt="LinkedIn" />
					</a>
					<a
						href="https://www.youtube.com/channel/UCL9BfymRSvKxQW4yiF7tFZg"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src="/assets/icons/social/youtube.svg" alt="YouTube" />
					</a>
					<a href="https://www.instagram.com/dktwomancare/" target="_blank" rel="noopener noreferrer">
						<img src="/assets/icons/social/instagram.svg" alt="Instagram" />
					</a>
				</div>
			</div>
		</div>
	)
}

export default SocialCard;