import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BackLink from "../../components/BackLink";
import CaretLink from "../../components/CaretLink";
import Button from "../../components/forms/Button";
import DropdownCheckbox from "../../components/forms/DropdownCheckbox";
import DropdownInput from "../../components/forms/DropdownInput";
import LinkButton from "../../components/LinkButton";
import Pager from "../../components/Pager";
import Item from "../../components/resources/Item";
import ResourceSelectors from "../../selectors/resource.selectors";
import { getBestPractices } from "../../services/resources.service";
import { store } from "../../store/configure.store";
import ResourceSlice from "../../store/slices/resource.slice";
import { usePager } from "../../utils/layout.utils";
import { getFile } from "../../utils/s3.utils";
import "./resourceList.scss";

export default () => {
    const allItems = useSelector(ResourceSelectors.getBestPractices());
    const highlightedItem = allItems?.find((x) => x.highlight);
    const [filteredItems, setFilteredItems] = useState(allItems || []);
    const [subjects, setSubjects] = useState([]);
    const [searchFilter, setSearchFilter] = useState(null);

    const [subjectList, setSubjectList] = useState(null);
    const [sortingOrder, setSortingOrder] = useState("mostRecent");

    const { page, setPage, pageCount, displayedItems } = usePager(filteredItems, 18);

    const applyFilters = (list) => {
        if (!list) {
            return [];
        }
        let newList = [...list];

        if (subjects.length > 0) {
            newList = newList.filter((x) => subjects.includes(x.resource?.subject));
        }

        if (searchFilter) {
            newList = newList.filter(
                (x) =>
                    x.title?.includes(searchFilter) ||
                    x.excerpt?.includes(searchFilter) ||
                    x.introduction?.includes(searchFilter) ||
                    x.content?.includes(searchFilter)
            );
        }

        switch (sortingOrder) {
            case "mostRecent":
                newList = newList.sort((a, b) => (a.creationDate > b.creationDate ? 1 : -1));
                break;
            case "lessRecent":
                newList = newList.sort((a, b) => (a.creationDate < b.creationDate ? 1 : -1));
                break;
        }

        return newList;
    };

    const resetFilters = () => {
        setSubjects([]);
        setSearchFilter(null);
        setSortingOrder("mostRecent");
    };

    useEffect(() => {
        (async () => {
            const res = await getBestPractices();
            store.dispatch(ResourceSlice.actions.setBestPractices(res.data));
            setFilteredItems(res.data);
            const subjects = res.data.reduce((acc, obj) => {
                const sub = obj.resource.subject;
                if (sub && !acc[sub]) {
                    acc[sub] = { value: sub, label: sub };
                }
                return acc;
            }, {});
            setSubjectList(Object.values(subjects));
        })();
    }, []);

    useEffect(() => {
        const newItems = applyFilters(allItems);
        setFilteredItems(newItems);
    }, [subjects, searchFilter, sortingOrder]);

    return (
        <div className="profile resourceList">
            <BackLink to="/resources" label="Back to Resources" />
            <div className="title-wrapper">
                <h1>Best practices</h1>
            </div>
            <div className="links">
                <CaretLink to="/resources/videos-and-tutorials" label="Videos & tutorials" />
                <CaretLink to="/resources/marketing-materials" label="marketing materials" />
                <CaretLink to="/resources/womancare-academy" label="Womancare academy" />
                <CaretLink to="/resources/pos-and-pop" label="POS & POP" />
                <CaretLink to="/resources/market-data" label="Studies and market data" />
            </div>
            {highlightedItem ? (
                <div className="highlight">
                    <img
                        className="image"
                        src={getFile(highlightedItem.thumbnail)}
                        alt="Highlighted resource"
                    />
                    <div className="text-content">
                        <div className="title">{highlightedItem.title}</div>
                        <div className="excerpt">
                            {highlightedItem.excerpt || highlightedItem.introduction}
                        </div>
                        <Button text="Read more" />
                    </div>
                </div>
            ) : null}
            <div className="content">
                <div className="left-column">
                    <div className="sorter">
                        <p>Sort by :</p>
                        <DropdownInput
                            label=""
                            items={[
                                { value: "mostRecent", label: "Most recent" },
                                { value: "lessRecent", label: "Less recent" },
                            ]}
                            value={"mostRecent"}
                            onChange={(x) => setSortingOrder(String(x))}
                            color="primary"
                        />
                    </div>
                    <div className="filters">
                        <p>Filter by :</p>
                        <DropdownCheckbox
                            title="Category"
                            label=""
                            items={subjectList}
                            value={subjects}
                            onChange={(x) => setSubjects(x)}
                            color="primary"
                        />
                        <LinkButton label="reset filters" onClick={resetFilters} />
                    </div>
                </div>
                <div className="list">
                    <div className="items">
                        {displayedItems.map((item, index) => (
                            <Item
                                key={index}
                                item={item}
                                onClickLink={`/resources/best-practices/${item.id}`}
                            />
                        ))}
                    </div>
                    <Pager
                        pageCount={pageCount}
                        currentPage={page}
                        onSetPage={(x) => setPage(x)}
                        link="#"
                    />
                </div>
            </div>
        </div>
    );
};
