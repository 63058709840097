import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import "./documents.scss";
import BackLink from "../../components/BackLink";
import CaretLink from "../../components/CaretLink";
import IconLink from "../../components/IconLink";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import LinkButton from "../../components/LinkButton";
import DocumentsSelectors from "../../selectors/documents.selectors";
import Download from "../../components/icons/Download";
import Button from "../../components/forms/Button";
import DropdownInput from "../../components/forms/DropdownInput";
import ProfileSlice from "../../store/slices/profile.slice";
import Pager from "../../components/Pager";
import { usePager } from "../../utils/layout.utils";
import Select from "../../components/forms/Select";
import TextInput from "../../components/forms/TextInput";
import FileInput from "../../components/forms/FileInput";
import { setError } from "../../utils/app.utils";
import { postDocument, putDocument } from "../../services/documents.service";

const Cols = ["Upload date", "Download", "Name", "Validity date", "Type", "Action"];

export const useOutsideAlerter = (
    ref: any,
    setShowSlider: React.Dispatch<React.SetStateAction<boolean>>
) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowSlider(false);
            }
        };
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
};

export default () => {
    const { documents, types, years } = useSelector((state: any) =>
        DocumentsSelectors.documentsData(state)
    );
    const filters = useSelector((state: any) => state.profile.filters.documents);

    const setFilter = (key, value) => {
        store.dispatch(ProfileSlice.actions.setDocumentsFilter({ key, value }));
    };

    const [showFilters, setShowFilters] = useState(false);

    const {
        page,
        setPage,
        pageCount,
        displayedItems: displayedDocuments,
    } = usePager(documents, 18);

    const [showAddForm, setShowAddForm] = useState(false);

    const [documentType, setDocumentType] = useState(null);
    const [documentName, setDocumentName] = useState("");
    const [documentFile, setDocumentFile] = useState(null);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setShowFilters(false));

    const [documentToEditId, setDocumentToEditId] = useState(null);

    const documentToEdit = documents.find((x) => x.id === documentToEditId);

    useEffect(() => {
        if (documentToEdit) {
            setDocumentType(
                { "Due diligence": "DUE_DILIGENCE", "Business license": "BUSINESS_LICENSE" }[
                    documentToEdit.type
                ]
            );
            setDocumentName(documentToEdit.name);
            setDocumentFile(documentToEdit.file);
        } else {
            setDocumentType(null);
            setDocumentName("");
            setDocumentFile(null);
        }
    }, [documentToEdit]);

    const addDocument = async () => {
        if (!documentType || !documentName || !documentFile || documentFile.length === 0) {
            setError("Please fill in your document type, name and file");
            return;
        }

        const res = await postDocument({
            type: documentType,
            name: documentName,
            file: documentFile,
        });

        if (res.success) {
            store.dispatch(DistributorSlice.actions.addDocument(res.document));
        } else {
            setError("Error while uploading document");
        }

        setShowAddForm(false);
    };

    const updateDocument = async () => {
        if (!documentType || !documentName) {
            setError("Please fill in document type and name");
            return;
        }

        const data = {
            id: documentToEditId,
            type: documentType,
            name: documentName,
            file: documentFile,
        };

        const res = await putDocument(data);

        if (res.success) {
            store.dispatch(DistributorSlice.actions.setDocument(data));
        } else {
            setError("Error while updating document");
        }

        setShowAddForm(false);
    };

    return (
        <div id="documents" className="profile">
            <BackLink to="/profile/me" label="Back to my data" />
            <h1>My documents</h1>
            <div className="links">
                <CaretLink to="/profile/forecasts" label="Forecasts" />
                <CaretLink to="/profile/labels" label="Label and artwork" />
                <CaretLink to="/profile/agreements" label="My commercial agreements" />
            </div>
            <div className="filters" ref={wrapperRef}>
                <Button
                    text="Filter by"
                    onClick={() => setShowFilters((prev) => !prev)}
                    color="dark"
                    icon="caret"
                />
                {showFilters ? (
                    <div className="inputs">
                        <DropdownInput
                            title="Type"
                            label="Select type"
                            items={types.map((x) => ({ value: x, label: x }))}
                            value={filters.type}
                            onChange={(x) => setFilter("type", x)}
                            allowNull
                            itemsClass="semi-round"
                        />
                        <DropdownInput
                            title="Year"
                            label="Select year"
                            items={years.map((x) => ({ value: x, label: x }))}
                            value={filters.year}
                            onChange={(x) => setFilter("year", x)}
                            allowNull
                            itemsClass="semi-round"
                        />
                    </div>
                ) : null}
            </div>
            <table>
                <thead>
                    <tr>
                        {Cols.map((col, index) => (
                            <th key={index}>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {displayedDocuments.map((doc) => (
                        <tr key={doc.id}>
                            <td data-label={Cols[0]}>{doc.uploadDate}</td>
                            <td data-label={Cols[1]}>
                                <Download color="primary" url={doc.download} />
                            </td>
                            <td data-label={Cols[2]}>{doc.name}</td>
                            <td data-label={Cols[3]}>{doc.validityDate}</td>
                            <td data-label={Cols[4]}>{doc.type}</td>
                            <td data-label={Cols[5]}>
                                <div className="actions">
                                    {["Due diligence", "Business license"].includes(doc.type) ? (
                                        <LinkButton
                                            color="primary"
                                            label="Update"
                                            to="#edit"
                                            onClick={() => {
                                                setShowAddForm(true);
                                                setDocumentToEditId(doc.id);
                                            }}
                                        />
                                    ) : null}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pager
                pageCount={pageCount}
                currentPage={page}
                onSetPage={(x) => setPage(x)}
                link="#documents"
            />
            <div id="edit" className="edit">
                <IconLink
                    icon="plus"
                    label="Add new document"
                    onClick={() => setShowAddForm(true)}
                    color="secondary"
                />
                {showAddForm && (
                    <div className="form">
                        <div className="row">
                            <Select
                                title="Document type"
                                label="Select"
                                options={[
                                    { value: "DUE_DILIGENCE", label: "Due diligence" },
                                    { value: "BUSINESS_LICENSE", label: "Business license" },
                                ]}
                                value={documentType}
                                onChange={(x) => setDocumentType(x)}
                                color="secondary"
                            />
                            <TextInput
                                label="Document name"
                                placeholder="Type here"
                                value={documentName}
                                onChange={(x) => setDocumentName(x)}
                                color="secondary"
                            />
                        </div>
                        <div className="buttons">
                            <FileInput
                                required
                                onChange={(x) => {
                                    setDocumentFile(x && x[0] ? x[0].s3Url : null);
                                }}
                                uploadToS3
                                placeholder={"Upload document"}
                                color="dark"
                                buttonHasBorder
                            />
                            <div className="row">
                                <Button
                                    text="Validate"
                                    color="dark"
                                    onClick={documentToEditId ? updateDocument : addDocument}
                                />
                                <Button
                                    text="Cancel"
                                    color="transparent"
                                    hasBorder
                                    onClick={() => {
                                        setShowAddForm(false);
                                        setDocumentToEditId(null);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
