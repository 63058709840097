import React, { useState } from "react";

import "./addressDropdown.scss";
import Close from "../icons/Close";
import Caret from "../icons/Caret";
import AddressForm from "../forms/AddressForm";
import { deleteAddress } from "../../services/addresses.service";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import ConfirmationModal from "../ConfirmationModal";

export default ({ address }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const onDelete = async () => {
        const res = await deleteAddress(address.id);
        if (res.success) {
            if (address.type === "SHIPPING") {
                store.dispatch(
                    DistributorSlice.actions.deleteShippingAddress({
                        id: address.id,
                    })
                );
            } else if (address.type === "BILLING") {
                store.dispatch(
                    DistributorSlice.actions.deleteBillingAddress({
                        id: address.id,
                    })
                );
            }
        }
    };

    return (
        <>
            {isOpen ? (
                <AddressForm
                    address={address}
                    onClose={() => setIsOpen(false)}
                    onDelete={onDelete}
                    onAskDelete={() => setShowDeleteConfirmation(true)}
                />
            ) : (
                <div className="address-dropdown">
                    <div className="title">
                        <div className="name">{address.name}</div>
                        <Close color="white" onClick={() => setShowDeleteConfirmation(true)} />
                    </div>
                    <div className="right" onClick={() => setIsOpen(true)}>
                        {address.isPrimary ? (
                            <div className="primary-label">Primary address</div>
                        ) : null}
                        <Caret type="down" color="primary" />
                    </div>
                </div>
            )}
            {showDeleteConfirmation && (
                <ConfirmationModal
                    message="Delete this address ?"
                    onValidate={() => {
                        onDelete();
                        setShowDeleteConfirmation(false);
                    }}
                    onCancel={() => setShowDeleteConfirmation(false)}
                />
            )}
        </>
    );
};
