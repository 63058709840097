import { createSelector } from "reselect";
import { formatDatetime } from "../utils/dates.utils";
import { emphasizeSubstring } from "../utils/string.utils";

const getNotifications = (state: any) => state.distributor.notifications || [];

const ResourceTypeSlugs = {
    VIDEOS_AND_TUTORIALS: "videos-and-tutorials",
    MARKETING_MATERIALS: "marketing-materials",
    WC_ACADEMY: "womancare-academy",
    POS_AND_POP: "pos-and-pop",
    MARKET_DATA: "market-data",
    BEST_PRACTICES: "best-practices",
};

const NotificationTypes = {
    NEW_QUOTE: {
        title: "New quote",
        content: () =>
            "Your sales representative sent you the quote. You have 30 days to confirm or modify your order.",
        link: ({ orderId }) => `/profile/order/${orderId}`,
    },
    NEW_INVOICE: {
        title: "New invoice",
        content: () => "Your sales representative sent you the invoice for you order.",
        link: ({ orderId }) => `/profile/order/${orderId}`,
    },
    ORDER_SHIPPED: {
        title: "Order shipped",
        content: () =>
            emphasizeSubstring(
                "Your order has been shipped. You can track it via the 'My orders' section.",
                "My orders"
            ),
        link: () => "/profile/orders",
    },
    LOYALTY_PROGRAM: {
        title: "Loyalty program",
        content: ({ data }) =>
            emphasizeSubstring(
                `Congratulations! You just received the ${data} status of our loyalty program.`,
                data
            ),
        link: () => "/my-womancare/my-loyalty-program",
    },
    PROMO_CODE: {
        title: "Promo code",
        content: () =>
            emphasizeSubstring(
                "Congratulations! You just earned a promo code. You can find it on your 'loyalty program' section.",
                "loyalty program"
            ),
        link: () => "/my-womancare/my-loyalty-program",
    },
    FORECAST_REMINDER: {
        title: "Remember to place your order !",
        content: ({ data }) =>
            emphasizeSubstring(
                `Place your order of ${data} now to receive it in time ! Click to check your forecasts.`,
                data
            ),
        link: () => "/profile/forecasts",
    },
    NEW_DOCUMENT_AVAILABLE: {
        title: "New document available",
        content: () => "A new document has been uploaded in your account.",
        link: () => "/profile/documents",
    },
    NEW_RESOURCE_AVAILABLE: {
        title: "New resource available",
        content: () => "A new resource has been uploaded. You can consult it here.",
        link: ({ resourceType }) => `/resources/${ResourceTypeSlugs[resourceType]}`,
    },
    FINALIZE_ACCOUNT: {
        title: "Finalize account",
        content: () => "Your account isn't activated yet. Please fill in the necessary documents.",
        link: () => "/profile/documents",
    },
};

const formatNotifications = (notifications) =>
    notifications.map((notification) => {
        const formattingData = NotificationTypes[notification.type];
        const orderId = notification.order ? notification.order.id : null;
        return {
            ...notification,
            date: formatDatetime(notification.createDate),
            title: formattingData.title,
            content: formattingData.content(notification),
            link: formattingData.link({ ...notification, orderId }),
        };
    });

const NotificationsSelectors = {
    all: createSelector(getNotifications, (notifications) => formatNotifications(notifications)),

    featured: createSelector(getNotifications, (notifications) =>
        formatNotifications(notifications).slice(0, 3)
    ),

    count: createSelector(getNotifications, (notifications) =>
        notifications && notifications.length
            ? notifications.filter((notification) => !notification.read).length
            : 0
    ),
};

export default NotificationsSelectors;
