import { get, post } from "./utils.service";

export const getMe = async () => {
    try {
        const res = await get(`/users/me`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const upsertUser = async (data) => {
    const res = await post(`/users/upsert`, data);
    return await res.json();
};

export const setUserData = async (data) => {
    try {
        const res = await post(`/users/set-data`, data);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const setSecurityData = async (data) => {
    try {
        const res = await post(`/users/set-security`, data);
        return await res.json();
    } catch (e) {
        return null;
    }
};
