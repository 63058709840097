import React, { useEffect, useState } from "react";
import { useOutsideAlerter } from "../../pages/user/Documents";
import Colors from "../../themes/colors.theme";
import Caret from "../icons/Caret";

import "./dropdownInput.scss";

type DropdownInputItemValue = string | number;

type DropdownInputItem = {
    value: DropdownInputItemValue;
    label: string;
};

type DropdownInputProps = {
    title?: string;
    tooltip?: string;
    label: string;
    items: DropdownInputItem[];
    allowNull?: boolean;
    value?: DropdownInputItemValue;
    onChange: (value: DropdownInputItemValue) => void;
    color?: string;
    itemsClass?: string;
};

export default (props: DropdownInputProps) => {
    const [showOptions, setShowOptions] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const wrapperRef = React.useRef(null);
    useOutsideAlerter(wrapperRef, () => setShowOptions(false));

    useEffect(() => {
        setSelectedItem(props.value ? props.items.find((x) => x.value == props.value) : null);
    }, [props.value]);

    const color = props.color ? Colors[props.color] : Colors.dark;

    return (
        <div className="dropdown-input" ref={wrapperRef}>
            <div
                className="input"
                onClick={() => setShowOptions((prev) => !prev)}
                onBlur={() => setShowOptions(false)}
            >
                {props.title ? (
                    <div
                        className="title"
                        style={{
                            color,
                        }}
                    >
                        {props.title}
                    </div>
                ) : null}
                <div
                    className="label"
                    style={{
                        color,
                    }}
                >
                    <span>{selectedItem ? selectedItem.label : props.label}</span>
                    <Caret type="down" color={props.color} size={12} />
                </div>
            </div>
            {showOptions && (
                <div className={`items ${props.itemsClass}`}>
                    {props.allowNull ? (
                        <div
                            className="item"
                            onClick={() => {
                                setSelectedItem(null);
                                props.onChange(null);
                                setShowOptions(false);
                            }}
                        >
                            Any
                        </div>
                    ) : null}
                    {props.items.map((item, index) => (
                        <div
                            key={index}
                            className="item"
                            onClick={() => {
                                setSelectedItem(item);
                                props.onChange(item.value);
                                setShowOptions(false);
                            }}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
