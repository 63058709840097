import React from "react";

import Colors from "../../themes/colors.theme";

export default ({ color }) => {
    const colorCode = Colors[color || "secondary"];

    return (
        <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
            <g
                id="01.-Atoms-/-02.-Icons-/-Alert"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Group"
                    transform="translate(7.000000, 8.000000)"
                    fill={colorCode}
                    fillRule="nonzero"
                >
                    <path
                        d="M13.0008917,0 C14.4268561,0 15.7205085,0.89372361 16.6420698,2.5171402 L16.6420698,2.5171402 L25.2290562,17.6446003 C26.146573,19.2620053 26.2488402,20.8367566 25.5159246,22.0768625 C24.7838756,23.3169684 23.3498223,24 21.4763663,24 L21.4763663,24 L4.52426154,24 C2.65109441,24 1.21588554,23.3169684 0.48383657,22.0768625 C-0.248501289,20.8353253 -0.146811772,19.2620053 0.77070501,17.6446003 L0.77070501,17.6446003 L9.35855808,2.5171402 C10.2801193,0.89372361 11.5737718,0 13.0008917,0 Z M13.0008917,1.98096329 C12.3370209,1.98096329 11.6448389,2.53030845 11.0999911,3.48815573 L11.0999911,3.48815573 L2.51329356,18.6153296 C1.9667124,19.5794747 1.8552006,20.4766335 2.20966946,21.0766478 C2.56298275,21.6746583 3.40741019,22.0187504 4.52426154,22.0187504 L4.52426154,22.0187504 L21.4763663,22.0187504 C22.5935065,22.0187504 23.4367784,21.6763759 23.7912473,21.0766478 C24.1442717,20.4766335 24.0330488,19.5794747 23.4856009,18.6153296 L23.4856009,18.6153296 L14.8994812,3.48815573 C14.355789,2.53030845 13.6638958,1.98096329 13.0008917,1.98096329 Z M13.4993417,17 C14.3281764,17 15,17.6714552 15,18.4988486 C15,19.3285448 14.3281764,20 13.4993417,20 C12.6718236,20 12,19.3282158 12,18.4988486 C12,17.6714552 12.6718236,17 13.4993417,17 Z M13.4993417,8 C14.3281764,8 15,8.55307906 15,9.2346047 C15,9.27118759 14.9917709,9.30424768 14.9878209,9.33893368 L14.9878209,9.33893368 L14.9957209,9.33893368 L14.39763,15.3203712 C14.3538512,15.7000203 13.9713627,16 13.4996708,16 C13.0292956,16 12.6464779,15.6994784 12.6076366,15.3203712 L12.6076366,15.3203712 L12.6030283,15.3203712 L12.0046083,9.33893368 C12.0088874,9.30424768 12,9.27118759 12,9.2346047 C12,8.55307906 12.6718236,8 13.4993417,8 Z"
                        id="Combined-Shape"
                    ></path>
                </g>
            </g>
        </svg>
    );
};
