import React, { useState } from "react";
import { useSelector } from "react-redux";

import "./labels.scss";
import BackLink from "../../components/BackLink";
import CaretLink from "../../components/CaretLink";
import LinkButton from "../../components/LinkButton";
import Download from "../../components/icons/Download";
import DistributorSelectors from "../../selectors/distributor.selectors";
import Modal from "../../components/Modal";
import Button from "../../components/forms/Button";
import { reportLabelIssue } from "../../services/labels.service";
import TextArea from "../../components/forms/TextArea";
import { setError, setSuccess } from "../../utils/app.utils";

const Cols = ["Date", "Download", "Label", "Region", "Référence n°", "Action"];

export default () => {
    const labels = useSelector((state: any) => DistributorSelectors.labelsData(state));

    const [labelToReport, setLabelToReport] = useState(null);

    const [reportDetail, setReportDetail] = useState("");

    const reportIssue = async () => {
        const res = await reportLabelIssue({
            labelId: labelToReport.id,
            region: labelToReport.region,
            detail: reportDetail,
        });

        setLabelToReport(null);

        if (res.success) {
            setSuccess("Issue successfully reported");
        } else {
            setError("Error while reporting issue");
        }
    };

    return (
        <div id="labels" className="profile">
            <BackLink to="/profile/me" label="Back to my data" />
            <h1>Label and artwork</h1>
            <div className="links">
                <CaretLink to="/profile/forecasts" label="Forecasts" />
                <CaretLink to="/profile/documents" label="My documents" />
                <CaretLink to="/profile/agreements" label="My commercial agreements" />
            </div>
            <table>
                <thead>
                    <tr>
                        {Cols.map((col, index) => (
                            <th key={index}>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {labels.map((label, index) => (
                        <tr key={index}>
                            <td data-label={Cols[0]}>{label.date}</td>
                            <td data-label={Cols[1]}>
                                <Download color="primary" url={label.download} />
                            </td>
                            <td data-label={Cols[2]}>{label.name}</td>
                            <td data-label={Cols[3]}>{label.region}</td>
                            <td data-label={Cols[4]}>{label.code}</td>
                            <td data-label={Cols[5]}>
                                <LinkButton
                                    color="primary"
                                    label="Report an issue"
                                    onClick={() => setLabelToReport(label)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {labelToReport ? (
                <Modal
                    className="report-label-issue"
                    onClose={() => setLabelToReport(null)}
                    closeOnBackgroundClick
                >
                    <p>Thank you for notifying us. Please tell us more about your issue.</p>
                    <p>Warning: your order is on hold until your Label changes are validated</p>
                    <TextArea value={reportDetail} onChange={(x) => setReportDetail(x)} />
                    <div className="buttons">
                        <Button text="Submit" onClick={reportIssue} />
                        <Button
                            text="Cancel"
                            onClick={() => setLabelToReport(null)}
                            color="white"
                            hasBorder
                        />
                    </div>
                </Modal>
            ) : null}
        </div>
    );
};
