import React, { useState } from "react";

import "./security.scss";
import Button from "../../components/forms/Button";
import TextInput from "../../components/forms/TextInput";
import { store } from "../../store/configure.store";
import CaretLink from "../../components/CaretLink";
import BackLink from "../../components/BackLink";
import PasswordInput from "../../components/forms/PasswordInput";
import { useLogin } from "../../utils/auth.utils";
import MeSlice from "../../store/slices/me.slice";
import { setSecurityData } from "../../services/users.service";
import { validatePassword } from "../../utils/regex.util";
import { setSuccess } from "../../utils/app.utils";

export default () => {
    const { me } = useLogin();

    const [state, setState] = useState({
        username: me.username,
        currentPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
    });

    const [errorMsg, setErrorMsg] = useState("");

    const set = (field, value) => setState((prev) => ({ ...prev, [field]: value }));

    const submit = async () => {
        setErrorMsg("");

        if (state.username === me.username && !state.newPassword) {
            return;
        }

        if (!state.username) {
            setErrorMsg("Missing mandatory fields");
            return;
        }

        if (state.newPassword && !validatePassword(state.newPassword)) {
            setErrorMsg(
                "Password must contain at least 8 signs, including one number, one capital, one lowercase and one special sign"
            );
            return;
        }

        if (state.newPassword && state.newPassword !== state.newPasswordConfirmation) {
            setErrorMsg("Please check your new password confirmation");
            return;
        }

        const res = await setSecurityData(state);

        if (res.error) {
            setErrorMsg(res.error);
            return;
        }

        store.dispatch(MeSlice.actions.setUsername(state.username));

        setSuccess("Account successfully updated");
    };

    return (
        <div id="security" className="profile">
            <BackLink to="/profile/me" label="Back to my profile" />
            <h1>Security</h1>
            <div className="links">
                <CaretLink to="/profile/company" label="My company" />
                <CaretLink to="/profile/users" label="My users" />
            </div>
            <div className="form">
                <section>
                    <div className="title">
                        <h2>Account information</h2>
                    </div>
                    <div className="inputs">
                        <TextInput
                            label="Login"
                            value={state.username}
                            onChange={(x) => set("username", x)}
                            required
                            color="dark"
                        />
                        <PasswordInput
                            label="Current password"
                            value={state.currentPassword}
                            onChange={(x) => set("currentPassword", x)}
                            required
                            color="dark"
                        />
                        <PasswordInput
                            label="New password"
                            value={state.newPassword}
                            onChange={(x) => set("newPassword", x)}
                            color="dark"
                        />
                        <PasswordInput
                            label="Confirm password"
                            value={state.newPasswordConfirmation}
                            onChange={(x) => set("newPasswordConfirmation", x)}
                            color="dark"
                        />
                    </div>
                </section>
                <div className="submit-block">
                    <Button color="white" text="Validate" onClick={submit} />
                    {errorMsg ? <div className="error">{errorMsg}</div> : null}
                </div>
                <div className="note">* Mandatory field</div>
            </div>
        </div>
    );
};
