import { get } from "./utils.service";

export const getAllEvents = async () => {
    const res = await get(`/events`);
    return await res.json();
};

export const getSomeEvents = async (skip: number, take: number) => {
    const res = await get(`/events/some/${skip}/${take}`);
    return await res.json();
};

export const countAllEvents = async () => {
    const res = await get(`/events/count`);
    return await res.json();
};

export const getEventDetails = async (id: number) => {
    const res = await get(`/events/details/${id}`);
    return await res.json();
};

export const getLatestEvents = async (take: number, excludeId: number) => {
    const res = await get(`/events/latests/${take}/${excludeId}`);
    return await res.json();
};

export const getEventsByDate = async (year: string, month: string) => {
    const res = await get(`/events/date/${year}/${month}`);
    return await res.json();
};
