import { post, put } from "./utils.service";

export const postDocument = async data => {
    try {
        const res = await post(`/documents`, data);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const putDocument = async data => {
    try {
        const res = await put(`/documents`, data);
        return await res.json();
    } catch (e) {
        return null;
    }
};
