import React from "react";
import "./play.scss";

export default (props: { color?: string; circle?: boolean; onClick?: () => void }) => (
    <div className="play" onClick={props?.onClick}>
        <svg
            width="21px"
            height="26px"
            viewBox="0 0 21 26"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Bundle-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="T18---Videos-&amp;-tutorials"
                    transform="translate(-439.000000, -570.000000)"
                >
                    <g
                        id="02.-Molecules-/-03.-Cards-/-Video"
                        transform="translate(292.000000, 429.000000)"
                    >
                        <g
                            id="02.-Molecules-/-01.-Buttons-/-Player"
                            transform="translate(104.000000, 104.000000)"
                        >
                            <ellipse
                                id="Oval"
                                fill="#8E58C0"
                                cx="50"
                                cy="49.5"
                                rx="50"
                                ry="49.5"
                            ></ellipse>
                            <g
                                id="01.-Atoms-/-02.-Icons-/-Player"
                                transform="translate(43.000000, 37.000000)"
                                fill="#FFFFFF"
                            >
                                <polygon
                                    id="Triangle"
                                    transform="translate(10.068966, 13.000000) rotate(-270.000000) translate(-10.068966, -13.000000) "
                                    points="10.0689655 3 22.137931 23 -2 23"
                                ></polygon>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
);
