import queryString from "query-string";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import BackLink from "../../components/BackLink";
import Button from "../../components/forms/Button";
import MultipleRadioInput from "../../components/forms/MultipleRadioInput";
import OrderStepper from "../../components/order/Stepper";
import DistributorSelectors from "../../selectors/distributor.selectors";
import OrderSelectors from "../../selectors/order.selectors";
import { setOrder, setOrderItems } from "../../services/orders.service";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import OrderSlice from "../../store/slices/order.slice";
import { addOrRemove } from "../../utils/arrays.utils";
import "./certificates.scss";

const AvailableCertificates = [
    "Declaration of conformity",
    "Certificate of origin (certified by Chamber of Commerce)",
    "Certificate of analysis",
    "Certificate of sterilization (only for sterile products)",
];

export default () => {
    const history = useHistory();

    const location = useLocation();
    const { orderToEdit } = queryString.parse(location.search);

    const existingOrder = useSelector(DistributorSelectors.orderById(orderToEdit));

    const { products } = useSelector((state: any) => OrderSelectors.orderProducts(state));

    const actualProducts = existingOrder ? existingOrder.products : products;

    const order = useSelector((state: any) => state.order);

    const [certificates, setCertificates] = useState(() => {
        const currentOrder = existingOrder ? existingOrder : order;
        const certificates = currentOrder.certificates?.split(", ");
        const certificateList = certificates?.filter((y) => AvailableCertificates.includes(y));
        const unknownCertificates = certificates?.filter((y) => !AvailableCertificates.includes(y));
        const customCertificate = unknownCertificates ? unknownCertificates[0] : null;
        return {
            certificates: certificateList || [],
            customCertificate: customCertificate,
        };
    });

    const toggleOrderCertificate = (certificate) => {
        setCertificates((prev) => ({
            ...prev,
            certificates: addOrRemove(prev.certificates, certificate),
        }));
    };

    const setOrderCustomCertificate = (certificate) => {
        setCertificates((prev) => ({
            ...prev,
            customCertificate: certificate,
        }));
    };

    const goToNext = async () => {
        if (existingOrder) {
            await setOrder({
                orderId: existingOrder.id,
                fields: {
                    certificates:
                        certificates.certificates.join(", ") +
                        (certificates.customCertificate
                            ? ", " + certificates.customCertificate
                            : ""),
                },
            });
            history.push("/profile/order/" + existingOrder.id + "/edit");
        } else {
            store.dispatch(
                OrderSlice.actions.setCertificates(
                    certificates.certificates.join(", ") +
                        (certificates.customCertificate
                            ? ", " + certificates.customCertificate
                            : "")
                )
            );
            history.push("/order/validation");
        }
    };

    if (existingOrder && !existingOrder.statusData.editable) {
        return <Redirect to={"/profile/order/" + existingOrder.id} />;
    } else {
        return (
            <div id="order-certificates">
                {existingOrder ? (
                    <BackLink
                        label="Back to my order"
                        to={"/profile/order/" + existingOrder.id + "/edit/"}
                    />
                ) : (
                    <OrderStepper currentStep={3} />
                )}
                <h1>Certificates</h1>
                <div className="subtitle">Select the certificate to provide for this order.</div>
                <div className="content">
                    <div className="certificates">
                        <div className="title">Certificate(s) needed:</div>
                        <MultipleRadioInput
                            items={AvailableCertificates.map((x) => ({
                                value: x,
                                label: x,
                            }))}
                            values={[...certificates.certificates, certificates.customCertificate]}
                            onChange={(value) => toggleOrderCertificate(value)}
                            setCustomValue={(value) => setOrderCustomCertificate(value)}
                            allowCustomValues
                        />
                    </div>
                </div>
                <Button text="Validate" onClick={goToNext} />
            </div>
        );
    }
};
