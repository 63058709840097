import React, { useEffect, useState } from "react";
import BackLink from "../../components/BackLink";
import CaretLink from "../../components/CaretLink";
import Button from "../../components/forms/Button";
import CheckInput from "../../components/forms/CheckInput";
import DropdownInput from "../../components/forms/DropdownInput";
import FileInput from "../../components/forms/FileInput";
import TextArea from "../../components/forms/TextArea";
import TextInput from "../../components/forms/TextInput";
import Modal from "../../components/Modal";
import RequestModal from "../../components/shop/RequestModal";
import { getAll } from "../../services/countries.service";
import { postDocument } from "../../services/documents.service";
import { createTicket } from "../../services/zendesk.service";
import "./reportLawChange.scss";

export default () => {
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [showWaitingModal, setShowWaitingModal] = useState(false);
    const [state, setState] = useState({
        country: null,
        products: "",
        comment: "",
        file: null,
    });

    const [countries, setCountries] = useState(null);

    const [check, setCheck] = useState(false);

    const [response, setResponse] = useState(null);

    const set = (field, value) => setState((prev) => ({ ...prev, [field]: value }));

    const submit = async () => {
        setResponse(null);

        if (!state.country || !state.comment || !check) {
            setResponse({ success: false, message: "Missing mandatory fields" });
            return;
        }
        setShowWaitingModal(true);
        const newFile =
            state.file && state.file[0]
                ? {
                      name: state.file[0].name,
                      file: state.file[0].s3Url,
                      type: "REPORT_LAW_CHANGE",
                  }
                : null;

        if (newFile) {
            await postDocument(newFile);
        }
        const data = { type: "reportLawChange", state: state };
        data.state.file = newFile;
        const res = await createTicket(data);

        setResponse(res);
        setShowWaitingModal(false);
        setShowRequestModal(true);
        setState({
            country: null,
            products: "",
            comment: "",
            file: null,
        });
    };

    useEffect(() => {
        (async () => {
            const res = await getAll();
            if (res) {
                setCountries(
                    res.map((x) => {
                        return { label: x.name, value: x.id };
                    })
                );
            }
        })();
    }, []);

    return (
        <div id="reportLawChange" className="profile">
            <BackLink to="/my-womancare" label="Back to my Womancare" />
            <h1>Report a change of policy</h1>
            <p id="description">Description</p>
            <div className="links">
                <CaretLink to="/my-womancare/report/order-issue" label="Report an order issue" />
                <CaretLink to="/my-womancare/report/tender" label="Report a tender" />
                <CaretLink to="/my-womancare/report/competitor" label="Report a new competitor" />
            </div>
            <div className="form">
                <section>
                    <div className="inputs">
                        <div id="first-row">
                            <DropdownInput
                                title="What is your country ? *"
                                label=""
                                items={countries}
                                value={state.country}
                                onChange={(x) => set("country", x)}
                                color="white"
                                allowNull={false}
                            />
                            <TextInput
                                label="Product(s) concerned"
                                value={state.products}
                                onChange={(x) => set("products", x)}
                                required
                                color="dark"
                            />
                        </div>
                        <TextArea
                            label="Can you describe the change of policy*"
                            value={state.comment}
                            onChange={(x) => set("comment", x)}
                            color="dark"
                            required
                            placeholder="Type here"
                        />
                        <FileInput
                            onChange={(x) => {
                                set("file", x);
                            }}
                            buttonHasBorder
                            uploadToS3
                        />
                        <CheckInput
                            label="By ticking this box, I agree that my information will be used to contact me regarding this request
and accept the confidentiality politicy of Womancare."
                            onChange={(x) => setCheck((prev) => !prev)}
                            color="dark"
                            value={check}
                        />
                    </div>
                </section>
                <div className="submit-block">
                    <Button color="white" text="Validate" onClick={submit} />
                    {response?.success === false ? (
                        <div className="error">{response?.message}</div>
                    ) : null}
                </div>
                <div className="note">* Mandatory field</div>
            </div>
            {showWaitingModal ? (
                <Modal onClose={() => setShowRequestModal(false)}>
                    <div className="content">Your request is being sent...</div>
                </Modal>
            ) : null}
            {showRequestModal ? (
                <RequestModal onClose={() => setShowRequestModal(false)} response={response} />
            ) : null}
        </div>
    );
};
