import React from "react";
import BackLink from "../BackLink";
import CaretLink from "../CaretLink";
import Wave from "../style/Wave";
import "./womanCareAcademyHeader.scss";

export default () => (
    <div className="womancare-academy-header">
        <div className="header-background"></div>
        <div className="header-wrapper">
            <BackLink to="/resources" label="Back to Resources" color="white" />
            <div className="header-content">
                <div className="title-wrapper">
                    <h1>Womancare Academy</h1>
                    <p> Description Test</p>
                </div>
                <div className="logo">
                    <img
                        src="/assets/images/womancare-academy-logo.png"
                        alt="WomanCare Academy Logo"
                    ></img>
                </div>
            </div>
            <div className="links">
                <CaretLink
                    to="/resources/videos-and-tutorials"
                    label="Videos & tutorials"
                    color="white"
                />
                <CaretLink
                    to="/resources/marketing-materials"
                    label="marketing materials"
                    color="white"
                />
                <CaretLink to="/resources/pos-and-pop" label="POS & POP" color="white" />
                <CaretLink
                    to="/resources/market-data"
                    label="Studies and market data"
                    color="white"
                />
                <CaretLink to="/resources/best-practices" label="Best practices" color="white" />
            </div>
        </div>
        <Wave color="light" />
    </div>
);
