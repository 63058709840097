import React from "react";
import Colors from "../../themes/colors.theme";

export default ({ color }) => {
    const colorCode = Colors[color || "primary"];

    return (
        <svg className="plus" width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
            <g
                id="01.-Atoms-/-02.-Icons-/-Check"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Group"
                    transform="translate(2.000000, 2.000000)"
                    stroke={colorCode}
                    strokeWidth="2"
                >
                    <path
                        d="M7.52537082,4.61282265 C10.8497164,2.01146831 15.1376659,0.641531218 19.6502436,1.08127196 C24.1628212,1.52101271 28.1056163,3.69301773 30.865196,6.88723717 C33.6247756,10.0814566 35.2011399,14.2978905 34.9808559,18.8264889 C34.7605719,23.3550874 32.7824192,27.3986194 29.7258493,30.3099246 C26.6692794,33.2212299 22.5342923,35.0003084 18.0009028,35.0000002 C16.8995688,34.9993048 15.8008765,34.8922397 14.720681,34.6804045 C10.2719681,33.805372 6.55824741,31.2614542 4.12124545,27.8148124 C1.68424348,24.3681707 0.523960198,20.0188051 1.18212195,15.5328768 C1.84028369,11.0469486 4.20102527,7.21417699 7.52537082,4.61282265 Z"
                        id="Path"
                    ></path>
                </g>
                <polyline
                    id="Path-2"
                    stroke={colorCode}
                    strokeWidth="2"
                    strokeLinecap="round"
                    points="15 20.5 18.5 23.5 25.5 16"
                ></polyline>
            </g>
        </svg>
    );
};
