import React, { useState } from "react";
import Colors from "../../themes/colors.theme";

import "./radioInput.scss";
import TextInput from "./TextInput";

type MultipleRadioInputItemValue = string;

type MultipleRadioInputItem = {
    value: MultipleRadioInputItemValue;
    label: string;
};

type MultipleRadioInputProps = {
    label?: string;
    items: MultipleRadioInputItem[];
    values?: string[];
    required?: boolean;
    allowCustomValues?: boolean;
    onChange: (value: MultipleRadioInputItemValue) => void;
    setCustomValue?: (label: string) => void;
    color?: string;
};

export default (props: MultipleRadioInputProps) => {
    const [customValue, setCustomValue] = useState(
        props.values ? props.values.find(x => !props.items.some(i => i.value === x)) || "" : ""
    );

    const onCustomValueChange = text => {
        setCustomValue(text);
        props.setCustomValue(text);
    };

    const color = props.color ? Colors[props.color] : Colors.dark;

    return (
        <div className={`radio-input multiple multiple-radio-input ${props.color || ""}`}>
            {props.label || props.required ? (
                <div
                    className="label"
                    style={{
                        color,
                    }}
                >
                    {props.label || ""}
                    {props.required ? "*" : ""}
                </div>
            ) : null}
            <div className="items">
                {props.items.map((item, index) => (
                    <div
                        key={index}
                        className={`item ${props.values.includes(item.value) ? "active" : ""}`}
                        onClick={() => props.onChange(item.value)}
                        style={{
                            color,
                        }}
                    >
                        {item.label}
                    </div>
                ))}
                {props.allowCustomValues ? (
                    <div
                        className={`item ${customValue ? "active" : ""}`}
                        onClick={() => (customValue ? onCustomValueChange("") : {})}
                        style={{
                            color,
                        }}
                    >
                        Other (please specify):
                        <TextInput
                            value={customValue}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            }}
                            onChange={onCustomValueChange}
                            color="primary"
                            style={{ marginBottom: -4 }}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};
