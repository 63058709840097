import { createSelector } from "reselect";
import { formatAddressDetail } from "../utils/addresses.utils";
import { formatDate, formatTextDate, formatTextYearMonth } from "../utils/dates.utils";
import { formatPrice } from "../utils/numeric.utils";
import {
    CareCoinValue,
    computeOrderPromoCodeDiscountOnTotal,
    computeOrderTotal,
} from "../utils/orders.utils";
import { computeOrderItemPrices } from "../utils/products.utils";
import { getFile } from "../utils/s3.utils";
import { atomize, atomizeByCondition, sumByKey } from "./utils.selectors";

const getDistributor = (state: any) => state.distributor;
const getRegistrations = (state: any) => state.distributor.registrations || [];
const getUsers = (state: any) => state.distributor.users || [];
const getOrders = (state: any) => state.distributor.orders || [];
const getProducts = (state: any) => state.shop.products || [];
const getFilters = (state: any) => state.profile.filters.orders || {};
const getProductHistory = (state: any) => state.distributor.historyProducts || [];
const booleanToBinary = (x) => (x ? 1 : 0);

const AgreementTypes = {
    DISTRIBUTOR: "Distributor agreement",
    SUPPLIER: "Supplier agreement",
    QUALITY_MEDICAL_DEVICES: "Quality agreement (medical devices)",
    QUALITY_MEDICINES: "Quality agreement (medicines)",
};

const OrderStatuses = {
    CANCELLED: {
        label: "Order cancelled",
        color: "error",
        percentage: 0,
        icon: "",
        linkText: "",
        actions: [{ key: "REDO_ORDER", value: "Place this order again" }],
        docType: "",
    },
    WAITING_FOR_QUOTE: {
        label: "Waiting for the quote",
        percentage: 10,
        icon: "",
        linkText: "",
        actions: [{ key: "EDIT_ORDER", value: "Modify the order" }],
        docType: "",
    },
    QUOTE_RECEIVED: {
        label: "Quote received",
        percentage: 20,
        icon: "",
        linkText: "",
        actions: [
            { key: "APPROVE_QUOTE", value: "Confirm the order" },
            { key: "EDIT_ORDER", value: "Modify the order" },
        ],
        docType: "",
    },
    CONFIRMED: {
        label: "Confirmed",
        percentage: 30,
        icon: "",
        linkText: "",
        actions: [{ key: "REDO_ORDER", value: "Place this order again" }],
        docType: "",
    },
    WAITING_FOR_PREPAYMENT: {
        label: "Waiting for pre-payment",
        percentage: 40,
        icon: "alert",
        linkText: "Waiting for pre-payment",
        actions: [{ key: "REDO_ORDER", value: "Place this order again" }],
        docType: "PROOF_OF_PREPAYMENT",
    },
    IMPORT_LICENSE_DOCUMENT_REQUIRED: {
        label: "In production",
        percentage: 50,
        icon: "alert",
        linkText: "Import license document required",
        actions: [{ key: "REDO_ORDER", value: "Place this order again" }],
        docType: "LICENSE_DOCUMENT",
    },
    IN_PRODUCTION: {
        label: "In production",
        percentage: 60,
        icon: "",
        linkText: "",
        actions: [{ key: "REDO_ORDER", value: "Place this order again" }],
        docType: "",
    },
    PENDING_APPROVAL: {
        label: "In production",
        percentage: 60,
        icon: "alert",
        linkText: "Pending Approval of the docs",
        actions: [{ key: "REDO_ORDER", value: "Place this order again" }],
        docType: "",
    },
    WAITING_FOR_PREINSPECTION: {
        label: "Waiting for approval",
        percentage: 70,
        icon: "alert",
        linkText: "Waiting for pre-inspection",
        actions: [{ key: "REDO_ORDER", value: "Place this order again" }],
        docType: "PREINSPECTION",
    },
    SHIPPED: {
        label: "In transit",
        percentage: 80,
        icon: "order",
        linkText: "Track order",
        actions: [{ key: "REDO_ORDER", value: "Place this order again" }],
        docType: "",
    },
    DELIVERED: {
        label: "Delivered",
        color: "success-outline",
        percentage: 100,
        icon: "",
        linkText: "",
        actions: [{ key: "REDO_ORDER", value: "Place this order again" }],
        docType: "",
    },
    BILLED: {
        label: "Billed",
        color: "success-outline",
        percentage: 100,
        icon: "",
        linkText: "",
        actions: [{ key: "REDO_ORDER", value: "Place this order again" }],
        docType: "",
    },
};

const statusesWithLinks = [
    "WAITING_FOR_PREPAYMENT",
    "IMPORT_LICENSE_DOCUMENT_REQUIRED",
    "WAITING_FOR_PREINSPECTION",
    "SHIPPED",
    "PENDING_APPROVAL",
];

const editableStatuses = ["WAITING_FOR_QUOTE", "QUOTE_RECEIVED"];

const confirmableOrderStatuses = ["QUOTE_RECEIVED"];

const redoOrderStatuses = [
    "CANCELLED",
    "CONFIRMED",
    "WAITING_FOR_PREPAYMENT",
    "IMPORT_LICENSE_DOCUMENT_REQUIRED",
    "IN_PRODUCTION",
    "WAITING_FOR_PREINSPECTION",
    "SHIPPED",
    "DELIVERED",
];

const downloadableDocuments = [
    { type: "QUOTE", label: "Sales quote" },
    { type: "IMPORT_LICENSE", label: "Import license" },
    { type: "COMMERCIAL_INVOICE", label: "Commercial invoice" },
    { type: "PACKING_SLIP", label: "Packing slip" },
];

const computeFullName = (user) => [user.firstname, user.lastname].join(" ");

const computeOrderData = (order) => {
    const products = order.orderItems.map((orderItem) => {
        const product = orderItem.registration.product;

        return {
            ...orderItem,
            ...product,
            ...computeOrderItemPrices(orderItem),
        };
    });

    const subtotal = sumByKey(products, "totalNonPromoCodeDiscountedPrice");

    const OrderDiscountFields = {
        DISCOUNT: order.promoCodeDiscount,
        CASH_BACK: order.promoCodeCashBackAmount,
        SHIPPING_DISCOUNT: order.promoCodeShippingDiscount,
    };

    const { discountOnTotal, effectiveDiscountOnTotal } = computeOrderPromoCodeDiscountOnTotal(
        subtotal,
        {
            type: Object.keys(OrderDiscountFields).find((x) => !!OrderDiscountFields[x]),
            discount: order.promoCodeDiscount,
            cashBackAmount: order.promoCodeCashBackAmount,
            shippingDiscount: order.promoCodeShippingDiscount,
            products: [],
        },
        true,
        order.freight
    );

    return {
        products,
        subtotal,
        total: computeOrderTotal(
            products,
            order.freight,
            discountOnTotal,
            order.careCoinsUsed,
            true,
            order.promoCodeShippingDiscount
        ),
        totalPromoCodeDiscount: sumByKey(products, "promoCodeDiscount") + effectiveDiscountOnTotal,
        shippingPrice: order.freight || 0,
        careCoinsUsedTotalValue: Math.round(order.careCoinsUsed * CareCoinValue * 100) / 100,
    };
};

const computeProductsData = (order, registrations) => {
    const newProducts = order.products.map((x) => {
        const registration = registrations.find(
            (registration) => registration.id === +x.registration.id
        );
        return {
            ...x,
            country: registration ? registration.country : "",
        };
    });

    return {
        ...order,
        products: newProducts,
    };
};

const computeOrderDisplayData = (order) => {
    const status = order.documentStatus === "RECEIVED" ? "PENDING_APPROVAL" : order.status;
    return {
        ...order,
        date: formatDate(order.creationDate),
        num: `#${order.id}`,
        destination:
            order.shippingAddress?.country?.name && order.shippingAddress?.city
                ? `${order.shippingAddress?.country?.name}, ${order.shippingAddress?.city}`
                : null,
        deliveryDate: formatTextDate(order.deliveryDate),
        statusData: {
            hasStatusLink: statusesWithLinks.includes(status),
            icon: OrderStatuses[status]?.icon,
            linkText: OrderStatuses[status]?.linkText,
            statusTextColor: OrderStatuses[status]?.color,
            actions: OrderStatuses[status]?.actions,
            editable: editableStatuses.includes(status),
            redoOrder: redoOrderStatuses.includes(status),
            toConfirm: confirmableOrderStatuses.includes(status),
            choice: status === "PENDING_APPROVAL",
            statusLabel: OrderStatuses[status]?.label,
            percentage: OrderStatuses[status]?.percentage,
            docType: OrderStatuses[status]?.docType,
        },
        shippingAddressFormatted: formatAddressDetail(order.shippingAddress),
        billingAddressFormatted: formatAddressDetail(order.billingAddress),
        contact: `${order.contactName || ""} ${order.contactPhone || ""}`,

        downloads: order.orderDocuments
            .filter((doc) => doc.file && downloadableDocuments.some((x) => x.type === doc.type))
            .map((doc) => ({
                name: doc.name,
                url: doc.file,
                type: doc.type,
                label: doc.label,
            })),
    };
};

const DistributorSelectors = {
    shippingAddresses: createSelector(getDistributor, (distributor) =>
        [...distributor.shippingAddresses].sort(
            (a, b) => booleanToBinary(b.isPrimary) - booleanToBinary(a.isPrimary)
        )
    ),

    billingAddresses: createSelector(getDistributor, (distributor) =>
        [...distributor.billingAddresses].sort(
            (a, b) => booleanToBinary(b.isPrimary) - booleanToBinary(a.isPrimary)
        )
    ),

    fullDistributor: createSelector(getDistributor, (distributor) => ({
        ...distributor,

        primaryShippingAddress: distributor.shippingAddresses.find((x) => x.isPrimary),

        secondaryShippingAddresses: distributor.shippingAddresses.filter((x) => !x.isPrimary),

        primaryBillingAddress: distributor.billingAddresses.find((x) => x.isPrimary),

        secondaryBillingAddresses: distributor.billingAddresses.filter((x) => !x.isPrimary),
    })),

    agreementsData: createSelector(getRegistrations, (registrations) =>
        registrations.map((registration) => ({
            ...registration,
            date: `Signed in ${formatTextYearMonth(registration.creationDate)}`,
            type: AgreementTypes[registration.agreementType] || "",
            productCode: registration.product?.code,
            countryName: registration.country?.name,
            download: registration.agreementDocument
                ? getFile(registration.agreementDocument)
                : null,
        }))
    ),

    labelsData: createSelector(getRegistrations, (registrations) => {
        const allLabels = atomizeByCondition(
            registrations.reduce(
                (acc, registration) => [
                    ...acc,
                    ...(registration.label
                        ? [
                              {
                                  ...registration.label,
                                  registration,
                              },
                          ]
                        : []),
                    ...(registration.secondaryPackagingLabel
                        ? [
                              {
                                  ...registration.secondaryPackagingLabel,
                                  registration,
                              },
                          ]
                        : []),
                    ...(registration.masterCartonLabel
                        ? [
                              {
                                  ...registration.masterCartonLabel,
                                  registration,
                              },
                          ]
                        : []),
                    ...(registration.shippingCartonLabel
                        ? [
                              {
                                  ...registration.shippingCartonLabel,
                                  registration,
                              },
                          ]
                        : []),
                    ...(registration.otherLabel
                        ? [
                              {
                                  ...registration.otherLabel,
                                  registration,
                              },
                          ]
                        : []),
                ],
                []
            ),
            (x, y) => x.id === y.id && x.registration.country.id === y.registration.country.id
        );

        return allLabels.map((label) => ({
            ...label,
            date: formatDate(label.registration.creationDate),
            download: label.file ? getFile(label.file) : "#",
            name: label.name,
            code: label.code,
            region: label.registration.country.name,
        }));
    }),

    usersData: createSelector(getDistributor, (distributor) =>
        distributor.users.map((user) => ({
            ...user,
            fullName: computeFullName(user),
        }))
    ),

    ordersData: createSelector(
        getOrders,
        getProducts,
        getFilters,
        getRegistrations,
        (orders, products, filters, registrations) => {
            const formattedOrders = orders.map((order) => {
                const orderDisplayData = computeOrderDisplayData(order);
                const orderData = computeOrderData(order);
                const products = computeProductsData(orderData, registrations);
                return {
                    ...orderDisplayData,
                    ...orderData,
                    ...products,
                    total: `${formatPrice(orderData.total)} (excl. VAT)`,
                };
            });

            const filteredOrders = formattedOrders.filter(
                (order) =>
                    (!filters.status || order.statusData.statusLabel === filters.status) &&
                    (!filters.destination || order.destination === filters.destination) &&
                    (!filters.productId ||
                        order.products.some((product) => product.id === filters.productId))
            );

            const statusList = atomize(
                Object.keys(OrderStatuses).map((statusKey) => OrderStatuses[statusKey].label)
            );
            const productList = products;
            const destinationList = atomize(
                formattedOrders.map((order) => order.destination).filter((x) => !!x)
            );

            return {
                orders: filteredOrders,
                filtersData: { statusList, productList, destinationList },
            };
        }
    ),

    userById: (id) => (state: any) => getUsers(state).find((user) => user.id === +id) || {},

    orderById: (id) => (state: any) => {
        if (id) {
            const registrations = getRegistrations(state);

            const order = getOrders(state).find((order) => order.id === +id);
            const orderDisplayData = computeOrderDisplayData(order);
            const orderData = computeOrderData(order);
            const products = computeProductsData(orderData, registrations);

            return {
                ...orderDisplayData,
                ...orderData,
                ...products,
            };
        } else {
            return null;
        }
    },

    viewedProducts: (id) => (state: any) => {
        if (id) {
            const viewedProducts = getProductHistory(state).find((p) => p.distributorId === +id);
            return viewedProducts;
        } else {
            return null;
        }
    },
};

export default DistributorSelectors;
