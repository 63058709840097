import React from "react";
import { Link } from "react-router-dom";

import "./header.scss";

export default () => {
    return (
        <header id="header-auth">
            <Link className="logo" to="/">
                <img src="/assets/logo.png" alt="Logo" />
            </Link>
        </header>
    );
};
