import React, { useState } from "react";
import TextInput from "./TextInput";

import "./passwordInput.scss";

type PasswordInputProps = {
    onChange?: (text: string) => void;
    onBlur?: () => void;
    label?: string;
    tooltip?: string;
    placeholder?: string;
    required?: boolean;
    value?: string;
    color?: string;
    style?: Object;
    errorMsg?: string;
    successMsg?: string;
};

export default (props: PasswordInputProps) => {
    const [isHidden, setIsHidden] = useState(true);

    return (
        <div className="password-input">
            <TextInput {...props} password={isHidden} />
            <div className="toggle" onClick={() => setIsHidden((prev) => !prev)}>
                <img src="/assets/icons/eye.svg" alt={isHidden ? "Show" : "Hide"} />
            </div>
        </div>
    );
};
