const production = {
    apiUrl: "https://api.mywomancare.org",
};

const staging = {
    apiUrl: "https://api-staging.mywomancare.org",
};

const dev = {
    apiUrl: "http://localhost:3000",
};

const isStaging = () => window.location.host === "staging.mywomancare.org";

export const environment =
    process.env.NODE_ENV === "development" ? dev : isStaging() ? staging : production;
