import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import ProductItem from "./Item";
import Caret from "../icons/Caret";
import { useResize } from "../../utils/layout.utils";
import "./productSlider.scss";
import useWindowDimensions from "../../utils/window.utils";

export default (props: {
    title: string;
    seeAllPath?: string;
    seeAllLabel?: string;
    products: any;
    hideProductsDetails?: boolean;
    hideProductsControls?: boolean;
}) => {
    const [currentProductIndex, setCurrentProductIndex] = useState(0);
    const [intervalLeft, setIntervalLeft] = useState<ReturnType<typeof setInterval> | undefined>();
    const [intervalRight, setIntervalRight] = useState<
        ReturnType<typeof setInterval> | undefined
    >();
    const { width } = useWindowDimensions();

    const PRODUCT_ITEM_WIDTH = width > 768 ? 382 : width;

    const sliderContainer = useRef();

    const { width: sliderContainerWidth } = useResize(sliderContainer);

    const sliderItemsWidth = props.products.length * PRODUCT_ITEM_WIDTH;

    const sliderOffset =
        sliderItemsWidth < sliderContainerWidth
            ? 0
            : Math.min(
                  sliderItemsWidth - sliderContainerWidth,
                  PRODUCT_ITEM_WIDTH * currentProductIndex
              );

    const doIncrementCurrentProductIndex = () => {
        return sliderOffset < sliderItemsWidth - sliderContainerWidth;
    };

    const handleRight = () => {
        if (doIncrementCurrentProductIndex()) {
            setCurrentProductIndex((prev) => Math.min(props.products.length - 1, prev + 1));
        }
    };

    return (
        <div className="products-slider">
            <div className="products-slider-header">
                <h2 className="title">{props.title}</h2>
                <div className="arrows">
                    <button
                        className="arrow left"
                        aria-label="Previous"
                        onMouseDown={() => {
                            setCurrentProductIndex((prev) => Math.max(0, prev - 1));
                            const intervalLeft = setInterval(() => {
                                setCurrentProductIndex((prev) => Math.max(0, prev - 1));
                            }, 100);
                            setIntervalLeft(intervalLeft);
                        }}
                        onMouseUp={() => {
                            clearInterval(intervalLeft);
                        }}
                    >
                        <Caret type="left" size={12} />
                    </button>
                    <button
                        className="arrow right"
                        aria-label="Next"
                        onMouseDown={() => {
                            handleRight();
                            const intervalRight = setInterval(() => {
                                handleRight();
                            }, 100);
                            setIntervalRight(intervalRight);
                        }}
                        onMouseUp={() => {
                            clearInterval(intervalRight);
                        }}
                    >
                        <Caret type="right" size={12} />
                    </button>
                </div>
            </div>
            {props.seeAllPath ? (
                <Link className="primary see-all" to={props.seeAllPath}>
                    {props.seeAllLabel || "See all"}
                </Link>
            ) : null}
            <div className="slider-container" ref={sliderContainer}>
                <div
                    className="slider"
                    style={{
                        marginLeft: -sliderOffset,
                        transition: "margin-left 0.300s ease",
                        gap: width > 768 ? 76 : 140,
                    }}
                >
                    {props.products.map((product, index) => (
                        <ProductItem
                            key={index}
                            product={product}
                            hideDetails={props.hideProductsDetails}
                            hideControls={props.hideProductsControls}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
