import React, { useState } from "react";
import { getFile, uploadFile } from "../../utils/s3.utils";
import DownloadLink from "../DownloadLink";
import Delete from "../icons/Delete";
import Button from "./Button";

import "./fileInput.scss";

type FileInputProps = {
    label?: string;
    required?: boolean;
    placeholder?: string;
    color?: "primary" | "secondary" | "dark" | "light" | "white" | "transparent";
    buttonHasBorder?: boolean;
    multiple?: boolean;
    onChange: (files: any) => void;
    uploadToS3?: boolean;
};

export default (props: FileInputProps) => {
    const [files, setFiles] = useState([]);

    const uploadToS3 = async file => {
        const filePath = await uploadFile(file);
        return filePath;
    };

    const onChange = async e => {
        if (props.uploadToS3) {
            setFiles([]);
            for (const file of e.target.files) {
                const s3Url = await uploadToS3(file);
                setFiles(prev => [...prev, { name: file.name, s3Url: s3Url.substring(1) }]);
                props.onChange([{ name: file.name, s3Url: s3Url.substring(1) }]);
            }
        } else {
            props.onChange(e.target.files);
        }
    };

    const addFile = async event => {
        const file = event.target.files[0];
        if (props.uploadToS3) {
            const s3Url = await uploadToS3(file);
            const newFileList = [...files, { name: file.name, s3Url: s3Url.substring(1) }];
            setFiles(newFileList);
            props.onChange(newFileList);
        } else {
            const newFileList = [...files, file];
            setFiles(newFileList);
            props.onChange(newFileList);
        }
    };

    const removeFile = (event, index) => {
        setFiles(prev => [...prev.slice(0, index), ...prev.slice(index + 1)]);
        props.onChange([]);

        event.preventDefault();
        event.stopPropagation();
        return false;
    };

    return (
        <div className={`file-input ${props.color || ""}`}>
            {props.uploadToS3 && props.multiple && files.length >= 1 ? (
                <div className="files">
                    {files.map((file, index) => (
                        <div className="file-row" key={index}>
                            {props.label ? <div className="label">{props.label}</div> : null}
                            <div className="right">
                                <div className="file-label">{file.name}</div>
                                <DownloadLink url={getFile(file.s3Url)} color={props?.color} />
                                <Delete onClick={e => removeFile(e, index)} color={props?.color} />
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
            <label>
                {props.label ? (
                    <div className="label">
                        {props.label}
                        {props.required ? "*" : ""}
                    </div>
                ) : null}
                <input
                    type="file"
                    onChange={props.multiple ? addFile : onChange}
                    onClick={(e: any) => (e.target.value = null)}
                />
                <div className="right">
                    {props.multiple || files.length === 0 ? (
                        <Button
                            text={props.multiple ? "Add new document" : "Upload document"}
                            inactive
                            color={!props.color || props.color === "white" ? "dark" : "white"}
                            hasBorder={props?.buttonHasBorder}
                        />
                    ) : props.uploadToS3 && files.length === 1 ? (
                        <>
                            <div className="file-label">{files[0].name}</div>
                            <DownloadLink url={getFile(files[0].s3Url)} color={props?.color} />
                            <Delete onClick={e => removeFile(e, 0)} color={props?.color} />
                        </>
                    ) : null}
                </div>
            </label>
        </div>
    );
};
