import React from "react";

import "./download.scss";
import Colors from "../../themes/colors.theme";

const DownloadIcon = (props: { color?: string; onClick?: () => void }) => (
    <svg
        className={`download ${props.onClick ? "has-action" : ""}`}
        onClick={props?.onClick}
        width="40px"
        height="40px"
        viewBox="0 0 40 40"
        version="1.1"
    >
        <g
            id="01.-Atoms-/-02.-Icons-/-Document"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="046-download"
                transform="translate(8.000000, 7.000000)"
                fill={Colors[props.color || "dark"]}
                fillRule="nonzero"
            >
                <path
                    d="M22.4769607,23.6083669 L1.00691028,23.6083669 C0.535332661,23.6083669 0.152867944,23.9908317 0.152867944,24.4624093 C0.152867944,24.9339869 0.535201613,25.3164516 1.00691028,25.3164516 L22.4768952,25.3164516 C22.9486038,25.3164516 23.3309375,24.9341179 23.3309375,24.4624093 C23.3309375,23.9908317 22.9486038,23.6083669 22.4769607,23.6083669 Z"
                    id="Path"
                ></path>
                <path
                    d="M20.3858216,11.5931956 C20.0716331,11.2415272 19.5317792,11.2109274 19.1799143,11.5251815 L12.5959123,17.4069607 L12.5959123,0.854959677 C12.5959123,0.383382056 12.2135786,0.00091733871 11.74187,0.00091733871 C11.2702923,0.00091733871 10.8878276,0.383251008 10.8878276,0.854959677 L10.8878276,17.4069607 L4.3038256,11.525247 C3.95215726,11.2109929 3.4122379,11.2416583 3.09791835,11.5932611 C2.78379536,11.9450605 2.81413306,12.4849798 3.16586694,12.7991683 L10.3194052,19.1895464 C10.7248034,19.5518296 11.2332056,19.732873 11.7418044,19.732873 C12.2502067,19.732873 12.7586744,19.5518296 13.1642036,19.1897429 L20.3177419,12.7991683 C20.6696069,12.4847833 20.7000101,11.9448639 20.3858216,11.5931956 Z"
                    id="Path"
                ></path>
            </g>
        </g>
    </svg>
);

export default (props: { color?: string; url?: string; onClick?: () => void }) =>
    props.url ? (
        <a href={props.url} target="_blank" rel="noopener noreferrer">
            <DownloadIcon color={props.color} />
        </a>
    ) : (
        <DownloadIcon color={props.color} onClick={props?.onClick} />
    );
