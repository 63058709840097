import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./app.scss";
import { Loader } from "./components/style/Loader";
import PagesNavigation from "./pages/pages.navigation";
import { getMe } from "./services/users.service";
import { persistor, store } from "./store/configure.store";
import DistributorSlice from "./store/slices/distributor.slice";
import MeSlice from "./store/slices/me.slice";

function App() {
    const [loading, setLoading] = useState(true);

    const me = async () => {
        const res = await getMe();
        if (res && res.id) {
            store.dispatch(
                MeSlice.actions.setMe({
                    id: res.id,
                    creationDate: res.creationDate,
                    username: res.username,
                    email: res.email,
                    civility: res.civility,
                    firstname: res.firstname,
                    lastname: res.lastname,
                    phone: res.phone,
                    phone2: res.phone2,
                    role: res.role,
                    job: res.job,
                })
            );
            store.dispatch(DistributorSlice.actions.setDistributorData(res.distributor));
        } else {
            localStorage.removeItem("token");
        }
        setLoading(false);
    };

    useEffect(() => {
        me();
    }, []);

    return (
        <>
            <Helmet>
                <script
                    id="ze-snippet"
                    src="https://static.zdassets.com/ekr/snippet.js?key=b02537a0-93c5-4b9a-9fee-e58ac826edef"
                >
                    {" "}
                </script>
            </Helmet>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    {loading ? (
                        <div id="loading">
                            <Loader
                                size="big"
                                color="primary"
                                style={{ width: 200, height: 200 }}
                            />
                        </div>
                    ) : (
                        <PagesNavigation />
                    )}
                </PersistGate>
            </Provider>
        </>
    );
}

export default App;
