import React from "react";
import { HashLink } from "react-router-hash-link";
import "./pager.scss";

type PagerProps = {
    pageCount: number;
    currentPage: number;
    onSetPage?: (page: number) => void;
    link?: string;
};

export default (props: PagerProps) => {
    return props.pageCount >= 2 ? (
        <div className="pager">
            {Array(props.pageCount)
                .fill(0)
                .map((_, index) => (
                    <HashLink
                        key={index}
                        className={`page-link ${props.currentPage === index ? "active" : ""}`}
                        to={props.link || "#"}
                        onClick={() => props.onSetPage(index)}
                    >
                        {`0${index + 1}`.slice(-2)}
                    </HashLink>
                ))}
        </div>
    ) : null;
};
