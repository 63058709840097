import { useSelector } from "react-redux";
import { getMe } from "../services/users.service";
import MeSlice from "../store/slices/me.slice";
import { store } from "../store/configure.store";
import DistributorSlice from "../store/slices/distributor.slice";
import ShopSlice from "../store/slices/shop.slice";
import OrderSlice from "../store/slices/order.slice";

export const isSignupFormValid = (state: any) =>
    Object.keys(state).every((field) => field === "phone2" || !!state[field]) &&
    Object.keys(state.company).every(
        (field) => field === "address2" || field === "country" || !!state.company[field]
    );

export const useLogin = () => {
    const me = useSelector((state: any) => state.me);
    return { isLogin: Boolean(localStorage.getItem("token")), me };
};

export const login = async (jwt) => {
    localStorage.setItem("token", jwt);
    const res = await getMe();
    if (res) {
        store.dispatch(MeSlice.actions.setMe(res));
        store.dispatch(DistributorSlice.actions.setDistributorData(res.distributor));
    }
};

export const logout = () => {
    store.dispatch(MeSlice.actions.resetMe());
    store.dispatch(DistributorSlice.actions.reset());
    store.dispatch(OrderSlice.actions.reset());
    store.dispatch(ShopSlice.actions.reset());
    localStorage.removeItem("token");
};
