import React, { useState } from "react";
import { Redirect, useHistory } from "react-router";
import { useSelector } from "react-redux";

import Wrapper from "../../components/style/Wrapper";
import Button from "../../components/forms/Button";
import TextInput from "../../components/forms/TextInput";
import { RadioInput } from "../../components/forms/RadioInput";
import { isDistributorAccountFree } from "../../services/auth.service";
import { validateEmail, validatePassword } from "../../utils/regex.util";
import { isSignupFormValid } from "../../utils/auth.utils";
import "./signup.scss";
import Wave from "../../components/style/Wave";
import PasswordInput from "../../components/forms/PasswordInput";
import { store } from "../../store/configure.store";
import AuthSlice from "../../store/slices/auth.slice";
import Select from "../../components/forms/Select";

const SignupTextInput = (props: {
    field: string;
    subState?: string;
    label: string;
    required?: boolean;
    tooltip?: string;
    onChange: (value: any) => void;
    isPassword?: boolean;
    errorMsg?: string;
}) => {
    const TextInputComponent = props.isPassword ? PasswordInput : TextInput;

    return (
        <TextInputComponent
            label={props.label}
            required={props.required}
            tooltip={props.tooltip}
            onChange={(text) =>
                props.onChange((prev) =>
                    props.subState
                        ? {
                              ...prev,
                              [props.subState]: {
                                  ...prev[props.subState],
                                  [props.field]: text,
                              },
                          }
                        : {
                              ...prev,
                              [props.field]: text,
                          }
                )
            }
            color="dark"
            errorMsg={props.errorMsg}
        />
    );
};

export default () => {
    const history = useHistory();

    const authStore = useSelector((state: any) => state.auth);
    const shopStore = useSelector((state: any) => state.shop);

    const [state, setState] = useState(authStore);

    const [errorMsg, setErrorMsg] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const submit = async () => {
        setErrorMsg("");
        if (!isSignupFormValid(state) || !passwordConfirmation) {
            setErrorMsg("Missing mandatory fields");
            return;
        }
        if (state.password !== passwordConfirmation) {
            setErrorMsg("Passwords are not the same");
            return;
        }
        if (!validateEmail(state.email)) {
            setErrorMsg("Personal email invalid");
            return;
        }
        if (!validateEmail(state.company.email)) {
            setErrorMsg("Company email invalid");
            return;
        }
        if (!validatePassword(state.password)) {
            setErrorMsg(
                "Password must contain at least 8 signs, including one number, one capital, one lowercase and one special sign"
            );
            return;
        }

        const { conflictType } = await isDistributorAccountFree({
            username: state.username,
            email: state.email,
            companyEmail: state.company.email,
        });

        if (conflictType) {
            setErrorMsg(
                {
                    username: "This login is already used",
                    email: "This personal email is already used",
                    companyEmail: "This company email is already used",
                }[conflictType]
            );
        } else {
            store.dispatch(AuthSlice.actions.setData(state));
            history.push("/signup/documents");
        }
    };

    return localStorage.getItem("token") ? (
        <Redirect to="/home" />
    ) : (
        <div id="signup">
            <div className="header">
                <div className="content">
                    <img src="/assets/icons/key.svg" alt="Key" />
                    <h1>Create my account - Account information</h1>
                </div>
                <div className="background"></div>
                <Wave color="light" />
            </div>
            <Wrapper>
                <div className="form">
                    <section>
                        <div className="title">
                            <h2>Personal information</h2>
                        </div>
                        <RadioInput
                            label="Title"
                            required
                            items={[
                                { value: "MR", label: "Mr." },
                                { value: "MS", label: "Ms." },
                                { value: "OTHER", label: "I prefer not to disclose" },
                            ]}
                            value={state.civility}
                            onChange={(civility) => setState((prev) => ({ ...prev, civility }))}
                            color="white"
                        />
                        <div className="inputs">
                            <SignupTextInput
                                field="lastname"
                                onChange={setState}
                                label="Last name"
                                required
                            />
                            <SignupTextInput
                                field="firstname"
                                onChange={setState}
                                label="First name"
                                required
                            />
                            <SignupTextInput
                                field="job"
                                onChange={setState}
                                label="Position"
                                required
                            />
                            <SignupTextInput
                                field="email"
                                onChange={setState}
                                label="Email address"
                                required
                            />
                            <SignupTextInput
                                field="phone"
                                onChange={setState}
                                label="Phone number"
                                required
                            />
                            <SignupTextInput
                                field="phone2"
                                onChange={setState}
                                label="Whatsapp / Skype / Wechat"
                            />
                        </div>
                    </section>
                    <section>
                        <div className="title">
                            <h2>Company information</h2>
                        </div>
                        <div className="inputs">
                            <SignupTextInput
                                subState="company"
                                field="organizationName"
                                onChange={setState}
                                label="Organization name"
                                required
                            />
                            <SignupTextInput
                                subState="company"
                                field="address"
                                onChange={setState}
                                label="Address"
                                required
                            />
                            <SignupTextInput
                                subState="company"
                                field="address2"
                                onChange={setState}
                                label="Address 2"
                            />
                            <SignupTextInput
                                subState="company"
                                field="zipCode"
                                onChange={setState}
                                label="Zipcode"
                                required
                            />
                            <SignupTextInput
                                subState="company"
                                field="city"
                                onChange={setState}
                                label="City"
                                required
                            />
                            <Select
                                title="Country*"
                                options={shopStore.countries.map((country) => ({
                                    value: country.id,
                                    label: country.name,
                                }))}
                                onChange={(x) =>
                                    setState((prev) => ({
                                        ...prev,
                                        company: { ...prev.company, countryId: x },
                                    }))
                                }
                                color="white"
                            />
                            <SignupTextInput
                                subState="company"
                                field="email"
                                onChange={setState}
                                label="Email address"
                                required
                            />
                            <SignupTextInput
                                subState="company"
                                field="phone"
                                onChange={setState}
                                label="Phone number"
                                required
                            />
                        </div>
                    </section>
                    <section>
                        <div className="title">
                            <h2>Account information</h2>
                        </div>
                        <div className="inputs">
                            <SignupTextInput
                                field="username"
                                onChange={setState}
                                label="Login"
                                required
                            />
                        </div>
                        <div className="inputs">
                            <SignupTextInput
                                field="password"
                                onChange={setState}
                                label="Password"
                                required
                                tooltip="Password must contain at least 8 signs, including one number, one capital, one lowercase and one special sign"
                                isPassword
                            />
                            <TextInput
                                label="Password confirmation"
                                required
                                onChange={(text) => setPasswordConfirmation(text)}
                                color="dark"
                                password
                            />
                        </div>
                    </section>
                    <div className="submit-block">
                        <Button color="white" text="Next step" onClick={submit} />
                        {errorMsg ? <div className="error">{errorMsg}</div> : null}
                    </div>
                    <div className="note">* Mandatory field</div>
                </div>
            </Wrapper>
        </div>
    );
};
