import React, { useEffect, useState } from "react";
import Caret from "../icons/Caret";

import "./select.scss";

type SelectItemValue = string | number;

type SelectItem = {
    value: SelectItemValue;
    label: string;
};

type SelectProps = {
    title?: string;
    label?: string;
    tooltip?: string;
    options: SelectItem[];
    allowNull?: boolean;
    value?: SelectItemValue;
    onChange: (value: SelectItemValue) => void;
    color?: string;
    style?: any;
};

export default (props: SelectProps) => {
    const [showOptions, setShowOptions] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        setSelectedItem(props.value ? props.options.find((x) => x.value == props.value) : null);
    }, [props.value]);

    return (
        <div
            className={`select ${props.color || "primary"}`}
            onMouseLeave={() => setShowOptions(false)}
            style={props?.style}
        >
            {props.title ? <div className="title">{props.title}</div> : null}
            <div
                className="label"
                onClick={() => setShowOptions((prev) => !prev)}
                onBlur={() => setShowOptions(false)}
            >
                <span>{selectedItem ? selectedItem.label : props.label || ""}</span>
                <Caret
                    type="down"
                    color={
                        !props.color || ["primary", "secondary"].includes(props.color)
                            ? "primary"
                            : props.color
                    }
                    size={12}
                />
            </div>
            {showOptions && (
                <div className="options">
                    {props.allowNull ? (
                        <div
                            className="item"
                            onClick={() => {
                                setSelectedItem(null);
                                props.onChange(null);
                                setShowOptions(false);
                            }}
                        >
                            Default
                        </div>
                    ) : null}
                    {props.options.map((item, index) => (
                        <div
                            key={index}
                            className="option"
                            onClick={() => {
                                setSelectedItem(item);
                                props.onChange(item.value);
                                setShowOptions(false);
                            }}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
