import NotFound from "./404/NotFound";
import EventsList from "./events/EventsList";
import Home from "./home/Home";
import Landing from "./landing/Landing";
import Login from "./login/Login";
import ResetPassword from "./login/ResetPassword";
import NewsList from "./news/NewsList";
import NewsPage from "./news/NewsPage";
import Address from "./order/Address";
import Cart from "./order/Cart";
import Certificates from "./order/Certificates";
import Confirmation from "./order/Confirmation";
import Shipping from "./order/Shipping";
import Validation from "./order/Validation";
import BestPracticeDetails from "./resources/BestPracticeDetails";
import BestPractices from "./resources/BestPractices";
import MarketData from "./resources/MarketData";
import MarketDataDetails from "./resources/MarketDataDetails";
import MarketingMaterialDetails from "./resources/MarketingMaterialDetails";
import MarketingMaterial from "./resources/MarketingMaterials";
import PosAndPop from "./resources/PosAndPop";
import PosAndPopDetails from "./resources/PosAndPopDetails";
import Resources from "./resources/Resources";
import TutorialDetails from "./resources/VideoAndTutorialDetails";
import VideosAndTutorials from "./resources/VideosAndTutorials";
import WomanCareAcademy from "./resources/WomanCareAcademy";
import WomanCareAcademyDetails from "./resources/WomanCareAcademyDetails";
import Product from "./shop/Product";
import Shop from "./shop/Shop";
import SignupDocuments from "./signup/Documents";
import Signup from "./signup/Signup";
import Agreements from "./user/Agreements";
import BillingAddresses from "./user/BillingAddresses";
import Company from "./user/Company";
import CustomerService from "./user/CustomerService";
import Documents from "./user/Documents";
import EditOrder from "./user/EditOrder";
import ForecastForm from "./user/ForecastForm";
import Forecasts from "./user/Forecasts";
import Labels from "./user/Labels";
import MyData from "./user/MyData";
import MyLoyaltyProgram from "./user/MyLoyaltyProgram";
import MyWomanCare from "./user/MyWomanCare";
import Notifications from "./user/Notifications";
import OrderDetails from "./user/OrderDetails";
import Orders from "./user/Orders";
import Profile from "./user/Profile";
import ReportCompetitor from "./user/ReportCompetitor";
import ReportLawChange from "./user/ReportLawChange";
import ReportOrderIssue from "./user/ReportOrderIssue";
import ReportTender from "./user/ReportTender";
import Security from "./user/Security";
import ShippingAddresses from "./user/ShippingAddresses";
import UserForm from "./user/UserForm";
import Users from "./user/Users";

export default [
    {
        path: "/",
        component: Landing,
        auth: true,
    },
    {
        path: "/home",
        component: Home,
        auth: true,
    },

    {
        path: "/login",
        component: Login,
        headerType: "auth",
    },
    {
        path: "/reset-password",
        component: ResetPassword,
        headerType: "auth",
    },
    {
        path: "/signup",
        component: Signup,
        headerType: "auth",
    },
    {
        path: "/signup/documents",
        component: SignupDocuments,
        headerType: "auth",
    },
    {
        path: "/shop",
        component: Shop,
    },
    {
        path: "/product/:productId",
        component: Product,
    },
    {
        path: "/cart",
        component: Cart,
        auth: true,
    },
    {
        path: "/order/cart",
        component: Cart,
        auth: true,
    },
    {
        path: "/order/address",
        component: Address,
        auth: true,
    },
    {
        path: "/order/shipping",
        component: Shipping,
        auth: true,
    },
    {
        path: "/order/certificates",
        component: Certificates,
        auth: true,
    },
    {
        path: "/order/validation",
        component: Validation,
        auth: true,
    },
    {
        path: "/order/confirmation",
        component: Confirmation,
        auth: true,
        headerType: "none",
        footerType: "none",
    },
    {
        path: "/profile/me",
        component: Profile,
        auth: true,
    },
    {
        path: "/profile/security",
        component: Security,
        auth: true,
    },
    {
        path: "/profile/company",
        component: Company,
        auth: true,
    },
    {
        path: "/profile/users",
        component: Users,
        auth: true,
    },
    {
        path: "/profile/users/edit/:id?",
        component: UserForm,
        auth: true,
    },
    {
        path: "/profile/shipping-addresses",
        component: ShippingAddresses,
        auth: true,
    },
    {
        path: "/profile/billing-addresses",
        component: BillingAddresses,
        auth: true,
    },
    {
        path: "/profile/forecasts",
        component: Forecasts,
        auth: true,
    },
    {
        path: "/profile/forecasts/edit/:id?",
        component: ForecastForm,
        auth: true,
    },
    {
        path: "/profile/documents",
        component: Documents,
        auth: true,
    },
    {
        path: "/profile/agreements",
        component: Agreements,
        auth: true,
    },
    {
        path: "/profile/labels",
        component: Labels,
        auth: true,
    },
    {
        path: "/profile/orders",
        component: Orders,
        auth: true,
    },
    {
        path: "/profile/order/:orderId",
        component: OrderDetails,
        auth: true,
    },
    {
        path: "/profile/order/:orderId/edit",
        component: EditOrder,
        auth: true,
    },
    {
        path: "/profile/data",
        component: MyData,
        auth: true,
    },
    {
        path: "/notifications",
        component: Notifications,
        auth: true,
    },
    {
        path: "/my-womancare/customer-service",
        component: CustomerService,
        auth: true,
    },
    {
        path: "/my-womancare",
        component: MyWomanCare,
        auth: true,
    },
    {
        path: "/my-womancare/report/tender",
        component: ReportTender,
        auth: true,
    },
    {
        path: "/my-womancare/report/competitor",
        component: ReportCompetitor,
        auth: true,
    },
    {
        path: "/my-womancare/report/law-change",
        component: ReportLawChange,
        auth: true,
    },
    {
        path: "/my-womancare/report/order-issue",
        component: ReportOrderIssue,
        auth: true,
    },
    {
        path: "/my-womancare/my-loyalty-program",
        component: MyLoyaltyProgram,
        auth: true,
    },
    {
        path: "/resources/videos-and-tutorials",
        component: VideosAndTutorials,
        auth: true,
    },
    {
        path: "/resources/videos-and-tutorials/:itemId",
        component: TutorialDetails,
        auth: true,
    },
    {
        path: "/resources/marketing-materials",
        component: MarketingMaterial,
        auth: true,
    },
    {
        path: "/resources/marketing-materials/:itemId",
        component: MarketingMaterialDetails,
        auth: true,
    },
    {
        path: "/resources/pos-and-pop",
        component: PosAndPop,
        auth: true,
    },
    {
        path: "/resources/pos-and-pop/:itemId",
        component: PosAndPopDetails,
        auth: true,
    },
    {
        path: "/resources/market-data",
        component: MarketData,
        auth: true,
    },
    {
        path: "/resources/market-data/:itemId",
        component: MarketDataDetails,
        auth: true,
    },
    {
        path: "/resources/best-practices",
        component: BestPractices,
        auth: true,
    },
    {
        path: "/resources/best-practices/:itemId",
        component: BestPracticeDetails,
        auth: true,
    },
    {
        path: "/resources/womancare-academy",
        component: WomanCareAcademy,
        auth: true,
    },
    {
        path: "/resources/womancare-academy/:resourceId",
        component: WomanCareAcademyDetails,
        auth: true,
    },
    {
        path: "/resources",
        component: Resources,
        auth: true,
    },
    {
        path: "/news",
        component: NewsList,
    },
    {
        path: "/news/:newsId",
        component: NewsPage,
    },
    {
        path: "/events",
        component: EventsList,
    },
    {
        path: "/404",
        component: NotFound,
    },
];
