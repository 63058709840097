import { createSelector } from "reselect";
import { computeOrderPromoCodeDiscountOnTotal } from "../utils/orders.utils";
import { computeProductPrices } from "../utils/products.utils";
import {
    atomizeByCondition,
    atomizeById,
    combineFilters,
    except,
    mapAndSelect,
    sumByKey,
} from "./utils.selectors";

const getRegistrations = (state: any) => state.distributor.registrations || [];
const getProductCategories = (state: any) => state.shop.productCategories || [];
const getProductSubCategories = (state: any) => state.shop.productSubCategories || [];
const getProducts = (state: any) => state.shop.products || [];
const getCountries = (state: any) => state.shop.countries || [];
const getCart = (state: any) => state.order.cart || [];
const getPromoCode = (state: any) => state.order.promoCode;
const getFilters = (state: any) => state.shop.filters || {};
const getDistributor = (state: any) => state.distributor || {};

const sortByName = (products) =>
    products.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
const sortByCountryName = (x) =>
    x.sort((a, b) => {
        if (!a.country) {
            return 1;
        }

        if (!b.country) {
            return -1;
        }

        return a.country.name < b.country.name ? -1 : a.country.name > b.country.name ? 1 : 0;
    });
const sortByAvailability = (products) =>
    products.sort((a, b) =>
        a.available && !b.available ? -1 : !a.available && b.available ? 1 : 0
    );

const filterBySearch = (search) => (products) => {
    if (search) {
        return products.filter((product) => {
            const regex = new RegExp(`.*${search || ""}.*`, "i");
            return (
                regex.test(product.name) ||
                regex.test(product.reference) ||
                regex.test(product.productSubCategory.name) ||
                regex.test(product.productSubCategory.productCategory.name)
            );
        });
    } else {
        return products;
    }
};

const filterByCategory = (categoryId) => (products) =>
    categoryId
        ? products.filter(
              (product) => product.productSubCategory.productCategory.id === +categoryId
          )
        : products;

const filterBySubCategory = (subCategoryId) => (products) =>
    subCategoryId
        ? products.filter((product) => product.productSubCategory.id === +subCategoryId)
        : products;

const filterByCountry = (countryId) => (products) =>
    countryId
        ? products.filter((product) =>
              product.countries.some((country) => country.id === +countryId)
          )
        : products;

const isAvailable = (registration) =>
    [
        "REGISTERED",
        "UNDER_RENEWAL",
        "ELIGIBLE_FOR_IMPORT_WITH_LETTER",
        "REGISTRATION_NOT_REQUIRED_OR_ELIGIBLE_FOR_IMPORT",
    ].includes(registration.status);

const productWithRegistrations = (product, registrations) => {
    const productRegistrations = registrations.filter(
        (registration) => registration.product.id === product.id && isAvailable(registration)
    );

    const formattedRegistrations = productRegistrations.map((registration) => {
        const formattedRegistration = {
            ...registration,
            effectivePrice: registration.price * (1 - (registration.discount || 0) / 100),
        };

        delete formattedRegistration.product;

        return formattedRegistration;
    });

    return {
        ...product,
        registrations: formattedRegistrations,
        countries: productRegistrations.map((registration) => registration.country),
        available: productRegistrations.length >= 1,
        minPrice: Math.min(
            ...formattedRegistrations.map((registration) => registration.effectivePrice)
        ),
    };
};

const productWithRegistration = (product, countryId, registrations, promoCode) => {
    const productRegistration = registrations.find(
        (registration) =>
            registration.product.id === product.id && registration.country.id === countryId
    );

    return {
        ...product,
        ...computeProductPrices(productRegistration, product.quantity, promoCode),
    };
};

export default {
    byId: (id) => (state: any) => {
        const product = getProducts(state).find((product) => product.id === +id);
        return product ? productWithRegistrations(product, getRegistrations(state)) : null;
    },

    byMBDreferenceCode: (MBDreferenceCode) => (state: any) => {
        const product = getProducts(state).find(
            (product) => product.MBDreferenceCode === MBDreferenceCode
        );
        return product;
    },

    bySubCategory: (id, productIdToExclude?) => (state: any) =>
        productWithRegistrations(
            getProducts(state).filter(
                (product) =>
                    product.productSubCategory.id === +id &&
                    (!productIdToExclude || product.id !== +productIdToExclude)
            ),
            getRegistrations(state)
        ),

    byCategory: (id, productIdToExclude?) => (state: any) =>
        productWithRegistrations(
            getProducts(state).filter(
                (product) =>
                    product.productSubCategory.productCategory.id === +id &&
                    (!productIdToExclude || product.id !== +productIdToExclude)
            ),
            getRegistrations(state)
        ),

    productsRegistrations: (productIds) => (state: any) => {
        return productIds.map((productId) =>
            productWithRegistrations(
                getProducts(state).find((x) => x.id === productId),
                getRegistrations(state)
            )
        );
    },

    searchResults: createSelector(
        getProductCategories,
        getProductSubCategories,
        getProducts,
        getFilters,
        getRegistrations,
        (productCategories, productSubCategories, products, filters, registrations) => {
            const regex = new RegExp(`.*${filters.search || ""}.*`, "i");

            const matchedCategories = productCategories.filter((x) => regex.test(x.name));

            const matchedSubCategories = productSubCategories.filter((x) => regex.test(x.name));

            const matchedProducts = filterBySearch(filters.search)(products);

            return matchedCategories.map((category) => ({
                ...category,
                productSubCategories: matchedSubCategories
                    .filter((subCategory) => subCategory.productCategory.id === category.id)
                    .map((subCategory) => ({
                        ...subCategory,
                        products: matchedProducts
                            .filter((product) => product.productSubCategory.id === subCategory.id)
                            .map((product) => productWithRegistrations(product, registrations)),
                    })),
            }));
        }
    ),

    byCurrentDistributor: createSelector(
        getProducts,
        getRegistrations,
        (products, registrations) => {
            const registrationsProducts = atomizeById(
                registrations.reduce((acc, registration) => {
                    const fullProduct = products.find(
                        (product) => product.id === registration.product.id
                    );

                    return [...acc, ...(fullProduct ? [fullProduct] : [])];
                }, [])
            );

            const countriesByProduct = registrationsProducts
                .map((product) => productWithRegistrations(product, registrations))
                .filter((product) => product.available);

            return sortByName(sortByAvailability(countriesByProduct));
        }
    ),

    byCurrentDistributorAndCountry: createSelector(getRegistrations, (registrations) => {
        const countries = registrations.reduce(
            (acc, registration) => [
                ...acc,
                ...(acc.some((country) => country.id === registration.country.id)
                    ? []
                    : [registration.country]),
            ],
            []
        );

        const productsByCountry = countries.map((country) => ({
            ...country,
            products: registrations
                .filter((registration) => registration.country.id === country.id)
                .map((registration) => {
                    const formattedRegistration = {
                        ...registration,
                        ...registration.product,
                        available: isAvailable(registration),
                    };

                    delete formattedRegistration.product;
                    delete formattedRegistration.country;

                    return formattedRegistration;
                }),
        }));

        return productsByCountry;
    }),

    categories: createSelector(getProducts, (products) =>
        atomizeById(products.map((product) => product.productSubCategory.productCategory))
    ),

    subCategories: createSelector(getProducts, getFilters, (products, filters) =>
        atomizeById(products.map((product) => product.productSubCategory)).filter(
            (subCategory) =>
                !filters.productCategory ||
                filters.productCategory === subCategory.productCategory.id
        )
    ),

    countries: createSelector(getRegistrations, (registrations) =>
        atomizeById(registrations.map((registration) => registration.country))
    ),

    cart: createSelector(
        getCart,
        getProducts,
        getCountries,
        getRegistrations,
        getDistributor,
        getPromoCode,
        (cart, products, countries, registrations, distributor, promoCode) => {
            const cartProducts = cart.reduce((acc, cartItem) => {
                const product = products.find((product) => product.id === cartItem.productId);

                if (product)
                    return [
                        ...acc,
                        {
                            ...cartItem,
                            ...product,
                        },
                    ];

                return acc;
            }, []);

            const sections = sortByCountryName(
                atomizeByCondition(
                    mapAndSelect(cartProducts, ["countryId", "productSubCategory"]),
                    (section, product) =>
                        section.countryId === product.countryId &&
                        section.productSubCategory.id === product.productSubCategory.id
                ).map((section) => {
                    const sectionId = `${section.countryId}_${section.productSubCategory.id}`;

                    const promoCodeToUse =
                        promoCode && promoCode.sectionId === sectionId ? promoCode : null;

                    const products = sortByName(
                        cartProducts
                            .filter(
                                (cartItem) =>
                                    section.countryId === cartItem.countryId &&
                                    section.productSubCategory.id === cartItem.productSubCategory.id
                            )
                            .map((cartItem) =>
                                productWithRegistration(
                                    cartItem,
                                    section.countryId,
                                    registrations,
                                    promoCodeToUse
                                )
                            )
                    );

                    const sectionAddress = distributor.shippingAddresses.find(
                        (x) => x.isPrimary && x.country.id === section.countryId
                    );

                    const nonPromoCodeDiscountedTotal = sumByKey(
                        products,
                        "totalNonPromoCodeDiscountedPrice"
                    );

                    const { effectiveDiscountOnTotal } = computeOrderPromoCodeDiscountOnTotal(
                        nonPromoCodeDiscountedTotal,
                        promoCodeToUse
                    );

                    return {
                        ...section,
                        sectionId,
                        country: countries.find((x) => x.id === section.countryId),
                        address: sectionAddress ? sectionAddress.name : null,
                        products,
                        total: sumByKey(products, "totalEffectivePrice") - effectiveDiscountOnTotal,
                        nonPromoCodeDiscountedTotal,
                        promoCodeDiscount:
                            sumByKey(products, "promoCodeDiscount") + effectiveDiscountOnTotal,
                    };
                })
            );

            return {
                sections,
                total: sumByKey(sections, "total"),
            };
        }
    ),

    applyFilters: createSelector(
        getProducts,
        getFilters,
        getRegistrations,
        (products, filters, registrations) => {
            return combineFilters(
                filterBySearch(filters.search),
                filterByCategory(filters.productCategory),
                filterBySubCategory(filters.productSubCategory),
                filterByCountry(filters.country),
                sortByName
            )(
                products
                    .map((product) => productWithRegistrations(product, registrations))
                    .filter((product) => product.available)
            );
        }
    ),
};
