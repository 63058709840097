import { createSelector } from "reselect";
import {
    applyCareCoinsUseOnOrderTotal,
    CareCoinValue,
    computeOrderPromoCodeDiscountOnTotal,
    computeOrderTotal,
} from "../utils/orders.utils";
import { computeProductPrices } from "../utils/products.utils";
import { sumByKey } from "./utils.selectors";

const getDistributor = (state) => state.distributor;
const getOrder = (state) => state.order;
const getCart = (state) => state.order.cart || [];
const getPromoCode = (state: any) => state.order.promoCode;
const getProducts = (state) => state.shop.products || [];
const getRegistrations = (state) => state.distributor.registrations || [];
const getLoyalty = (state) => state.distributor.loyalty || {};
const getCountries = (state) => state.shop.countries || [];

const OrderSelectors = {
    defaultShippingAddress: createSelector(getOrder, getDistributor, (order, distributor) =>
        order.shippingAddress.id
            ? order.shippingAddress
            : distributor.shippingAddresses.find(
                  (x) => x.isPrimary && x.country && x.country.id === order.countryId
              )
    ),

    defaultBillingAddress: createSelector(getOrder, getDistributor, (order, distributor) =>
        order.billingAddress.id
            ? order.billingAddress
            : distributor.billingAddresses.find((x) => x.isPrimary)
    ),

    orderProducts: createSelector(
        getOrder,
        getCart,
        getProducts,
        getRegistrations,
        getLoyalty,
        getPromoCode,
        getCountries,
        (order, cart, products, registrations, loyalty, promoCode, countries) => {
            const orderedProducts = cart
                .filter((cartItem) => cartItem.countryId === order.countryId)
                .map((cartItem) => {
                    const product = products.find((product) => product.id === cartItem.productId);
                    const registration = registrations.find(
                        (x) => x.product.id === product.id && x.country.id === order.countryId
                    );

                    return {
                        ...cartItem,
                        ...product,
                        registration,
                        ...computeProductPrices(registration, cartItem.quantity, promoCode),
                    };
                })
                .filter((product) => product.productSubCategory.id === order.productSubCategoryId);

            const nonPromoCodeDiscountedTotal = sumByKey(
                orderedProducts,
                "totalNonPromoCodeDiscountedPrice"
            );

            const { discountOnTotal, effectiveDiscountOnTotal } =
                computeOrderPromoCodeDiscountOnTotal(nonPromoCodeDiscountedTotal, promoCode);

            const nonCareCoinsDiscountedTotal = computeOrderTotal(orderedProducts, discountOnTotal);

            const country = countries.find((x) => x.id === order.countryId);

            return {
                sectionId: `${order.countryId}_${order.productSubCategoryId}`,
                products: orderedProducts,
                nonPromoCodeDiscountedTotal,
                totalPromoCodeDiscount:
                    sumByKey(orderedProducts, "promoCodeDiscount") + effectiveDiscountOnTotal,
                nonCareCoinsDiscountedTotal: Math.round(nonCareCoinsDiscountedTotal * 100) / 100,
                careCoinsDiscount: order.careCoinsToUse * CareCoinValue,
                careCoinsAfterOrder: Math.round(loyalty.careCoinsBalance - order.careCoinsToUse),
                total: applyCareCoinsUseOnOrderTotal(
                    nonCareCoinsDiscountedTotal,
                    order.careCoinsToUse
                ),
                country,
            };
        }
    ),
};

export default OrderSelectors;
