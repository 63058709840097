import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";

import "./filters.scss";
import Button from "../../components/forms/Button";
import Close from "../../components/icons/Close";
import ProductsSelectors from "../../selectors/products.selectors";
import ShopSlice from "../../store/slices/shop.slice";
import { store } from "../../store/configure.store";
import DropdownInput from "../../components/forms/DropdownInput";
import { useOutsideAlerter } from "../user/Documents";

const FilterCriterion = ({ label, onRemove }) => (
    <div className="criterion">
        <div className="label">{label}</div>
        <Close onClick={onRemove} circle color="secondary" />
    </div>
);

export default () => {
    const location = useLocation();
    const { subCategory } = queryString.parse(location.search);
    const wrapperRef = React.useRef(null);
    useOutsideAlerter(wrapperRef, () => setShowFilters(null));

    const categories = useSelector((state: any) => ProductsSelectors.categories(state));
    const subCategories = useSelector((state: any) => ProductsSelectors.subCategories(state));
    const countries = useSelector((state: any) => ProductsSelectors.countries(state));

    const filters = useSelector((state: any) => state.shop.filters);

    const setCategoryFilter = (id) => {
        store.dispatch(ShopSlice.actions.setProductCategoryFilter(id));
    };

    const setSubCategoryFilter = (id) => {
        store.dispatch(ShopSlice.actions.setProductSubCategoryFilter(id));
    };

    const setCountryFilter = (id) => {
        store.dispatch(ShopSlice.actions.setProductCountryFilter(id));
    };

    useEffect(() => {
        setSubCategoryFilter(null);
    }, [filters.productCategory]);

    useEffect(() => {
        if (subCategory) {
            setSubCategoryFilter(subCategory);
            const subCategoryData = subCategories.find((x) => x.id === +subCategory);
            if (subCategoryData) {
                setCategoryFilter(subCategoryData.productCategory.id);
            }
        } else {
            store.dispatch(ShopSlice.actions.resetFilters());
        }
    }, [subCategory]);

    const nameFromId = (array, id) => (array.find((x) => x.id === +id) || {}).name || "";

    const [showFilters, setShowFilters] = useState(false);

    const filteredSubCategories = filters.productCategory
        ? subCategories.filter((x) => x.productCategory.id === +filters.productCategory)
        : [];

    return (
        <div className="shop-filters" ref={wrapperRef}>
            <Button
                text="Filter by"
                onClick={() => setShowFilters((prev) => !prev)}
                color="dark"
                icon="caret"
            />
            {showFilters ? (
                <>
                    <div className="inputs">
                        <DropdownInput
                            title="Category"
                            label="Select category"
                            items={categories.map((x) => ({ value: x.id, label: x.name }))}
                            value={filters.productCategory}
                            onChange={setCategoryFilter}
                            allowNull
                            itemsClass="semi-round"
                        />
                        <DropdownInput
                            title="Sub-category"
                            label="Select sub-category"
                            items={filteredSubCategories.map((x) => ({
                                value: x.id,
                                label: x.name,
                            }))}
                            value={filters.productSubCategory}
                            onChange={setSubCategoryFilter}
                            allowNull
                            itemsClass="semi-round"
                        />
                        <DropdownInput
                            title="Country"
                            label="Select country"
                            items={countries.map((x) => ({ value: x.id, label: x.name }))}
                            value={filters.country}
                            onChange={setCountryFilter}
                            allowNull
                            itemsClass="semi-round"
                        />
                    </div>
                    {filters.productCategory || filters.productSubCategory || filters.country ? (
                        <div className="criteria">
                            {filters.productCategory ? (
                                <FilterCriterion
                                    label={nameFromId(categories, filters.productCategory)}
                                    onRemove={() => setCategoryFilter(null)}
                                />
                            ) : null}
                            {filters.productSubCategory ? (
                                <FilterCriterion
                                    label={nameFromId(subCategories, filters.productSubCategory)}
                                    onRemove={() => setSubCategoryFilter(null)}
                                />
                            ) : null}
                            {filters.country ? (
                                <FilterCriterion
                                    label={nameFromId(countries, filters.country)}
                                    onRemove={() => setCountryFilter(null)}
                                />
                            ) : null}
                        </div>
                    ) : null}
                    <Link
                        className="primary see-all"
                        to="#"
                        onClick={() => store.dispatch(ShopSlice.actions.resetFilters())}
                    >
                        See all products
                    </Link>
                </>
            ) : null}
        </div>
    );
};
