import { del, get, post } from "./utils.service";

export const getNextOrders = async () => {
    try {
        const res = await get("/forecast/next");
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const upsertForecast = async (data) => {
    const res = await post(`/forecast/upsert`, data);
    return await res.json();
};

export const deleteForecast = async (id) => {
    const res = await del(`/forecast/${id}`);
    return await res.json();
};
