import React from "react";
import { Link } from "react-router-dom";
import Button from "../forms/Button";
import Modal from "../Modal";
import "./requestModal.scss";

export default ({ onClose, response = null }) => (
    <Modal className="request-modal" onClose={onClose} closeOnBackgroundClick>
        {response?.success ? (
            <div className="content">
                Your request has been sent to your sales representative, you can trace it in{" "}
                <Link className="primary" to="/my-womancare/customer-service">
                    Customer requests
                </Link>
            </div>
        ) : (
            <div className="content">{response?.message}</div>
        )}
        <Button text="Close" onClick={onClose} />
    </Modal>
);
