import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BackLink from "../../components/BackLink";
import Button from "../../components/forms/Button";
import DropdownInput from "../../components/forms/DropdownInput";
import Play from "../../components/icons/Play";
import Item from "../../components/resources/Item";
import ResourceSelectors from "../../selectors/resource.selectors";
import { getFile } from "../../utils/s3.utils";
import "./videoAndTutorialDetails.scss";

export default () => {
    const history = useHistory();
    const { itemId }: any = useParams();

    const [sameResourceItems, setSameResourceItems] = useState([]);
    const [displayedItems, setDisplayedItems] = useState([]);

    const item = useSelector(ResourceSelectors.videoAndTutorialbyId(itemId));
    const allItems = useSelector(ResourceSelectors.getVideosAndTutorials());

    const redirectOtherLanguage = (id) => {
        history.push(`/resources/tutorial-details/${id}`);
    };

    useEffect(() => {
        const relatedItems = [...allItems]
            .filter((x) => x.language.id === item.language.id && x.id != item.id)
            .sort((a, b) => (a.creationDate < b.creationDate ? 1 : -1))
            .slice(0, 3);
        const inCommonItems = allItems
            .filter((x) => x.resource.id === item.resource.id)
            .reduce((acc, obj) => {
                const key = obj.language.id;
                if (!acc[key]) {
                    acc[key] = { label: obj.language.name, value: obj.id };
                }
                return acc;
            }, {});
        setDisplayedItems(relatedItems);
        setSameResourceItems(Object.values(inCommonItems));
    }, [allItems]);

    return (
        <div id="tutorialDetails" className="profile">
            <div id="content">
                <BackLink label="Back to resources" to="/resources/videos-and-tutorials/" />
                <div className="page-title">
                    <h1>{item.title}</h1>
                    {sameResourceItems.length > 1 ? (
                        <DropdownInput
                            label=""
                            items={sameResourceItems}
                            onChange={(x) => redirectOtherLanguage(x)}
                            color="primary"
                            value={item.id}
                        />
                    ) : null}
                </div>
                <div className="player-margins">
                    <div className="player-wrapper">
                        <ReactPlayer
                            className="react-player"
                            url={getFile(item.file)}
                            controls={true}
                            volumn={null}
                            light={getFile(item.image)}
                            width="100%"
                            height="100%"
                            playing={true}
                            playsinline={true}
                            playIcon={
                                <div className="play-wrapper">
                                    <Play color="white" />
                                </div>
                            }
                        ></ReactPlayer>
                    </div>
                </div>
                <div className="introduction">{item.introduction}</div>
                {item.product ? (
                    <Button
                        text={`Discover the ${item.product.name}`}
                        to={`/product/${item.product?.id}`}
                    />
                ) : null}
                <div className="content">{item.content}</div>
                <div className="related-products">
                    <h2>Related Products</h2>
                    <div className="items">
                        {displayedItems.map((item, index) => (
                            <Item
                                key={index}
                                item={item}
                                onClickLink={`/resources/tutorial-details/${item.id}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
