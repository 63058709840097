import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistCombineReducers, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";

import MeSlice from "./slices/me.slice";
import ProfileSlice from "./slices/profile.slice";
import DistributorSlice from "./slices/distributor.slice";
import AuthSlice from "./slices/auth.slice";
import ShopSlice from "./slices/shop.slice";
import OrderSlice from "./slices/order.slice";
import AppSlice from "./slices/app.slice";
import ResourceSlice from "./slices/resource.slice";

const persistConfig = {
    key: "root",
    whitelist: ["app", "me", "distributor", "shop", "order", "resource"],
    storage,
};

export const store = configureStore({
    reducer: persistCombineReducers(persistConfig, {
        app: AppSlice.reducer,
        me: MeSlice.reducer,
        profile: ProfileSlice.reducer,
        distributor: DistributorSlice.reducer,
        auth: AuthSlice.reducer,
        shop: ShopSlice.reducer,
        order: OrderSlice.reducer,
        resource: ResourceSlice.reducer,
    }),
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
