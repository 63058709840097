import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Eye from "../../components/icons/Eye";
import LinkButton from "../../components/LinkButton";
import RoundRating from "../../components/RoundRating";
import StatusLink from "../../components/StatusLink";
import { useBonusTrocarsManagement } from "../../hooks/useBonusTrocarsManagement";
import DistributorSelectors from "../../selectors/distributor.selectors";
import { confirmOrReject } from "../../services/orders.service";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import OrderSlice from "../../store/slices/order.slice";
import "./orders.scss";

export default () => {
    const history = useHistory();
    const { orders } = useSelector((state: any) => DistributorSelectors.ordersData(state));

    useBonusTrocarsManagement();

    const displayedOrders = orders
        .sort((a, b) => (a.creationDate > b.creationDate ? -1 : 1))
        .slice(0, 3);

    const Cols = [
        "Details",
        "N° order",
        "Order date",
        "Total price",
        "Destination",
        "Status",
        "",
        "",
        "Actions",
    ];

    const confirm = async (id) => {
        const res = await confirmOrReject({
            orderId: id,
            status: "CONFIRMED",
        });

        const { orderId, fields }: any = res.updatedFields;
        store.dispatch(DistributorSlice.actions.setOrderAttribute({ id: orderId, fields: fields }));
    };

    const redoOrder = (order) => {
        const products = order.products.map((product) => {
            return {
                productId: product?.id,
                countryId: product.country?.id,
                quantity: product.quantity,
                packagingId: product.packaging?.id || null,
            };
        });

        store.dispatch(
            OrderSlice.actions.addProductsToCart({
                products: products,
            })
        );
        history.push("/cart");
    };

    const edit = (order) => {
        history.push("/profile/order/" + order.id + "/edit/");
    };

    return (
        <div className="orders-table">
            <div className="table-title-block">
                <h2 className="title">Your orders</h2>
                <LinkButton label="See all my orders" to="/profile/orders/" />
            </div>
            <table>
                <tbody>
                    {displayedOrders.map((order) => (
                        <tr key={order.MBDreferenceCode || order.id}>
                            <td data-label={Cols[0]}>
                                <Eye color="primary" url={"/profile/order/" + order.id} />
                            </td>
                            <td data-label={Cols[1]}>{order.num}</td>
                            <td data-label={Cols[2]}>{order.date}</td>
                            <td data-label={Cols[3]}>{order.total}</td>
                            <td data-label={Cols[4]}>{order.destination}</td>
                            <td data-label={Cols[5]} className={order.statusData.statusTextColor}>
                                {["SHIPPED", "DELIVERED"].includes(order.status) &&
                                order.deliveryDate
                                    ? `${order.statusData.statusLabel}` +
                                      `${order.status === "SHIPPED" ? " since " : " on "}` +
                                      `${order.deliveryDate}`
                                    : order.statusData.statusLabel}
                            </td>
                            <td data-label={Cols[6]}>
                                {order.statusData.hasStatusLink ? (
                                    <StatusLink
                                        icon={order.statusData.icon}
                                        label={order.statusData.linkText}
                                        color="secondary"
                                        to={"/profile/order/" + order.id + "/edit/"}
                                    />
                                ) : null}
                            </td>
                            <td data-label={Cols[7]}>
                                <RoundRating rating={order.statusData.percentage / 100} />
                            </td>
                            <td data-label={Cols[8]}>
                                <div id="actionsCell">
                                    {order.statusData.actions
                                        ? order.statusData.actions.map((element, index) => (
                                              <LinkButton
                                                  key={index}
                                                  label={element.value}
                                                  onClick={
                                                      element.key === "APPROVE_QUOTE"
                                                          ? () => confirm(order.id)
                                                          : element.key === "REDO_ORDER"
                                                          ? () => redoOrder(order)
                                                          : element.key === "EDIT_ORDER"
                                                          ? () => edit(order)
                                                          : null
                                                  }
                                              />
                                          ))
                                        : null}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
