import "./tableItem.scss";
import React from "react";

interface TableItemProps {
    visibleBadge?: boolean;
    events?: string[];
}

export const TableItem: React.FC<TableItemProps> = (props) => {
    return (
        <div>
            <div className="calendar-tooltip">
                <div className="calendar-cell">
                    <span className="calendar-text">{props.children}</span>
                    {props.visibleBadge && <span className="calendar-badge" />}
                </div>
                {props.visibleBadge && (
                    <div className="calendar-right">
                        <div className="calendar-text-content">
                            <ul>
                                {props.events?.map((event: any, index: number) => {
                                    return (
                                        <li key={index}>
                                            {index !== 0 && <hr></hr>}
                                            <h4>{new Date(event?.date).toLocaleDateString('US')}</h4>
                                            <h3>{event?.title}</h3>
                                            <p>{event?.description}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="calendar-tooltip-body" />
                    </div>
                )}
            </div>
        </div>
    );
};
