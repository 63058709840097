import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DropdownSection from "../../components/DropdownSection";
import Button from "../../components/forms/Button";
import InfiniteSelect from "../../components/forms/InfiniteSelect";
import { RadioInput } from "../../components/forms/RadioInput";
import Close from "../../components/icons/Close";
import CartBonusItem from "../../components/order/CartBonusItem";
import OrderStepper from "../../components/order/Stepper";
import { useBonusTrocarsManagement } from "../../hooks/useBonusTrocarsManagement";
import ProductsSelectors from "../../selectors/products.selectors";
import { sumByKey } from "../../selectors/utils.selectors";
import { store } from "../../store/configure.store";
import OrderSlice from "../../store/slices/order.slice";
import { formatNumber, formatPrice } from "../../utils/numeric.utils";
import { getFile } from "../../utils/s3.utils";
import "./cart.scss";

export const Columns = ["Product", "Shipping to", "Per unit price", "Quantity", "Total"];
const AvailableSpecialRequests = ["Documents", "Packing", "Need for pre-shipment inspection"];

const CartSection = ({ section, products }) => {
    const history = useHistory();

    const me = useSelector((state: any) => state.distributor);

    const order = useSelector((state: any) => state.order);

    const removeProduct = (countryId, productId) => {
        store.dispatch(OrderSlice.actions.removeProductFromCart({ countryId, productId }));
    };

    const setQuantity = (countryId, productId, quantity) => {
        store.dispatch(
            OrderSlice.actions.setProductQuantityInCart({ countryId, productId, quantity })
        );
    };

    const setSpecialRequest = (specialRequest) => {
        store.dispatch(OrderSlice.actions.setSpecialRequest(specialRequest));
    };

    const placeOrder = () => {
        store.dispatch(
            OrderSlice.actions.setCountryAndProductSubCategory({
                countryId: section.countryId,
                productSubCategoryId: section.productSubCategory.id,
            })
        );

        if (order.shippingAddress.id && order.billingAddress.id) {
            if (
                order.shipping.method &&
                ((order.shipping.method === "EXW" && order.shipping.destination) ||
                    (order.shipping.method === "FOB" && order.shipping.destination) ||
                    (order.shipping.method === "CIP" && order.shipping.destination))
            ) {
                history.push("/order/certificates");
            } else {
                history.push("/order/shipping");
            }
        } else {
            const shippingAddresses = me.shippingAddresses.find(
                (x) => x.isPrimary && x.country && x.country.id === section.countryId
            );
            const billingAddresses = me.billingAddresses.find((x) => x.isPrimary);

            if (shippingAddresses) {
                store.dispatch(OrderSlice.actions.setShippingAddress(shippingAddresses));
            }

            if (billingAddresses) {
                store.dispatch(OrderSlice.actions.setBillingAddress(billingAddresses));
            }

            history.push("/order/address");
        }
    };

    return (
        <div className="products">
            <table>
                <thead>
                    <tr>
                        {Columns.map((column, index) => (
                            <th key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {products.map((product) => (
                        <tr key={product.id}>
                            <td data-label={Columns[0]}>
                                <div className="product-block">
                                    <div className="image-wrapper">
                                        <img
                                            className="image"
                                            src={getFile(product.image)}
                                            alt="Product"
                                        />
                                        <div
                                            className="close-wrapper"
                                            onClick={() =>
                                                removeProduct(section.countryId, product.id)
                                            }
                                        >
                                            <Close color="white" />
                                        </div>
                                    </div>
                                    <div className="title">
                                        <div className="name">{product.name}</div>
                                        <div className="reference">{product.reference}</div>
                                    </div>
                                </div>
                            </td>
                            <td data-label={Columns[1]}>{section.country.name}</td>
                            <td data-label={Columns[2]}>
                                {formatPrice(product.nonPromoCodeDiscountedPrice)}
                            </td>
                            <td data-label={Columns[3]}>
                                <InfiniteSelect
                                    minValue={product.minimumOrderQuantity}
                                    step={product.orderQuantityStep}
                                    value={product.quantity}
                                    onChange={(x) => setQuantity(section.countryId, product.id, x)}
                                    className="in-order"
                                />
                            </td>
                            <td data-label={Columns[4]}>
                                {formatPrice(product.totalNonPromoCodeDiscountedPrice)}
                            </td>
                        </tr>
                    ))}
                    {order.cartBonus
                        .filter(
                            (cartBonusProduct) => cartBonusProduct.sectionId === section.sectionId
                        )
                        .map((cartBonusProduct, i) => (
                            <CartBonusItem
                                key={i}
                                cartBonusProduct={cartBonusProduct}
                                countryName={section.country.name}
                            />
                        ))}
                </tbody>
            </table>
            <div className="validation">
                <div className="totals">
                    <div className="row subtotal">
                        <div className="label">Sub-total (excl. VAT)</div>
                        <div className="value">
                            {formatPrice(section.nonPromoCodeDiscountedTotal)}
                        </div>
                    </div>

                    <div className="row total">
                        <div className="label">Total (excl. VAT)</div>
                        <div className="value">{formatPrice(section.total)}</div>
                    </div>
                </div>
            </div>
            <div className="buttons">
                <Button text="Place my order" onClick={placeOrder} color="dark" />
                <Button text="Add another product" to="/shop" color="white" hasBorder />
            </div>
            <RadioInput
                label="Do you have any special request?"
                sublabel="Regarding"
                items={AvailableSpecialRequests.map((x) => ({ value: x, label: x }))}
                value={order.specialRequest}
                onChange={(value) => setSpecialRequest(value)}
                allowCustomValues
                vertical
                color="primary"
            />
        </div>
    );
};

export default () => {
    const cartData = useSelector((state: any) => ProductsSelectors.cart(state));

    useBonusTrocarsManagement();

    return (
        <div id="cart">
            <OrderStepper currentStep={0} />
            <h1>My cart</h1>
            <div className="content">
                <div className="sections">
                    {cartData.sections.map((section, index) => (
                        <DropdownSection
                            key={index}
                            title={`${section.country.name}: ${section.productSubCategory.name}${
                                section.address ? ` - ${section.address}` : ""
                            }`}
                            contentComponent={() => (
                                <CartSection section={section} products={section.products} />
                            )}
                            openByDefault
                        />
                    ))}
                </div>
                <div className="summary">
                    <h2>Summary</h2>
                    <div className="sections">
                        {cartData.sections.map((section, index) => (
                            <div key={index} className="section">
                                <div className="title">{`${section.country.name}: ${
                                    section.productSubCategory.name
                                }${section.address ? ` - ${section.address}` : ""}`}</div>
                                <div className="products">
                                    {section.products.map((product) => (
                                        <div key={product.id} className="product">
                                            <div className="name">{product.name}</div>
                                            <div className="row">
                                                <div className="title">Total quantity</div>
                                                <div className="value">
                                                    {formatNumber(product.quantity, true)}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="title">Total price</div>
                                                <div className="value">
                                                    {formatPrice(product.totalEffectivePrice)}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="total">
                        <div className="title">
                            Order total price
                            <br />
                            (excl. VAT):
                        </div>
                        <div className="value">{formatPrice(cartData.total)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
