import React, { useEffect, useState } from "react";
import CaretLink from "../../components/CaretLink";
import MyDataChart from "../../components/forms/MyDataChart";
import LinkButton from "../../components/LinkButton";
import ForecastsSelectors from "../../selectors/forecasts.selectors";
import { getStatistics } from "../../services/distributors.service";
import { getNextOrders } from "../../services/forecasts.service";
import "./myData.scss";

export default () => {
    const [allData, setAllData] = useState(null);
    const [nextOrders, setNextOrders] = useState([]);

    const formattedNextOrders = ForecastsSelectors.nextOrders(nextOrders);

    const getData = async () => {
        const response = await getStatistics();

        const data = response.data.map((x) => ({
            ...x,
            key: x.year * 100 + x.month,
        }));
        return data;
    };

    const setupChart = async () => {
        const res = await getData();
        setAllData(res);
    };

    const getNextOrdersData = async () => {
        const res = await getNextOrders();
        setNextOrders(res);
    };

    useEffect(() => {
        setupChart();
        getNextOrdersData();
    }, []);

    return (
        <div id="mydata" className="profile">
            <h1>My data</h1>
            <div className="links">
                <CaretLink to="/profile/forecasts" label="Forecasts" />
                <CaretLink to="/profile/documents" label="My documents" />
                <CaretLink to="/profile/labels" label="Label and artwork" />
                <CaretLink to="/profile/agreements" label="My commercial agreements" />
            </div>
            <div id="chartPage">
                {allData ? <MyDataChart data={allData} /> : <h2>Loading chart...</h2>}
                <div id="sideData">
                    <div id="totalOrders">
                        <p>Total quantity of products ordered</p>
                        <p>(since your account creation)</p>
                        <p id="total">
                            {allData
                                ? allData.reduce((acc, obj) => {
                                      acc += +obj.orderCount;
                                      return acc;
                                  }, 0)
                                : null}
                        </p>
                    </div>
                    <div id="history">
                        <LinkButton label="Access my purchase history" to="/profile/orders" />
                    </div>
                </div>
            </div>
            <div id="forecasts">
                <h2>Forecasts</h2>
                <LinkButton label="See all my forecasts" to="/profile/forecasts" />
                <div id="recentForecasts">
                    {formattedNextOrders.map((x) => (
                        <div key={x.forecastMonthId} className="recentForecast">
                            {`Place your order of ${x.orderQuantity} ${x.productName} by ${x.formattedMaxOrderDate} and receive it in ${x.formattedForecastDate}.`}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
