import React, { useState } from "react";
import { formatNumber } from "../../utils/numeric.utils";
import Caret from "../icons/Caret";
import "./infiniteSelect.scss";

type InfiniteSelectProps = {
    title?: string;
    label?: string;
    tooltip?: string;
    minValue: number;
    step: number;
    allowNull?: boolean;
    value?: number;
    onChange: (value: number) => void;
    color?: string;
    className?: string;
    style?: any;
};

export default (props: InfiniteSelectProps) => {
    const [showOptions, setShowOptions] = useState(false);
    const [options, setOptions] = useState([]);

    const increaseOptions = (actualArray) => {
        let min;
        let range;
        if (actualArray.length === 0) {
            min = props.minValue;
            range = 10;
        } else {
            min = actualArray[actualArray.length - 1].value + (props.step || 1);
            range = 1;
        }
        const next = Array(range)
            .fill(0)
            .map((_, index) => {
                const value = (min || 1) + index * (props.step || 1);
                return { value: value, label: formatNumber(value, true) };
            });
        return actualArray.concat(next);
    };

    return (
        <div
            className={`infinite-select ${props.color || "primary"} ${props.className || ""}`}
            style={props?.style}
            onMouseLeave={() => setShowOptions(false)}
        >
            {props.title ? <div className="title">{props.title}</div> : null}
            <div
                className="label"
                onClick={() => {
                    setShowOptions((prev) => !prev);
                    setOptions(increaseOptions(options));
                }}
            >
                <span>{props.value ? formatNumber(props.value, true) : props.label || ""}</span>
                <Caret
                    type="down"
                    color={
                        !props.color || ["primary", "secondary"].includes(props.color)
                            ? "primary"
                            : props.color
                    }
                    size={12}
                />
            </div>
            {showOptions && (
                <div className="options" onScroll={() => setOptions(increaseOptions(options))}>
                    {props.allowNull ? (
                        <div
                            className="item"
                            onClick={() => {
                                props.onChange(null);
                                setShowOptions(false);
                            }}
                        >
                            Default
                        </div>
                    ) : null}
                    {options.map((item, index) => (
                        <div
                            key={index}
                            className="option"
                            onClick={() => {
                                props.onChange(item.value);
                                setShowOptions(false);
                            }}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
