import React from "react";

import Colors from "../../themes/colors.theme";

export default (props: { color?: string; reversed?: boolean }) => (
    <div
        className="wave"
        style={{
            overflow: "hidden",
            opacity: 1,
            zIndex: 2,
        }}
    >
        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: "100%", width: "100%" }}>
            <path
                d={
                    props.reversed
                        ? "M-2.00,0.00 C250.00,420.00 250.00,-210.00 500.00,80.00 L500.00,150.00 L0.00,150.00 Z"
                        : "M-1.00,80.00 C250.00,-210.00 250.00,420.00 500.00,0.00 L500.00,150.00 L0.00,150.00 Z"
                }
                style={{ stroke: "none", fill: Colors[props.color] || "#FFFFFF" }}
            ></path>
        </svg>
    </div>
);
