import React, { useEffect, useState } from "react";
import Close from "../icons/Close";
import "./autocompleteTextInput.scss";
import TextInput from "./TextInput";

type AutocompleteTextInputProps = {
    label?: string;
    options: any[];
    value?: any;
    onChange: (value) => void;
    placeholder?: string;
    allowUnsuggestedValues?: boolean;
    keepValueInTextInput?: boolean;
    cancellable?: boolean;
    maxSuggestionsCount?: number;
    color?: string;
};

export default function AutocompleteTextInput(props: AutocompleteTextInputProps) {
    const [textInputValue, setTextInputValue] = useState(props.value || "");
    const [matchedOptions, setMatchedOptions] = useState([]);

    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        const regex = new RegExp(`^${textInputValue}`, "i");
        setMatchedOptions(props.options.filter((option) => regex.test(option.label)));
    }, [textInputValue]);

    const onTextInputChange = (text) => {
        if (props.allowUnsuggestedValues) {
            props.onChange(text);
        }
        setTextInputValue(text);
    };

    const onOptionPress = (option) => {
        props.onChange(option.value);
        setShowOptions(false);
        if (props.keepValueInTextInput) {
            setTextInputValue(option.label);
        } else {
            setTextInputValue("");
        }
    };

    return (
        <div className="autocomplete-text-input" onMouseLeave={() => setShowOptions(false)}>
            {props.label ? <div className="label">{props.label}</div> : null}
            <div className="row">
                <TextInput
                    value={textInputValue}
                    onChange={onTextInputChange}
                    onInput={() => setShowOptions(true)}
                    onClick={() => setShowOptions(true)}
                    placeholder={props?.placeholder}
                    color={props?.color}
                />
                {props.cancellable ? (
                    <Close
                        onClick={() => {
                            props.onChange(null);
                            setTextInputValue("");
                        }}
                        color={props?.color}
                    />
                ) : null}
            </div>
            {showOptions && matchedOptions.length >= 1 && (
                <div className="options">
                    {matchedOptions
                        .slice(0, props.maxSuggestionsCount || 5)
                        .map((option, index) => (
                            <div
                                className="option-wrapper"
                                key={index}
                                onClick={() => onOptionPress(option)}
                            >
                                <div className="option-label">{option.label}</div>
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
}
