import { createSelector } from "reselect";

const getMe = state => state.me;

const computeFullName = me => [me.firstname, me.lastname].join(" ");

const MeSelectors = {
    fullName: createSelector(getMe, me => computeFullName(me)),

    formattedMe: createSelector(getMe, me => ({
        ...me,
        fullName: computeFullName(me),
    })),
};

export default MeSelectors;
