import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    civility: null, // MR/MS/OTHER
    lastname: "",
    firstname: "",
    job: "",
    username: "",
    email: "",
    phone: "",
    phone2: "",
    password: "",
    company: {
        organizationName: "",
        address: "",
        address2: "",
        zipCode: "",
        city: "",
        countryId: null,
        email: "",
        phone: "",
    },
};

export default createSlice({
    name: "auth",
    initialState,
    reducers: {
        setData: (state, { payload }) => ({
            ...state,
            ...payload,
        }),
        reset: () => initialState,
    },
});
