import React from "react";
import "./textArea.scss";

type TextAreaProps = {
    label?: string;
    value?: string;
    color?: string;
    required?: boolean;
    onChange: (text: string) => void;
    placeholder?: string;
};

const TextArea = (props: TextAreaProps) => {
    return (
        <div className={`text-area-container ${props.color || ""}`}>
            {props.label ? (
                <div className="label-wrapper">
                    <div className="label">
                        {props.label || ""}
                        {props.required ? "*" : ""}
                    </div>
                </div>
            ) : null}
            <textarea
                className={`text-area`}
                rows={5}
                value={props.value || ""}
                onChange={(e) => props.onChange(e.target.value)}
                placeholder={props.placeholder || ""}
            ></textarea>
        </div>
    );
};

export default TextArea;
