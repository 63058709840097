import { get } from "./utils.service";

export const getCurrentCareCoinsAmount = async () => {
    try {
        const res = await get(`/carecoins/count`);
        return await res.json();
    } catch (e) {
        return null;
    }
};
