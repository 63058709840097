import React from "react";
import LinkButton from "../LinkButton";
import "./categoryCard.scss";

export default (props: { title: any; excerpt: string; onClickLink: string; logo: string }) => {
    return (
        <div className="category-card" id="resourceCategory">
            <div className="logo-wrapper">
                <img className="logo" src={props.logo} alt="Category logo" />
            </div>
            <div className="text-content">
                <div className="category-name">{props.title}</div>
                <div className="category-excerpt">{props.excerpt}</div>
                <LinkButton label="See more" color="primary" to={props.onClickLink} />
            </div>
        </div>
    );
};
