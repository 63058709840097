import React from "react";
import { Link } from "react-router-dom";

import Caret from "./icons/Caret";
import "./caretLink.scss";

export default (props: { to?: string; label: string; color?: string }) =>
    props.to.slice(0, 4) === "http" ? (
        <a href={props.to} className={`caret-link ${props.color || "primary"}`}>
            <div className={`label ${props.color || "primary"}`}>{props.label}</div>
            <Caret type="right" color={props.color || "primary"} size={12} />
        </a>
    ) : (
        <Link to={props.to || "/"} className={`caret-link ${props.color || "primary"}`}>
            <div className={`label ${props.color || "primary"}`}>{props.label}</div>
            <Caret type="right" color={props.color || "primary"} size={12} />
        </Link>
    );
