import React from "react";

import Colors from "../../themes/colors.theme";

export default (props: { type: "up" | "right" | "down" | "left"; color?: string; size?: number }) => (
    <svg
        className="caret"
        width={`${props.size || 16}px`}
        height={`${props.size || 16}px`}
        viewBox="0 0 12 12"
        version="1.1"
        style={{
            transform: `rotate(${["up", "right", "down", "left"].indexOf(props.type) * 90}deg`,
        }}
    >
        <g
            id="01.-Atoms-/-02.-Icons-/-Arrow:up"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
        >
            <g
                id="Group"
                transform="translate(-0.000000, -0.000000)"
                stroke={Colors[props.color || "dark"]}
                strokeWidth="1.5"
            >
                <g
                    id="01.-Atoms-/-02.-Icons-/-Arrow:down"
                    transform="translate(6.000000, 4.000000) rotate(-180.000000) translate(-6.000000, -4.000000) "
                >
                    <g
                        id="01.-Atoms-/-Icons-/-Arrow"
                        transform="translate(6.500000, 4.500000) rotate(-90.000000) translate(-6.500000, -4.500000) translate(-8.000000, -16.000000)"
                    >
                        <polyline
                            id="Path"
                            transform="translate(15.230769, 20.391305) rotate(-270.000000) translate(-15.230769, -20.391305) "
                            points="11 18 15.414716 22.782609 19.461539 18"
                        ></polyline>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
