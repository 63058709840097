import React from "react";
import "./downloadLink.scss";
import Download from "./icons/Download";

export default (props: { url: string; label?: string; color?: string }) => (
    <a
        href={props.url}
        className={`download-link ${props.label ? "has-label" : ""}`}
        target="_blank"
        rel="noopener noreferrer"
        download
    >
        <Download color={props?.color} />
        {props.label ? <div className="label">{` ${props.label}`}</div> : null}
    </a>
);
