import React, { useState } from "react";
import { useSelector } from "react-redux";

import "./addresses.scss";
import BackLink from "../../components/BackLink";
import DistributorSelectors from "../../selectors/distributor.selectors";
import Plus from "../../components/icons/Plus";
import AddressDropdown from "../../components/user/AddressDropdown";
import AddressForm from "../../components/forms/AddressForm";
import queryString from "query-string";
import { Redirect, useLocation } from "react-router-dom";

export default () => {
    const [showAddAddress, setShowAddAddress] = useState(false);

    const location = useLocation();

    const addresses = useSelector((state: any) => DistributorSelectors.shippingAddresses(state));

    const { orderToEdit } = queryString.parse(location.search);

    const existingOrder = useSelector(DistributorSelectors.orderById(orderToEdit));

    if (existingOrder && !existingOrder.statusData.editable) {
        return <Redirect to={"/profile/order/" + existingOrder.id} />;
    } else {
        return (
            <div className="profile-addresses">
                {existingOrder ? (
                    <BackLink
                        label="Back to addresses"
                        to={"/order/address/?orderToEdit=" + existingOrder.id}
                    />
                ) : (
                    <BackLink to="/profile/company" label="Back" />
                )}
                <h1>My shipping addresses</h1>
                <div className="addresses">
                    {addresses.map((address) => (
                        <AddressDropdown key={address.id} address={address} />
                    ))}
                    <div id="add" className="add" onClick={() => setShowAddAddress(true)}>
                        <Plus color="secondary" />
                        <a className="secondary" href="#">
                            Add an address
                        </a>
                    </div>
                    {showAddAddress && (
                        <AddressForm type="SHIPPING" onClose={() => setShowAddAddress(false)} />
                    )}
                </div>
            </div>
        );
    }
};
