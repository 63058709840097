import React, { useState } from "react";
import { Redirect, useHistory } from "react-router";
import { useSelector } from "react-redux";

import Wrapper from "../../components/style/Wrapper";
import Button from "../../components/forms/Button";
import { signin, signup } from "../../services/auth.service";
import { login, isSignupFormValid } from "../../utils/auth.utils";
import "./signupDocuments.scss";
import Wave from "../../components/style/Wave";
import BackLink from "../../components/BackLink";
import FileInput from "../../components/forms/FileInput";
import CheckInput from "../../components/forms/CheckInput";

const SignupFileInput = (props: {
    field: string;
    label: string;
    multiple?: boolean;
    required?: boolean;
    onChange: (value: any) => void;
}) => {
    return (
        <FileInput
            label={props.label}
            required={props?.required}
            onChange={(x) => {
                props.onChange((prev) => ({
                    ...prev,
                    [props.field]: x,
                }));
            }}
            uploadToS3
            multiple={props?.multiple}
            placeholder={props.multiple ? "Add new document" : null}
            color="white"
            buttonHasBorder
        />
    );
};

export default () => {
    const history = useHistory();

    const authStore = useSelector((state: any) => state.auth);

    const [state, setState] = useState({
        certificateOfIncorporation: null,
        memorandum: null,
        registerOfDirectors: null,
        registerOfMembers: null,
        wholesalerLicense: null,
        ceCertificate: null,
        freeSaleCertificate: null,
        isoCertification: null,
        other: null,
    });

    const [acceptWho, setAcceptWho] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [, setLoading] = useState(false);

    const submit = async () => {
        setErrorMsg("");
        if (
            !isSignupFormValid(authStore) ||
            !(state.certificateOfIncorporation && state.certificateOfIncorporation.length) ||
            !(state.memorandum && state.memorandum.length) ||
            !(state.registerOfDirectors && state.registerOfDirectors.length) ||
            !(state.registerOfMembers && state.registerOfMembers.length) ||
            !(state.freeSaleCertificate && state.freeSaleCertificate.length)
        ) {
            setErrorMsg("Missing mandatory fields");
            return;
        }

        if (!(state.wholesalerLicense && state.wholesalerLicense.length) && !acceptWho) {
            setErrorMsg("Please upload wholesaler license or check the related checkbox");
        }

        setLoading(true);
        const res = await signup({ ...authStore, files: state, acceptWho });
        if (!res.error) {
            const res = await signin(authStore.email, authStore.password);
            if (res.jwt) {
                login(res.jwt);
                history.push("/home");
            }
        } else {
            setErrorMsg("Email already use");
        }
        setLoading(false);
    };

    if (!isSignupFormValid(authStore)) {
        return <Redirect to="/signup" />;
    }

    if (localStorage.getItem("token")) {
        return <Redirect to="/home" />;
    }

    return (
        <div id="signup" className="documents">
            <div className="header">
                <div className="content">
                    <img src="/assets/icons/key.svg" alt="Key" />
                    <h1>Create my account - Documents</h1>
                </div>
                <div className="background"></div>
                <Wave color="light" />
            </div>
            <Wrapper>
                <BackLink to="/signup" label="Back" />
                <div className="form">
                    <section className="due-diligence">
                        <div className="title">
                            <h2>Due diligence</h2>
                        </div>
                        <div className="inputs">
                            <SignupFileInput
                                field="certificateOfIncorporation"
                                onChange={setState}
                                label="Certificate of incorporation"
                                required
                            />
                            <SignupFileInput
                                field="memorandum"
                                onChange={setState}
                                label="Memorandum and articles of association"
                                required
                            />
                            <SignupFileInput
                                field="registerOfDirectors"
                                onChange={setState}
                                label="Register of directors"
                                required
                            />
                            <SignupFileInput
                                field="registerOfMembers"
                                onChange={setState}
                                label="Register of members"
                                required
                            />
                            <SignupFileInput
                                field="wholesalerLicense"
                                onChange={setState}
                                label="Wholesaler license"
                            />
                            <CheckInput
                                label="I don’t have a wholesaler but I confirm that I am compliant
to the WHO Good Distributions Practices."
                                value={acceptWho}
                                onChange={(x) => setAcceptWho(x)}
                                color="dark"
                            />
                            <SignupFileInput
                                field="ceCertificate"
                                onChange={setState}
                                label="CE Certificate"
                            />
                            <SignupFileInput
                                field="freeSaleCertificate"
                                onChange={setState}
                                label="Wholesaler questionnaire"
                                required
                            />

                            <SignupFileInput
                                field="isoCertification"
                                onChange={setState}
                                label="ISO certification"
                            />
                            <SignupFileInput
                                field="other"
                                onChange={setState}
                                multiple
                                label="Other"
                            />
                        </div>
                    </section>

                    <div className="submit-block">
                        <Button color="white" text="Create my account" onClick={submit} />
                        {errorMsg ? <div className="error">{errorMsg}</div> : null}
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};
