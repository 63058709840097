import React from "react";

import { useSelector } from "react-redux";
import Products from "../../components/products/ProductSlider";
import "./recentlyViewedProducts.scss";
import ProductsSelectors from "../../selectors/products.selectors";

export default (props: { sortedProducts: any[] }) => {
    const distributorProducts = useSelector((state: any) =>
        ProductsSelectors.byCurrentDistributor(state)
    );

    const displayedProducts = props.sortedProducts.map((product) => {
        const distProd = distributorProducts.find((x) => x.id === product.id);
        return distProd
            ? {
                  ...product,
                  ...distProd,
              }
            : {
                  ...product,
                  available: false,
              };
    });

    return (
        <div className="recently-viewed-products">
            <Products
                title="Products you recently viewed"
                products={displayedProducts}
                hideProductsDetails
            />
        </div>
    );
};
