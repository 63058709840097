import React from "react";
import { Link } from "react-router-dom";
import Caret from "./icons/Caret";

import "./stepper.scss";

type StepperStep = {
    label: string;
    slug?: string;
};

type StepperProps = {
    steps: StepperStep[];
    currentStep: number;
    basePath?: string;
    allowClickOnFutureSteps?: boolean;
};

export default (props: StepperProps) => {
    return (
        <div className="stepper">
            {props.steps.map((step, index) => (
                <div key={index} className={`step ${index === props.currentStep ? "active" : ""}`}>
                    <Caret type="right" color={index === props.currentStep ? "primary" : "gray"} size={12} />
                    <div className="label">
                        {props.basePath &&
                        step.slug &&
                        (index <= props.currentStep || props.allowClickOnFutureSteps) ? (
                            <Link to={`${props.basePath}/${step.slug}`}>{step.label}</Link>
                        ) : (
                            <span>{step.label}</span>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};
