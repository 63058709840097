import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AutocompleteTextInput from "../../components/forms/AutocompleteTextInput";
import Button from "../../components/forms/Button";
import DropdownInput from "../../components/forms/DropdownInput";
import Eye from "../../components/icons/Eye";
import LinkButton from "../../components/LinkButton";
import Pager from "../../components/Pager";
import RoundRating from "../../components/RoundRating";
import StatusLink from "../../components/StatusLink";
import { useBonusTrocarsManagement } from "../../hooks/useBonusTrocarsManagement";
import DistributorSelectors from "../../selectors/distributor.selectors";
import { confirmOrReject } from "../../services/orders.service";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import OrderSlice from "../../store/slices/order.slice";
import ProfileSlice from "../../store/slices/profile.slice";
import { usePager } from "../../utils/layout.utils";
import { useOutsideAlerter } from "./Documents";
import "./orders.scss";

const Cols = [
    "Details",
    "N° order",
    "Order date",
    "Total price",
    "Destination",
    "Status",
    "",
    "",
    "Actions",
];

export default () => {
    const history = useHistory();
    const wrapperRef = React.useRef(null);
    useOutsideAlerter(wrapperRef, () => setShowFilters(false));

    const { orders, filtersData } = useSelector((state: any) =>
        DistributorSelectors.ordersData(state)
    );

    const filters = useSelector((state: any) => state.profile.filters.orders);

    useBonusTrocarsManagement();

    const [showFilters, setShowFilters] = useState(false);

    const setFilter = (key, value) => {
        store.dispatch(ProfileSlice.actions.setOrdersFilter({ key, value }));
    };

    const { page, setPage, pageCount, displayedItems: displayedOrders } = usePager(orders, 7);

    const confirm = async (id) => {
        const res = await confirmOrReject({
            orderId: id,
            status: "CONFIRMED",
        });
        const { orderId, fields }: any = res.updatedFields;
        store.dispatch(DistributorSlice.actions.setOrderAttribute({ id: orderId, fields: fields }));
    };

    const redoOrder = (order) => {
        const products = order.products.map((product) => {
            return {
                productId: product?.id,
                countryId: product.country?.id,
                quantity: product.quantity,
                packagingId: product.packaging?.id || null,
            };
        });

        store.dispatch(
            OrderSlice.actions.addProductsToCart({
                products: products,
            })
        );
        history.push("/cart");
    };

    const edit = (order) => {
        history.push("/profile/order/" + order.id + "/edit/");
    };

    const currentProduct = useMemo(
        () => filtersData.productList.find((x) => x.id === filters.productId),
        [filtersData, filters.productId]
    );

    return (
        <div id="orders" className="profile">
            <h1>My orders</h1>
            <div className="filters" ref={wrapperRef}>
                <Button
                    text="Filter by"
                    onClick={() => setShowFilters((prev) => !prev)}
                    color="dark"
                    icon="caret"
                />
                <div className={`inputs ${showFilters ? "" : "hidden"}`}>
                    <DropdownInput
                        title="Status"
                        label="Select status"
                        items={filtersData.statusList.map((status) => ({
                            value: status,
                            label: status,
                        }))}
                        value={filters.status}
                        onChange={(x) => setFilter("status", x)}
                        allowNull
                        itemsClass="semi-round"
                    />
                    <AutocompleteTextInput
                        label="Destination"
                        options={filtersData.destinationList.map((dest) => ({
                            value: dest,
                            label: dest,
                        }))}
                        value={filters.destination}
                        onChange={(value) => setFilter("destination", value)}
                        keepValueInTextInput
                        cancellable
                        color="secondary"
                    />
                    <AutocompleteTextInput
                        label="Product"
                        options={filtersData.productList.map((product) => ({
                            value: product.id,
                            label: product.name,
                        }))}
                        value={currentProduct ? currentProduct.name : ""}
                        onChange={(value) => setFilter("productId", value)}
                        keepValueInTextInput
                        cancellable
                        color="secondary"
                    />
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        {Cols.map((col, index) => (
                            <th key={index}>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {displayedOrders.map((order) => (
                        <tr key={order.id}>
                            <td data-label={Cols[0]}>
                                <Eye color="primary" url={"/profile/order/" + order.id} />
                            </td>
                            <td data-label={Cols[1]}>{order.num}</td>
                            <td data-label={Cols[2]}>{order.date}</td>
                            <td data-label={Cols[3]}>{order.total}</td>
                            <td data-label={Cols[4]}>{order.destination}</td>
                            <td data-label={Cols[5]} className={order.statusData.statusTextColor}>
                                {["SHIPPED", "DELIVERED"].includes(order.status) &&
                                order.deliveryDate
                                    ? `${order.statusData.statusLabel}` +
                                      `${order.status === "SHIPPED" ? " since " : " on "}` +
                                      `${order.deliveryDate}`
                                    : order.statusData.statusLabel}
                            </td>
                            <td data-label={Cols[6]}>
                                {order.statusData.hasStatusLink ? (
                                    <StatusLink
                                        icon={order.statusData.icon}
                                        label={order.statusData.linkText}
                                        color="secondary"
                                        to={"/profile/order/" + order.id + "/edit/"}
                                    />
                                ) : null}
                            </td>
                            <td data-label={Cols[7]}>
                                <RoundRating rating={order.statusData.percentage / 100} />
                            </td>
                            <td data-label={Cols[8]}>
                                <div id="actionsCell">
                                    {order.statusData.actions
                                        ? order.statusData.actions.map((element, index) => (
                                              <LinkButton
                                                  key={index}
                                                  label={element.value}
                                                  onClick={
                                                      element.key === "APPROVE_QUOTE"
                                                          ? () => confirm(order.id)
                                                          : element.key === "REDO_ORDER"
                                                          ? () => redoOrder(order)
                                                          : element.key === "EDIT_ORDER"
                                                          ? () => edit(order)
                                                          : null
                                                  }
                                              />
                                          ))
                                        : null}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pager
                pageCount={pageCount}
                currentPage={page}
                onSetPage={(x) => setPage(x)}
                link="#orders"
            />
        </div>
    );
};
