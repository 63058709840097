import React from "react";

import "./loader.scss";

//https://loading.io/css/

export const Loader = (props) => {
    return (
        <div className={`loader ${props.size || ""} ${props.color || ""}`} style={props?.style}>
            <div className="lds-grid">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};
