import React from "react";

import "./icon.scss";

export default (props: { count?: number; size?: number; color?: string }) => (
    <div className="notifications-icon">
        <svg width={`${props.size || 40}px`} height={`${props.size || 40}px`} viewBox="0 0 40 40" version="1.1">
            <g id="01.-Atoms-/-02.-Icons-/-Notification" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="069-bell"
                    transform="translate(10.000000, 6.000000)"
                    fill={props.color || "#000000"}
                    fillRule="nonzero"
                >
                    <path
                        d="M20.1364844,22.70275 L18.7705547,20.1391094 L18.7705547,13.8079922 C18.7705547,9.89428125 16.1421641,6.58317188 12.5578906,5.54640625 L12.5578906,2.386125 C12.5579453,1.07039844 11.4876016,0 10.171875,0 C8.85614844,0 7.78580469,1.07039844 7.78580469,2.386125 L7.78580469,5.54646094 C4.20164063,6.58328125 1.57314063,9.89433594 1.57314063,13.8080469 L1.57314063,20.1391094 L0.207265625,22.70275 C-0.05578125,23.1964141 -0.04090625,23.7774688 0.24696875,24.2571328 C0.534898438,24.7366875 1.04070313,25.0230313 1.60010156,25.0230313 L7.02280469,25.0230313 C7.11517188,26.6801172 8.49203906,28 10.171875,28 C11.8516563,28 13.2285781,26.6801172 13.3209453,25.0230313 L18.7437031,25.0230313 C19.3030469,25.0230313 19.8088516,24.7366875 20.0968359,24.2571328 C20.3846016,23.7774688 20.3994766,23.1964141 20.1364844,22.70275 Z M9.04832031,2.386125 C9.04832031,1.76651563 9.55232031,1.26251562 10.1718203,1.26251562 C10.7913203,1.26251562 11.2953203,1.76651563 11.2953203,2.386125 L11.2953203,5.2828125 C10.9274922,5.23457813 10.5525,5.20942188 10.1718203,5.20942188 C9.79103125,5.20942188 9.41609375,5.23463281 9.04832031,5.2828125 L9.04832031,2.386125 L9.04832031,2.386125 Z M2.83571094,13.8079922 C2.83571094,9.76286719 6.12669531,6.47182813 10.171875,6.47182813 C14.2170547,6.47182813 17.5080391,9.76286719 17.5080391,13.8079922 L17.5080391,19.6167344 L2.83571094,19.6167344 L2.83571094,13.8079922 Z M10.171875,26.7374844 C9.18853906,26.7374844 8.37839844,25.9832344 8.28871094,25.0230312 L12.0551484,25.0230312 C11.9653516,25.9832344 11.1551016,26.7374844 10.171875,26.7374844 Z M19.0142969,23.6072812 C18.986625,23.6533828 18.9049766,23.7605156 18.7437031,23.7605156 L1.60010156,23.7605156 C1.43888281,23.7605156 1.357125,23.6533828 1.32950781,23.6072812 C1.30183594,23.5612891 1.24572656,23.4387344 1.32157813,23.2963828 L2.60941406,20.87925 L17.7344453,20.87925 L19.0222813,23.2963828 C19.0980234,23.4387344 19.0418594,23.5612344 19.0142969,23.6072812 Z"
                        id="Shape"
                    ></path>
                    <path
                        d="M10.171875,8.15461719 C7.05457813,8.15461719 4.51839063,10.6908047 4.51839063,13.8081016 C4.51839063,14.1566797 4.80101563,14.4393594 5.14964844,14.4393594 C5.49828125,14.4393594 5.78090625,14.1566797 5.78090625,13.8081016 C5.78090625,11.3869766 7.75069531,9.41713281 10.171875,9.41713281 C10.5205078,9.41713281 10.8031328,9.13445313 10.8031328,8.785875 C10.8031328,8.4371875 10.5205078,8.15461719 10.171875,8.15461719 Z"
                        id="Path"
                    ></path>
                </g>
            </g>
        </svg>
        {props.count ? <div className="badge">{props.count}</div> : null}
    </div>
);
