import React, { useState } from "react";

import "./tooltip.scss";

export default (props: {
    content?: string;
    onClick?: () => void;
    contentComponent?: any;
    color?: string;
}) => {
    const [showContent, setShowContent] = useState(false);

    return (
        <div
            className={`tooltip ${props.color || ""}`}
            onMouseEnter={() => setShowContent(true)}
            onMouseLeave={() => setShowContent(false)}
            onClick={props?.onClick}
        >
            {showContent && props.content ? <div className="content">{props.content}</div> : null}
            {showContent && props.contentComponent ? <props.contentComponent /> : null}
        </div>
    );
};
