import React, { useState } from "react";
import { useSelector } from "react-redux";

import "./company.scss";
import Button from "../../components/forms/Button";
import TextInput from "../../components/forms/TextInput";
import { validateEmail } from "../../utils/regex.util";
import { store } from "../../store/configure.store";
import CaretLink from "../../components/CaretLink";
import BackLink from "../../components/BackLink";
import Select from "../../components/forms/Select";
import { setDistributorData } from "../../services/distributors.service";
import DistributorSlice from "../../store/slices/distributor.slice";
import { select } from "../../selectors/utils.selectors";
import DistributorSelectors from "../../selectors/distributor.selectors";
import IconLink from "../../components/IconLink";

const Address = (props: { title?: string; address: any }) => {
    return (
        <div className="address">
            <h4 className="title">{props.title || "Head quarter address"}</h4>
            <div className="content">
                <div className="name">{props.address.name}</div>
                {props.address.address ||
                props.address.zipCode ||
                props.address.city ||
                props.address.country ? (
                    <>
                        {props.address.address ? (
                            <>
                                {props.address.address}
                                <br />
                            </>
                        ) : null}
                        {props.address.zipCode || props.address.city ? (
                            <>
                                {props.address.zipCode || ""} {props.address.city || ""}
                            </>
                        ) : null}
                        <br />
                        {props.address.country ? props.address.country.name : null}
                    </>
                ) : null}
                {props.address.email || props.address.phone ? (
                    <div className="bottom">
                        {props.address.email ? (
                            <>
                                {props.address.email}
                                <br />
                            </>
                        ) : null}
                        {props.address.phone ? (
                            <>
                                {props.address.phone}
                                <br />
                            </>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default () => {
    const shop = useSelector((state: any) => state.shop);
    const distributor = useSelector((state: any) => DistributorSelectors.fullDistributor(state));

    const [state, setState] = useState({
        ...distributor,
        countryId: distributor.addressCountry.id,
    });

    const [errorMsg, setErrorMsg] = useState("");

    const set = (field, value) => setState((prev) => ({ ...prev, [field]: value }));

    const submit = async () => {
        setErrorMsg("");
        if (
            !state.organizationName ||
            !state.address ||
            !state.zipCode ||
            !state.city ||
            !state.countryId ||
            !state.email ||
            !state.phone
        ) {
            setErrorMsg("Missing mandatory fields");
            return;
        }
        if (!validateEmail(state.email)) {
            setErrorMsg("Email invalid");
            return;
        }

        const newData = select(state, [
            "organizationName",
            "address",
            "address2",
            "zipCode",
            "city",
            "countryId",
            "email",
            "phone",
            "contactName",
            "contactPhone",
        ]);

        const res = await setDistributorData(newData);
        if (res.success) {
            store.dispatch(
                DistributorSlice.actions.setDistributorData({
                    ...newData,
                    addressCountry: res.country,
                })
            );
        } else {
            setErrorMsg("This email is already used");
        }
    };

    return (
        <div id="company" className="profile">
            <BackLink to="/profile/me" label="Back to my profile" />
            <h1>My company</h1>
            <div className="links">
                <CaretLink to="/profile/users" label="My users" />
                <CaretLink to="/profile/security" label="Security" />
            </div>
            <div className="form">
                <section>
                    <div className="title">
                        <h2>Company information</h2>
                    </div>
                    <div className="inputs">
                        <TextInput
                            label="Organization name"
                            value={state.organizationName}
                            onChange={(x) => set("organizationName", x)}
                            required
                            color="dark"
                        />
                        <TextInput
                            value={state.address}
                            onChange={(x) => set("address", x)}
                            label="Address"
                            required
                            color="dark"
                        />
                        <TextInput
                            value={state.address2}
                            onChange={(x) => set("address2", x)}
                            label="Address 2"
                            color="dark"
                        />
                        <TextInput
                            value={state.zipCode}
                            onChange={(x) => set("zipCode", x)}
                            label="Zipcode"
                            required
                            color="dark"
                        />
                        <TextInput
                            value={state.city}
                            onChange={(x) => set("city", x)}
                            label="City"
                            required
                            color="dark"
                        />
                        <Select
                            title="Country*"
                            options={shop.countries.map((country) => ({
                                value: country.id,
                                label: country.name,
                            }))}
                            value={state.countryId}
                            onChange={(x) => set("countryId", x)}
                            color="white"
                        />
                        <TextInput
                            label="Email address"
                            value={state.email}
                            onChange={(x) => set("email", x)}
                            required
                            color="dark"
                        />
                        <TextInput
                            label="Phone number"
                            value={state.phone}
                            onChange={(x) => set("phone", x)}
                            required
                            color="dark"
                        />
                    </div>
                </section>
                <section>
                    <div className="title">
                        <h2>Primary contact for delivery</h2>
                    </div>
                    <div className="inputs">
                        <TextInput
                            label="Name"
                            value={state.contactName}
                            onChange={(x) => set("contactName", x)}
                            color="dark"
                        />
                        <TextInput
                            label="Phone"
                            value={state.contactPhone}
                            onChange={(x) => set("contactPhone", x)}
                            color="dark"
                        />
                    </div>
                </section>
                <div className="submit-block">
                    <Button color="white" text="Validate" onClick={submit} />
                    {errorMsg ? <div className="error">{errorMsg}</div> : null}
                </div>
                <div className="note">* Mandatory field</div>
            </div>
            <div className="addresses">
                <div className="col">
                    <h2>My shipping addresses</h2>
                    <div className="block">
                        <IconLink
                            to="/profile/shipping-addresses"
                            icon="edit"
                            label="Edit"
                            color="secondary"
                        />
                        {distributor.primaryShippingAddress ? (
                            <div className="primary">
                                <h3>Primary Shipping address</h3>
                                <Address address={distributor.primaryShippingAddress} />
                            </div>
                        ) : null}
                        {distributor.secondaryShippingAddresses.length >= 1 ? (
                            <div className="secondary">
                                <h3>Secondary Shipping addresses</h3>
                                {distributor.secondaryShippingAddresses.map((address) => (
                                    <Address key={address.id} address={address} />
                                ))}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="col">
                    <h2>My billing addresses</h2>
                    <div className="block">
                        <IconLink
                            to="/profile/billing-addresses"
                            icon="edit"
                            label="Edit"
                            color="secondary"
                        />
                        {distributor.primaryBillingAddress ? (
                            <div className="primary">
                                <h3>Primary Billing address</h3>
                                <Address address={distributor.primaryBillingAddress} />
                            </div>
                        ) : null}
                        {distributor.secondaryBillingAddresses.length >= 1 ? (
                            <div className="secondary">
                                <h3>Secondary Billing addresses</h3>
                                {distributor.secondaryBillingAddresses.map((address) => (
                                    <Address key={address.id} address={address} />
                                ))}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};
