import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NotificationsSelectors from "../../selectors/notifications.selectors";
import * as NotificationsService from "../../services/notifications.service";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import Colors from "../../themes/colors.theme";
import NotificationIcon from "./Icon";
import "./popin.scss";

export default (props: { onClose: () => void }) => {
    const notifications = useSelector((state) => NotificationsSelectors.featured(state));

    const setRead = (notificationId) => {
        NotificationsService.setRead([notificationId]);

        store.dispatch(
            DistributorSlice.actions.setNotificationsRead({
                notificationsIds: [notificationId],
            })
        );
    };

    return (
        <div className="notifications-popin-wrapper" onClick={props.onClose}>
            <div className="notifications-popin">
                <div className="section">
                    <div className="title">Your notifications</div>
                </div>
                <div className="notifications">
                    {notifications.map((notification) => (
                        <Link
                            key={notification.id}
                            className={`notification ${notification.read ? "read" : ""}`}
                            to={notification.link}
                            onClick={
                                !notification.read ? () => setRead(notification.id) : undefined
                            }
                        >
                            <div className="icon">
                                <NotificationIcon
                                    size={32}
                                    color={notification.read ? Colors.gray : Colors.primary}
                                />
                            </div>
                            <div className="text">{notification.content}</div>
                        </Link>
                    ))}
                </div>
                <div className="section">
                    <Link className="white" to="/notifications">
                        See all notifications
                    </Link>
                </div>
            </div>
        </div>
    );
};
