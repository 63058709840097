import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import BackLink from "../../components/BackLink";
import Download from "../../components/icons/Download";
import { getNewsDetails } from "../../services/news.service";
import { getFile } from "../../utils/s3.utils";
import { formatRichText } from "../../utils/string.utils";
import { getLatestNews } from "./../../services/news.service";
import NewsCard from "./NewsCard";
import "./newsPage.scss";

const NewsPage = () => {
    const { newsId }: any = useParams();
    const history = useHistory();

    const [news, setNews] = useState<any>(null);
    const [latestNews, setLastestNews] = useState<any[]>([]);

    useEffect(() => {
        _fectchNews();
        _fectchLatestNews();
    }, []);

    const _fectchNews = async () => {
        const news = await getNewsDetails(newsId);

        if (news.statusCode === 404) history.push("/404");

        setNews(news);
    };

    const _fectchLatestNews = async () => {
        const latestNews = await getLatestNews(3, newsId);
        setLastestNews(latestNews);
    };

    return (
        news && (
            <div id="news-page">
                <BackLink to={"/news/"} label="GO BACK" />

                <h1 className="news-title">{news.title}</h1>
                <div className="news-date">{new Date(news.date).toLocaleDateString("US")}</div>

                {news.introduction && <p className="news-intro">{news.introduction}</p>}

                <div className="news-content">
                    {news.content1 && <div>{formatRichText(news.content1)}</div>}

                    {news.image && <img src={getFile(news.image)} alt={news.title} />}

                    {news.content2 && <div>{formatRichText(news.content2)}</div>}
                </div>

                {news.newsDetails && (
                    <div className="news-footer">
                        <h3>Attachments</h3>
                        <a
                            href={getFile(news.newsDetails)}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                        >
                            {news.newsDetails.replace("news/details/", "")}
                            <Download color={"light"} />
                        </a>
                    </div>
                )}

                <h2 className="latest-news-title">Latest news</h2>
                <div className="lastest-news-container">
                    {latestNews.map((lateNews) => (
                        <NewsCard
                            key={lateNews.id}
                            id={lateNews.id}
                            title={lateNews.title}
                            date={lateNews.date}
                            excerpt={lateNews.excerpt}
                        />
                    ))}
                </div>
            </div>
        )
    );
};

export default NewsPage;
