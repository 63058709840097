import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import BackLink from "../../components/BackLink";
import Button from "../../components/forms/Button";
import LoyaltyStatus from "../../components/loyaltyProgram/LoyaltyStatus";
import { getAllLoyaltyStatus, getCurrentLoyaltyStatus } from "../../services/loyaltyStatus.service";
import {
    createPartnerOrder,
    getAllPartnerOrdersForDistributor,
} from "../../services/partnerOrders.service";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import { formatDatetime } from "../../utils/dates.utils";
import { formatNumber, formatPercentage } from "../../utils/numeric.utils";
import { useAtomicQuery, useQuery } from "../../utils/query.utils";
import { getAllPartnerProducts } from "./../../services/partnerProducts.service";
import "./myLoyaltyProgram.scss";


const MyLoyaltyProgram = () => {
    const distributor = useSelector((state: any) => state.distributor);
    const loyaltyData = distributor.loyalty;

    const [loyaltyStatuses] = useQuery(getAllLoyaltyStatus);
    const [currentLoyaltyStatus] = useAtomicQuery(getCurrentLoyaltyStatus);
    const [partnerProducts] = useQuery(getAllPartnerProducts);
    const [partnerOrders, setPartnerOrders] = useQuery(getAllPartnerOrdersForDistributor);

    const [numberOfCareCoins, setNumberOfCareCoins] = useState(0);

    useEffect(() => {
        _initPage();
    }, []);

    const _animatedCareCoinsBalance = (careCoinsAmount: number, direction: "ASC" | "DESC") => {
        const amountToAnimated = careCoinsAmount > 15 ? 15 : careCoinsAmount;
        const baseTimeBetweenChange = 20;

        let index = 0;
        for (
            let currentDisplayedAmount =
                direction === "ASC"
                    ? careCoinsAmount - amountToAnimated
                    : careCoinsAmount + amountToAnimated;
            direction === "ASC"
                ? currentDisplayedAmount <= careCoinsAmount
                : currentDisplayedAmount >= careCoinsAmount;
            direction === "ASC" ? currentDisplayedAmount++ : currentDisplayedAmount--
        ) {
            // The more we approch the end of the animation, the slower it is
            const time = index * (baseTimeBetweenChange + 2 * index++);
            setTimeout(() => {
                setNumberOfCareCoins(currentDisplayedAmount);
            }, time);
        }
    };

    const _initPage = async () => {
        _animatedCareCoinsBalance(loyaltyData.careCoinsBalance, "ASC");
    };

    const _tryBuyPartnerProduct = async (id: number) => {
        const product = partnerProducts.find((p) => p.id === id);

        if (!product || product.priceInCC > numberOfCareCoins) return;

        const res = await createPartnerOrder(product.id);
        if (res.success) {
            store.dispatch(DistributorSlice.actions.addCareCoinsBalance(-res.data.costInCC));
            setPartnerOrders([...partnerOrders, res.data]);
            _animatedCareCoinsBalance(numberOfCareCoins - res.data.costInCC, "DESC");
        }
    };

    if (!distributor.loyaltyProgramGroup) {
        return <Redirect to="/home" />;
    }

    return (
        <div id="my-loyalty-program">
            <BackLink to="/my-womancare" label="BACK TO MY WOMANCARE" />

            <div className="loyalty-program-logo-wrapper">
                <img
                    className="partnercare-loyalty-program-logo"
                    src="/assets/images/loyaltyProgram/partnercare-loyalty-program-logo.png"
                    alt="PartnerCare loyalty program logo"
                />
            </div>

            <section className="section-top">
                <div className="presentation">
                    <h1>What is the «PartnerCare» program?</h1>
                    <p>
                        The PartnerCare program is a loyalty program implemented by DKT WomanCare to
                        support your activities and improve your satisfaction with the company. This
                        program aims not only to reward you for your purchases but also offers
                        support and services that will assist you to develop and conquer your
                        markets.
                    </p>
                    <p>
                        Through this loyalty program we aim to interact with you and keeping you
                        informed about our promotions and exclusive offers.
                    </p>

                    <h1>How does the «PartnerCare» program work?</h1>
                    <ul>
                        <li>
                            For each item purchased you get a certain number of points called
                            “CareCoins”
                        </li>
                        <li>
                            The CareCoins will be accumulated on your DKT WCG account and available
                            at your convenience
                        </li>
                        <li>
                            You can redeem the CareCoins earned into goods and services (see table
                            below). 1 CareCoin = 0,10$
                        </li>
                        <li>
                            Activities rewarded
                            <ul>
                                <li>Completed forecast</li>
                                <li>Completed survey</li>
                                <li>Training conducted</li>
                                <li>Business opportunity reported</li>
                                <li>Annual business review</li>
                                <li>Sell-out data</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="pyramid">
                    <div className="pyramid-explanation">
                        By earning CareCoins you reach different levels of loyalty. At each new
                        level, you unlock access to specific privileges.
                    </div>
                    <img
                        src="/assets/images/loyaltyProgram/partner-ranks-pyramid.png"
                        alt="Partner ranks pyramid"
                    />
                </div>
                <div className="my-care-coins">
                    My CareCoins balance
                    <div className="my-care-coins-amount">
                        {formatNumber(numberOfCareCoins, true, 0)}
                    </div>
                </div>
            </section>

            <div className="section-title loyalty-statuses">The loyalty statuses</div>

            <div className="loyalty-status-list">
                {loyaltyStatuses.map((status, index) => (
                    <LoyaltyStatus
                        key={index}
                        {...status}
                        active={currentLoyaltyStatus?.reference === status.reference}
                    />
                ))}
            </div>

            <div className="statuses-informations">
                Each status is defined regarding the quantity of products ordered, the turnover
                generated, the order frequency, etc
            </div>

            <div className="section-title partners-shop">Redeemed benefits</div>

            <div className="shop-carecoins-balance">
                My CareCoins balance : {formatNumber(numberOfCareCoins, true, 0)}
            </div>
            <table className="partners-shop-table" cellSpacing="0">
                <thead>
                    <tr>
                        <th>Marketing/training material</th>
                        <th>Price in CareCoins</th>
                        <th className="table-th-lg">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {partnerProducts.map((product) => (
                        <React.Fragment key={product.id}>
                            <tr>
                                <td>{product.name}</td>
                                <td>{formatNumber(product.priceInCC, true, 0)}</td>
                                <td className="table-td-lg">
                                    <Button
                                        text={
                                            partnerOrders.find(
                                                (o) => o.partnerProduct.id === product.id
                                            )
                                                ? `Order again (${
                                                      partnerOrders.filter(
                                                          (o) => o.partnerProduct.id === product.id
                                                      ).length
                                                  } orders in progress)`
                                                : "Order now"
                                        }
                                        onClick={() => _tryBuyPartnerProduct(product.id)}
                                        disabled={product.priceInCC > numberOfCareCoins}
                                    />
                                </td>
                            </tr>
                            <tr className="table-tr-sm">
                                <td colSpan={2}>
                                    <Button
                                        text={
                                            partnerOrders.find(
                                                (o) => o.partnerProduct.id === product.id
                                            )
                                                ? `Order again (${
                                                      partnerOrders.filter(
                                                          (o) => o.partnerProduct.id === product.id
                                                      ).length
                                                  } orders in progress)`
                                                : "Order now"
                                        }
                                        onClick={() => _tryBuyPartnerProduct(product.id)}
                                        disabled={product.priceInCC > numberOfCareCoins}
                                    />
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MyLoyaltyProgram;
