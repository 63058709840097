import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import BackLink from "../../components/BackLink";
import Button from "../../components/forms/Button";
import InfiniteSelect from "../../components/forms/InfiniteSelect";
import { RadioInput } from "../../components/forms/RadioInput";
import IconLink from "../../components/IconLink";
import Close from "../../components/icons/Close";
import LinkButton from "../../components/LinkButton";
import DistributorSelectors from "../../selectors/distributor.selectors";
import {
    cancelOrder,
    confirmOrReject,
    deleteOrderItems,
    setOrder,
    setOrderItems,
} from "../../services/orders.service";
import { getProductSubCategories } from "../../services/products.service";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import { formatNumber, formatPrice } from "../../utils/numeric.utils";
import { getFile } from "../../utils/s3.utils";
import "./editOrder.scss";

export default () => {
    const history = useHistory();
    const { orderId }: any = useParams();
    const order = useSelector(DistributorSelectors.orderById(orderId));
    const [showRequestConfirmation, setShowRequestConfirmation] = useState(false);

    const productColumns = ["Product(s)", "", "Per unite price", "Quantity", "Total"];

    const AvailableSpecialRequests = ["Documents", "Packing", "Need for pre-shipment inspection"];

    const handleConfirmOrReject = async (status) => {
        const res = await confirmOrReject({
            orderId: order.id,
            status: status,
        });
        if (res.updatedFields) {
            const { orderId, fields }: any = res.updatedFields;
            store.dispatch(
                DistributorSlice.actions.setOrderAttribute({ id: orderId, fields: fields })
            );
        }
    };

    const cancel = async () => {
        const res = await cancelOrder({
            orderId: order.id,
        });
        const { orderId, fields }: any = res.updatedFields;
        store.dispatch(DistributorSlice.actions.setOrderAttribute({ id: orderId, fields: fields }));
    };

    const addImplant = async () => {
        const subCategories = await getProductSubCategories();
        history.push(
            "/shop/?subCategory=" +
                subCategories.find((x) => x.name === "Contraceptive implants").id +
                "&orderToEdit=" +
                order.id
        );
    };

    const deleteProduct = async (registrationId) => {
        const orderItem = order.orderItems.find((x) => x.registration.id === registrationId);
        const newOrderItems = order.orderItems.filter((x) => x.registration.id != registrationId);
        const res = await deleteOrderItems(orderItem.id);
        if (res.success) {
            store.dispatch(
                DistributorSlice.actions.setOrderItems({ id: orderId, orderItems: newOrderItems })
            );
            if (newOrderItems.length === 0) {
                cancel();
            }
        }
    };

    const setSpecialRequest = (value) => {
        const fields = { specialRequest: value };
        store.dispatch(
            DistributorSlice.actions.setOrderAttribute({
                id: order.id,
                fields: fields,
            })
        );
        setShowRequestConfirmation(false);
    };

    const validateSpecialRequest = async () => {
        const fields = { specialRequest: order.specialRequest };
        const res = await setOrder({
            orderId: order.id,
            fields: fields,
        });
        if (res.success) {
            setShowRequestConfirmation(true);
        }
    };

    const setItemQuantity = async (qty: number, index: string) => {
        const newProducts = order.products.map((product, i) => {
            if (i === index) {
                return {
                    ...product,
                    quantity: qty,
                };
            }
            return product;
        });
        const newOrderItems = order.orderItems.map((orderItem, i) => {
            if (i === index) {
                return {
                    ...orderItem,
                    quantity: qty,
                };
            }
            return orderItem;
        });
        const res = await setOrderItems({
            orderId: order.id,
            orderItems: newOrderItems,
            products: newProducts,
        });
        if (res.success) {
            store.dispatch(
                DistributorSlice.actions.setOrderItems({ id: orderId, orderItems: newOrderItems })
            );
        }
    };

    if (!order.statusData.editable) {
        return <Redirect to={"/profile/order/" + order.id} />;
    } else {
        return (
            <div id="editOrder" className="profile">
                <div id="order">
                    <BackLink label="Back to my orders" to="/profile/orders/" />
                    <div className="section">
                        <h1>Order {order.num}</h1>
                        <div id="details">
                            <p>Order date : {order.date}</p>
                            <p>
                                Shipping address : {order.shippingAddressFormatted}
                                <LinkButton
                                    label="Change address"
                                    color="primary"
                                    to={"/order/address/?orderToEdit=" + order.id}
                                />
                            </p>
                            <p>
                                Shipping method : {order.shippingMethod}
                                <LinkButton
                                    label="Change shipping method"
                                    color="primary"
                                    to={"/order/shipping/?orderToEdit=" + order.id}
                                />
                            </p>
                            <p>
                                Billing address : {order.billingAddressFormatted}
                                <LinkButton
                                    label="Change address"
                                    color="primary"
                                    to={"/order/address/?orderToEdit=" + order.id}
                                />
                            </p>
                            <p>
                                Contact name : {order.contact}
                                <LinkButton
                                    label="Edit"
                                    color="primary"
                                    to={"/order/address/?orderToEdit=" + order.id}
                                />
                            </p>
                            <p>
                                Certificates: {order.certificates}
                                <LinkButton
                                    label="Change"
                                    color="primary"
                                    to={"/order/certificates/?orderToEdit=" + order.id}
                                />
                            </p>
                        </div>
                    </div>
                    <div id="products" className="section">
                        <h2 className="title">Details</h2>
                        <table id="productsTable">
                            <thead>
                                <tr>
                                    {productColumns.map((column, index) => (
                                        <th key={index}>{column}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {order.products.map((product, index) => (
                                    <Fragment key={product.id}>
                                        <tr className="product-block">
                                            <td data-label={productColumns[0]}>
                                                <div
                                                    className="image-wrapper"
                                                    onClick={() =>
                                                        deleteProduct(product.registration.id)
                                                    }
                                                >
                                                    <img
                                                        className="image"
                                                        src={getFile(product.image)}
                                                        alt="Product"
                                                    />
                                                    <div className="close-wrapper">
                                                        <Close color="white" />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="name">{product.name}</div>
                                                <div className="reference">{product.reference}</div>
                                            </td>
                                            <td data-label={productColumns[2]}>
                                                {formatPrice(product.effectivePrice)}
                                            </td>
                                            <td data-label={productColumns[3]}>
                                                <InfiniteSelect
                                                    className="in-order"
                                                    minValue={0}
                                                    onChange={(value) => {
                                                        setItemQuantity(value, index);
                                                    }}
                                                    step={product.orderQuantityStep}
                                                    value={product.quantity}
                                                />
                                            </td>
                                            <td data-label={productColumns[4]}>
                                                {formatPrice(product.totalEffectivePrice)}
                                            </td>
                                        </tr>
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div id="addAndTotal" className="section">
                        <div>
                            <IconLink
                                icon="plus"
                                label="Add a contraceptive implant"
                                color="secondary"
                                onClick={addImplant}
                            />
                        </div>
                        <div id="totalTable">
                            <table>
                                <tbody>
                                    <tr className="sub-total">
                                        <td>Sub-total (excl. VAT)</td>
                                        <td>{formatPrice(order.subtotal)}</td>
                                    </tr>
                                    <tr className="sub-total">
                                        <td>Shipping cost</td>
                                        <td>{formatPrice(order.shippingPrice)}</td>
                                    </tr>
                                    {order.careCoinsUsed ? (
                                        <>
                                            <tr className="sub-total carecoins">
                                                <td>CareCoins used</td>
                                                <td>{formatNumber(order.careCoinsUsed, true)}</td>
                                            </tr>
                                            <tr className="sub-total">
                                                <td>CareCoins total value</td>
                                                <td>
                                                    {formatPrice(order.careCoinsUsedTotalValue)}
                                                </td>
                                            </tr>
                                        </>
                                    ) : null}
                                    <tr id="total">
                                        <td>Total (excl. VAT)</td>
                                        <td>{formatPrice(order.total)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div id="requestAndButtons" className="section">
                        <div id="specialRequest">
                            <RadioInput
                                label="Do you have any special request?"
                                sublabel="Regarding"
                                items={AvailableSpecialRequests.map((x) => ({
                                    value: x,
                                    label: x,
                                }))}
                                value={order.specialRequest}
                                onChange={(value) => setSpecialRequest(value)}
                                allowCustomValues
                                vertical
                                color="primary"
                            />
                            <LinkButton
                                label="Validate"
                                color="primary"
                                onClick={() => validateSpecialRequest()}
                            />
                            <p id="requestConfirmation">
                                {showRequestConfirmation ? "Your request has been saved." : null}
                            </p>
                        </div>
                        <div id="buttons">
                            <Button
                                text="Cancel this order"
                                color="white"
                                hasBorder
                                onClick={cancel}
                            />
                            {order.toConfirm ? (
                                <div className="buttons-container">
                                    <Button
                                        text="Reject this order"
                                        color="transparent"
                                        hasBorder
                                        onClick={() => handleConfirmOrReject("CANCELLED")}
                                    />
                                    <Button
                                        text="Confirm this order"
                                        color="primary"
                                        onClick={() => handleConfirmOrReject("CONFIRMED")}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
