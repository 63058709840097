import React, { useRef, useState } from "react";
import { useResize } from "../utils/layout.utils";
import Caret from "./icons/Caret";
import "./Slider.scss";

export default ({
    items,
    itemWidth,
    gap,
    title,
    children,
}: {
    items: any[];
    itemWidth: number;
    gap: number;
    title: string;
    children: JSX.Element[];
}) => {
    const [currentItemIndex, setCurrentItemIndex] = useState(0);
    const [intervalLeft, setIntervalLeft] = useState<ReturnType<typeof setInterval> | undefined>();
    const [intervalRight, setIntervalRight] = useState<
        ReturnType<typeof setInterval> | undefined
    >();

    const sliderContainer = useRef();

    const { width: sliderContainerWidth } = useResize(sliderContainer);

    const sliderItemsWidth = items.length * itemWidth;

    const sliderOffset =
        sliderItemsWidth < sliderContainerWidth
            ? 0
            : Math.min(sliderItemsWidth - sliderContainerWidth, itemWidth * currentItemIndex);

    const doIncrementCurrentItemIndex = () => {
        return sliderOffset <= sliderItemsWidth - sliderContainerWidth;
    };

    const handleRight = () => {
        if (doIncrementCurrentItemIndex()) {
            setCurrentItemIndex((prev) => Math.min(items.length - 1, prev + 1));
        }
    };
    return (
        <div className="slider-wrapper">
            <div className="slider-header">
                <h2 className="title">{title}</h2>
                <div className="arrows">
                    <button
                        className="arrow left"
                        aria-label="Previous"
                        onMouseDown={() => {
                            setCurrentItemIndex((prev) => Math.max(0, prev - 1));
                            const intervalLeft = setInterval(() => {
                                setCurrentItemIndex((prev) => Math.max(0, prev - 1));
                            }, 100);
                            setIntervalLeft(intervalLeft);
                        }}
                        onMouseUp={() => {
                            clearInterval(intervalLeft);
                        }}
                    >
                        <Caret type="left" size={12} />
                    </button>
                    <button
                        className="arrow right"
                        aria-label="Next"
                        onMouseDown={() => {
                            handleRight();
                            const intervalRight = setInterval(() => {
                                handleRight();
                            }, 100);
                            setIntervalRight(intervalRight);
                        }}
                        onMouseUp={() => {
                            clearInterval(intervalRight);
                        }}
                    >
                        <Caret type="right" size={12} />
                    </button>
                </div>
            </div>
            <div className="slider-container" ref={sliderContainer}>
                <div
                    className="slider"
                    style={{
                        marginLeft: -sliderOffset,
                        transition: "margin-left 0.300s ease",
                        gap: gap,
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};
