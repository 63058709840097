import { getS3Url } from "../services/s3.service";

export const uploadFile = async (file) => {
    let type = file.type;
    const { url } = await getS3Url(type, "womancare-files");

    if (!url) return null;

    const headersContent = {
        "Content-Type": file.type,
        Accept: "application/json",
        "x-amz-acl": "public-read",
    };
    const headers = new Headers(headersContent);
    const response = await fetch(
        new Request(url, {
            method: "PUT",
            body: file,
            headers,
        })
    );
    if (!response) return null;
    let resUrl = new URL(response?.url);
    return resUrl.pathname;
};

export const getFile = (key) => `https://s3.eu-west-3.amazonaws.com/womancare-files/${key}`;
