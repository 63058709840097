import React, { useEffect, useState } from "react";

import "./multiSelectAutocomplete.scss";
import AutocompleteTextInput from "./AutocompleteTextInput";
import Close from "../icons/Close";
import { addUnique } from "../../utils/arrays.utils";

type MultiSelectAutocompleteProps = {
    label?: string;
    options: any[];
    values: any;
    onChange: (values) => void;
    placeholder?: string;
    color?: string;
};

export default function MultiSelectAutocomplete(props: MultiSelectAutocompleteProps) {
    const [currentValues, setCurrentValues] = useState(props.values || []); // value attr of options

    useEffect(() => {
        props.onChange(currentValues);
    }, [currentValues]);

    const onInputChange = text => {
        setCurrentValues(prev => addUnique(prev, text));
    };

    const removeValue = value => setCurrentValues(prev => prev.filter(x => x !== value));

    const formattedCurrentValues = props.options.filter(x => currentValues.includes(x.value));

    return (
        <div className="multi-select-autocomplete">
            {props.label ? <div className="label">{props.label}</div> : null}
            <AutocompleteTextInput
                options={props.options.filter(x => !currentValues.includes(x.value))}
                onChange={onInputChange}
                placeholder={props?.placeholder}
                color={props?.color}
            />
            {formattedCurrentValues.length >= 1 ? (
                <div className="values">
                    {formattedCurrentValues.map((fcv, index) => (
                        <div className="value-wrapper" key={index}>
                            <div className="value-label">{fcv.label}</div>
                            <Close
                                onClick={() => removeValue(fcv.value)}
                                color="secondary"
                                circle
                            />
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
}
