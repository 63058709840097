import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import Menus from "../../pages/header-menus.navigation";
import { logout, useLogin } from "../../utils/auth.utils";
import NotificationsIcon from "../../components/notifications/Icon";
import NotificationsPopin from "../../components/notifications/Popin";
import "./header.scss";
import NotificationsSelectors from "../../selectors/notifications.selectors";
import { useOutsideAlerter } from "../../pages/user/Documents";

const Tab = (props: { item: any; onClick?: () => void; isCurrent?: boolean }) =>
    props.item.path ? (
        <Link
            className={`tab ${props.item.emph ? "emph" : ""} ${props.isCurrent ? "current" : ""}`}
            to={props.item.path}
            onClick={props?.onClick}
        >
            {props.item.icon ? (
                <img src={`/assets/icons/${props.item.icon}.svg`} alt="" />
            ) : (
                <span className="title">{props.item.title}</span>
            )}
        </Link>
    ) : (
        <span
            className={`tab ${props.item.emph ? "emph" : ""} ${props.isCurrent ? "current" : ""}`}
            onClick={props?.onClick}
        >
            {props.item.icon ? (
                <img src={`/assets/icons/${props.item.icon}.svg`} alt="" />
            ) : (
                <span className="title">
                    {props.item.title}
                    {props.item.items ? <img src="/assets/icons/down.svg" alt="" /> : null}
                </span>
            )}
        </span>
    );

export default () => {
    const history = useHistory();
    const [showSubmenu, setShowSubmenu] = useState(false);

    const notificationsCount = useSelector((state) => NotificationsSelectors.count(state));

    const [currentTab, setCurrentTab] = useState(null);
    const wrapperRef = React.useRef(null);
    const submenuRef = React.useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        setTimeout(() => {
            setCurrentTab(null);
        }, 300);
        setShowSubmenu(false);
    });

    const [showNotificationsPopin, setShowNotificationsPopin] = useState(false);

    const { isLogin } = useLogin();

    const disconnect = () => {
        logout();
        history.push("/");
    };

    return (
        <header
            id="header"
            onBlur={() => {
                setTimeout(() => {
                    setCurrentTab(null);
                }, 300);
                setShowSubmenu(false);
            }}
            ref={wrapperRef}
        >
            <div className="header-content">
                <Link className="logo" to={isLogin ? "/home" : "/"}>
                    <img src="/assets/logo.png" alt="Logo" />
                </Link>
                <nav className="menu">
                    <div className="tabs">
                        {Menus.filter((tab) => !tab.icon).map((tab, index) => (
                            <Tab
                                key={index}
                                item={tab}
                                isCurrent={currentTab && tab.title === currentTab.title}
                                onClick={() => {
                                    setShowSubmenu(currentTab !== tab);
                                    if (currentTab === tab)
                                        setTimeout(() => {
                                            setCurrentTab(null);
                                        }, 300);
                                    else setCurrentTab(tab);
                                }}
                            />
                        ))}
                    </div>
                    <div className="icon-links">
                        {Menus.filter((tab) => !!tab.icon).map((tab, index) => (
                            <Tab
                                key={index}
                                item={tab}
                                isCurrent={currentTab && tab.title === currentTab.title}
                                onClick={() => {
                                    setShowSubmenu(currentTab !== tab);
                                    if (currentTab === tab)
                                        setTimeout(() => {
                                            setCurrentTab(null);
                                        }, 300);
                                    else setCurrentTab(tab);
                                }}
                            />
                        ))}
                        <Link
                            className="tab"
                            to="#"
                            onClick={() => {
                                setTimeout(() => {
                                    setCurrentTab(null);
                                }, 300);
                                setShowSubmenu(false);
                                setShowNotificationsPopin((prev) => !prev);
                            }}
                        >
                            <NotificationsIcon count={notificationsCount} />
                        </Link>
                    </div>
                </nav>
            </div>
            <nav
                ref={submenuRef}
                className="submenu"
                style={{
                    top: showSubmenu
                        ? "102px"
                        : (submenuRef?.current?.offsetHeight || 0) * -1 + "px",
                    transition: "top 0.3s ease",
                }}
            >
                <div className="tabs">
                    {currentTab?.items?.map((item, index) =>
                        item.title === "Logout" ? (
                            <Tab key={index} item={item} onClick={disconnect} />
                        ) : (
                            <Tab key={index} item={item} />
                        )
                    )}
                </div>
            </nav>
            {showNotificationsPopin && (
                <NotificationsPopin onClose={() => setShowNotificationsPopin(false)} />
            )}
        </header>
    );
};
