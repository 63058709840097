import { get, put } from "./utils.service";

export const getAll = async () => {
    try {
        const res = await get("/notification");
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const setRead = async (notificationIds) => {
    const res = await put(`/notification/read`, { notificationIds });
    return await res.json();
};
