import moment from "moment";
import { createSelector } from "reselect";
import { currentYearMonth, formatDate, formatYearMonth, monthDiff } from "../utils/dates.utils";
import { formatNumber } from "../utils/numeric.utils";
import { sumByKey } from "./utils.selectors";

const getForecasts = (state) => state.distributor.forecasts;

const ForecastsSelectors = {
    byId: (id) => (state) => {
        if (id) {
            const forecast = getForecasts(state).find((x) => x.id === +id);
            return {
                ...forecast,
                productId: forecast.product ? forecast.product.id : null,
            };
        } else {
            return {};
        }
    },

    forecastsData: createSelector(getForecasts, (forecasts) =>
        forecasts.map((forecast) => ({
            ...forecast,
            date: formatDate(forecast.stockTickingDate),
            productName: forecast.product.name || "",
            ordersInNext3Months: formatNumber(
                sumByKey(
                    forecast.forecastMonths.filter((x) => monthDiff(currentYearMonth(), x) < 3),
                    "orderCount"
                ),
                true
            ),
            quantity: formatNumber(sumByKey(forecast.forecastMonths, "orderQuantity"), true),
        }))
    ),

    nextOrders: (data) =>
        data.reduce(
            (rules, forecast) => [
                ...rules,
                ...forecast.rules
                    .map((rule) => {
                        const forecastMonth = forecast.forecastMonths.find(
                            (x) => x.id === rule.forecastMonthId
                        );

                        const maxOrderDate = moment(
                            `${forecastMonth.year}-${`00${forecastMonth.month}`.slice(-2)}-15`
                        ).subtract(rule.delay, "day");

                        return {
                            ...rule,
                            productName: forecast.product.name,
                            orderQuantity: forecastMonth.orderQuantity,
                            formattedForecastDate: formatYearMonth(forecastMonth),
                            maxOrderDate,
                            formattedMaxOrderDate: maxOrderDate.format("dddd Do MMMM"),
                        };
                    })
                    .filter((rule) => rule.maxOrderDate >= moment()),
            ],
            []
        ),
};

export default ForecastsSelectors;
