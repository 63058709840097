import React, { useEffect, useState } from "react";
import WCAcademyItem from "../../components/resources/WCAcademyItem";
import WomanCareAcademyHeader from "../../components/resources/WomanCareAcademyHeader";
import Slider from "../../components/Slider";
import { getWCAcademy } from "../../services/resources.service";
import { store } from "../../store/configure.store";
import ResourceSlice from "../../store/slices/resource.slice";
import useWindowDimensions from "../../utils/window.utils";
import "./womanCareAcademy.scss";

export default () => {
    const [resources, setResources] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const { width } = useWindowDimensions();

    useEffect(() => {
        (async () => {
            const res = await getWCAcademy();
            store.dispatch(ResourceSlice.actions.setWCAcademy(res.data));
            const actualResources = Object.values(
                res.data?.reduce((acc, obj) => {
                    const pc = obj.resource;
                    if (!acc[pc.id]) {
                        acc[pc.id] = pc;
                    }
                    return acc;
                }, {})
            );
            const categories = actualResources?.reduce((acc, obj: any) => {
                const pc = obj.productCategory;
                if (!acc[pc.id]) {
                    acc[pc.id] = pc;
                }
                return acc;
            }, {});
            setResources(actualResources || []);
            setProductCategories(Object.values(categories || []));
        })();
    }, []);

    return (
        <div id="womanCareAcademy">
            <WomanCareAcademyHeader />
            <div className="content">
                <div className="items">
                    {productCategories.map((category) => (
                        <div
                            style={{
                                margin: "50px",
                            }}
                            key={category.id}
                        >
                            <Slider
                                title={category.name}
                                items={resources.filter(
                                    (x) => x.productCategory.id === category.id
                                )}
                                itemWidth={width > 1080 ? 667 : width}
                                gap={width > 768 ? 30 : 100}
                            >
                                {resources
                                    .filter((x) => x.productCategory.id === category.id)
                                    .map((item, index) => (
                                        <WCAcademyItem
                                            key={index}
                                            item={item}
                                            onClickLink={`/resources/womancare-academy/${item.id}`}
                                        />
                                    ))}
                            </Slider>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
