const getResources = (state: any) => state.resource || [];

const ResourceSelectors = {
    getAll: () => (state: any) => {
        const res = getResources(state);
        return res;
    },

    getVideosAndTutorials: () => (state: any) => {
        let res = getResources(state);
        if (res) {
            return res.videosAndTutorials;
        } else {
            return [];
        }
    },

    getMarketingMaterials: () => (state: any) => {
        let res = getResources(state);
        if (res) {
            return res.marketingMaterials;
        } else {
            return [];
        }
    },

    getPosAndPop: () => (state: any) => {
        let res = getResources(state);
        if (res) {
            return res.posAndPop;
        } else {
            return [];
        }
    },

    getMarketData: () => (state: any) => {
        let res = getResources(state);
        if (res) {
            return res.marketData;
        } else {
            return [];
        }
    },

    getBestPractices: () => (state: any) => {
        let res = getResources(state);
        if (res) {
            return res.bestPractices;
        } else {
            return [];
        }
    },

    getWCAcademy: () => (state: any) => {
        let res = getResources(state);
        if (res) {
            return res.wcAcademy;
        } else {
            return [];
        }
    },

    getWCAcademyByResourceId: (id) => (state: any) => {
        const res = getResources(state);
        if (res) {
            return res.wcAcademy.filter((x) => +x.resource.id === +id);
        } else {
            return [];
        }
    },

    byId: (id) => (state: any) => {
        const resources = getResources(state);
        const resource = resources.find((item: any) => item.id === +id);
        return resource || null;
    },

    videoAndTutorialbyId: (id) => (state: any) => {
        const resources = getResources(state).videosAndTutorials;
        const resource = resources.find((item: any) => item.id === +id);
        return resource || null;
    },

    marketingMaterialbyId: (id) => (state: any) => {
        const resources = getResources(state).marketingMaterials;
        const resource = resources.find((item: any) => item.id === +id);
        return resource || null;
    },

    posAndPopById: (id) => (state: any) => {
        const resources = getResources(state).posAndPop;
        const resource = resources.find((item: any) => item.id === +id);
        return resource || null;
    },

    marketDatabyId: (id) => (state: any) => {
        const resources = getResources(state).marketData;
        const resource = resources.find((item: any) => item.id === +id);
        return resource || null;
    },

    bestPracticesById: (id) => (state: any) => {
        const resources = getResources(state).bestPractices;
        const resource = resources.find((item: any) => item.id === +id);
        return resource || null;
    },

    wcAcademyById: (id) => (state: any) => {
        const resources = getResources(state).wcAcademy;
        const resource = resources.find((item: any) => item.id === +id);
        return resource || null;
    },
};
export default ResourceSelectors;
