export const computeOrderItemPrices = (orderItem) => {
    let effectivePrice = orderItem.price;

    if (orderItem.discount) {
        effectivePrice *= 1 - orderItem.discount / 100;
    }

    let nonPromoCodeDiscountedPrice = effectivePrice;
    let promoCodeDiscount = 0;

    if (orderItem.promoCodeDiscount) {
        promoCodeDiscount = Math.round(effectivePrice * orderItem.promoCodeDiscount) / 100;
        effectivePrice -= promoCodeDiscount;
    }

    const productQuantity = orderItem.quantity || 1;

    return {
        price: orderItem.price,
        discount: orderItem.discount,
        effectivePrice,
        totalEffectivePrice: effectivePrice * productQuantity,
        nonPromoCodeDiscountedPrice,
        totalNonPromoCodeDiscountedPrice: nonPromoCodeDiscountedPrice * productQuantity,
        promoCodeDiscount: promoCodeDiscount * productQuantity,
    };
};

export const computeProductPrices = (registration, quantity, promoCode) =>
    computeOrderItemPrices({
        price: Number(registration.price),
        discount: registration.discount,
        promoCodeDiscount:
            promoCode && promoCode.products.includes(registration.product.id)
                ? promoCode.discount
                : null,
        quantity,
    });
