import React from "react";

import { useSelector } from "react-redux";
import Products from "../../components/products/ProductSlider";
import Wave from "../../components/style/Wave";
import ProductsSelectors from "../../selectors/products.selectors";
import "./relatedProducts.scss";

export default (props: { sortedProducts: any[] }) => {
    const distributorProducts = useSelector((state: any) =>
        ProductsSelectors.byCurrentDistributor(state)
    );

    const sortProducts = (productList) => {
        const res = productList
            .filter((y) => !props.sortedProducts.some((x) => x.id === y.id))
            .map((newProduct) => {
                const distProd = distributorProducts.find((x) => x.id === newProduct.id);
                if (distProd) {
                    return {
                        ...newProduct,
                        ...distProd,
                    };
                } else {
                    return {
                        ...newProduct,
                        available: false,
                        minPrice: 0,
                    };
                }
            })
            .sort((a, b) => (a.minPrice > b.minPrice ? 1 : -1));
        return res;
    };

    const displayedProducts = props.sortedProducts.reduce((acc, product) => {
        const subCatProducts = sortProducts(
            distributorProducts.filter(
                (y) => y.productSubCategory.id === product.productSubCategory.id
            )
        );

        const catProducts = sortProducts(
            distributorProducts.filter(
                (y) =>
                    y.productSubCategory.productCategory.id ===
                    product.productSubCategory.productCategory.id
            )
        );

        let newProduct =
            subCatProducts.find((x) => !acc.some((y) => y.id === x.id)) ||
            catProducts.find((x) => !acc.some((y) => y.id === x.id));

        if (newProduct) {
            acc.push(newProduct);
        }

        return acc;
    }, []);

    return (
        <div className="related-products">
            {displayedProducts.length > 0 ? (
                <Products
                    title="Frequently purchased with"
                    seeAllPath="/shop"
                    seeAllLabel="See all new products"
                    products={displayedProducts}
                    hideProductsDetails
                />
            ) : null}
            <Wave color="white" />
        </div>
    );
};
