import { get, post } from "./utils.service";

export const getAllPartnerOrdersForDistributor = async () => {
    try {
        const res = await get(`/partner-orders`);
        return await res.json();
    } catch (e) {
        return null;
    }
}

export const createPartnerOrder = async (partnerProductId: number) => {
    try {
        const res = await post(`/partner-orders`, {
			partnerProductId
		});
        return await res.json();
    } catch (e) {
        return null;
    }
}