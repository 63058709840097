import React from "react";
import { Link } from "react-router-dom";

import Colors from "../../themes/colors.theme";
import Caret from "../icons/Caret";
import { Loader } from "../style/Loader";
import "./button.scss";

type ButtonProps = {
    text: string;
    inactive?: boolean;
    disabled?: boolean;
    color?: "primary" | "secondary" | "dark" | "light" | "white" | "transparent";
    hasBorder?: boolean;
    style?: Object;
    to?: string;
    onClick?: () => void;
    preserveEvents?: boolean;
    loading?: boolean;
    icon?: string;
};

const ButtonHOC = ({ children, inactive, ...rest }) =>
    inactive ? <div {...rest}>{children}</div> : <button {...rest}>{children}</button>;

const ButtonComponent = (props: ButtonProps) => {
    return (
        <ButtonHOC
            className={`button ${props.color || ""} ${props.hasBorder ? "has-border" : ""} ${
                props.inactive ? "inactive" : ""
            } ${props.disabled ? "disabled" : ""}`}
            inactive={props.inactive}
            color={props.color}
            style={{
                ...(props.disabled
                    ? {}
                    : {
                          backgroundColor: props.color ? Colors[props.color] : Colors["dark"],
                          color:
                              !props.color || ["dark", "primary"].includes(props.color)
                                  ? "#FFFFFF"
                                  : Colors.dark,
                      }),
                ...props.style,
            }}
            onClick={
                props.inactive || props.disabled
                    ? null
                    : (e) => {
                          if (props.onClick) {
                              props.onClick();
                          }

                          if (!props.preserveEvents && !props.to) {
                              e.preventDefault();
                              e.stopPropagation();
                              return false;
                          }
                      }
            }
        >
            {props.loading ? <Loader /> : <>{props.text}</>}
            {props.icon ? (
                <div className="icon">
                    {props.icon === "caret" && <Caret type="down" size={12} color="primary" />}
                </div>
            ) : null}
        </ButtonHOC>
    );
};

export default (props: ButtonProps) => {
    return props.to ? (
        <Link to={props.to} className={`button-link ${props.color || "dark"}`}>
            <ButtonComponent {...props} />
        </Link>
    ) : (
        <ButtonComponent {...props} />
    );
};
