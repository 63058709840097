import React from "react";
import { Link } from "react-router-dom";

import "./iconLink.scss";
import Edit from "./icons/Edit";
import Plus from "./icons/Plus";

export default (props: {
    to?: string;
    onClick?: () => void;
    icon: string;
    label: string;
    color: string;
}) => {
    const IconComponent = {
        plus: Plus,
        edit: Edit
    }[props.icon];

    return (
        <Link
            className="icon-link"
            to={props.to ? props.to : "#"}
            onClick={e => {
                if (props.onClick) {
                    props.onClick();
                }

                if (!props.to) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }
            }}
        >
            {IconComponent ? (
                <IconComponent color={props.color} />
            ) : (
                <img src={`/assets/icons/${props.icon}.svg`} alt={props.label} />
            )}
            <span className={`link-text ${props.color || "primary"}`}>{props.label}</span>
        </Link>
    );
};
