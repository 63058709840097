import React, { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BackLink from "../../components/BackLink";
import Button from "../../components/forms/Button";
import Download from "../../components/icons/Download";
import LinkButton from "../../components/LinkButton";
import ProductSlider from "../../components/products/ProductSlider";
import Item from "../../components/resources/Item";
import Slider from "../../components/Slider";
import productsSelectors from "../../selectors/products.selectors";
import ResourceSelectors from "../../selectors/resource.selectors";
import { useResize } from "../../utils/layout.utils";
import { getFile } from "../../utils/s3.utils";
import useWindowDimensions from "../../utils/window.utils";
import "./womanCareAcademyDetails.scss";

export default () => {
    const { resourceId }: any = useParams();
    const history = useHistory();
    const items = useSelector(ResourceSelectors.getWCAcademyByResourceId(resourceId ?? ""));
    const products = useSelector(
        productsSelectors.byCategory(items?.[0].resource.productCategory.id)
    );
    const { width } = useWindowDimensions();

    const resource = useMemo(() => items[0].resource, [items]);
    const actualProducts = useMemo(
        () => Object.values(products).filter((product: any) => product?.id),
        [products]
    );

    const { videos, trainingMaterials, technicalSheets } = useMemo(() => {
        if (items) {
            return {
                videos: items.filter((x) => x.contentType === "VIDEO"),
                trainingMaterials: items.filter((x) => x.contentType === "TRAINING_MATERIAL"),
                technicalSheets: items.filter((x) => x.contentType === "TECHNICAL_SHEET"),
            };
        }
        return {
            videos: [],
            trainingMaterials: [],
            technicalSheets: [],
        };
    }, [items]);

    if (!resource || !actualProducts) {
        return null;
    }

    return (
        <div id="womanCareAcademyDetails">
            <BackLink label="Back to resources" to="/resources/womancare-academy/" />
            <div className="header">
                <div className="text-content">
                    <div className="name">{resource.title}</div>
                    <div className="category">{resource.productCategory.name}</div>
                    <div className="excerpt">{resource.introduction}</div>
                </div>
                <div className="image-content">
                    {resource.image ? (
                        <div className="image-wrapper">
                            <img
                                className="image"
                                src={getFile(resource.image)}
                                alt="WomanCare Academy resource"
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="content">
                <div className="products">
                    <ProductSlider title="Products" products={actualProducts} />
                </div>
                {videos?.length > 0 ? (
                    <div className="videos">
                        <h2 className="title">Videos</h2>
                        <LinkButton label="More videos on youtube" />
                        <div className="video-items">
                            {videos.map((item, index) => (
                                <Item
                                    key={index}
                                    item={item}
                                    onClickLink={item.externalLink}
                                    newTab
                                />
                            ))}
                        </div>
                    </div>
                ) : null}
                {trainingMaterials?.length > 0 ? (
                    <div className="training-materials">
                        <Slider
                            title="Training materials"
                            items={actualProducts}
                            itemWidth={width > 768 ? 230 : width - 140}
                            gap={width > 768 ? 30 : 240}
                        >
                            {actualProducts?.map((product: any) => (
                                <div className="product-training-item" key={product.id}>
                                    <div className="presentation">
                                        <div className="name">{product.name}</div>
                                        <img
                                            className="logo"
                                            src={getFile(product.image)}
                                            alt="Logo"
                                        />
                                    </div>
                                    <div className="links">
                                        {trainingMaterials
                                            .filter((x) => +x.product?.id === +product?.id)
                                            .map((item, index) => (
                                                <div className="material" key={index}>
                                                    <h2 className="training-title">
                                                        {item.language.name}
                                                    </h2>
                                                    <Download
                                                        color="white"
                                                        url={getFile(item.file)}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                ) : null}

                {technicalSheets?.length > 0 ? (
                    <div className="technical-sheets">
                        <Slider
                            title="Technical sheets"
                            items={actualProducts}
                            itemWidth={width > 768 ? 230 : width}
                            gap={width > 768 ? 30 : 240}
                        >
                            {actualProducts?.map((product: any) => (
                                <div className="product-technical-item" key={product.id}>
                                    <div className="name">{product.name}</div>
                                    <img className="logo" src={getFile(product.image)} alt="Logo" />
                                    <Button
                                        text="About the product"
                                        color="dark"
                                        hasBorder
                                        onClick={() => history.push(`/product/${product.id}`)}
                                    />
                                    <div className="links">
                                        {technicalSheets
                                            .filter((x) => +x.product?.id === +product?.id)
                                            .map((item, index) => (
                                                <div className="material" key={index}>
                                                    <h2 className="training-title">{item.title}</h2>
                                                    <Download
                                                        color="white"
                                                        url={getFile(item.file)}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
