import React, { useEffect, useState } from "react";
import LinkButton from "../../components/LinkButton";
import CategoryCard from "../../components/resources/CategoryCard";
import { getBestPractices } from "../../services/resources.service";
import { store } from "../../store/configure.store";
import ResourceSlice from "../../store/slices/resource.slice";
import { getFile } from "../../utils/s3.utils";
import "./resources.scss";

export default () => {
    const defaultHighlight = {
        excerpt: null,
        thumbnail: "/assets/images/default-resource.png",
    };
    const [highlightedResource, setHighlightedResource] = useState(defaultHighlight);

    useEffect(() => {
        (async () => {
            const res = await getBestPractices();
            store.dispatch(ResourceSlice.actions.setWCAcademy(res.data));
            const highlight = res.data?.find((x) => x.highlight);
            setHighlightedResource(
                highlight
                    ? { ...highlight, thumbnail: getFile(highlight.thumbnail) }
                    : defaultHighlight
            );
        })();
    }, []);

    return (
        <div id="resources">
            <div className="header">
                <div className="title">Resources</div>
                <div className="introduction">Introduction</div>
            </div>
            <div className="content">
                <div className="highlight">
                    <img
                        className="highlight-image"
                        src={highlightedResource.thumbnail}
                        alt="Highlighted resource"
                    />
                    <div className="text-content">
                        <h2>Best practices</h2>
                        <div className="excerpt">{highlightedResource.excerpt}</div>
                        <LinkButton
                            label="See more"
                            to="/resources/best-practices"
                            color="primary"
                        />
                    </div>
                </div>
                <div className="academy">
                    <div className="logo">
                        <img
                            src="/assets/images/womancare-academy-logo.png"
                            alt="WomanCare Academy logo"
                        ></img>
                    </div>
                    <div className="excerpt">Introduction</div>
                    <LinkButton
                        label="See more"
                        to="/resources/womancare-academy"
                        color="primary"
                    />
                </div>
                <div className="other-categories">
                    <div className="categories-line">
                        <CategoryCard
                            title="Videos & Tutorials"
                            excerpt="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lorem nisl, fermentum a ornare eu, cursus ac massa. Ut consequat, tortor vel consectetur lacinia, justo lacus lobortis dui, in vestibulum justo nunc a urna."
                            logo="/assets/icons/video.svg"
                            onClickLink="/resources/videos-and-tutorials"
                        />
                        <CategoryCard
                            title="Marketing materials"
                            excerpt="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lorem nisl, fermentum a ornare eu, cursus ac massa. Ut consequat, tortor vel consectetur lacinia, justo lacus lobortis dui, in vestibulum justo nunc a urna."
                            logo="/assets/icons/book.svg"
                            onClickLink="/resources/marketing-material"
                        />
                    </div>
                    <div className="categories-line">
                        <CategoryCard
                            title="Point of sale and point of purchase"
                            excerpt="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lorem nisl, fermentum a ornare eu, cursus ac massa. Ut consequat, tortor vel consectetur lacinia, justo lacus lobortis dui, in vestibulum justo nunc a urna."
                            logo="/assets/icons/pharmacy.svg"
                            onClickLink="/resources/pos-and-pop"
                        />
                        <CategoryCard
                            title="Studies & market data"
                            excerpt="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lorem nisl, fermentum a ornare eu, cursus ac massa. Ut consequat, tortor vel consectetur lacinia, justo lacus lobortis dui, in vestibulum justo nunc a urna."
                            logo="/assets/icons/graphic.svg"
                            onClickLink="/resources/market-data"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
