import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { requestProduct } from "../../services/products.service";
import { formatNumber, formatPrice } from "../../utils/numeric.utils";
import { getFile } from "../../utils/s3.utils";
import { formatRichText } from "../../utils/string.utils";
import Button from "../forms/Button";
import "./item.scss";

export default (props: {
    product: any;
    hideDetails?: boolean;
    hideControls?: boolean;
    orderToEdit?: number;
}) => {
    const history = useHistory();
    const me = useSelector((state: any) => state.distributor);
    const [requestSucceeded, setRequestSucceeded] = useState(null);

    const goToProduct = () => {
        if (props.orderToEdit) {
            history.push(`/product/${props.product.id}/?orderToEdit=${props.orderToEdit}`);
        } else {
            history.push(`/product/${props.product.id}`);
        }
    };

    const sendRequest = async () => {
        if (requestSucceeded !== true) {
            const res = await requestProduct(props.product.id);
            setRequestSucceeded(!!res.success);
        }
    };

    const careCoinsReward = useMemo(() => {
        let amount;

        if (me.loyaltyProgramGroup === 1 && props.product.careCoinGroup1) {
            amount = props.product.careCoinGroup1;
        } else if (me.loyaltyProgramGroup === 2 && props.product.careCoinGroup2) {
            amount = props.product.careCoinGroup2;
        }

        return amount;
    }, [me.loyaltyProgramGroup, props.product.careCoinGroup1, props.product.careCoinGroup2]);

    return (
        <div onClick={goToProduct} className="product-item">
            <div className="content">
                <div className="image-wrapper">
                    {props.product.image ? (
                        <img className="image" src={getFile(props.product.image)} alt="Product" />
                    ) : null}
                    <div className="name">{props.product.name}</div>
                    {props.product.discount ? (
                        <div className="discount">-{props.product.discount}%</div>
                    ) : null}
                </div>
                {!props.hideDetails ? (
                    <>
                        <div className="reference">Ref. {props.product.reference}</div>
                        <div className="description">
                            {formatRichText(props.product.description)}
                        </div>
                    </>
                ) : null}
            </div>
            {!props.hideControls ? (
                <div className="bottom">
                    {props.product.available ? (
                        <>
                            <div className="numbers-row">
                                <div className="price-row">
                                    <div className="price">
                                        {formatPrice(props.product.minPrice)}
                                    </div>
                                    <div className="vat">excl. VAT</div>
                                </div>
                                {careCoinsReward ? (
                                    <div className="cc-reward-row">
                                        <div className="cc-reward">
                                            {formatNumber(careCoinsReward, true, 2)}
                                        </div>
                                        <div className="vat">Care Coins reward</div>
                                    </div>
                                ) : null}
                            </div>
                            <Button
                                text="Order"
                                style={{
                                    marginTop: 33,
                                }}
                                to={"/product/" + props.product.id}
                            />
                        </>
                    ) : (
                        <>
                            <div className="price-row">
                                <div className="price">Not available in your region</div>
                            </div>
                            <Button
                                color="light"
                                hasBorder
                                text={
                                    requestSucceeded === null
                                        ? "Request"
                                        : requestSucceeded
                                        ? "Requested"
                                        : "Retry"
                                }
                                onClick={sendRequest}
                                style={{
                                    marginTop: 33,
                                }}
                            />
                        </>
                    )}
                </div>
            ) : null}
        </div>
    );
};
