import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Download from "../../components/icons/Download";
import LinkButton from "../../components/LinkButton";
import { FAQURL } from "../../constants";
import { getCurrentLoyaltyStatus } from "../../services/loyaltyStatus.service";
import "./myWomancare.scss";

const ServiceBlock = (props: { serviceType: "CUSTOMER" | "REPORT" | "LOYALTY"; title: string }) => {
    const [currentLoyaltyStatus, setCurrentLoyaltyStatus] = useState(null);

    useEffect(() => {
        _getCareCoinsCount();
    }, []);

    const _getCareCoinsCount = async () => {
        const response = await getCurrentLoyaltyStatus();
        setCurrentLoyaltyStatus(response);
    };

    return (
        <div className="service-block">
            <div className="title">{props.title}</div>
            {props.serviceType === "CUSTOMER" ? (
                <div className="content">
                    <div className="description">Description</div>
                    <p>We respond within 48 hours.</p>
                    <div className="links">
                        <Download />
                        <LinkButton
                            label="Consult your requests"
                            to="/my-womancare/customer-service"
                        />
                        <LinkButton label="Consult your FAQ" to={FAQURL} />
                    </div>
                </div>
            ) : null}
            {props.serviceType === "REPORT" ? (
                <div className="content">
                    <div className="description">Description</div>
                    <div className="links">
                        <LinkButton
                            label="Report an issue with an order"
                            to="/my-womancare/report/order-issue"
                        />
                        <LinkButton label="Report a tender" to="/my-womancare/report/tender" />
                        <LinkButton
                            label="Report a new competitor"
                            to="/my-womancare/report/competitor"
                        />
                        <LinkButton
                            label="Report a change of law"
                            to="/my-womancare/report/law-change"
                        />
                    </div>
                </div>
            ) : null}

            {props.serviceType === "LOYALTY" ? (
                <div className="content">
                    <div className="description">Description</div>
                    <div className="current-partner-level-wrapper">
                        {currentLoyaltyStatus?.reference === "BLUE" && (
                            <img
                                src="/assets/images/loyaltyProgram/blue-partner-badge.png"
                                alt="Blue Partner Badge"
                                className="partner-badge"
                            />
                        )}
                        {currentLoyaltyStatus?.reference === "BRONZE" && (
                            <img
                                src="/assets/images/loyaltyProgram/bronze-partner-badge.png"
                                alt="Bronze Partner Badge"
                                className="partner-badge"
                            />
                        )}
                        {currentLoyaltyStatus?.reference === "SILVER" && (
                            <img
                                src="/assets/images/loyaltyProgram/silver-partner-badge.png"
                                alt="Silver Partner Badge"
                                className="partner-badge"
                            />
                        )}
                        {currentLoyaltyStatus?.reference === "GOLD" && (
                            <img
                                src="/assets/images/loyaltyProgram/gold-partner-badge.png"
                                alt="Gold Partner Badge"
                                className="partner-badge"
                            />
                        )}

                        <div
                            className="partner-level-name"
                            style={{
                                color:
                                    currentLoyaltyStatus?.reference === "BLUE"
                                        ? "#5CFDDB"
                                        : currentLoyaltyStatus?.reference === "BRONZE"
                                        ? "#A25D26"
                                        : currentLoyaltyStatus?.reference === "SILVER"
                                        ? "#A8B7B5"
                                        : currentLoyaltyStatus?.reference === "GOLD"
                                        ? "#CD9650"
                                        : null,
                            }}
                        >
                            {currentLoyaltyStatus === null
                                ? "You are not yet a partner"
                                : currentLoyaltyStatus?.displayName}
                        </div>

                        <LinkButton
                            label="My loyalty program"
                            to="/my-womancare/my-loyalty-program"
                            color="white"
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default () => {
    const distributor = useSelector((state: any) => state.distributor);

    return (
        <div id="my-womancare">
            <h1>My Womancare</h1>
            <div className="services">
                <ServiceBlock serviceType="CUSTOMER" title="Customer Service" />
                <ServiceBlock serviceType="REPORT" title="Report an event" />
                {distributor.loyaltyProgramGroup ? (
                    <ServiceBlock serviceType="LOYALTY" title="My loyalty program" />
                ) : null}
            </div>
        </div>
    );
};
