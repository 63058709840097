import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import BackLink from "../../components/BackLink";
import Button from "../../components/forms/Button";
import DropdownInput from "../../components/forms/DropdownInput";
import InfiniteSelect from "../../components/forms/InfiniteSelect";
import LabelModal from "../../components/order/LabelModal";
import CategorySlider from "../../components/products/CategorySlider";
import ProductsSlider from "../../components/products/ProductSlider";
import RequestModal from "../../components/shop/RequestModal";
import Tooltip from "../../components/Tooltip";
import { useBonusTrocarsManagement } from "../../hooks/useBonusTrocarsManagement";
import DistributorSelectors from "../../selectors/distributor.selectors";
import ProductsSelectors from "../../selectors/products.selectors";
import { addToHistory } from "../../services/productHistory.service";
import { requestProduct } from "../../services/products.service";
import { getAll } from "../../services/resources.service";
import { setError } from "../../utils/app.utils";
import { formatNumber, formatPrice } from "../../utils/numeric.utils";
import { getFile } from "../../utils/s3.utils";
import { formatRichText } from "../../utils/string.utils";
import "./product.scss";

const PricesTooltip = ({ registrations }) => (
    <div className="prices-tooltip">
        <div className="title">There are the prices for your available countries (excl. VAT):</div>
        <div className="formatted-text">
            <ul>
                {registrations.map((registration) => (
                    <li key={registration.id}>
                        {registration.country.name}:{" "}
                        <span className="price">{formatPrice(registration.effectivePrice)}</span>
                    </li>
                ))}
            </ul>
        </div>
    </div>
);

export default () => {
    const { productId }: any = useParams();
    const me = useSelector((state: any) => state.distributor);

    const history = useHistory();
    const location = useLocation();
    const { orderToEdit } = queryString.parse(location.search);
    const existingOrder = useSelector(DistributorSelectors.orderById(orderToEdit));

    const product = useSelector(ProductsSelectors.byId(productId));
    const relatedProducts = useSelector(
        ProductsSelectors.bySubCategory(
            product ? product.productSubCategory.id : -1,
            product ? product.id : null
        )
    );

    useBonusTrocarsManagement();

    const [relatedResources, setRelatedResources] = useState(null);

    const [showLabelModal, setShowLabelModal] = useState(false);
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [response, setResponse] = useState(null);

    const [quantity, setQuantity] = useState<string | number>(null);
    const [packaging, setPackaging] = useState(null);

    const sendToHistory = async () => {
        await addToHistory(product.id);
    };

    const goToCategory = () => {
        history.push(`/shop?category=${product.productSubCategory.productCategory.id}`);
    };

    const goToSubCategory = () => {
        history.push(`/shop?subCategory=${product.productSubCategory.id}`);
    };

    const placeOrder = () => {
        const isPackagingInvalid =
            product.packagings && product.packagings.length >= 1 && !packaging;

        if (!quantity) {
            if (isPackagingInvalid) {
                setError("Please select quantity and packaging");
            } else {
                setError("Please select quantity");
            }
        } else if (isPackagingInvalid) {
            setError("Please select packaging");
        } else {
            setShowLabelModal(true);
        }
    };

    const sendRequest = async () => {
        const res = await requestProduct(product.id);
        setResponse(res);
        if (res.success) {
            setShowRequestModal(true);
        }
    };

    useEffect(() => {
        (async () => {
            const res = await getAll();
            setRelatedResources(res.data.filter((x) => +x.product?.id === +productId));
        })();
    }, []);

    const careCoinsReward = useMemo(() => {
        let amount;

        if (product) {
            if (me.loyaltyProgramGroup === 1 && product.careCoinGroup1) {
                amount = product.careCoinGroup1;
            } else if (me.loyaltyProgramGroup === 2 && product.careCoinGroup2) {
                amount = product.careCoinGroup2;
            }
        }

        return amount;
    }, [me.loyaltyProgramGroup, product]);

    if (!product) return null;
    if (existingOrder && !existingOrder.statusData.editable) {
        return <Redirect to={"/profile/order/" + existingOrder.id} />;
    } else {
        useEffect(() => {
            sendToHistory();
        }, []);

        return (
            <div id="product">
                {existingOrder ? (
                    <BackLink
                        to={"/shop/?orderToEdit=" + existingOrder.id}
                        label="Back to all products"
                    />
                ) : (
                    <BackLink to="/shop" label="Back to all products" />
                )}
                <div className="content">
                    <div className="presentation">
                        <div className="image-wrapper">
                            {product.image ? (
                                <img className="image" src={getFile(product.image)} alt="Product" />
                            ) : null}
                        </div>
                        <div className="categories">
                            <div className="category" onClick={goToCategory}>
                                <div className="label">
                                    {product.productSubCategory.productCategory.name}
                                </div>
                            </div>
                            <div className="category" onClick={goToSubCategory}>
                                <div className="label">{product.productSubCategory.name}</div>
                            </div>
                        </div>
                    </div>
                    <div className="main">
                        <div className="title">
                            <div className="price-row">
                                {product.available ? (
                                    <>
                                        {product.registrations.length >= 2 ? (
                                            <>
                                                <div className="price">
                                                    From {formatPrice(product.minPrice)}
                                                </div>
                                                <Tooltip
                                                    contentComponent={() => (
                                                        <PricesTooltip
                                                            registrations={product.registrations}
                                                        />
                                                    )}
                                                    color="secondary"
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <div className="price">
                                                    {formatPrice(product.minPrice)}
                                                </div>
                                                <div className="vat">excl. VAT</div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <div className="unavailable">Not available in your region</div>
                                )}
                            </div>
                            <h1 className="name">{product.name}</h1>
                        </div>
                        <div className="content">
                            <div className="detail">
                                <div className="reference">Ref. {product.reference}</div>
                                <div className="description">
                                    {formatRichText(product.description)}
                                </div>
                                {product.technicalDescription ? (
                                    <>
                                        <h2>Technical description</h2>
                                        <div className="technical">
                                            {formatRichText(product.technicalDescription)}
                                        </div>
                                    </>
                                ) : null}
                                {product.file ? (
                                    <a
                                        href={getFile(product.file)}
                                        className="primary download-sheet"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Download the product sheet
                                    </a>
                                ) : null}
                                {!product.available ? (
                                    <Button
                                        color="light"
                                        hasBorder
                                        text="Request"
                                        onClick={sendRequest}
                                        style={{
                                            marginTop: 33,
                                        }}
                                    />
                                ) : null}
                            </div>
                            <div className="order">
                                {product.available ? (
                                    <div className="place-order-block">
                                        <InfiniteSelect
                                            title={`Minimum order quantity: ${
                                                product.minimumOrderQuantity || 1
                                            }`}
                                            label="Select quantity"
                                            minValue={product.minimumOrderQuantity}
                                            step={product.orderQuantityStep}
                                            value={quantity ? Number(quantity) : null}
                                            onChange={(x) => setQuantity(x)}
                                            color="white"
                                        />
                                        {product.packagings && product.packagings.length >= 1 ? (
                                            <DropdownInput
                                                title="Choose your presentation:"
                                                label="Select packaging"
                                                items={product.packagings.map((x) => ({
                                                    value: x.id,
                                                    label: x.name,
                                                }))}
                                                onChange={(x) => setPackaging(x)}
                                                color="white"
                                            />
                                        ) : null}
                                        <Button
                                            color="white"
                                            text="Add to cart"
                                            onClick={placeOrder}
                                        />
                                        {careCoinsReward ? (
                                            <div className="cc-reward">
                                                Unit reward:{" "}
                                                {formatNumber(careCoinsReward, true, 2)} Care Coins
                                            </div>
                                        ) : null}
                                    </div>
                                ) : null}
                                {!product.available ? (
                                    <div className="sales-rep">
                                        <div className="title">
                                            Interested in expanding in a new country?
                                        </div>
                                        <div className="content">
                                            Please contact your sales representative for more
                                            information:
                                        </div>
                                        {me.salesRepresentative ? (
                                            <>
                                                <img
                                                    src={
                                                        me.salesRepresentative.picture
                                                            ? getFile(
                                                                  me.salesRepresentative.picture
                                                              )
                                                            : "/assets/images/sample.jpg"
                                                    }
                                                    alt="Sales representative"
                                                    className="picture"
                                                />
                                                <div className="contact">
                                                    {me.salesRepresentative.firstname}{" "}
                                                    {me.salesRepresentative.lastname}
                                                    <br />
                                                    {me.salesRepresentative.email}
                                                    <br />
                                                    {me.salesRepresentative.phone}
                                                </div>
                                            </>
                                        ) : (
                                            <div className="contact">
                                                <img
                                                    src="/assets/images/commercial-director.png"
                                                    alt="Sales representative"
                                                />
                                                <div className="info">
                                                    Rodrigo Portugues
                                                    <br />
                                                    rodrigo@dktwomancare.org
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                {relatedProducts.length >= 1 ? (
                    <div className="related-products">
                        <ProductsSlider
                            title="Frequently purchased with"
                            seeAllPath={`/shop?subCategory=${product.productSubCategory.id}`}
                            seeAllLabel="See all new products"
                            products={relatedProducts}
                            hideProductsDetails
                            hideProductsControls
                        />
                    </div>
                ) : null}
                {relatedResources?.length >= 1 ? (
                    <div className="related-products">
                        <CategorySlider
                            title="You could also be interested in"
                            items={relatedResources}
                        />
                    </div>
                ) : null}
                {showRequestModal ? (
                    <RequestModal onClose={() => setShowRequestModal(false)} response={response} />
                ) : null}
                {showLabelModal && (
                    <LabelModal
                        product={product}
                        quantity={quantity}
                        packaging={packaging}
                        onClose={() => setShowLabelModal(false)}
                    />
                )}
            </div>
        );
    }
};
