import React from "react";
import { formatRichText } from "../../utils/string.utils";

import "./loyaltyStatus.scss";

type LoyaltyStatusProps = {
	reference: string;
	displayName: string;
	avantages: string;
	active: boolean;
}

const LoyaltyStatus = (props: LoyaltyStatusProps) => {
	return (
		<article className={`loyalty-status ${props.active ? 'active' : ''}`}>
			{props.active &&
				<div className="active-indicator">Your current status</div>
			}
			<div className="top-box">
				{props.reference === 'BRONZE' &&
					<img
						src="/assets/images/loyaltyProgram/bronze-partner-badge.png"
						alt="Bronze Partner Badge"
						className="partner-badge"
					/>
				}
				{props.reference === 'SILVER' &&
					<img
						src="/assets/images/loyaltyProgram/silver-partner-badge.png"
						alt="Silver Partner Badge"
						className="partner-badge"
					/>
				}
				{props.reference === 'GOLD' &&
					<img
						src="/assets/images/loyaltyProgram/gold-partner-badge.png"
						alt="Gold Partner Badge"
						className="partner-badge"
					/>
				}
				{props.reference === 'BLUE' &&
					<img
						src="/assets/images/loyaltyProgram/blue-partner-badge.png"
						alt="Blue Partner Badge"
						className="partner-badge"
					/>
				}

				<h1
					className="loyalty-status-name"
					style={{
						color: props.reference === 'BRONZE'
							? '#A25D26'
							: props.reference === 'SILVER'
								? '#A8B7B5'
								: props.reference === 'GOLD'
									? '#CD9650'
									: props.reference === 'BLUE'
										? '#5CFDDB'
										: '#000000'
					}}
				>
					{ props.displayName }
				</h1>
			</div>

			<h2 className="advantages-title">Your advantages:</h2>
			<div className="advantages">
				{ formatRichText(props.avantages ?? "") }
			</div>
		</article>
	)
}

export default LoyaltyStatus;