import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { formatNativeDate, formatNativeDatetime } from "../../utils/dates.utils";

import "./dateInput.scss";

type DateInputProps = {
    onClick?: any;
    onChange?: (text: string) => void;
    onBlur?: () => void;
    label?: string;
    required?: boolean;
    time?: boolean;
    value?: any;
    color?: string;
    errorMsg?: string;
    successMsg?: string;
};

const DateInput = (props: DateInputProps) => {
    const [internalValue, setInternalValue] = useState(props.value ? new Date(props.value) : null);

    useEffect(() => {
        if (internalValue) {
            props.onChange(
                props.time ? formatNativeDatetime(internalValue) : formatNativeDate(internalValue)
            );
        } else {
            props.onChange(null);
        }
    }, [internalValue]);

    const onChange = x => {
        setInternalValue(x);
    };

    return (
        <div className={`date-input ${props.color || ""}`} onClick={props?.onClick}>
            {props.label || props.required ? (
                <div className="label-wrapper">
                    <div className="label">
                        {props.label || ""}
                        {props.required ? "*" : ""}
                    </div>
                </div>
            ) : null}
            <DatePicker
                value={internalValue || null}
                onChange={onChange}
                format="dd/MM/yyyy"
            />
            {props.errorMsg && <div className="error-text">{props.errorMsg}</div>}
            {props.successMsg && <div className="success-text">{props.successMsg}</div>}
        </div>
    );
};

export default DateInput;
