import { store } from "../store/configure.store";
import AppSlice from "../store/slices/app.slice";

export const setError = (error) => {
    store.dispatch(AppSlice.actions.setError(error));
};

export const setSuccess = (success) => {
    store.dispatch(AppSlice.actions.setSuccess(success));
};
