import React from "react";

import Colors from "../../themes/colors.theme";

export default ({ color }) => (
    <svg className="search-icon" width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
        <g id="01.-Atoms-/-02.-Icons-/-Search" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group" transform="translate(9.000000, 8.000000)" stroke={Colors[color || "dark"]} strokeWidth="2">
                <circle id="Oval" fillRule="nonzero" cx="9.9" cy="9.9" r="9.9"></circle>
                <line x1="17.05" y1="18.15" x2="22" y2="23" id="Line-7" strokeLinecap="round"></line>
            </g>
        </g>
    </svg>
);
