const Colors = {
    primary: "#8E58C0",
    secondary: "#0CADDD",
    success: "#28A745",
    danger: "#DC3545",
    warning: "#FFC107",
    info: "#17A2B8",
    dark: "#191817",
    light: "#F5FBFD",
    gray: "#AEADAA",
    white: "#FFFFFF",
    transparent: "transparent"
};

export default Colors;