import React from "react";
import { Link } from "react-router-dom";
import { FAQURL } from "../../constants";

import { useLogin } from "../../utils/auth.utils";
import "./footer.scss";

export default () => {
    const { isLogin } = useLogin();

    return (
        <div id="footer">
            <div className="top">
                <div className="left">
                    <Link className="logo" to={isLogin ? "/home" : "/"}>
                        <img src="/assets/logo.png" alt="Logo" />
                    </Link>
                    <nav className="menu">
                        <a href={FAQURL}>Frequently asked questions</a>
                        <a
                            href="/assets/documents/FSOPRE171D_Ver01_%20General%20Terms%20and%20Condition%20of%20Sales_Ver01.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                        >
                            Terms of sale
                        </a>
                        <Link to="/">Privacy policy</Link>
                        <a href="mailto:contact@dktwomancare.org">Contact us</a>
                    </nav>
                </div>
                <div className="social">
                    <div className="links">
                        <a
                            href="https://www.facebook.com/DKTWomanCare"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src="/assets/icons/social/facebook.svg" alt="Facebook" />
                        </a>
                        <a
                            href="https://twitter.com/DKTWomanCare"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src="/assets/icons/social/twitter.svg" alt="Twitter" />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/dktwomancareglobal"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src="/assets/icons/social/linkedin.svg" alt="LinkedIn" />
                        </a>
                        <a
                            href="https://www.youtube.com/channel/UCL9BfymRSvKxQW4yiF7tFZg"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src="/assets/icons/social/youtube.svg" alt="YouTube" />
                        </a>
                        <a
                            href="https://www.instagram.com/dktwomancare/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src="/assets/icons/social/instagram.svg" alt="Instagram" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div>Version {process.env.REACT_APP_VERSION}</div>
                <div>© 2021 DKT International</div>
                <div>Designed by Adveris</div>
            </div>
        </div>
    );
};
