import React, { useEffect } from "react";

import "./roundRating.scss";
import Colors from "../themes/colors.theme";

export default ({ rating }) => {
    const canvasSize = 50;
    const lineWidth = 2;

    const id = Math.random().toString().substring(2);

    const drawCircle = (xc, yc, r) => {
        var partLength = rating * 2 * Math.PI;
        var start = -Math.PI / 2;

        var canvas: any = document.getElementById(id);

        let dpi = window.devicePixelRatio;

        let styleHeight = +getComputedStyle(canvas).getPropertyValue("height").slice(0, -2);
        let styleWidth = +getComputedStyle(canvas).getPropertyValue("width").slice(0, -2);

        canvas.setAttribute("height", `${styleHeight * dpi}`);
        canvas.setAttribute("width", `${styleWidth * dpi}`);

        r *= dpi;
        xc *= dpi;
        yc *= dpi;

        /* Base circle */

        var ctx = canvas.getContext("2d");
        ctx.beginPath();
        ctx.arc(xc, yc, r, start, start + 2 * Math.PI);
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = "#E5E5E5";
        ctx.stroke();
        ctx.closePath();

        /* Rating circle */

        ctx.beginPath();
        ctx.arc(xc, yc, r, start, start + partLength);
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = rating >= 0.5 ? "#74B200" : Colors.dark;
        ctx.stroke();
        ctx.closePath();

        canvas.style.height = styleHeight + "px";
        canvas.style.width = styleWidth + "px";
    };

    useEffect(() => {
        drawCircle(canvasSize / 2, canvasSize / 2, canvasSize / 2 - lineWidth);
    }, []);

    return (
        <div className="round-rating">
            <canvas id={id} width={canvasSize} height={canvasSize} />
            <div className="content">
                <div className="label">{Math.round(rating * 100)}%</div>
            </div>
        </div>
    );
};
