import React from "react";

import Colors from "../../themes/colors.theme";
import "./close.scss";

export default (props: { color?: string; circle?: boolean, onClick?: () => void }) => (
    <div className="close" onClick={props?.onClick}>
        {props.circle ? (
            <svg viewBox="0 0 24 24" version="1.1">
                <g
                    id="01.-Atoms-/-02.-Icons-/-Circles-/-Cross"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <circle id="Oval" stroke={Colors[props.color || "primary"]} cx="12" cy="12" r="11.5"></circle>
                    <g
                        id="Group"
                        transform="translate(12.186187, 12.501977) rotate(-315.000000) translate(-12.186187, -12.501977) translate(6.186187, 6.501977)"
                        stroke={Colors[props.color || "primary"]}
                        strokeLinecap="round"
                        strokeWidth="1.5"
                    >
                        <path
                            d="M5.68421053,0.315789474 L5.68421053,11.6842105 M11.3684211,6 L1.14575016e-13,6"
                            id="Combined-Shape"
                        ></path>
                    </g>
                </g>
            </svg>
        ) : (
            <svg viewBox="0 0 40 40" version="1.1">
                <g
                    id="01.-Atoms-/-02.-Icons-/-Cross"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                >
                    <g
                        id="Group-2"
                        transform="translate(20.000000, 20.000000) rotate(-315.000000) translate(-20.000000, -20.000000) translate(10.000000, 10.000000)"
                        stroke={Colors[props.color || "primary"]}
                        strokeWidth="1.5"
                    >
                        <line x1="10" y1="0" x2="10" y2="20" id="Line-4"></line>
                        <line x1="20" y1="10" x2="0" y2="10" id="Line-4"></line>
                    </g>
                </g>
            </svg>
        )}
    </div>
);
