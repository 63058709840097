import React, { useState, useEffect } from "react";
import EventsCard from "./EventsCard";
import Pager from "../../components/Pager";
import FiltersDropdown from "./FiltersDropdown";
import { getAllEvents } from "../../services/events.service";
import { usePager } from "../../utils/layout.utils";

import "./eventsList.scss";

const eventsFilters = [
    {
        id: "date",
        label: "Date",
    },
    {
        id: "location",
        label: "Location",
    },
];

const EventsList = () => {
    const [events, setEvents] = useState<any[]>([]);
    const [currentFilter, setCurrentFilter] = useState<{ id: string; label: string }>(
        eventsFilters[0]
    );
    const [numberOfEventsPerPage, setNumberOfEventsPerPage] = useState<number>(
        window.innerWidth > 1024 ? 15 : 5
    );

    const { page, setPage, pageCount, displayedItems: displayedEvents } = usePager(events, numberOfEventsPerPage);

    useEffect(() => {
        _getEvents();

        window.addEventListener("resize", _handleWindowResize);

        return () => window.removeEventListener("resize", _handleWindowResize);
    }, []);

    useEffect(() => {
        switch (currentFilter.id) {
            case "date":
                setEvents(_sortEventsByDate(events));
                break;
            case "location":
                setEvents(_sortEventsByLocation(events));
                break;
        }
    }, [currentFilter]);

    const _handleWindowResize = () => {
        setNumberOfEventsPerPage(window.innerWidth > 1024 ? 15 : 5);
    };

    const _getEvents = async () => {
        const events = await getAllEvents();
        setEvents(_sortEventsByDate(events));
    };

    const _sortEventsByDate = (events) => {
        return [...events].sort((a, b) => {
            const aDate = new Date(a.date);
            const bDate = new Date(b.date);
            return bDate.getTime() - aDate.getTime();
        });
    };

    const _sortEventsByLocation = (events) => {
        return [...events].sort((a, b) => {
            const aLocation = a.location.name;
            const bLocation = b.location.name;
            return aLocation > bLocation
                ? 1
                : aLocation < bLocation
                    ? -1
                    : 0;
        });
    };

    return (
        <div id="events">
            <div className="title-wrapper">
                <h1>Events</h1>
            </div>

            <FiltersDropdown
                text={currentFilter.label}
                filters={eventsFilters.filter((filter) => filter.id !== currentFilter.id)}
                onChange={(id) => setCurrentFilter(eventsFilters.find((filter) => filter.id === id))}
            />

            {events.length !== 0 ? (
                <div className="events-container">
                    {displayedEvents.map((item) => (
                        <EventsCard
                            key={item.id}
                            id={item.id}
                            date={item.date}
                            title={item.title}
                            description={item.description}
                            city={item.location?.name}
                        />
                    ))}
                </div>
            ) : (
                <div className="no-events">No events yet...</div>
            )}

            <Pager
                pageCount={pageCount}
                currentPage={page}
                onSetPage={(x) => setPage(x)}
                link="#events"
            />
        </div>
    );
};

export default EventsList;
