import { get, post, put, del } from "./utils.service";

export const getOrders = async () => {
    try {
        const res = await get(`/order`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const saveOrder = async (data) => {
    const res = await post(`/order`, data);
    return await res.json();
};

export const setOrder = async (data) => {
    const res = await put(`/order`, data);
    return await res.json();
};

export const confirmOrReject = async (data) => {
    const res = await put(`/order/status`, data);
    return await res.json();
};

export const cancelOrder = async (data) => {
    const res = await put(`/order/cancel`, data);
    return await res.json();
};

export const setOrderAddress = async (data) => {
    const res = await put(`/order/address`, data);
    return await res.json();
};

export const setOrderItems = async (data) => {
    const res = await put(`/order/items`, data);
    return await res.json();
};

export const addOrderItems = async (data) => {
    const res = await post(`/order/items`, data);
    return await res.json();
};

export const deleteOrderItems = async (id) => {
    const res = await del(`/order/items/${id}`);
    return await res.json();
};

export const uploadDocument = async (data) => {
    const res = await post(`/order-document/`, data);
    return await res.json();
};
