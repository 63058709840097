import { useEffect, useState } from "react";

export const useQuery = (
    query: () => Promise<any>,
    onSuccess?: (data: any) => void | Promise<void>,
    defaultValue: any = []
) => {
    const [data, setData] = useState(defaultValue);

    useEffect(() => {
        (async () => {
            const res = await query();
            setData(res);
            if (onSuccess) {
                await onSuccess(res);
            }
        })();
    }, []);

    return [data, setData];
};

export const useAtomicQuery = (
    query: () => Promise<any>,
    onSuccess?: (data: any) => void | Promise<void>
) => useQuery(query, onSuccess, null);
