import queryString from "query-string";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import BackLink from "../../components/BackLink";
import Button from "../../components/forms/Button";
import TextInput from "../../components/forms/TextInput";
import IconLink from "../../components/IconLink";
import AddressModal from "../../components/order/AddressModal";
import OrderStepper from "../../components/order/Stepper";
import DistributorSelectors from "../../selectors/distributor.selectors";
import OrderSelectors from "../../selectors/order.selectors";
import { setOrder, setOrderAddress } from "../../services/orders.service";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import OrderSlice from "../../store/slices/order.slice";
import { setError } from "../../utils/app.utils";
import "./address.scss";

const AddressBlock = (props: {
    addressType: "BILLING" | "SHIPPING" | "CONTACT";
    title: string;
    address: any;
    setContact?: any;
    orderToEdit?: number;
}) => {
    const [showModal, setShowModal] = useState(false);

    const [name, setName] = useState(props.address ? props.address.name : "");
    const [phone, setPhone] = useState(props.address ? props.address.phone : "");

    return (
        <div className="address-block">
            <div className="title">{props.title}</div>
            {props.address && !(props.addressType === "CONTACT" && showModal) ? (
                <>
                    <div className="content">
                        <div className="name">{props.address.name}</div>
                        {props.address.address ||
                        props.address.zipCode ||
                        props.address.city ||
                        props.address.country ? (
                            <div className="detail">
                                {props.address.address ? (
                                    <>
                                        {props.address.address}
                                        <br />
                                    </>
                                ) : null}
                                {props.address.zipCode || props.address.city ? (
                                    <>
                                        {props.address.zipCode || ""} {props.address.city || ""}
                                    </>
                                ) : null}
                                <br />
                                {props.address.country ? props.address.country.name : null}
                            </div>
                        ) : null}
                        {props.address.email ? (
                            <div className="email">{props.address.email}</div>
                        ) : null}
                        {props.address.phone ? (
                            <div className="phone">{props.address.phone}</div>
                        ) : null}
                    </div>
                    <IconLink
                        onClick={() => setShowModal(true)}
                        icon="edit"
                        label="Edit"
                        color="secondary"
                    />
                </>
            ) : null}
            {!props.address && !(props.addressType === "CONTACT" && showModal) ? (
                <IconLink
                    onClick={() => setShowModal(true)}
                    icon="edit"
                    label="Edit"
                    color="secondary"
                />
            ) : null}
            {showModal && ["BILLING", "SHIPPING"].includes(props.addressType) && (
                <AddressModal
                    addressType={props.addressType}
                    onClose={() => setShowModal(false)}
                    orderToEdit={props.orderToEdit}
                />
            )}
            {showModal && props.addressType === "CONTACT" && (
                <div className="contact-form">
                    <TextInput label="Your name" value={name} onChange={(x) => setName(x)} />
                    <TextInput label="Phone number" value={phone} onChange={(x) => setPhone(x)} />
                    <div className="buttons">
                        <Button
                            text="Validate"
                            onClick={() => {
                                if (props.orderToEdit) {
                                    store.dispatch(
                                        DistributorSlice.actions.setOrderAttribute({
                                            id: props.orderToEdit,
                                            fields: { contactName: name, contactPhone: phone },
                                        })
                                    );
                                } else {
                                    props.setContact({ name, phone });
                                }
                                setShowModal(false);
                            }}
                        />
                        <Button
                            text="Cancel"
                            onClick={() => {
                                setShowModal(false);
                            }}
                            color="light"
                            hasBorder
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default () => {
    const history = useHistory();
    const location = useLocation();

    const { orderToEdit } = queryString.parse(location.search);

    const defaultShippingAddress = useSelector((state: any) =>
        OrderSelectors.defaultShippingAddress(state)
    );

    const defaultBillingAddress = useSelector((state: any) =>
        OrderSelectors.defaultBillingAddress(state)
    );

    const order = useSelector((state: any) => state.order);

    const existingOrder = useSelector(DistributorSelectors.orderById(orderToEdit));

    const [contact, setContact] = useState({
        name:
            order.shippingAddress && order.shippingAddress.addressee
                ? order.shippingAddress.addressee
                : "",
        phone:
            order.shippingAddress && order.shippingAddress.phone ? order.shippingAddress.phone : "",
    });

    const goToNext = async () => {
        if (existingOrder) {
            await setOrderAddress({
                orderId: existingOrder.id,
                addressId: existingOrder.shippingAddress.id,
                addressType: "SHIPPING",
            });
            await setOrderAddress({
                orderId: existingOrder.id,
                addressId: existingOrder.billingAddress.id,
                addressType: "BILLING",
            });
            await setOrder({
                orderId: existingOrder.id,
                fields: {
                    contactName: existingOrder.contactName ? existingOrder.contactName : "",
                    contactPhone: existingOrder.contactPhone ? existingOrder.contactPhone : "",
                },
            });
            history.push("/profile/order/" + existingOrder.id + "/edit");
        } else {
            if (order.shippingAddress.id && order.billingAddress.id) {
                store.dispatch(OrderSlice.actions.setContact(contact));
                history.push("/order/shipping");
            } else {
                setError("Please select shipping and billing adresses");
            }
        }
    };

    if (existingOrder && !existingOrder.statusData.editable) {
        return <Redirect to={"/profile/order/" + existingOrder.id} />;
    } else {
        return (
            <div id="order-address">
                {existingOrder ? (
                    <BackLink
                        label="Back to my order"
                        to={"/profile/order/" + existingOrder.id + "/edit/"}
                    />
                ) : (
                    <OrderStepper currentStep={1} />
                )}
                <h1>Shipping and billing addresses</h1>
                <div className="subtitle">
                    Edit if needed the shipping and billing addresses, and add a contact name for
                    the delivery.
                    <br />
                    Please note that you can only make one order per shipping address.{" "}
                </div>
                <div className="addresses">
                    <AddressBlock
                        addressType="SHIPPING"
                        title="Shipping address"
                        address={
                            existingOrder ? existingOrder.shippingAddress : defaultShippingAddress
                        }
                        orderToEdit={existingOrder ? existingOrder.id : null}
                    />
                    <AddressBlock
                        addressType="BILLING"
                        title="Billing address"
                        address={
                            existingOrder ? existingOrder.billingAddress : defaultBillingAddress
                        }
                        orderToEdit={existingOrder ? existingOrder.id : null}
                    />
                    <AddressBlock
                        addressType="CONTACT"
                        title="Contact for delivery"
                        address={
                            existingOrder
                                ? {
                                      name: existingOrder.contactName,
                                      phone: existingOrder.contactPhone,
                                  }
                                : contact
                        }
                        setContact={setContact}
                        orderToEdit={existingOrder ? existingOrder.id : null}
                    />
                </div>
                <Button text="Validate" onClick={goToNext} />
            </div>
        );
    }
};
