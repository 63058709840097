import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    videosAndTutorials: [],
};

const ResourceSlice = createSlice({
    name: "resource",
    initialState,
    reducers: {
        setResourcesVideosAndTutorials: (state, { payload }) => ({
            ...state,
            videosAndTutorials: payload,
        }),

        setMarketingMaterials: (state, { payload }) => ({
            ...state,
            marketingMaterials: payload,
        }),

        setPosAndPop: (state, { payload }) => ({
            ...state,
            posAndPop: payload,
        }),

        setMarketData: (state, { payload }) => ({
            ...state,
            marketData: payload,
        }),

        setBestPractices: (state, { payload }) => ({
            ...state,
            bestPractices: payload,
        }),
        
        setWCAcademy: (state, { payload }) => ({
            ...state,
            wcAcademy: payload,
        }),

        resetResources: () => (initialState),
    },
});

export default ResourceSlice;
