import { get, post } from "./utils.service";

export const getCurrentDistributor = async () => {
    try {
        const res = await get(`/distributors/me`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const patchPassword = async (token, password) => {
    const res = await post(`/users/password`, { token, password });
    return await res.json();
};

export const setDistributorData = async data => {
    try {
        const res = await post(`/distributors/set-data`, data);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const requestAgreement = async data => {
    try {
        const res = await post(`/distributors/request-agreement`, data);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const getStatistics = async () => {
    try {
        const res = await get(`/distributors/statistics`);
        return await res.json();
    } catch (e) {
        return null;
    }
};