import React from "react";
import { useSelector } from "react-redux";

import "./users.scss";
import BackLink from "../../components/BackLink";
import CaretLink from "../../components/CaretLink";
import DistributorSelectors from "../../selectors/distributor.selectors";
import IconLink from "../../components/IconLink";

export default () => {
    const users = useSelector((state: any) => DistributorSelectors.usersData(state));

    return (
        <div id="users" className="profile">
            <BackLink to="/profile/me" label="Back to my profile" />
            <h1>My users</h1>
            <div className="links">
                <CaretLink to="/profile/company" label="My company" />
                <CaretLink to="/profile/security" label="Security" />
            </div>
            <div className="users">
                {users.map((user) => (
                    <div key={user.id} className="user">
                        <div className="info">
                            <div className="line">{user.fullName}</div>
                            <div className="line">{user.job}</div>
                            <div className="line">{user.email}</div>
                            <div className="line">{user.phone}</div>
                            <div className="line">/{user.username}</div>
                        </div>
                        <IconLink
                            icon="edit"
                            label="Edit"
                            color="white"
                            to={`/profile/users/edit/${user.id}`}
                        />
                    </div>
                ))}
            </div>
            <IconLink icon="plus" label="Add new user" color="secondary" to="/profile/users/edit" />
        </div>
    );
};
