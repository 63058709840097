import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BackLink from "../../components/BackLink";
import Button from "../../components/forms/Button";
import DateInput from "../../components/forms/DateInput";
import NumberInput from "../../components/forms/NumberInput";
import Select from "../../components/forms/Select";
import ForecastsSelectors from "../../selectors/forecasts.selectors";
import { upsertForecast } from "../../services/forecasts.service";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import { computeNextMonthsFromNow, MonthLabels } from "../../utils/dates.utils";
import "./forecastForm.scss";

const MonthRow = (props: {
    date: any;
    onChange: (ym: any, key: string, value: number) => void;
}) => (
    <div className="month-row">
        <div className="title">
            {MonthLabels[props.date.month - 1]} {props.date.year}
        </div>
        <div className="inputs">
            <NumberInput
                label="How many orders?"
                min={0}
                value={props.date.orderCount}
                onChange={(x) => props.onChange(props.date, "orderCount", x)}
                color="dark"
            />
            <NumberInput
                label="Order quantity?"
                min={0}
                value={props.date.orderQuantity}
                onChange={(x) => props.onChange(props.date, "orderQuantity", x)}
                color="dark"
            />
        </div>
    </div>
);

export default () => {
    const history = useHistory();

    const { id }: any = useParams();

    const forecast = useSelector(ForecastsSelectors.byId(id));
    const products = useSelector((state: any) => state.shop.products);

    const [state, setState] = useState({
        ...forecast,
        forecastMonths: computeNextMonthsFromNow(12).map((month) => {
            const forecastMonth =
                forecast && forecast.forecastMonths
                    ? forecast.forecastMonths.find(
                          (x) => x.year === month.year && x.month === month.month
                      )
                    : {};
            return {
                ...month,
                ...forecastMonth,
            };
        }),
    });

    const [errorMsg, setErrorMsg] = useState("");

    const set = (field, value) => setState((prev) => ({ ...prev, [field]: value }));

    const setMonth = ({ year, month }, key, value) =>
        setState((prev) => ({
            ...prev,
            forecastMonths: prev.forecastMonths.map((x) =>
                x.year === year && x.month === month
                    ? {
                          ...x,
                          [key]: value,
                      }
                    : x
            ),
        }));

    const submit = async () => {
        setErrorMsg("");

        const res = await upsertForecast(state);
        if (res.success) {
            if (id) {
                store.dispatch(DistributorSlice.actions.setForecast({ ...state, ...res.forecast }));
            } else {
                store.dispatch(DistributorSlice.actions.addForecast(res.forecast));
            }
            history.push("/profile/forecasts");
        } else {
            setErrorMsg("Error while creating forecast");
        }
    };

    return (
        <div id="forecast-form" className="profile">
            <BackLink to="/profile/forecasts" label="Back" />
            <h1>{id ? "Edit" : "New"} forecast</h1>
            <div className="form">
                <section>
                    <div className="inputs">
                        <Select
                            title="Product"
                            options={products.map((product) => ({
                                value: product.id,
                                label: product.name,
                            }))}
                            value={state.productId}
                            onChange={(x) => set("productId", x)}
                            color="white"
                        />
                    </div>
                    <div className="inputs">
                        <NumberInput
                            label="Current inventory level"
                            min={0}
                            value={state.currentInventoryLevel}
                            onChange={(x) => set("currentInventoryLevel", x)}
                            color="dark"
                        />
                        <DateInput
                            label="Date of stock ticking"
                            value={state.stockTickingDate}
                            onChange={(x) => set("stockTickingDate", x)}
                            color="dark"
                        />
                    </div>
                </section>
                <section>
                    <div className="title">
                        <h2>Regarding the next 12 months</h2>
                    </div>
                    <div className="months">
                        {state.forecastMonths.map((date, index) => (
                            <MonthRow key={index} date={date} onChange={setMonth} />
                        ))}
                    </div>
                </section>
                <div className="submit-block">
                    <Button color="white" text="Validate" onClick={submit} />
                    {errorMsg ? <div className="error">{errorMsg}</div> : null}
                </div>
                <div className="note">* Mandatory field</div>
            </div>
        </div>
    );
};
