import React, { useState } from "react";
import "./radioInput.scss";
import TextInput from "./TextInput";

type RadioInputItem<T> = {
    value: T;
    label: string;
};

type RadioInputProps<T> = {
    label?: string;
    sublabel?: string;
    items: RadioInputItem<T>[];
    value?: T;
    required?: boolean;
    allowCustomValues?: boolean;
    onChange: (value: T | string) => void;
    color?: string;
    vertical?: boolean;
};

export const RadioInput = <T extends any>(props: RadioInputProps<T>) => {
    const [customValue, setCustomValue] = useState(
        props.value && props.items.every((i) => i.value !== props.value)
            ? props.value.toString()
            : ""
    );

    return (
        <div className={`radio-input ${props.color || ""} ${props.vertical ? "vertical" : ""}`}>
            {props.label || props.required ? (
                <div className="label">
                    {props.label || ""}
                    {props.required ? "*" : ""}
                </div>
            ) : null}
            {props.sublabel ? <div className="sublabel">{props.sublabel}</div> : null}
            <div className="items">
                {props.items.map((item, index) => (
                    <div
                        key={index}
                        className={`item ${
                            props.value === item.value && !customValue ? "active" : ""
                        }`}
                        onClick={() => {
                            if (props.value === item.value) {
                                props.onChange(null);
                            } else {
                                props.onChange(item.value);
                            }
                            setCustomValue(null);
                        }}
                    >
                        {item.label}
                    </div>
                ))}
                {props.allowCustomValues ? (
                    <div
                        className={`item ${customValue ? "active" : ""}`}
                        onClick={() => (customValue ? setCustomValue(null) : {})}
                    >
                        Other (please specify):
                        <TextInput
                            value={customValue}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            }}
                            onChange={(x) => setCustomValue(x)}
                            onBlur={() => props.onChange(customValue)}
                            color="primary"
                            style={{ marginBottom: -4 }}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};
