import React, { PropsWithChildren } from "react";
import Close from "./icons/Close";
import "./modal.scss";

export default (
    props: PropsWithChildren<{
        className?: string;
        onClose?: () => void;
        closeOnBackgroundClick?: boolean;
        hasCloseIcon?: boolean;
    }>
) => {
    return (
        <div
            className="modal-wrapper"
            onClick={(e) => {
                if (props.onClose && props.closeOnBackgroundClick) {
                    props.onClose();
                }

                e.stopPropagation();
            }}
        >
            <div
                className={`modal ${props.className || ""}`}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {props.children}
                {props.hasCloseIcon ? <Close onClick={props.onClose} color="dark" /> : null}
            </div>
        </div>
    );
};
