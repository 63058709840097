import React from "react";
import { useHistory } from "react-router-dom";
import { getFile } from "../../utils/s3.utils";
import Button from "../forms/Button";
import "./wcAcademyItem.scss";

export default (props: { item: any; onClickLink: string }) => {
    const history = useHistory();

    const redirectToDetails = () => {
        history.push(props.onClickLink);
    };

    return (
        <div onClick={redirectToDetails} id="academyResourceItem">
            <div className="image-content">
                {props.item.image ? (
                    <div className="image-wrapper">
                        <img
                            className="image"
                            src={getFile(props.item.image)}
                            alt="Academy resource"
                        />
                    </div>
                ) : null}
            </div>
            <div className="text-content">
                <div className="name">{props.item.title}</div>
                <div className="excerpt">{props.item.introduction}</div>
                <Button text="See more" color="dark" onClick={redirectToDetails} />
            </div>
        </div>
    );
};
