import React, { useRef, useState } from "react";
import { useResize } from "../../utils/layout.utils";
import Caret from "../icons/Caret";
import "./categorySlider.scss";
import WCAcademyItem from "./ResourceItem";

const ITEM_WIDTH = 416;

const TYPE_LINK = {
    VIDEOS_AND_TUTORIALS: "videos-and-tutorials",
    MARKETING_MATERIALS: "marketing-materials",
    WC_ACADEMY: "womancare-academy",
    POS_AND_POP: "pos-and-pop",
    MARKET_DATA: "market-data",
    BEST_PRACTICES: "best-practices",
};

export default (props: { title: string; items: any }) => {
    const [currentItemIndex, setCurrentItemIndex] = useState(0);

    const sliderContainer = useRef();

    const { width: sliderContainerWidth } = useResize(sliderContainer);

    const sliderItemsWidth = props.items.length * ITEM_WIDTH;

    const sliderOffset =
        sliderItemsWidth < sliderContainerWidth
            ? 0
            : Math.min(sliderItemsWidth - sliderContainerWidth, ITEM_WIDTH * currentItemIndex);

    const doIncrementCurrentItemIndex = () => {
        return sliderOffset < sliderItemsWidth - sliderContainerWidth;
    };

    return (
        <div className="wcacademy-slider">
            <div className="slider-header">
                <h2 className="title">{props.title}</h2>
                <div className="arrows">
                    <button
                        className="arrow left"
                        aria-label="Previous"
                        onClick={() => setCurrentItemIndex((prev) => Math.max(0, prev - 1))}
                    >
                        <Caret type="left" size={12} />
                    </button>
                    <button
                        className="arrow right"
                        aria-label="Next"
                        onClick={() => {
                            if (doIncrementCurrentItemIndex()) {
                                setCurrentItemIndex((prev) =>
                                    Math.min(props.items.length - 1, prev + 1)
                                );
                            }
                        }}
                    >
                        <Caret type="right" size={12} />
                    </button>
                </div>
            </div>
            <div className="slider-container" ref={sliderContainer}>
                <div
                    className="slider"
                    style={{
                        marginLeft: -sliderOffset,
                    }}
                >
                    {props.items.map((item, index) => (
                        <WCAcademyItem
                            key={index}
                            item={item}
                            onClickLink={
                                item.resource.type === "WC_ACADEMY"
                                    ? `/resources/${TYPE_LINK[item.resource.type]}/${
                                          item.resource.id
                                      }`
                                    : `/resources/${TYPE_LINK[item.resource.type]}/${item.id}`
                            }
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
