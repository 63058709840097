import React, { useEffect, useState } from "react";
import Tooltip from "../Tooltip";

import "./textInput.scss";

type TextInputProps = {
    onClick?: any;
    onChange?: (text: string) => void;
    onInput?: () => void;
    onBlur?: () => void;
    onFocus?: () => void;
    label?: string;
    tooltip?: string;
    placeholder?: string;
    required?: boolean;
    password?: boolean;
    email?: boolean;
    value?: string;
    color?: string;
    style?: Object;
    errorMsg?: string;
    successMsg?: string;
};

const TextInput = (props: TextInputProps) => {
    const [internalValue, setInternalValue] = useState(props.value || "");

    useEffect(() => {
        props.onChange(internalValue);
    }, [internalValue]);

    const onTextChange = e => {
        setInternalValue(e.target.value);
    };

    return (
        <div
            className={`text-input ${props.color || ""}`}
            onClick={props?.onClick}
            style={props?.style}
        >
            {props.label || props.required ? (
                <div className="label-wrapper">
                    <div className="label">
                        {props.label || ""}
                        {props.required ? "*" : ""}
                    </div>
                    {props.tooltip ? (
                        <Tooltip content={props.tooltip} color={props?.color} />
                    ) : null}
                </div>
            ) : null}
            <input
                className="input"
                type={props.password ? "password" : props.email ? "email" : "text"}
                value={internalValue || props.value || ""}
                onChange={onTextChange}
                onInput={props?.onInput}
                onBlur={props?.onBlur}
                onFocus={props?.onFocus}
                placeholder={props.placeholder}
            />
            {props.errorMsg && <div className="error-text">{props.errorMsg}</div>}
            {props.successMsg && <div className="success-text">{props.successMsg}</div>}
        </div>
    );
};

export default TextInput;
