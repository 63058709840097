import React, { useState } from "react";
import { useSelector } from "react-redux";
import BackLink from "../../components/BackLink";
import CaretLink from "../../components/CaretLink";
import Button from "../../components/forms/Button";
import MultiSelectAutocomplete from "../../components/forms/MultiSelectAutocomplete";
import Download from "../../components/icons/Download";
import Modal from "../../components/Modal";
import DistributorSelectors from "../../selectors/distributor.selectors";
import { requestAgreement } from "../../services/distributors.service";
import { setError, setSuccess } from "../../utils/app.utils";
import "./agreements.scss";

const Cols = ["Agreement", "Type of agreement", "Product code", "Country", "Download"];

export default () => {
    const agreements = useSelector((state: any) => DistributorSelectors.agreementsData(state));

    const shop = useSelector((state: any) => state.shop);

    const [showRequestModal, setShowRequestModal] = useState(false);
    const [countries, setCountries] = useState([]);

    const [requesting, setRequesting] = useState(false);

    const requestAccess = async () => {
        setRequesting(true);
        const res = await requestAgreement({ countriesIds: countries });

        if (res.success) {
            setShowRequestModal(false);
            setCountries([]);
            setSuccess("Agreement successfully requested");
        } else {
            setError("Error while requesting agreement");
        }

        setRequesting(false);
    };

    return (
        <div id="agreements" className="profile">
            <BackLink to="/profile/me" label="Back to my data" />
            <h1>My commercial agreements</h1>
            <div className="links">
                <CaretLink to="/profile/forecasts" label="Forecasts" />
                <CaretLink to="/profile/labels" label="Label and artwork" />
                <CaretLink to="/profile/documents" label="My documents" />
            </div>
            <table>
                <thead>
                    <tr>
                        {Cols.map((col, index) => (
                            <th key={index}>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {agreements.map((agreement) => {
                        return (
                            <tr key={agreement.id}>
                                <td data-label={Cols[0]}>{agreement.date}</td>
                                <td data-label={Cols[1]}>{agreement.type}</td>
                                <td data-label={Cols[2]}>{agreement.productCode}</td>
                                <td data-label={Cols[3]}>{agreement.countryName}</td>
                                <td data-label={Cols[4]}>
                                    {agreement.download ? (
                                        <Download color="primary" url={agreement.download} />
                                    ) : (
                                        <p>Not available yet.</p>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="request">
                <div className="title">Do you want to expand to a new country?</div>
                <Button text="Request access" onClick={() => setShowRequestModal(true)} />
                {showRequestModal ? (
                    <Modal onClose={() => setShowRequestModal(false)} closeOnBackgroundClick>
                        <div className="title">Select countries</div>
                        <MultiSelectAutocomplete
                            options={shop.countries.map((x) => ({ value: x.id, label: x.name }))}
                            values={countries}
                            onChange={(x) => setCountries(x)}
                        />
                        <div className="buttons">
                            <Button
                                text="Request access"
                                onClick={requestAccess}
                                disabled={requesting || countries.length === 0}
                            />
                            <Button
                                text="Cancel"
                                onClick={() => setShowRequestModal(false)}
                                color="transparent"
                                hasBorder
                            />
                        </div>
                    </Modal>
                ) : null}
            </div>
        </div>
    );
};
