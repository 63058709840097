import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    productCategories: [],
    productSubCategories: [],
    products: [],
    countries: [],
    filters: {
        search: "",
        productCategory: null,
        productSubCategory: null,
        country: null,
    },
};

const ShopSlice = createSlice({
    name: "shop",
    initialState,
    reducers: {
        setProductCategories: (state, { payload }) => ({
            ...state,
            productCategories: payload,
        }),
        setProductSubCategories: (state, { payload }) => ({
            ...state,
            productSubCategories: payload,
        }),
        setProducts: (state, { payload }) => ({
            ...state,
            products: payload,
        }),
        setCountries: (state, { payload }) => ({
            ...state,
            countries: payload,
        }),
        setSearchFilter: (state, { payload }) => ({
            ...state,
            filters: {
                ...state.filters,
                search: payload,
            },
        }),
        setProductCategoryFilter: (state, { payload }) => ({
            ...state,
            filters: {
                ...state.filters,
                productCategory: payload,
            },
        }),
        setProductSubCategoryFilter: (state, { payload }) => ({
            ...state,
            filters: {
                ...state.filters,
                productSubCategory: payload,
            },
        }),
        setProductCountryFilter: (state, { payload }) => ({
            ...state,
            filters: {
                ...state.filters,
                country: payload,
            },
        }),
        resetFilters: (state: any) => ({
            ...state,
            filters: initialState.filters,
        }),
        reset: () => initialState,
    },
});

export default ShopSlice;
