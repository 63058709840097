import React, { useEffect, useState } from "react";
import { Timeline } from "react-twitter-widgets";
import Calender from "../../components/events/Calender";
import NewsletterSubscribeCard from "../../components/NewsletterSubscribeCard";
import Pager from "../../components/Pager";
import { getAllNews } from "../../services/news.service";
import { usePager } from "../../utils/layout.utils";
import FiltersDropdown from "./FiltersDropdown";
import NewsCard from "./NewsCard";
import "./newsList.scss";
import SocialCard from "./SocialCard";

const newsFilters = [
    {
        id: "latest",
        label: "Most recent",
    },
    {
        id: "oldest",
        label: "Less recent",
    },
];

const NewsList = () => {
    const [news, setNews] = useState<any[]>([]);
    const [currentFilter, setCurrentFilter] = useState<{ id: string; label: string }>(
        newsFilters[0]
    );
    const [numberOfNewsPerPage, setNumberOfNewsPerPage] = useState<number>(
        window.innerWidth > 1024 ? 8 : 4
    );

    const {
        page,
        setPage,
        pageCount,
        displayedItems: displayedNews,
    } = usePager(news, numberOfNewsPerPage);

    useEffect(() => {
        _getNews();

        window.addEventListener("resize", _handleWindowResize);

        return () => window.removeEventListener("resize", _handleWindowResize);
    }, []);

    useEffect(() => {
        switch (currentFilter.id) {
            case "latest":
                setNews(_sortNewsByDate("DESC"));
                break;
            case "oldest":
                setNews(_sortNewsByDate("ASC"));
                break;
        }
    }, [currentFilter]);

    const _handleWindowResize = () => {
        setNumberOfNewsPerPage(window.innerWidth > 1024 ? 8 : 4);
    };

    const _getNews = async () => {
        const news = await getAllNews();
        setNews(news);
    };

    const _sortNewsByDate = (order: "ASC" | "DESC") => {
        return [...news].sort((a, b) => {
            const aDate = new Date(a.date);
            const bDate = new Date(b.date);

            if (order === "ASC") {
                return aDate.getTime() - bDate.getTime();
            } else if (order === "DESC") {
                return bDate.getTime() - aDate.getTime();
            }

            return 0;
        });
    };

    return (
        <div id="news">
            <div className="title-wrapper">
                <h1>News & Events</h1>
            </div>

            <FiltersDropdown
                text={currentFilter.label}
                filters={newsFilters.filter((filter) => filter.id !== currentFilter.id)}
                onChange={(id) => setCurrentFilter(newsFilters.find((filter) => filter.id === id))}
            />

            <div className="news-grid">
                <section className="news-section">
                    {news.length !== 0 ? (
                        <div className="news-container">
                            {displayedNews.map((item) => (
                                <NewsCard
                                    key={item.id}
                                    id={item.id}
                                    date={item.date}
                                    title={item.title}
                                    excerpt={item.excerpt}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="no-news">No news yet...</div>
                    )}
                </section>
                <section className="pages-section">
                    <Pager
                        pageCount={pageCount}
                        currentPage={page}
                        onSetPage={(x) => setPage(x)}
                        link="#news"
                    />
                </section>
                <section className="twitter-section">
                    <Timeline
                        dataSource={{
                            sourceType: "profile",
                            screenName: "DKTWomanCare",
                        }}
                        options={{
                            theme: "dark",
                            height: 600,
                        }}
                    />
                </section>
                <section className="socials-section">
                    <SocialCard />
                </section>
            </div>

            <div className="bottom-row">
                <div className="next-events">
                    <h2 style={{ marginBottom: 0 }}>Next events</h2>
                    <a className="all-events-link" href="/events">
                        See all events
                    </a>
                    <div className="bottom-row-subtitle">
                        Discover the next events related to your activity.
                    </div>
                    <Calender />
                </div>
                <div className="newsletter-subscribe">
                    <h2>Subscribe to our newsletter</h2>
                    <div className="bottom-row-subtitle">
                        To get the latest new, subscribe to our newsletter.
                    </div>
                    <NewsletterSubscribeCard />
                </div>
            </div>
        </div>
    );
};

export default NewsList;
