import React from "react";
import { HashLink } from "react-router-hash-link";

type LinkButtonProps = {
    to?: string;
    onClick?: () => void;
    label: string;
    color?: string;
};

export default (props: LinkButtonProps) =>
    props.to ? (
        props.to.slice(0, 4) === "http" ? (
            <a href={props.to} className={`link-button ${props.color || "primary"}`}>
                {props.label}
            </a>
        ) : (
            <HashLink
                to={props.to}
                onClick={props?.onClick}
                className={`link-button ${props.color || "primary"}`}
            >
                {props.label}
            </HashLink>
        )
    ) : (
        <button onClick={props?.onClick} className={`link-text ${props.color || "primary"}`}>
            {props.label}
        </button>
    );
