import React from "react";

import "./language.scss";

export default (props: {
    language: string;
    color?: string;
    circle?: boolean;
    onClick?: () => void;
}) => (
    <div className={`language ${props.color || ""}`} onClick={props?.onClick}>
        <div className="language-text">{props.language}</div>
    </div>
);
