import React from "react";

import Colors from "../../themes/colors.theme";

export default ({ color }) => (
    <svg className="plus" width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
        <g
            id="01.-Atoms-/-02.-Icons-/-Plus"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
        >
            <g
                id="Group-2"
                transform="translate(10.000000, 10.000000)"
                stroke={Colors[color || "primary"]}
                strokeWidth="1.5"
            >
                <line x1="10" y1="0" x2="10" y2="20" id="Line-4"></line>
                <line x1="20" y1="10" x2="0" y2="10" id="Line-4"></line>
            </g>
        </g>
    </svg>
);
