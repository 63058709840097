export default [
    { title: "My WomanCare", path: "/my-womancare" },
    {
        title: "Resources",
        items: [
            { title: "All", path: "/resources" },
            { title: "Videos & tutorials", path: "/resources/videos-and-tutorials" },
            { title: "Marketing materials", path: "/resources/marketing-materials" },
            { title: "WomanCare Academy", path: "/resources/womancare-academy" },
            { title: "POP & POS", path: "/resources/pos-and-pop" },
            { title: "Studies & market data", path: "/resources/market-data" },
            { title: "Best practices", path: "/resources/best-practices" },
        ],
    },
    {
        title: "News",
        items: [
            { title: "News", path: "/news" },
            { title: "Events", path: "/events" },
        ],
    },
    { title: "Purchase", path: "/shop", emph: true },
    {
        title: "Report event",
        emph: true,
        items: [
            { title: "Report an issue with an order", path: "/my-womancare/report/order-issue" },
            { title: "Report a tender", path: "/my-womancare/report/tender" },
            { title: "Report a new competitor", path: "/my-womancare/report/competitor" },
            { title: "Report a change of law", path: "/my-womancare/report/law-change" },
        ],
    },
    { path: "/cart", icon: "cart" },
    {
        icon: "profile",
        items: [
            { title: "My profile", path: "/profile/me" },
            { title: "My company", path: "/profile/company" },
            { title: "My users", path: "/profile/users" },
            { title: "Security", path: "/profile/security" },
            { title: "Logout" },
        ],
    },
    {
        icon: "chart",
        items: [
            { title: "My commercial agreements", path: "/profile/agreements" },
            { title: "My documents", path: "/profile/documents" },
            { title: "Label artwork", path: "/profile/labels" },
            { title: "Forecasts", path: "/profile/forecasts" },
            { title: "My orders", path: "/profile/orders" },
            { title: "My data", path: "/profile/data" },
        ],
    },
];
