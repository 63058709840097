import { get } from "./utils.service";

export const getAllNews = async () => {
    const res = await get(`/news`);
    return await res.json(); 
}

export const getSomeNews = async (skip: number, take: number) => {
    const res = await get(`/news/some/${skip}/${take}`);
    return await res.json();
};

export const countAllNews = async () => {
    const res = await get(`/news/count`);
    return await res.json();
}

export const getNewsDetails = async (id: number) => {
    const res = await get(`/news/details/${id}`);
    return await res.json();
}

export const getLatestNews = async (take: number, excludeId: number) => {
    const res = await get(`/news/latests/${take}/${excludeId}`);
    return await res.json();
}
