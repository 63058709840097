import React, { useEffect, useState } from "react";
import BackLink from "../../components/BackLink";
import CaretLink from "../../components/CaretLink";
import Button from "../../components/forms/Button";
import CheckInput from "../../components/forms/CheckInput";
import DropdownInput from "../../components/forms/DropdownInput";
import TextArea from "../../components/forms/TextArea";
import TextInput from "../../components/forms/TextInput";
import Modal from "../../components/Modal";
import RequestModal from "../../components/shop/RequestModal";
import { getAll } from "../../services/countries.service";
import { createTicket } from "../../services/zendesk.service";
import "./reportOrderIssue.scss";

export default () => {
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [showWaitingModal, setShowWaitingModal] = useState(false);
    const [state, setState] = useState({
        country: null,
        order: null,
        comment: "",
    });
    const [response, setResponse] = useState(null);

    const [countries, setCountries] = useState(null);

    const [check, setCheck] = useState(false);

    const set = (field, value) => setState((prev) => ({ ...prev, [field]: value }));

    const submit = async () => {
        setResponse(null);

        if (!state.country || !state.comment || !check) {
            setResponse({ success: false, message: "Missing mandatory fields" });
            return;
        }
        setShowWaitingModal(true);

        const data = { type: "reportOrderIssue", state: state };
        const res = await createTicket(data);
        setShowWaitingModal(false);
        setResponse(res);
        setShowRequestModal(true);
        setState({
            country: null,
            order: "",
            comment: "",
        });
    };

    useEffect(() => {
        (async () => {
            const res = await getAll();
            if (res) {
                setCountries(
                    res.map((x) => {
                        return { label: x.name, value: x.id };
                    })
                );
            }
        })();
    }, []);

    return (
        <div id="reportOrderIssue" className="profile">
            <BackLink to="/my-womancare" label="Back to my Womancare" />
            <h1>Report an issue with an order</h1>
            <p id="description">Description</p>
            <div className="links">
                <CaretLink to="/my-womancare/report/tender" label="Report a tender" />
                <CaretLink to="/my-womancare/report/competitor" label="Report a new competitor" />
                <CaretLink to="/my-womancare/report/law-change" label="Report a change of law" />
            </div>
            <div className="form">
                <section>
                    <div className="inputs">
                        <div id="first-row">
                            <DropdownInput
                                title="What is your country ? *"
                                label=""
                                items={countries}
                                value={state.country}
                                onChange={(x) => set("country", x)}
                                color="white"
                                allowNull={false}
                            />
                            <TextInput
                                label="Order number"
                                value={state.order}
                                onChange={(x) => set("order", x)}
                                required
                                color="dark"
                            />
                        </div>
                        <TextArea
                            label="Can you the issue"
                            value={state.comment}
                            onChange={(x) => set("comment", x)}
                            color="dark"
                            required
                            placeholder="Type here"
                        />
                        <CheckInput
                            label="By ticking this box, I agree that my information will be used to contact me regarding this request
and accept the confidentiality politicy of Womancare."
                            onChange={(x) => setCheck((prev) => !prev)}
                            color="dark"
                            value={check}
                        />
                    </div>
                </section>
                <div className="submit-block">
                    <Button color="white" text="Validate" onClick={submit} />
                    {response?.success === false ? (
                        <div className="error">{response?.message}</div>
                    ) : null}
                </div>
                <div className="note">* Mandatory field</div>
            </div>
            {showWaitingModal ? (
                <Modal onClose={() => setShowRequestModal(false)}>
                    <div className="content">Your request is being sent...</div>
                </Modal>
            ) : null}
            {showRequestModal ? (
                <RequestModal onClose={() => setShowRequestModal(false)} response={response} />
            ) : null}
        </div>
    );
};
