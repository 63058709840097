import React from "react";
import parse from "html-react-parser";

const formatText = (text) =>
    text.split("\n").map((line, index, lines) => (
        <React.Fragment key={index}>
            {line}
            {index < lines.length - 1 && <br />}
        </React.Fragment>
    ));

// make valid new lines and other operations if necessary
const renderAsTextComponent = (locale) => {
    return locale
        .split("\n")
        .reduce(
            (acc, textPart, index) => [
                ...acc,
                ...(index === 0 ? [] : [<br key={index} />]),
                textPart,
            ],
            []
        );
};

const formatParagraphs = (text = "") =>
    text.split("\n\n").map((textPart, index) => <p key={index}>{textPart}</p>);

const ellipsize = (text = "", maxLength) =>
    `${text.substring(0, maxLength)}${text.length > maxLength ? "..." : ""}`;

// example : emphasizeWord("a b c", "b") => a <strong>b</strong> c
const emphasizeWord = (text = "", wordToEmphasize) => {
    return text
        .split(" ")
        .reduce(
            (acc, word, index) => [
                ...acc,
                index > 0 ? " " : "",
                word === wordToEmphasize ? <strong key={index}>{word}</strong> : word,
            ],
            ""
        );
};

const emphasizeSubstring = (text = "", substringToEmphasize = "") => {
    const substringIndex = text.indexOf(substringToEmphasize);

    return (
        <div className="emphasize-substring-result">
            {text.substring(0, substringIndex)}
            <strong>
                {text.substring(substringIndex, substringIndex + substringToEmphasize.length)}
            </strong>
            {text.substring(substringIndex + substringToEmphasize.length)}
        </div>
    );
};

const formatRichText = (text) => <div className="formatted-text">{text ? parse(text) : ""}</div>;

export {
    formatText,
    formatRichText,
    renderAsTextComponent,
    formatParagraphs,
    ellipsize,
    emphasizeWord,
    emphasizeSubstring,
};
