import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BackLink from "../../components/BackLink";
import Button from "../../components/forms/Button";
import DropdownInput from "../../components/forms/DropdownInput";
import Item from "../../components/resources/Item";
import ResourceSelectors from "../../selectors/resource.selectors";
import { getFile } from "../../utils/s3.utils";
import "./marketingMaterialDetails.scss";

export default () => {
    const history = useHistory();
    const { itemId }: any = useParams();

    const [sameResourceItems, setSameResourceItems] = useState([]);
    const [displayedItems, setDisplayedItems] = useState([]);
    const item = useSelector(ResourceSelectors.marketingMaterialbyId(itemId));

    const allItems = useSelector(ResourceSelectors.getMarketingMaterials());

    const redirectOtherLanguage = (id) => {
        history.push(`/resources/marketing-materials/${id}`);
    };

    useEffect(() => {
        const relatedItems = [...allItems]
            .filter((x) => x.language.id === item.language.id && x.id != item.id)
            .sort((a, b) => (a.creationDate < b.creationDate ? 1 : -1))
            .slice(0, 3);
        const inCommonItems = allItems
            .filter((x) => x.resource.id === item.resource.id)
            .reduce((acc, obj) => {
                const key = obj.language.id;
                if (!acc[key]) {
                    acc[key] = { label: obj.language.name, value: obj.id };
                }
                return acc;
            }, {});
        setDisplayedItems(relatedItems);
        setSameResourceItems(Object.values(inCommonItems));
    }, [allItems]);

    return (
        <div id="marketingMaterialDetails" className="profile">
            <div className="content">
                <BackLink label="Back to resources" to="/resources/marketing-materials/" />
                <div className="page-title">
                    <h1>{item.title}</h1>
                    {sameResourceItems.length > 1 ? (
                        <DropdownInput
                            label=""
                            items={sameResourceItems}
                            onChange={(x) => redirectOtherLanguage(x)}
                            color="primary"
                            value={item.id}
                        />
                    ) : null}
                </div>
                <div className="introduction">{item.introduction}</div>
                <a className="download-button" href={getFile(item.file)} download>
                    <Button text="Download material" inactive></Button>
                </a>
                {item.image ? (
                    <div className="cover-image">
                        <img src={getFile(item.image)} alt="Cover" />
                    </div>
                ) : null}
                <div className="text-content">{item.content}</div>
                <div className="related-products">
                    <h2>Related Marketing Materials</h2>
                    <div className="items">
                        {displayedItems.map((item, index) => (
                            <Item
                                key={index}
                                item={item}
                                onClickLink={`/resources/marketing-materials/${item.id}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
