import React, { Fragment, useEffect, useState } from "react";
import "./myDataChart.scss";
import * as d3 from "d3";
import Select from "./Select";
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

const WIDTH = 768;
const HEIGHT = 300;
const MARGIN = { top: 10, right: 30, bottom: 80, left: 15 };

const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

type MyDataChartProps = {
    data: any;
};

const MyDataChart = ({ data = [] }: MyDataChartProps) => {
    const svgRef = React.useRef(null);
    const chartRef = React.useRef(null);
    const [productFiltered, setProductFiltered] = useState<any>([]);
    const [dataProcessed, setDataProcessed] = useState<any>([]);
    const [categoryFilter, setCategoryFilter] = useState(0);
    const [subCategoryFilter, setSubCategoryFilter] = useState(0);
    const [countryFilter, setCountryFilter] = useState(0);
    const [productFilter, setProductFilter] = useState(0);
    const [yearFilter, setYearFilter] = useState(0);
    const [hasCountryFilter, setHasCountryFilter] = useState(false);
    const [countries, setCountries] = useState([]);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [years, setYears] = useState([]);

    // Create a global list depending on the property
    const setPropertyList = (data, property, addAll) => {
        let filteredData = data;
        if (property !== "category") {
            if (+categoryFilter != 0) {
                filteredData = filteredData.filter((x) => x.category_id === +categoryFilter);
            }
            if (property !== "subCategory") {
                if (+subCategoryFilter != 0) {
                    filteredData = filteredData.filter(
                        (x) => x.subCategory_id === +subCategoryFilter
                    );
                }
            }
        }

        const id = property + "_id";
        const name = property + "_name";
        const ids = filteredData.reduce((acc, obj) => {
            if (!acc.includes(obj[id])) {
                acc.push(obj[id]);
            }
            return acc;
        }, []);
        const list = [];
        if (addAll) {
            list.push({ id: 0, name: "All" });
        }
        for (const item of ids) {
            const newElt = {
                id: item,
                name: filteredData.find((x) => x[id] === item)[name],
            };
            list.push(newElt);
            list.sort((a, b) => (a.name > b.name ? 1 : -1));
        }
        switch (property) {
            case "country":
                setCountries(list);
                if (list.length > 1) {
                    setHasCountryFilter(true);
                }
                break;
            case "product":
                setProducts(list);
                break;
            case "category":
                setCategories(list);
                break;
            case "subCategory":
                setSubCategories(list);
                break;
        }
    };

    // Query the API to get the filteredData and parse it to initiates this.products
    const setLists = (currentData) => {
        setPropertyList(currentData, "category", false);
        setPropertyList(currentData, "subCategory", false);
        setPropertyList(currentData, "product", false);
        setPropertyList(currentData, "country", true);
        setYears(
            currentData.reduce((acc, obj) => {
                if (!acc.includes(obj.year)) {
                    acc.push(obj.year);
                }
                return acc;
            }, [])
        );
    };

    const filterData = (data) => {
        let filteredData = data;
        if (+categoryFilter != 0) {
            filteredData = filteredData.filter((x) => x.category_id === +categoryFilter);
        }
        if (+subCategoryFilter != 0) {
            filteredData = filteredData.filter((x) => x.subCategory_id === +subCategoryFilter);
        }
        if (+countryFilter != 0) {
            filteredData = filteredData.filter((x) => x.country_id === +countryFilter);
        }
        if (+productFilter != 0) {
            filteredData = filteredData.filter((x) => x.product_id === +productFilter);
        }
        if (+yearFilter != 0) {
            filteredData = filteredData.filter((x) => x.year === yearFilter);
        }
        return filteredData;
    };

    useEffect(() => {
        if (data && data.length > 0) {
            setLists(data);
        }
    }, [data, categoryFilter, subCategoryFilter]);

    useEffect(() => {
        if (data && data.length > 0) {
            const filtered = filterData(data);
            const max = d3.max(filtered?.map((x) => x.product_id)) + 1;
            const min = d3.min(filtered?.map((x) => x.product_id));
            const color = (i) => {
                return d3.interpolatePlasma((0.8 * (i - min)) / (max - min));
            };
            const products = filtered
                ? filtered
                      .map((x) => {
                          return {
                              color: color(x.product_id),
                              name: x.product_name,
                          };
                      })
                      .filter((v, i, a) => a.findIndex((x) => x.name === v.name) === i)
                : [];

            const dataKeys = filtered.map((x) => x.key);
            const minKey = Math.min(...dataKeys);
            const maxKey = Math.max(...dataKeys);
            let processedData = [];
            for (let i = minKey; i <= maxKey; i++) {
                const month = i % 100;
                if (month === 13) i = i - 12 + 100;
                const monthData = filtered.filter((x) => x.key === i);
                const processedMonthData = {
                    key: i,
                    label: i.toString().slice(4, 6) + "/" + i.toString().slice(0, 4),
                    ...monthData.reduce(
                        (a, v) => {
                            if (a[v.product_name]) {
                                a[v.product_name] += +v.orderCount * +v.orderQuantity;
                            } else {
                                a[v.product_name] = +v.orderCount * +v.orderQuantity;
                            }
                            return a;
                        },

                        {}
                    ),
                    ...monthData.reduce(
                        (a, v) => ({
                            ...a,
                            ["forecast : " + v.product_name]:
                                (parseInt(v.forecastOrderCount) || 0) *
                                (parseInt(v.forecastOrderQuantity) || 0),
                        }),
                        {}
                    ),
                };
                processedData.push(processedMonthData);
            }
            processedData = processedData.map((x) => {
                products.forEach((product) => {
                    if (!x[product.name]) x[product.name] = 0;
                    if (!x["forecast : " + product.name]) x["forecast : " + product.name] = 0;
                });
                return x;
            });
            setProductFiltered(products);
            setDataProcessed(processedData);
        }
    }, [
        categoryFilter,
        subCategoryFilter,
        categories,
        subCategories,
        products,
        countries,
        years,
        productFilter,
        countryFilter,
        yearFilter,
    ]);

    const CustomTooltip = (props) => {
        const { active, payload, label } = props;
        if (active && payload && payload.length > 0) {
            const monthData = dataProcessed.find((x) => x.key === +payload[0].payload.key);
            const products = Object.keys(monthData).filter((key) => {
                return key !== "key" && key !== "label" && key.slice(0, 11) !== "forecast : ";
            });

            return (
                <div id="tooltip-container">
                    <p className="label">{`${label}`}</p>
                    {products.map((x) => (
                        <p className="line">
                            {x}
                            <span className="value">
                                {monthData[x]} / {monthData["forecast : " + x]}
                            </span>
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    return (
        <div id="chartComponent" ref={chartRef}>
            <h2>Filters</h2>
            <div id="doubleDropdownMenu">
                <div className="menu">
                    <Select
                        title="Category"
                        label="All"
                        options={[{ label: "All", value: 0 }].concat(
                            categories?.map((x) => ({ label: x.name, value: x.id }))
                        )}
                        onChange={(x) => setCategoryFilter(+x)}
                        color="secondary"
                        value={categoryFilter}
                    />
                </div>
                <div className="menu">
                    <Select
                        title="Sub-category"
                        label="All"
                        options={[{ label: "All", value: 0 }].concat(
                            subCategories?.map((x) => ({ label: x.name, value: x.id }))
                        )}
                        onChange={(x) => setSubCategoryFilter(+x)}
                        color="secondary"
                        value={subCategoryFilter}
                    />
                </div>
                <div className="menu">
                    <Select
                        title="Product"
                        label="All"
                        options={[{ label: "All", value: 0 }].concat(
                            products?.map((x) => ({ label: x.name, value: x.id }))
                        )}
                        onChange={(x) => setProductFilter(+x)}
                        color="secondary"
                        value={productFilter}
                    />
                </div>
                {hasCountryFilter && (
                    <div className="menu">
                        <Select
                            title="Country"
                            label="All"
                            options={countries?.map((x) => ({ label: x.name, value: x.id }))}
                            onChange={(x) => setCountryFilter(+x)}
                            color="secondary"
                            value={countryFilter}
                        />
                    </div>
                )}
                <div className="menu">
                    <Select
                        title="Year"
                        label="All"
                        options={[{ label: "All", value: 0 }].concat(
                            years?.map((x) => ({ label: x, value: x }))
                        )}
                        onChange={(x) => setYearFilter(+x)}
                        color="secondary"
                        value={yearFilter}
                    />
                </div>
            </div>
            <h2 id="chartTitle">Sales</h2>
            <ResponsiveContainer aspect={2}>
                <ComposedChart
                    data={dataProcessed}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="label" name="label" scale="auto" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} wrapperStyle={{ outline: "none" }} />

                    {productFiltered.map((product, i) => {
                        return (
                            <Fragment key={i}>
                                <Line
                                    type="step"
                                    dataKey={"forecast : " + product.name}
                                    stroke={product.color}
                                    strokeWidth={3}
                                />{" "}
                            </Fragment>
                        );
                    })}
                    {productFiltered.map((product, i) => {
                        return (
                            <Fragment key={i}>
                                <Bar dataKey={product.name} barSize={20} fill={product.color} />
                            </Fragment>
                        );
                    })}
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

export default MyDataChart;
