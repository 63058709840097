import React from "react";
import { Link } from "react-router-dom";

import "./statusLink.scss";
import Alert from "./icons/Alert";
import Order from "./icons/Order";

export default (props: { to?: string; icon: string; label: string; color: string }) => {
    const IconComponent = {
        alert: Alert,
        order: Order,
    }[props.icon];

    return (
        <Link
            className="status-link"
            to={props.to ? props.to : "#"}
            onClick={(e) => {
                if (!props.to) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }
            }}
        >
            {IconComponent ? (
                <IconComponent color={props.color} />
            ) : (
                <img src={`/assets/icons/${props.icon}.svg`} alt={props.label} />
            )}
            <span className={`status-link-text ${props.color || "primary"}`}>{props.label}</span>
        </Link>
    );
};
