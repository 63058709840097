import queryString from "query-string";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import BackLink from "../../components/BackLink";
import Button from "../../components/forms/Button";
import { RadioInput } from "../../components/forms/RadioInput";
import TextInput from "../../components/forms/TextInput";
import Close from "../../components/icons/Close";
import Modal from "../../components/Modal";
import OrderStepper from "../../components/order/Stepper";
import Tooltip from "../../components/Tooltip";
import DistributorSelectors from "../../selectors/distributor.selectors";
import { setOrder } from "../../services/orders.service";
import { RootState, store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import OrderSlice, { TransportMode } from "../../store/slices/order.slice";
import { setError } from "../../utils/app.utils";
import "./shipping.scss";

const destinations = {
    options: Object.entries(TransportMode).map(([key, value]) => ({
        label: value,
        value: key,
    })),
    field: {
        name: "destination",
        label: "Destination",
    },
};

const Methods = ["EXW", "FCA", "FOB", "CIP", "CPT", "CIF", "DAP"];

export default () => {
    const history = useHistory();

    const order = useSelector((state: RootState) => state.order);

    const [transportMode, setTransportMode] = useState(order.shipping.transportMode || null);
    const [destination, setDestination] = useState("");

    const [showInfoModal, setShowInfoModal] = useState(false);

    const location = useLocation();
    const { orderToEdit } = queryString.parse(location.search);

    const existingOrder = useSelector(DistributorSelectors.orderById(orderToEdit));

    const [selectedShippingMethodIndex, setSelectedShippingMethodIndex] = useState(
        existingOrder ? Methods.findIndex((element) => element === existingOrder.shippingMethod) : 0
    );

    const goToNext = async () => {
        if (!transportMode) {
            setError("Please select an option within Airport, Road and Harbor");
        } else {
            if (existingOrder) {
                const res = await setOrder({
                    orderId: existingOrder.id,
                    fields: {
                        shippingMethod: Methods[selectedShippingMethodIndex],
                        transportMode: transportMode,
                        destination: destination,
                    },
                });
                const { orderId, fields }: any = res.updatedFields;
                store.dispatch(
                    DistributorSlice.actions.setOrderAttribute({ id: orderId, fields: fields })
                );
                history.push("/profile/order/" + existingOrder.id + "/edit");
            } else {
                store.dispatch(
                    OrderSlice.actions.setShipping({
                        method: Methods[selectedShippingMethodIndex],
                        transportMode,
                        destination: destination,
                    })
                );
                history.push("/order/certificates");
            }
        }
    };

    if (existingOrder && !existingOrder.statusData.editable) {
        return <Redirect to={"/profile/order/" + existingOrder.id} />;
    } else {
        return (
            <div id="order-shipping">
                {existingOrder ? (
                    <BackLink
                        label="Back to my order"
                        to={"/profile/order/" + existingOrder.id + "/edit/"}
                    />
                ) : (
                    <OrderStepper currentStep={2} />
                )}
                <h1>Shipping method</h1>
                <div className="subtitle">Select a shipping method:</div>
                <div className="content">
                    <div className="selection">
                        {Methods.map((method, index) => (
                            <div
                                key={index}
                                className={`method ${
                                    index === selectedShippingMethodIndex ? "active" : ""
                                }`}
                                onClick={() => setSelectedShippingMethodIndex(index)}
                            >
                                <div className="title">{method}</div>
                                {index === selectedShippingMethodIndex ? (
                                    <RadioInput
                                        items={destinations.options}
                                        value={transportMode}
                                        onChange={(x: TransportMode) => setTransportMode(x)}
                                        color="white"
                                    />
                                ) : null}
                                {index === selectedShippingMethodIndex ? (
                                    <TextInput
                                        label={destinations.field.label}
                                        placeholder="Type here"
                                        color="dark"
                                        value={
                                            existingOrder && method === existingOrder.shippingMethod
                                                ? existingOrder.shippingInfo
                                                : null
                                        }
                                        onChange={(x) => setDestination(x)}
                                    />
                                ) : null}
                            </div>
                        ))}
                    </div>
                    <div className="validation">
                        <div className="title">
                            <span>
                                More info regarding the Incoterm
                                <Tooltip onClick={() => setShowInfoModal(true)} color="primary" />
                            </span>
                        </div>
                        <div className="info">
                            The cost delivery will be calculated in your order quote.
                        </div>
                        <Button text="Validate" onClick={goToNext} />
                    </div>
                </div>
                {showInfoModal && (
                    <Modal
                        className="shipping-info"
                        onClose={() => setShowInfoModal(false)}
                        closeOnBackgroundClick
                    >
                        <a className="image" href="/assets/images/shipping-info.png">
                            <img src="/assets/images/shipping-info.png" alt="Shipping info" />
                        </a>
                        <Close onClick={() => setShowInfoModal(false)} />
                    </Modal>
                )}
            </div>
        );
    }
};
