import React from "react";

import "./eventsCard.scss";

const NewsCard = (props: {
	id: number;
    date: Date|string;
	title: string;
	description: string;
	city?: string;
}) => {
	return (
		<article className="events-card">
			<div className="wave-container">
				<svg
					viewBox="0 0 540 120"
					xmlns="http://www.w3.org/2000/svg"
					version="1.1"
					className="header-wave"
				>
					<path d="M0,120Q80,50,200,100T540,60L540,0L0,0L0,120" fill="#479bc4" />
				</svg>
				<div className="events-card-date-place">
					{ new Date(props.date).toLocaleDateString('US') } { props.city ? '- ' + props.city : '' }
				</div>
			</div>

			<div className="content">
				<h1 className="events-card-title">
					{ props.title }
				</h1>
				<p className="events-card-description">
					{ props.description }
				</p>
			</div>
		</article>
	);
};

export default NewsCard;