import React, { useState } from "react";
import Button from "../../components/forms/Button";

import "./filtersDropdown.scss";

const FiltersDropdown = (props: {
	text: string;
	filters: { id: string, label: string }[];
	onChange?: (id: string) => void;
}) => {
	const [showDropdown, setShowDropdown] = useState<boolean>(false);

	const _toggleDropdown = () => {
		setShowDropdown(!showDropdown)
	}

	return (
		<div className={`events-filter-button-wrapper ${showDropdown ? 'open' : ''}`}>
			<div className="events-filter-button-label">Sort by:</div>
			<Button
				text={props.text}
				color="dark"
				icon="caret"
				onClick={_toggleDropdown}
			/>

			{showDropdown &&
				<ul className="events-filters-list">
					{props.filters.map(filter => (
						<li
							key={filter.id}
							onClick={() => {
								_toggleDropdown();
								props.onChange(filter.id);
							}}
						>
							{ filter.label }
						</li>
					))}
				</ul>
			}
		</div>
	);
};

export default FiltersDropdown;