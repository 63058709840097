import React, { useState } from "react";

import Button from "../../components/forms/Button";
import TextInput from "../../components/forms/TextInput";
import { RadioInput } from "../../components/forms/RadioInput";
import { validateEmail } from "../../utils/regex.util";
import { useLogin } from "../../utils/auth.utils";
import "./profile.scss";
import { store } from "../../store/configure.store";
import CaretLink from "../../components/CaretLink";
import { setUserData } from "../../services/users.service";
import MeSlice from "../../store/slices/me.slice";

export default () => {
    const { me } = useLogin();

    const [state, setState] = useState(me);

    const [errorMsg, setErrorMsg] = useState("");

    const set = (field, value) => setState((prev) => ({ ...prev, [field]: value }));

    const submit = async () => {
        setErrorMsg("");
        if (!state.civility || !state.firstname || !state.lastname || !state.job || !state.phone) {
            setErrorMsg("Missing mandatory fields");
            return;
        }
        if (!validateEmail(state.email)) {
            setErrorMsg("Email invalid");
            return;
        }

        const res = await setUserData(state);
        if (res.success) {
            store.dispatch(MeSlice.actions.setMe(state));
        } else {
            setErrorMsg("This email is already used");
        }
    };

    return (
        <div id="profile" className="profile">
            <h1>My profile</h1>
            <div className="links">
                <CaretLink to="/profile/company" label="My company" />
                <CaretLink to="/profile/users" label="My users" />
                <CaretLink to="/profile/security" label="Security" />
            </div>
            <div className="form">
                <section>
                    <div className="title">
                        <h2>Personal information</h2>
                    </div>
                    <RadioInput
                        label="Title"
                        required
                        items={[
                            { value: "MR", label: "Mr." },
                            { value: "MS", label: "Ms." },
                            { value: "OTHER", label: "I prefer not to disclose" },
                        ]}
                        value={state.civility}
                        onChange={(civility) => setState((prev) => ({ ...prev, civility }))}
                        color="white"
                    />
                    <div className="inputs">
                        <TextInput
                            label="Last name"
                            value={state.lastname}
                            onChange={(x) => set("lastname", x)}
                            required
                            color="dark"
                        />
                        <TextInput
                            label="First name"
                            value={state.firstname}
                            onChange={(x) => set("firstname", x)}
                            required
                            color="dark"
                        />
                        <TextInput
                            label="Position"
                            value={state.job}
                            onChange={(x) => set("job", x)}
                            required
                            color="dark"
                        />
                        <TextInput
                            label="Email address"
                            value={state.email}
                            onChange={(x) => set("email", x)}
                            required
                            color="dark"
                        />
                        <TextInput
                            label="Phone number"
                            value={state.phone}
                            onChange={(x) => set("phone", x)}
                            required
                            color="dark"
                        />
                        <TextInput
                            label="Whatsapp / Skype / Wechat"
                            value={state.phone2 || ""}
                            onChange={(x) => set("phone2", x)}
                            color="dark"
                        />
                    </div>
                </section>
                <div className="submit-block">
                    <Button color="white" text="Validate" onClick={submit} />
                    {errorMsg ? <div className="error">{errorMsg}</div> : null}
                </div>
                <div className="note">* Mandatory field</div>
            </div>
        </div>
    );
};
