import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BackLink from "../../components/BackLink";
import Item from "../../components/resources/Item";
import ResourceSelectors from "../../selectors/resource.selectors";
import { getFile } from "../../utils/s3.utils";
import "./bestPracticeDetails.scss";

export default () => {
    const { itemId }: any = useParams();

    const [displayedItems, setDisplayedItems] = useState([]);
    const item = useSelector(ResourceSelectors.bestPracticesById(itemId));

    const allItems = useSelector(ResourceSelectors.getBestPractices());
    useEffect(() => {
        const relatedItems = [...allItems]
            .filter((x) => x.id != item.id)
            .sort((a, b) => (a.creationDate < b.creationDate ? 1 : -1))
            .slice(0, 3);
        setDisplayedItems(relatedItems);
    }, [allItems]);

    return (
        <div id="bestBracticesDetails" className="profile">
            <div className="content">
                <BackLink label="Back to resources" to="/resources/best-practices/" />
                <div className="page-title">
                    <h1>{item.title}</h1>
                </div>
                <div className="introduction">{item.introduction}</div>
                <div className="text-content">{item.content}</div>
                {item.file ? (
                    <div className="cover-image">
                        <img src={getFile(item.file)} alt="Cover" />
                    </div>
                ) : null}
                <div className="text-content">{item.content2}</div>
                <div className="related-products">
                    <h2>Related best practices</h2>
                    <div className="items">
                        {displayedItems.map((item, index) => (
                            <Item
                                key={index}
                                item={item}
                                onClickLink={`/resources/best-practices/${item.id}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
