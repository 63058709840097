import React from "react";
import { useSelector } from "react-redux";
import BackLink from "../../components/BackLink";
import Button from "../../components/forms/Button";
import { useBonusTrocarsManagement } from "../../hooks/useBonusTrocarsManagement";
import OrderSelectors from "../../selectors/order.selectors";
import { store } from "../../store/configure.store";
import OrderSlice from "../../store/slices/order.slice";
import "./confirmation.scss";

export default () => {
    const orderData = useSelector((state: any) => OrderSelectors.orderProducts(state));

    useBonusTrocarsManagement();

    const resetCart = () => {
        store.dispatch(OrderSlice.actions.removeProductsFromCart(orderData.products));
        store.dispatch(OrderSlice.actions.resetCartBonus({ sectionId: orderData.sectionId }));
    };

    return (
        <div id="order-confirmation">
            <BackLink to="/home" label="Go back to the site" onClick={resetCart} />
            <img src="/assets/icons/cart-checked.svg" alt="Order validated" />
            <h1>Thank you for your purchase order!</h1>
            <div className="subtitle">
                Thank you for your order request! Our team will get back to you with a quote
                including freight costs within 72 hours. Once you receive it, you have 30 days to
                confirm or cancel your order. Once confirmed, you won't be able to modify your order
                anymore. Keep an eye on your inbox!
            </div>
            <div className="order">
                <div className="block">
                    <div className="content">
                        <div className="title">
                            Place this order with a different shipping address
                        </div>
                        <Button text="Place this order again" to="/order/cart" />
                    </div>
                </div>
                <div className="block">
                    <div className="content">
                        <div className="title">Place an order with a different product</div>
                        <Button text="Request a new quote" to="/shop" onClick={resetCart} />
                    </div>
                </div>
            </div>
            <Button
                text="See my orders"
                to="/profile/orders"
                onClick={resetCart}
                color="transparent"
                hasBorder
            />
        </div>
    );
};
