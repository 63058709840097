import { get, post } from "./utils.service";

export const getForCurrentDistributor = async () => {
    try {
        const res = await get(`/product-history/fordistributor/`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const addToHistory = async productId => {
    try {
        const res = await post(`/product-history/add/`, { productId });
        return await res.json();
    } catch (e) {
        return null;
    }
};