import moment from "moment";

export type YearMonth = {
    year: number;
    month: number;
};

export const MonthLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const formatDate = (date) => (date ? moment(date).format("DD/MM/YYYY") : "");

export const formatDatetime = (date, withSeconds?, fallback?) => {
    if (!date) {
        return fallback || "";
    }

    return withSeconds
        ? moment(date).format("DD/MM/YYYY HH:mm:ss")
        : moment(date).format("DD/MM/YYYY HH:mm");
};

export const formatNativeDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    return `${year}-${`00${month + 1}`.slice(-2)}-${`00${day}`.slice(-2)}`;
};

export const formatNativeDatetime = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${year}-${`00${month + 1}`.slice(-2)}-${`00${day}`.slice(-2)} ${`00${hours}`.slice(
        -2
    )}:${`00${minutes}`.slice(-2)}:${`00${seconds}`.slice(-2)}`;
};

export const formatTextYearMonth = (date) => (date ? moment(date).format("MMMM YYYY") : "");

export const formatTextDate = (date) => (date ? moment(date).format("MMMM D YYYY") : "");

export const currentYearMonth: () => YearMonth = () => {
    const date = new Date();

    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
    };
};

export const monthDiff = (date1: YearMonth, date2: YearMonth) => {
    return date2.year * 12 + date2.month - (date1.year * 12 + date1.month);
};

export const computeNextMonths = (startDate: YearMonth, monthCount: number) => {
    let date = { ...startDate };
    let months = [{ ...date }];
    for (let i = 0; i < monthCount - 1; i++) {
        date.month++;
        if (date.month > 12) {
            date.month = 1;
            date.year++;
        }
        months.push({ ...date });
    }
    return months;
};

export const computeNextMonthsFromNow = (monthCount: number) =>
    computeNextMonths(currentYearMonth(), monthCount);

export const formatYearMonth = (yearMonth: YearMonth) =>
    `${MonthLabels[yearMonth.month - 1]} ${yearMonth.year}`;
