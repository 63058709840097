import React, { useState } from "react";
import { useSelector } from "react-redux";
import DistributorSelectors from "../../selectors/distributor.selectors";
import ProductsSelectors from "../../selectors/products.selectors";
import { addOrderItems } from "../../services/orders.service";
import { requestLabel } from "../../services/products.service";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import OrderSlice from "../../store/slices/order.slice";
import { setError } from "../../utils/app.utils";
import { getFile } from "../../utils/s3.utils";
import Button from "../forms/Button";
import Select from "../forms/Select";
import Modal from "../Modal";
import "./labelModal.scss";

export default (props: {
    product: any;
    quantity: number | string;
    packaging?: number;
    onClose: any;
    orderToEdit?: number;
}) => {
    const { product, quantity, packaging, onClose } = props;

    const [label] = useState(product.registrations[0] ? product.registrations[0].label : null);
    const [countryId, setCountryId] = useState(
        product.registrations[0] ? product.registrations[0].country.id : null
    );

    const [showConfirmation, setShowConfirmation] = useState(false);

    const existingOrder = useSelector(DistributorSelectors.orderById(props.orderToEdit));

    const addToOrder = async () => {
        const result = await addOrderItems({
            orderId: existingOrder.id,
            orderItems: [
                {
                    productId: product.id,
                    countryId: countryId,
                    quantity: 1,
                    packaging: packaging,
                },
            ],
        });
        result.orderItems.forEach((orderItem) => {
            existingOrder.orderItems.some((x) => x.id === orderItem.id)
                ? store.dispatch(
                      DistributorSlice.actions.setOrderItem({
                          id: existingOrder.id,
                          orderItem: orderItem,
                      })
                  )
                : store.dispatch(
                      DistributorSlice.actions.addOrderItem({
                          id: existingOrder.id,
                          orderItem: {
                              ...orderItem,
                              registration: {
                                  ...orderItem.registration,
                                  product: product,
                              },
                          },
                      })
                  );
        });
    };

    const addToCart = () => {
        if (!countryId) {
            setError("Please select a country");
            return;
        }

        store.dispatch(
            OrderSlice.actions.addProductsToCart({
                products: [
                    {
                        productId: product.id,
                        countryId: countryId,
                        quantity,
                        packagingId: packaging || null,
                    },
                   
                ],
            })
        );
    };

    const sendRequest = async () => {
        if (!countryId) {
            setError("Please select a country");
            return;
        }

        const res = await requestLabel(product.id, countryId);
        existingOrder ? addToOrder() : addToCart();

        if (res.success) {
            setShowConfirmation(true);
        }
    };

    return (
        <Modal className="label-modal" onClose={onClose} closeOnBackgroundClick>
            {product.registrations.length >= 2 ? (
                <>
                    <div className="title">To which country do you want to ship this product?</div>
                    <Select
                        label="Select a destination country"
                        options={product.registrations.map((registration) => ({
                            value: registration.country.id,
                            label: registration.country.name,
                        }))}
                        value={countryId}
                        onChange={(x) => setCountryId(x)}
                        style={{
                            marginTop: 32,
                        }}
                    />
                </>
            ) : (
                <div className="title">This is the current label defined for this product:</div>
            )}
            <div className="label">
                {label ? (
                    <>
                        <div className="code">{label.code}</div>
                        <a
                            href={getFile(label.file)}
                            className="white"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            See the label (.pdf)
                        </a>
                    </>
                ) : (
                    <p>No label available</p>
                )}
            </div>
            {showConfirmation ? (
                <>
                    {existingOrder ? (
                        <div className="message">
                            Your product was successfully added to your order
                        </div>
                    ) : (
                        <div className="message">
                            Your product was successfully added to the cart
                        </div>
                    )}
                    <div className="controls">
                        <Button
                            color="white"
                            text="Continue"
                            onClick={onClose ? onClose : () => {}}
                            hasBorder
                        />
                        {existingOrder ? (
                            <Button
                                color="dark"
                                text="See my order"
                                to={"/profile/order/" + existingOrder.id + "/edit"}
                            />
                        ) : (
                            <Button color="dark" text="See my cart" to="/cart" />
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className="message">Is this correct?</div>
                    <div className="controls">
                        <Button
                            color="white"
                            text="Continue, but request a new label"
                            onClick={sendRequest}
                            hasBorder
                        />
                        <Button
                            color="dark"
                            text={existingOrder ? "Yes, add to my order" : "Yes, add to cart"}
                            onClick={() => {
                                existingOrder ? addToOrder() : addToCart();
                                setShowConfirmation(true);
                            }}
                        />
                    </div>
                </>
            )}
        </Modal>
    );
};
