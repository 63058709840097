import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import "./userForm.scss";
import Button from "../../components/forms/Button";
import BackLink from "../../components/BackLink";
import Select from "../../components/forms/Select";
import DistributorSlice from "../../store/slices/distributor.slice";
import TextInput from "../../components/forms/TextInput";
import DistributorSelectors from "../../selectors/distributor.selectors";
import { upsertUser } from "../../services/users.service";
import { store } from "../../store/configure.store";
import { validateEmail } from "../../utils/regex.util";

export default () => {
    const history = useHistory();

    const { id }: any = useParams();

    const user = useSelector(DistributorSelectors.userById(id));

    const [state, setState] = useState(user);

    const [errorMsg, setErrorMsg] = useState("");

    const set = (field, value) => setState((prev) => ({ ...prev, [field]: value }));

    const submit = async () => {
        setErrorMsg("");

        if (!state.firstname || !state.lastname || !state.job || !state.phone) {
            setErrorMsg("Missing mandatory fields");
            return;
        }
        if (!validateEmail(state.email)) {
            setErrorMsg("Email invalid");
            return;
        }

        const res = await upsertUser(state);
        if (res.success) {
            if (id) {
                store.dispatch(DistributorSlice.actions.setUser(state));
            } else {
                store.dispatch(DistributorSlice.actions.addUser(res.user));
            }
            history.push("/profile/users");
        } else {
            setErrorMsg("Email already used");
        }
    };

    return (
        <div id="user-form" className="profile">
            <BackLink to="/profile/users" label="Back" />
            <h1>{id ? "Edit" : "Add a new"} user</h1>
            <div className="form">
                <section>
                    <div className="inputs">
                        <Select
                            title="Select a status"
                            options={[
                                { value: "PROCUREMENT", label: "Procurement" },
                                { value: "MARKETING", label: "Marketing" },
                            ]}
                            value={state.role}
                            onChange={(x) => set("role", x)}
                            color="white"
                        />
                        <TextInput
                            label="Last name"
                            value={state.lastname}
                            onChange={(x) => set("lastname", x)}
                            required
                            color="dark"
                        />
                        <TextInput
                            label="First name"
                            value={state.firstname}
                            onChange={(x) => set("firstname", x)}
                            required
                            color="dark"
                        />
                        <TextInput
                            label="Position"
                            value={state.job}
                            onChange={(x) => set("job", x)}
                            required
                            color="dark"
                        />
                        <TextInput
                            label="Email address"
                            value={state.email}
                            onChange={(x) => set("email", x)}
                            required
                            color="dark"
                        />
                        <TextInput
                            label="Phone number"
                            value={state.phone}
                            onChange={(x) => set("phone", x)}
                            required
                            color="dark"
                        />
                        <TextInput
                            label="Whatsapp / Skype / Wechat"
                            value={state.phone2 || ""}
                            onChange={(x) => set("phone2", x)}
                            color="dark"
                        />
                    </div>
                </section>
                <div className="submit-block">
                    <Button color="white" text="Validate" onClick={submit} />
                    {errorMsg ? <div className="error">{errorMsg}</div> : null}
                </div>
                <div className="note">* Mandatory field</div>
            </div>
        </div>
    );
};
