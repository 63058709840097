import { del, post } from "./utils.service";

export const upsertAddress = async (data) => {
    const res = await post(`/addresses/upsert`, data);
    return await res.json();
};

export const deleteAddress = async (id) => {
    const res = await del(`/addresses/${id}`);
    return await res.json();
};
