import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filters: {
        documents: {
            type: null,
            year: null,
        },
        orders: {
            status: null,
            destination: null,
            productId: null,
        },
    },
};

const ProfileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setDocumentsFilter: (state, { payload }) => ({
            ...state,
            filters: {
                ...state.filters,
                documents: {
                    ...state.filters.documents,
                    [payload.key]: payload.value,
                },
            },
        }),
        setOrdersFilter: (state, { payload }) => ({
            ...state,
            filters: {
                ...state.filters,
                orders: {
                    ...state.filters.orders,
                    [payload.key]: payload.value,
                },
            },
        }),
        resetProfile: () => initialState,
    },
});

export default ProfileSlice;
