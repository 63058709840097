import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./resourceList.scss";
import Item from "../../components/resources/Item";
import { store } from "../../store/configure.store";
import { usePager } from "../../utils/layout.utils";
import Pager from "../../components/Pager";
import BackLink from "../../components/BackLink";
import DropdownInput from "../../components/forms/DropdownInput";
import { getVideosAndTutorials } from "../../services/resources.service";
import CaretLink from "../../components/CaretLink";
import ResourceSelectors from "../../selectors/resource.selectors";
import ResourceSlice from "../../store/slices/resource.slice";
import DropdownCheckbox from "../../components/forms/DropdownCheckbox";

export default () => {
    const allItems = useSelector(ResourceSelectors.getVideosAndTutorials());
    const [filteredItems, setFilteredItems] = useState(allItems || []);
    const [categories, setCategories] = useState([]);
    const [languages, setLanguages] = useState([]);

    const [categoryList, setCategoryList] = useState(null);
    const [languageList, setLanguageList] = useState(null);
    const [sortingOrder, setSortingOrder] = useState("mostRecent");

    const { page, setPage, pageCount, displayedItems } = usePager(filteredItems, 12);

    const applyFilters = (list) => {
        if (!list) {
            return [];
        }
        let newList = [...list];
        if (languages.length > 0) {
            newList = list.filter((x) => languages.includes(x.language?.id));
        }

        if (categories.length > 0) {
            newList = newList.filter((x) => categories.includes(x.resource.productCategory?.id));
        }

        switch (sortingOrder) {
            case "mostRecent":
                newList = newList.sort((a, b) => (a.creationDate > b.creationDate ? 1 : -1));
                break;
            case "lessRecent":
                newList = newList.sort((a, b) => (a.creationDate < b.creationDate ? 1 : -1));
                break;
        }

        return newList;
    };

    useEffect(() => {
        (async () => {
            const res = await getVideosAndTutorials();
            store.dispatch(ResourceSlice.actions.setResourcesVideosAndTutorials(res.data));
            setFilteredItems(res.data);
            const languages = res.data.reduce((acc, obj) => {
                const lang = obj.language;
                if (lang && !acc[lang.id]) {
                    acc[lang.id] = { value: lang.id, label: lang.name };
                }
                return acc;
            }, {});
            const categories = res.data.reduce((acc, obj) => {
                const cat = obj.resource.productCategory;
                if (cat && !acc[cat.id]) {
                    acc[cat.id] = { value: cat.id, label: cat.name };
                }
                return acc;
            }, {});
            setLanguageList(Object.values(languages));
            setCategoryList(Object.values(categories));
        })();
    }, []);

    useEffect(() => {
        const newItems = applyFilters(allItems);
        setFilteredItems(newItems);
    }, [categories, languages, sortingOrder]);

    return (
        <div className="profile resourceList">
            <BackLink to="/resources" label="Back to Resources" />
            <div className="title-wrapper">
                <h1>Videos & Tutorials</h1>
            </div>
            <div className="links">
                <CaretLink to="/resources/marketing-materials" label="marketing materials" />
                <CaretLink to="/resources/womancare-academy" label="Womancare academy" />
                <CaretLink to="/resources/pos-and-pop" label="POS & POP" />
                <CaretLink to="/resources/market-data" label="Studies and market data" />
                <CaretLink to="/resources/best-practices" label="Best practices" />
            </div>
            <div className="content">
                <div className="left-column">
                    <div className="sorter">
                        <p>Sort by :</p>
                        <DropdownInput
                            label=""
                            items={[
                                { value: "mostRecent", label: "Most recent" },
                                { value: "lessRecent", label: "Less recent" },
                            ]}
                            value={"mostRecent"}
                            onChange={(x) => setSortingOrder(String(x))}
                            color="primary"
                        />
                    </div>
                    <div className="filters">
                        <p>Filter by :</p>
                        <DropdownCheckbox
                            title="Category"
                            label=""
                            items={categoryList}
                            value={categories}
                            onChange={(x) => setCategories(x)}
                            color="primary"
                        />
                        <DropdownCheckbox
                            title="Language"
                            label=""
                            items={languageList}
                            value={languages}
                            onChange={(x) => setLanguages(x)}
                            color="primary"
                        />
                    </div>
                </div>
                <div className="list">
                    <div className="items">
                        {displayedItems.map((item, index) => (
                            <Item
                                key={index}
                                displayPlay
                                item={item}
                                onClickLink={`/resources/videos-and-tutorials/${item.id}`}
                            />
                        ))}
                    </div>
                    <Pager
                        pageCount={pageCount}
                        currentPage={page}
                        onSetPage={(x) => setPage(x)}
                        link="#"
                    />
                </div>
            </div>
        </div>
    );
};
