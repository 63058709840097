import { get } from "./utils.service";

export const getAllLoyaltyStatus = async () => {
    try {
        const res = await get(`/loyalty-status`);
        return await res.json();
    } catch (e) {
        return null;
    }
}

export const getCurrentLoyaltyStatus = async () => {
    try {
        const res = await get(`/loyalty-status/current`);
        return await res.json();
    } catch (e) {
        return null;
    }
}