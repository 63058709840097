import { createSelector } from "reselect";

import { atomize } from "./utils.selectors";
import { formatDate } from "../utils/dates.utils";
import { getFile } from "../utils/s3.utils";

const Names = {
    CERTIFICATE_OF_INCORPORATION: "Certificate of incorporation",
    MEMORANDUM: "Memorandum and articles of association",
    REGISTER_OF_DIRECTORS: "Register of directors",
    REGISTER_OF_MEMBERS: "Register of members",
    WHOLESALER_LICENSE: "Wholesaler license",
    CE_CERTIFICATE: "CE certificate",
    FREE_SALE_CERTIFICATE: "Free sale certificate",
    ISO_CERTIFICATION: "ISO certification",
    OTHER: "Other",
    BUSINESS_LICENSE_FREE_SALE_CERTIFICATE: "Business license free sale certificate",
    BUSINESS_LICENSE: "Business license",
    REGISTRATION_CERTIFICATION: "Registration certification"
};

const Types = {
    CE_CERTIFICATE: "Certifications",
    FREE_SALE_CERTIFICATE: "Certifications",
    ISO_CERTIFICATION: "Certifications",
    CERTIFICATE_OF_INCORPORATION: "Due diligence",
    MEMORANDUM: "Due diligence",
    REGISTER_OF_DIRECTORS: "Due diligence",
    REGISTER_OF_MEMBERS: "Due diligence",
    WHOLESALER_LICENSE: "Due diligence",
    DUE_DILIGENCE: "Due diligence",
    OTHER: "Due diligence",
    BUSINESS_LICENSE_FREE_SALE_CERTIFICATE: "Business license",
    BUSINESS_LICENSE: "Business license",
    REGISTRATION_CERTIFICATION: "Registration certification"
};

const getDocuments = state => state.distributor.documents;
const getFilters = state => state.profile.filters.documents;

const DocumentsSelectors = {
    documentsData: createSelector(getDocuments, getFilters, (documents, filters) => {
        const formattedDocuments = documents.map(doc => ({
            ...doc,
            uploadDate: formatDate(doc.uploadDate),
            year: doc.uploadDate.substring(0, 4),
            download: getFile(doc.file),
            name: doc.name || Names[doc.type],
            validityDate: formatDate(doc.validityDate),
            type: Types[doc.type] || "",
        }));

        return {
            documents: formattedDocuments.filter(
                doc =>
                    (!filters.type || doc.type === filters.type) &&
                    (!filters.year || doc.year === filters.year)
            ),
            types: ["Certifications", "Due diligence", "Business license"],
            years: atomize(formattedDocuments.map(doc => doc.year)),
        };
    }),
};

export default DocumentsSelectors;
