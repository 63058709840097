import React from "react";
import { useSelector } from "react-redux";
import BackLink from "../../components/BackLink";
import Button from "../../components/forms/Button";
import Check from "../../components/icons/Check";
import Pager from "../../components/Pager";
import NotificationsSelectors from "../../selectors/notifications.selectors";
import * as NotificationsService from "../../services/notifications.service";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import { usePager } from "../../utils/layout.utils";
import "./notifications.scss";

const Cols = ["Date", "Subject", "Reference n°", ""];
// notif todo :  "You've received the shipping documents of your order for approval."
export default () => {
    const notifications = useSelector((state) => NotificationsSelectors.all(state));

    const {
        page,
        setPage,
        pageCount,
        displayedItems: displayedNotifications,
    } = usePager(notifications, 5);

    const setRead = (notificationId) => {
        NotificationsService.setRead([notificationId]);

        store.dispatch(
            DistributorSlice.actions.setNotificationsRead({
                notificationsIds: [notificationId],
            })
        );
    };

    return (
        <div id="notifications" className="profile">
            <BackLink to="/home" label="Back" />
            <h1>Notifications</h1>
            <table>
                <thead>
                    <tr>
                        {Cols.map((col, index) => (
                            <th key={index}>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {displayedNotifications.map((notification) => (
                        <tr
                            key={notification.id}
                            className={!notification.read ? "unread" : ""}
                            onClick={
                                !notification.read ? () => setRead(notification.id) : undefined
                            }
                        >
                            <td data-label={Cols[0]}>{notification.date}</td>
                            <td data-label={Cols[1]}>{notification.title}</td>
                            <td data-label={Cols[2]}>{notification.content}</td>
                            <td
                                data-label={Cols[3]}
                                className={notification.read ? "no-action" : ""}
                            >
                                <div className="actions">
                                    {!notification.read ? (
                                        <>
                                            {notification.link ? (
                                                <Button
                                                    to={notification.link}
                                                    text="View"
                                                    color="primary"
                                                    hasBorder
                                                />
                                            ) : null}
                                            <Check color="white" />
                                        </>
                                    ) : null}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pager
                pageCount={pageCount}
                currentPage={page}
                onSetPage={(x) => setPage(x)}
                link="#notifications"
            />
        </div>
    );
};
