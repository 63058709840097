import React, { useEffect, useState } from "react";
import moment from "moment";

import { TableItem } from "./TableItem";

interface CalenderTableProps {
    year: string;
    month: string;
    events?: any;
}

export const CalenderTable: React.FC<CalenderTableProps> = (props) => {
    const [weeks, setWeeks] = useState([]);

    useEffect(() => {
        let startDay = moment(`${props.year}-${props.month}-01`, "YYYY-MM-DD").day();
        const daysCount = moment(`${props.year}-${props.month}`, "YYYY-MM").daysInMonth();
        const days = [];
        while (startDay-- > 0) {
            days.push("");
        }
        for (let i = 1; i < daysCount + 1; i++) {
            days.push(i.toString());
        }
        setWeeks(divideArray(days, 7));
    }, [props.year, props.month]);

    useEffect(() => {
        const days = [];
        for (let i = 0; i < props.events?.length; i++) {
            days.push(moment(props.events[i]?.date).format("D"));
        }
    }, [props.events]);

    const divideArray = (arr: string[], chunkSize: number) => {
        return arr.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize);

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [];
            }

            resultArray[chunkIndex].push(item);

            return resultArray;
        }, []);
    };

    const isEvent = (num: string) => {
        for (let i = 0; i < props.events?.length; i++) {
            if (moment(props.events[i]?.date).format("D") == num) return true;
        }
        return false;
    };

    const getEvents = (num: string) => {
        const events = [];
        for (let i = 0; i < props.events?.length; i++) {
            if (moment(props.events[i]?.date).format("D") == num) events.push(props.events[i]);
        }
        return events;
    };

    return (
        <table cellPadding="10">
            <thead>
                <tr>
                    <th>Su</th>
                    <th>Mo</th>
                    <th>Tu</th>
                    <th>We</th>
                    <th>Th</th>
                    <th>Fr</th>
                    <th>Sa</th>
                </tr>
            </thead>
            <tbody>
                {weeks.map((week, weekIndex) => {
                    return (
                        <tr key={`${props.year}-${props.month}-${weekIndex}`}>
                            {week.map((day: string, dayIndex: number) => {
                                return (
                                    <th
                                        key={`${props.year}-${props.month}-${weekIndex}-${dayIndex}-${day}`}
                                    >
                                        <TableItem
                                            visibleBadge={isEvent(day)}
                                            events={getEvents(day)}
                                        >
                                            {day}
                                        </TableItem>
                                    </th>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
