import React, { useMemo } from "react";
import { formatNumber, formatPrice } from "../../utils/numeric.utils";
import { CareCoinValue } from "../../utils/orders.utils";
import Button from "../forms/Button";
import NumberInput from "../forms/NumberInput";
import Modal from "../Modal";
import "./careCoinsModal.scss";

export default ({
    visible,
    onValidate,
    onClose,
    orderTotal,
    nonCareCoinsDiscountedOrderTotal,
    availableCareCoins,
    careCoinsToUse,
    careCoinsDiscount,
}: {
    visible: boolean;
    onValidate: (value: number) => void;
    onClose: () => void;
    orderTotal: number;
    nonCareCoinsDiscountedOrderTotal: number;
    availableCareCoins: number;
    careCoinsToUse: number;
    careCoinsDiscount: number;
}) => {
    const maxCareCoinsToUse = useMemo(
        () =>
            Math.min(
                availableCareCoins,
                Math.floor(nonCareCoinsDiscountedOrderTotal * (1 / CareCoinValue))
            ),
        [availableCareCoins, nonCareCoinsDiscountedOrderTotal]
    );

    const handleValidate = () => {
        onClose();
    };

    const handleCancel = () => {
        onValidate(0);
        onClose();
    };

    return visible ? (
        <Modal className="carecoins-modal" onClose={onClose} closeOnBackgroundClick>
            <div className="title">How many CareCoins do you want to use for this order ?</div>
            <div className="available">
                Available CareCoins :{" "}
                <span className="value">{formatNumber(availableCareCoins, true)}</span>
            </div>
            <NumberInput
                min={0}
                max={maxCareCoinsToUse}
                value={careCoinsToUse}
                onChange={(x) => onValidate(x)}
                color="primary"
            />
            <div className="dollar-value">= {formatPrice(careCoinsDiscount)}</div>
            <div className="new-total">
                New total (excl. VAT) : <span className="value">{formatPrice(orderTotal)}</span>
            </div>
            <div className="buttons">
                <Button
                    text="Don't use CareCoins"
                    onClick={handleCancel}
                    color="transparent"
                    hasBorder
                />
                <Button
                    text="Validate"
                    onClick={handleValidate}
                    color="primary"
                    disabled={!careCoinsToUse || careCoinsToUse > maxCareCoinsToUse}
                />
            </div>
        </Modal>
    ) : null;
};
