import React from "react";
import QualityAssurance from "../../components/QualityAssurance";
import Wave from "../../components/style/Wave";
import "./landing.scss";

export default () => {
    return (
        <div id="landing">
            <div className="header">
                <div className="background"></div>
                <Wave color="white" />
            </div>
            <QualityAssurance />
        </div>
    );
};
