import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BackLink from "../../components/BackLink";
import Button from "../../components/forms/Button";
import TextInput from "../../components/forms/TextInput";
import FileInput from "../../components/forms/FileInput";
import IconLink from "../../components/IconLink";
import Download from "../../components/icons/Download";
import RoundRating from "../../components/RoundRating";
import StatusLink from "../../components/StatusLink";
import { useBonusTrocarsManagement } from "../../hooks/useBonusTrocarsManagement";
import DistributorSelectors from "../../selectors/distributor.selectors";
import { confirmOrReject, setOrder, uploadDocument } from "../../services/orders.service";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import OrderSlice from "../../store/slices/order.slice";
import { formatNumber, formatPrice } from "../../utils/numeric.utils";
import { getFile } from "../../utils/s3.utils";
import "./orderDetails.scss";
import Modal from "../../components/Modal";

export default () => {
    const history = useHistory();
    const { orderId }: any = useParams();
    const [openModal, setOpenModal] = useState(false);
    const [reason, setReason] = useState("");
    const order = useSelector(DistributorSelectors.orderById(orderId));

    useBonusTrocarsManagement();

    const productColumns = ["Product(s)", "", "Per unite price", "Quantity", "Total"];
    const editLink = "/profile/order/" + order.id + "/edit/";

    const [showUpload, setShowUpload] = useState(
        !order.orderDocuments.some((x) => x.type === order.statusData.docType)
    );

    const statusWithUpload = order.statusData.docType ? true : false;
    const statusWithChoice = order.statusData.choice ? true : false;

    const handleConfirmOrReject = async (status) => {
        const res = await confirmOrReject({
            orderId: order.id,
            status: status,
        });
        if (res.updatedFields) {
            const { orderId, fields }: any = res.updatedFields;
            store.dispatch(
                DistributorSlice.actions.setOrderAttribute({ id: orderId, fields: fields })
            );
        }
    };

    const redoOrder = () => {
        const products = order.products.map((product) => {
            return {
                productId: product.id,
                countryId: product.country.id,
                quantity: product.quantity,
                packagingId: product.packaging.id || null,
            };
        });

        store.dispatch(
            OrderSlice.actions.addProductsToCart({
                products: products,
            })
        );
        history.push("/cart");
    };

    const upladoDoc = async (files) => {
        setShowUpload(false);
        const newFile = {
            ...files[0],
            orderId: order.id,
            type:
                order.status === "WAITING_FOR_PREPAYMENT"
                    ? "PROOF_OF_PREPAYMENT"
                    : order.status === "IMPORT_LICENSE_DOCUMENT_REQUIRED"
                    ? "LICENSE_DOCUMENT"
                    : order.status === "WAITING_FOR_PREINSPECTION"
                    ? "PREINSPECTION"
                    : null,
        };
        const res = await uploadDocument(newFile);
        store.dispatch(DistributorSlice.actions.addOrderDocument({ id: order.id, file: res.file }));
    };

    const handleDocuments = async (status: string) => {
        setOrder({
            orderId: order.id,
            fields: {
                documentStatus: status,
                reason: reason,
            },
        });
        store.dispatch(
            DistributorSlice.actions.setOrderAttribute({
                id: orderId,
                fields: {
                    documentStatus: status,
                },
            })
        );
        setOpenModal(false);
    };

    return (
        <div id="orderDetails" className="profile">
            <div id="order">
                <BackLink label="Back to my orders" to="/profile/orders" />
                <div className="section">
                    <h1>
                        Order {order.num}
                        {order.statusData.editable ? (
                            <IconLink icon="edit" label="edit" color="secondary" to={editLink} />
                        ) : null}
                    </h1>
                    <div id="details">
                        <p>Order date : {order.date}</p>
                        <p>Shipping address : {order.shippingAddressFormatted}</p>
                        <p>Shipping method : {order.shippingMethod}</p>
                        <p>Billing address : {order.billingAddressFormatted}</p>
                        <p>Contact name : {order.contact || "N.C"}</p>
                        <p>Certificates: {order.certificates}</p>
                    </div>
                </div>
                <div id="status" className="section">
                    <h2 className="title">Status</h2>
                    <div id="statusTable">
                        <div>
                            {["SHIPPED", "DELIVERED"].includes(order.status) && order.deliveryDate
                                ? `${order.statusData.statusLabel} on ${order.deliveryDate}`
                                : order.statusData.statusLabel}
                        </div>
                        <div>
                            <RoundRating rating={order.statusData.percentage / 100} />
                        </div>
                        {order.statusData.hasStatusLink ? (
                            <div>
                                <StatusLink
                                    icon={order.statusData.icon}
                                    label={order.statusData.linkText}
                                    color="secondary"
                                />
                            </div>
                        ) : null}
                        {statusWithUpload ? (
                            <div>
                                {showUpload ? (
                                    <FileInput
                                        onChange={(x) => upladoDoc(x)}
                                        uploadToS3
                                        placeholder={"Upload document"}
                                        color="dark"
                                        buttonHasBorder
                                    />
                                ) : (
                                    <p>Document already submitted. Waiting for approval.</p>
                                )}
                            </div>
                        ) : null}
                        {statusWithChoice ? (
                            <div className="buttons-container">
                                <Button
                                    text="Approuver les documents"
                                    onClick={() => handleDocuments("APPROVED")}
                                    color="primary"
                                />
                                <Button
                                    text="Rejeter les documents"
                                    onClick={() => setOpenModal(true)}
                                    color="transparent"
                                    hasBorder
                                />
                            </div>
                        ) : null}
                        {openModal && (
                            <Modal
                                closeOnBackgroundClick
                                className="rejection-modal"
                                hasCloseIcon
                                onClose={() => setOpenModal(false)}
                            >
                                <TextInput
                                    label="Précisez la raison du rejet des documents"
                                    value={reason}
                                    onChange={(x) => setReason(x)}
                                    style={{
                                        width: "100%",
                                    }}
                                    // required
                                    // color="dark"
                                />
                                <Button
                                    text="Valider"
                                    onClick={() => handleDocuments("REJECTED")}
                                    color="primary"
                                />
                            </Modal>
                        )}
                    </div>
                </div>
                <div id="products" className="section">
                    <h2 className="title">Details</h2>
                    <table id="productsTable">
                        <thead>
                            <tr>
                                {productColumns.map((column, index) => (
                                    <th key={index}>{column}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {order.products.map((product) => (
                                <Fragment key={product.id}>
                                    <tr className="product-block">
                                        <td data-label={productColumns[0]}>
                                            <div className="image-wrapper">
                                                <img
                                                    className="image"
                                                    src={getFile(product.image)}
                                                    alt="Product"
                                                />
                                            </div>
                                        </td>
                                        <td data-label={productColumns[1]}>
                                            <div className="name">{product.name}</div>
                                            <div className="reference">{product.reference}</div>
                                        </td>
                                        <td data-label={productColumns[2]}>
                                            {formatPrice(product.effectivePrice)}
                                        </td>
                                        <td data-label={productColumns[3]}>{product.quantity}</td>
                                        <td data-label={productColumns[4]}>
                                            {formatPrice(product.totalEffectivePrice)}
                                        </td>
                                    </tr>
                                </Fragment>
                            ))}
                            {order.orderExtraItems.map((orderExtraItem) => (
                                <tr key={`extra-${orderExtraItem.id}`} className="product-block">
                                    <td data-label={productColumns[0]}>
                                        <div className="image-wrapper">
                                            <img
                                                className="image"
                                                src={getFile(orderExtraItem.product.image)}
                                                alt="Product"
                                            />
                                        </div>
                                    </td>
                                    <td data-label={productColumns[1]}>
                                        <div className="name">{orderExtraItem.product.name}</div>
                                        <div className="reference">
                                            {orderExtraItem.product.reference}
                                        </div>
                                    </td>
                                    <td data-label={productColumns[2]}>$0.00</td>
                                    <td data-label={productColumns[3]}>
                                        {orderExtraItem.quantity}
                                    </td>
                                    <td data-label={productColumns[4]}>$0.00</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div id="bottomDetail" className="section">
                    <table id="totalTable">
                        <tbody>
                            <tr className="sub-total">
                                <td>Sub-total (excl. VAT)</td>
                                <td>{formatPrice(order.subtotal)}</td>
                            </tr>
                            <tr className="sub-total">
                                <td>Shipping cost</td>
                                <td>{formatPrice(order.shippingPrice)}</td>
                            </tr>

                            {order.careCoinsUsed ? (
                                <>
                                    <tr className="sub-total carecoins">
                                        <td>CareCoins used</td>
                                        <td>{formatNumber(order.careCoinsUsed, true)}</td>
                                    </tr>
                                    <tr className="sub-total">
                                        <td>CareCoins discount</td>
                                        <td>- {formatPrice(order.careCoinsUsedTotalValue)}</td>
                                    </tr>
                                </>
                            ) : null}
                            <tr id="total">
                                <td>Total (excl. VAT)</td>
                                <td>{formatPrice(order.total)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {order.statusData.redoOrder || order.statusData.toConfirm ? (
                    <div id="buttons">
                        {order.statusData.redoOrder ? (
                            <Button
                                text="Place this order again"
                                color="white"
                                hasBorder
                                onClick={() => redoOrder()}
                            />
                        ) : null}
                        {order.statusData.toConfirm ? (
                            <div className="buttons-container">
                                <Button
                                    text="Reject this order"
                                    color="transparent"
                                    hasBorder
                                    onClick={() => handleConfirmOrReject("CANCELLED")}
                                />
                                <Button
                                    text="Confirm this order"
                                    color="primary"
                                    onClick={() => handleConfirmOrReject("CONFIRMED")}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
            <div className="download-rectangle">
                <div id="downloadContent">
                    <h2 id="downloadTitle">Documents</h2>
                    <table>
                        <tbody>
                            {order.downloads.map((doc, index) => (
                                <tr key={index} className="download-rows">
                                    <td>{doc.name}</td>
                                    <td>
                                        <Download url={doc.url} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
