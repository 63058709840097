import React from "react";
import { getFile } from "../../utils/s3.utils";
import Button from "../forms/Button";
import Play from "../icons/Play";
import "./item.scss";
import Language from "./Language";

export default (props: {
    item: any;
    onClickLink: string;
    newTab?: boolean;
    displayPlay?: boolean;
}) => {
    return (
        <a href={props.onClickLink} id="resourceItem" target={props.newTab ? "blank" : ""}>
            <div className="itemContent">
                {props.item.thumbnail ? (
                    <div className="image-wrapper">
                        <img className="image" src={getFile(props.item.thumbnail)} alt="Resource" />
                        {props.displayPlay && (
                            <div className="play-wrapper">
                                <Play color="white" />
                            </div>
                        )}
                        {props.item.language ? (
                            <div className="language-wrapper">
                                <Language
                                    language={props.item.language.abbreviation}
                                    color="white"
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}
                <div className="name">{props.item.title}</div>
                <div className="excerpt">{props.item.excerpt}</div>
            </div>
            <Button text="See more" color="primary" inactive />
        </a>
    );
};
