import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router";
import queryString from "query-string";

import Button from "../../components/forms/Button";
import TextInput from "../../components/forms/TextInput";
import PasswordInput from "../../components/forms/PasswordInput";
import { validateEmail } from "../../utils/regex.util";
import { forgottenPassword, signin } from "../../services/auth.service";
import { login, useLogin } from "../../utils/auth.utils";
import { Link } from "react-router-dom";

import "./login.scss";
import Wave from "../../components/style/Wave";
import CheckInput from "../../components/forms/CheckInput";
import { setSuccess } from "../../utils/app.utils";

export default () => {
    const history = useHistory();
    const location = useLocation();

    const { isLogin } = useLogin();
    const { isNew } = queryString.parse(location.search);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isRememberMe, setRememberMe] = useState(false);
    const [msg, setMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isNew) {
            setSuccess("Password successfully set");
        }
    }, [isNew]);

    const submit = async () => {
        if (!validateEmail(email)) {
            setErrorMsg("Credentials incorrect");
            return;
        }

        setLoading(true);
        const resSignin = await signin(email, password);
        setLoading(false);

        if (resSignin.jwt) {
            login(resSignin.jwt);
            history.push("/home");
        } else {
            setErrorMsg("Credentials incorrect");
            return;
        }
    };

    const resetPassword = async () => {
        if (!validateEmail(email)) {
            setErrorMsg("Please enter a valid email");
            return;
        }

        setLoading(true);
        const res = await forgottenPassword(email);
        setLoading(false);

        if (res.success) {
            setMsg(`An email have been sent at ${email} to reset your password`);
        } else {
            setErrorMsg("Credentials incorrect");
            return;
        }
    };

    return isLogin ? (
        <Redirect to="/" />
    ) : (
        <div id="login">
            <div className="header">
                <div className="content">
                    <img src="/assets/icons/key.svg" alt="Key" />
                    <h1>Log in to my account</h1>
                    <div className="signup">
                        Do not have an account yet?{" "}
                        <Link to="/signup" className="white">
                            Sign in
                        </Link>
                    </div>
                </div>
                <div className="background"></div>
                <Wave color="light" />
            </div>

            <div className="form">
                <div className="inputs">
                    <TextInput
                        label="Email address"
                        placeholder="Type your email address"
                        value={email}
                        onChange={text => setEmail(text)}
                    />
                    <PasswordInput
                        label="Password"
                        placeholder="Type your password"
                        value={password}
                        onChange={text => setPassword(text)}
                        errorMsg={errorMsg}
                    />
                </div>

                <Link to="#" onClick={resetPassword} className="primary reset-password">
                    Forgot your password?
                </Link>

                <div className="submit-block">
                    {msg ? <div className="message">{msg}</div> : null}
                    <CheckInput
                        label="Remember me"
                        value={isRememberMe}
                        onChange={x => setRememberMe(x)}
                    />
                    <Button loading={loading} text="Log in" color="dark" onClick={submit} />
                </div>
            </div>
        </div>
    );
};
