import React, { useState } from "react";
import { subscribeToNewsletter } from "../services/newsletter.service";
import { validateEmail } from "./../utils/regex.util";
import Button from "./forms/Button";
import TextInput from "./forms/TextInput";
import "./newsletterSubscribeCard.scss";

const NewsletterSubscribeCard = () => {
    const [email, setEmail] = useState<string>("");
    const [message, setMessage] = useState<{ type: "error" | "success"; text: string }>(null);

    const _submitForm = () => {
        if (validateEmail(email)) {
            subscribeToNewsletter(email).then(async (res) => {
                const response = await res.json();
                if (response.success) setMessage({ type: "success", text: response.success });
                else if (response.error) setMessage({ type: "error", text: response.error });
            });
        } else {
            setMessage({ type: "error", text: "Invalid email." });
        }
    };

    return (
        <div className="newsletter-subscribe-card">
            <div className="newsletter-email-input-wrapper">
                <TextInput
                    email
                    label="Email address"
                    placeholder="Type here"
                    value={email}
                    onChange={setEmail}
                    errorMsg={message?.type === "error" ? message.text : null}
                    successMsg={message?.type === "success" ? message.text : null}
                />
            </div>

            <div className="button-subscribe-wrapper">
                <Button text="Subscribe" onClick={_submitForm} />
            </div>
        </div>
    );
};

export default NewsletterSubscribeCard;
