import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CartBonus = {
    sectionId: string;
    productId: number;
    quantity: number;
    isTrocar?: boolean;
};

export enum TransportMode {
    AIRPORT = "Airport",
    ROAD = "Road",
    HARBOR = "Harbor",
}

type OrderState = {
    countryId: number;
    productSubCategoryId: number;
    shippingAddress: any;
    billingAddress: any;
    cart: any[];
    cartBonus: CartBonus[];
    contact: {
        name: string;
        phone: string;
    };
    shipping: {
        method: string;
        transportMode: TransportMode;
        destination: string;
    };
    certificates: string;
    promoCode: string;
    specialRequest: string;
    careCoinsToUse: number;
};

const initialState: OrderState = {
    countryId: null,
    productSubCategoryId: null,
    shippingAddress: {},
    billingAddress: {},
    cart: [],
    cartBonus: [],
    contact: {
        name: "",
        phone: "",
    },
    shipping: {
        method: null,
        transportMode: null,
        destination: null,
    },
    certificates: "",
    promoCode: null,
    specialRequest: null,
    careCoinsToUse: 0,
};

const OrderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        addProductToCart: (state, { payload }) => ({
            ...state,
            cart: state.cart.some(
                (cartItem) =>
                    cartItem.countryId === payload.countryId &&
                    cartItem.productId === payload.productId
            )
                ? state.cart
                : [...state.cart, payload],
        }),
        addProductsToCart: (state, { payload }) => ({
            ...state,
            cart: [
                ...state.cart,
                ...payload.products.filter(
                    (product) =>
                        !state.cart.some(
                            (cartItem) =>
                                cartItem.countryId === product.countryId &&
                                cartItem.productId === product.productId
                        )
                ),
            ],
        }),
        removeProductFromCart: (state, { payload }) => ({
            ...state,
            cart: state.cart.filter(
                (cartItem) =>
                    !(
                        cartItem.countryId === payload.countryId &&
                        cartItem.productId === payload.productId
                    )
            ),
        }),
        removeProductsFromCart: (state, { payload }) => ({
            ...state,
            cart: state.cart.filter(
                (cartItem) =>
                    !payload.some(
                        (product) =>
                            cartItem.countryId === product.countryId &&
                            cartItem.productId === product.productId
                    )
            ),
        }),
        setProductQuantityInCart: (state, { payload }) => ({
            ...state,
            cart: state.cart.map((cartItem) =>
                cartItem.countryId === payload.countryId && cartItem.productId === payload.productId
                    ? {
                          ...cartItem,
                          quantity: payload.quantity,
                      }
                    : cartItem
            ),
        }),
        setCartBonusProduct: (state, { payload }: PayloadAction<CartBonus>) => {
            if (payload.quantity === 0) {
                return {
                    ...state,
                    cartBonus: state.cartBonus.filter(
                        (cartBonusProduct) =>
                            !(
                                cartBonusProduct.sectionId === payload.sectionId &&
                                cartBonusProduct.productId === payload.productId
                            )
                    ),
                };
            }

            return {
                ...state,
                cartBonus: state.cartBonus.some(
                    (cartBonusProduct) =>
                        cartBonusProduct.sectionId === payload.sectionId &&
                        cartBonusProduct.productId === payload.productId
                )
                    ? state.cartBonus.map((cartBonusProduct) =>
                          cartBonusProduct.sectionId === payload.sectionId &&
                          cartBonusProduct.productId === payload.productId
                              ? { ...cartBonusProduct, quantity: payload.quantity }
                              : cartBonusProduct
                      )
                    : [...state.cartBonus, payload],
            };
        },
        resetCartBonus: (state, { payload }: PayloadAction<Pick<CartBonus, "sectionId">>) => ({
            ...state,
            cartBonus: state.cartBonus.filter(
                (cartBonusProduct) =>
                    cartBonusProduct.isTrocar || cartBonusProduct.sectionId !== payload.sectionId
            ),
        }),
        setCountryAndProductSubCategory: (state, { payload }) => ({
            ...state,
            countryId: payload.countryId,
            productSubCategoryId: payload.productSubCategoryId,
        }),
        setShippingAddress: (state, { payload }) => ({
            ...state,
            shippingAddress: payload,
        }),
        setBillingAddress: (state, { payload }) => ({
            ...state,
            billingAddress: payload,
        }),
        setContact: (state, { payload }) => ({
            ...state,
            contact: payload,
        }),
        setShipping: (state, { payload }) => ({
            ...state,
            shipping: payload,
        }),
        setCertificates: (state, { payload }) => ({
            ...state,
            certificates: payload,
        }),
        setPromoCode: (state, { payload }) => ({
            ...state,
            promoCode: payload,
            cartBonus: initialState.cartBonus,
        }),
        setSpecialRequest: (state, { payload }) => ({
            ...state,
            specialRequest: payload,
        }),
        setCareCoinsToUse: (state, { payload }) => ({
            ...state,
            careCoinsToUse: payload,
        }),
        resetOrderData: (state) => ({
            ...state,
            shippingAddress: initialState.shippingAddress,
            billingAddress: initialState.billingAddress,
            certificates: initialState.certificates,
            promoCode: initialState.promoCode,
            specialRequest: initialState.specialRequest,
            careCoinsToUse: initialState.careCoinsToUse,
        }),
        reset: () => initialState,
    },
});

export default OrderSlice;
