import React, { useState } from "react";
import "./dropdownSection.scss";
import Caret from "./icons/Caret";

type DropdownSectionProps = {
    title: string;
    contentComponent: any;
    color?: string;
    openByDefault?: boolean;
};

export default (props: DropdownSectionProps) => {
    const [showContent, setShowContent] = useState(props.openByDefault || false);

    return (
        <section className="dropdown-section">
            <div className="title" onClick={() => setShowContent((prev) => !prev)}>
                {props.title}
                <Caret type="down" color="white" size={12} />
            </div>
            {showContent && <props.contentComponent />}
        </section>
    );
};
