import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import ProductsSelectors from "../selectors/products.selectors";
import { getByMBDreferenceCode } from "../services/products.service";
import { store } from "../store/configure.store";
import OrderSlice from "../store/slices/order.slice";
import { useAtomicQuery } from "../utils/query.utils";

export const useBonusTrocarsManagement = () => {
    const [levoplant1] = useAtomicQuery(() => getByMBDreferenceCode("LEVOSU"));
    const [levoplant10] = useAtomicQuery(() => getByMBDreferenceCode("LEVO"));
    const [trocar] = useAtomicQuery(() => getByMBDreferenceCode("050556002"));

    const cartData = useSelector((state: any) => ProductsSelectors.cart(state));

    const productsLoaded = useMemo(() => {
        return levoplant1 && levoplant10 && trocar;
    }, [levoplant1, levoplant10, trocar]);

    useEffect(() => {
        if (!productsLoaded) {
            return;
        }

        for (const section of cartData.sections) {
            let trocarQuantity = 0;
            for (const product of section.products) {
                if (product.id === levoplant1.id) {
                    trocarQuantity += product.quantity;
                } else if (product.id === levoplant10.id) {
                    trocarQuantity += 10 * product.quantity;
                }
            }

            store.dispatch(
                OrderSlice.actions.setCartBonusProduct({
                    sectionId: section.sectionId,
                    productId: trocar.id,
                    quantity: trocarQuantity,
                })
            );
        }
    }, [cartData, productsLoaded]);
};
