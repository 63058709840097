import React from "react";
import { useSelector } from "react-redux";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import OrderSlice from "../../store/slices/order.slice";
import Button from "../forms/Button";
import Modal from "../Modal";
import "./addressModal.scss";

export default (props: {
    addressType: "BILLING" | "SHIPPING" | "CONTACT";
    onClose: () => void;
    orderToEdit?: number;
}) => {
    const addresses = useSelector(
        (state: any) =>
            state.distributor[
                { BILLING: "billingAddresses", SHIPPING: "shippingAddresses" }[props.addressType]
            ]
    );

    const setAddress = (id) => {
        const address = addresses.find((x) => x.id === id);

        if (props.addressType === "SHIPPING") {
            if (props.orderToEdit) {
                store.dispatch(
                    DistributorSlice.actions.setOrderAttribute({
                        id: props.orderToEdit,
                        fields: { shippingAddress: address },
                    })
                );
            } else {
                store.dispatch(OrderSlice.actions.setShippingAddress(address));
            }
        }

        if (props.addressType === "BILLING") {
            if (props.orderToEdit) {
                store.dispatch(
                    DistributorSlice.actions.setOrderAttribute({
                        id: props.orderToEdit,
                        fields: { billingAddress: address },
                    })
                );
            } else {
                store.dispatch(OrderSlice.actions.setBillingAddress(address));
            }
        }

        props.onClose();
    };

    return (
        <Modal className="address-modal" onClose={props.onClose} closeOnBackgroundClick>
            {addresses.map((address) => (
                <div key={address.id} className="address">
                    <div className="data">
                        <div className="name">{address.name}</div>
                        <div className="detail">
                            {address.address}, {address.zipCode} {address.city}
                        </div>
                    </div>
                    <Button text="Choose this address" onClick={() => setAddress(address.id)} />
                </div>
            ))}
            <div className="controls">
                <Button
                    text="Add an address"
                    to={
                        props.orderToEdit
                            ? {
                                  SHIPPING:
                                      "/profile/shipping-addresses?orderToEdit=" +
                                      props.orderToEdit,
                                  BILLING:
                                      "/profile/billing-addresses?orderToEdit=" + props.orderToEdit,
                              }[props.addressType] || "#"
                            : {
                                  SHIPPING: "/profile/shipping-addresses?fromOrder=true",
                                  BILLING: "/profile/billing-addresses?fromOrder=true",
                              }[props.addressType] || "#"
                    }
                />
                <Button text="Cancel" onClick={props.onClose} color="white" hasBorder />
            </div>
        </Modal>
    );
};
