import React, { useEffect, useState } from "react";
import BackLink from "../../components/BackLink";
import CaretLink from "../../components/CaretLink";
import Pager from "../../components/Pager";
import { FAQURL } from "../../constants";
import { getMyTickets } from "../../services/zendesk.service";
import { formatDate } from "../../utils/dates.utils";
import { usePager } from "../../utils/layout.utils";
import "./customerService.scss";

const Cols = ["Date", "Department", "Subject", "Comment"];

export default () => {
    const [tickets, setTickets] = useState([]);

    const { page, setPage, pageCount, displayedItems: displayedTickets } = usePager(tickets, 5);

    useEffect(() => {
        (async () => {
            const res = await getMyTickets();
            setTickets(res.data.sort((a, b) => (a.created_at < b.created_at ? 1 : -1)));
        })();
    }, []);

    return (
        <div id="tickets">
            <BackLink to="/my-womancare" label="Back to My Womancare" />
            <div className="requests-header">
                <h1>Customer service ticketing</h1>
                <div className="links">
                    <CaretLink to={FAQURL} label="FAQ" />
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        {Cols.map((col, index) => (
                            <th key={index}>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {displayedTickets.map((doc) => (
                        <tr key={doc.id}>
                            <td data-label={Cols[0]}>{formatDate(doc.created_at)}</td>
                            <td data-label={Cols[1]}></td>
                            <td data-label={Cols[2]}>
                                {doc.subject} <br></br>Status: {doc.status}
                            </td>
                            <td data-label={Cols[3]}>{doc.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pager
                pageCount={pageCount}
                currentPage={page}
                onSetPage={(x) => setPage(x)}
                link={"#" + pageCount}
            />
        </div>
    );
};
