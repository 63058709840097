import queryString from "query-string";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { upsertAddress } from "../../services/addresses.service";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import OrderSlice from "../../store/slices/order.slice";
import { setError } from "../../utils/app.utils";
import Caret from "../icons/Caret";
import Close from "../icons/Close";
import "./addressForm.scss";
import Button from "./Button";
import CheckInput from "./CheckInput";
import Select from "./Select";
import TextInput from "./TextInput";

const AddressFormTextInput = (props: {
    label: string;
    required?: boolean;
    value?: string;
    onChange: (value: any) => void;
}) => {
    return (
        <TextInput
            label={props.label}
            required={props.required}
            value={props.value || ""}
            onChange={(text) => props.onChange(text)}
            color="dark"
        />
    );
};

export default (props: {
    address?: any;
    type?: "SHIPPING" | "BILLING";
    onClose?: () => void;
    onDelete?: () => void;
    onAskDelete?: () => void;
}) => {
    const { address = { country: {}, type: props?.type }, onClose } = props;

    const history = useHistory();

    const location = useLocation();
    const { fromOrder } = queryString.parse(location.search);

    const shop = useSelector((state: any) => state.shop);

    const [state, setState] = useState({ ...address, countryId: address.country.id });

    const set = (field, value) => setState((prev) => ({ ...prev, [field]: value }));

    const isAddressFormValid = () =>
        ["name", "addressee", "address", "zipCode", "city", "countryId", "email", "phone"].every(
            (field) => !!state[field]
        );

    const submit = async () => {
        if (isAddressFormValid()) {
            const res = await upsertAddress(state);
            if (res.success) {
                if (address.type === "SHIPPING") {
                    if (props.address) {
                        store.dispatch(
                            DistributorSlice.actions.setShippingAddress({
                                ...state,
                                country: res.country,
                            })
                        );
                    } else {
                        store.dispatch(
                            DistributorSlice.actions.addShippingAddress({
                                ...state,
                                id: res.id,
                                country: res.country,
                            })
                        );

                        if (fromOrder) {
                            store.dispatch(
                                OrderSlice.actions.setShippingAddress({
                                    ...state,
                                    id: res.id,
                                    country: res.country,
                                })
                            );
                        }
                    }

                    if (state.isPrimary) {
                        store.dispatch(
                            DistributorSlice.actions.setPrimaryShippingAddress({ id: res.id })
                        );
                    }
                } else if (address.type === "BILLING") {
                    if (props.address) {
                        store.dispatch(
                            DistributorSlice.actions.setBillingAddress({
                                ...state,
                                country: res.country,
                            })
                        );
                    } else {
                        store.dispatch(
                            DistributorSlice.actions.addBillingAddress({
                                ...state,
                                id: res.id,
                                country: res.country,
                            })
                        );

                        if (fromOrder) {
                            store.dispatch(
                                OrderSlice.actions.setBillingAddress({
                                    ...state,
                                    id: res.id,
                                    country: res.country,
                                })
                            );
                        }
                    }

                    if (state.isPrimary) {
                        store.dispatch(
                            DistributorSlice.actions.setPrimaryBillingAddress({ id: res.id })
                        );
                    }
                }

                onClose();

                if (fromOrder) {
                    history.push("/order/address");
                }
            }
        } else {
            setError("Missing mandatory fields");
        }
    };

    return (
        <div className="address-form">
            <div className="close-wrapper" onClick={onClose}>
                <Caret type="up" color="primary" />
            </div>
            {props.address ? (
                <div className="header">
                    <h2>{address.name}</h2>
                    <Close color="dark" onClick={props?.onAskDelete} />
                    {address.isPrimary ? (
                        <div className="primary-label">Primary address</div>
                    ) : null}
                </div>
            ) : null}
            <div className="form">
                <section>
                    <AddressFormTextInput
                        value={state.name}
                        onChange={(x) => set("name", x)}
                        label="Address name"
                        required
                    />
                    <AddressFormTextInput
                        value={state.addressee}
                        onChange={(x) => set("addressee", x)}
                        label="Addressee"
                        required
                    />
                    <AddressFormTextInput
                        value={state.address}
                        onChange={(x) => set("address", x)}
                        label="Address"
                        required
                    />
                    <AddressFormTextInput
                        value={state.address2}
                        onChange={(x) => set("address2", x)}
                        label="Address 2"
                    />
                    <AddressFormTextInput
                        value={state.zipCode}
                        onChange={(x) => set("zipCode", x)}
                        label="Zipcode"
                        required
                    />
                    <AddressFormTextInput
                        value={state.city}
                        onChange={(x) => set("city", x)}
                        label="City"
                        required
                    />
                    <Select
                        title="Country*"
                        options={shop.countries
                            .filter((country) => country.code !== "ANY_COUNTRY")
                            .map((country) => ({
                                value: country.id,
                                label: country.name,
                            }))}
                        value={state.countryId}
                        onChange={(x) => set("countryId", x)}
                        color="white"
                    />
                    <AddressFormTextInput
                        value={state.email}
                        onChange={(x) => set("email", x)}
                        label="Email address"
                        required
                    />
                    <AddressFormTextInput
                        value={state.phone}
                        onChange={(x) => set("phone", x)}
                        label="Phone number"
                        required
                    />
                </section>
                <CheckInput
                    label="This is my primary shipping address"
                    value={state.isPrimary}
                    onChange={(x) => setState((prev) => ({ ...prev, isPrimary: x }))}
                    color="dark"
                />
                <div className="submit-block">
                    <Button color="white" text="Validate" onClick={submit} />
                </div>
                <div className="note">* Mandatory field</div>
            </div>
        </div>
    );
};
