import React from "react";
import Colors from "../themes/colors.theme";
import DownloadLink from "./DownloadLink";
import Button from "./forms/Button";
import "./qualityAssurance.scss";

export default () => {
    return (
        <div className="quality-assurance">
            <img
                src="/assets/images/quality-assurance.png"
                alt="Quality assurance"
                className="image"
            />
            <div className="content">
                <div className="title">Quality Assurance</div>
                <div className="text">
                    <div className="left">
                        <p>
                            DKT WomanCare conducts rigorous Quality Management and Quality Assurance
                            to ensure the women using our products have positive experiences that
                            enhance their quality of life. We regularly commission independent
                            research and product testing to validate the quality of our products,
                            and we personally visit our manufacturers to inspect their facilities
                            and ensure their compliance with international best practices.{" "}
                        </p>
                    </div>
                    <div className="right">
                        <p>
                            DKT WomanCare complies with ISO 13485, US Food and Drug Administration,
                            and European Medicines Agency quality requirements, which are among the
                            most stringent in the world.
                        </p>

                        <button
                            className="button"
                            style={{
                                backgroundColor: Colors["dark"],
                                color: "#FFFFFF",
                            }}
                        >
                            <a href="/assets/documents/qualitypolicy.pdf" download>
                                Quality policy
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
