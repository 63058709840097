import { get, post } from "./utils.service";

export const getMyTickets = async () => {
    try {
        const res = await get(`/zendesk/tickets`);
        return await res.json();
    } catch (e) {
        return null;
    }
};

export const createTicket = async (data) => {
    try {
        const res = await post(`/zendesk/ticket`, data);
        return await res.json();
    } catch (e) {
        return null;
    }
};