import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter } from "react-router-dom";
import Modal from "../components/Modal";
import AuthHeader from "../components/structure/auth/Header";
import EmptyHeader from "../components/structure/EmptyHeader";
import Footer from "../components/structure/Footer";
import MainHeader from "../components/structure/Header";
import * as CareCoinsService from "../services/carecoins.service";
import * as CountriesService from "../services/countries.service";
import * as NotificationsService from "../services/notifications.service";
import * as OrdersService from "../services/orders.service";
import * as ProductsService from "../services/products.service";
import * as RegistrationsService from "../services/registrations.service";
import { store } from "../store/configure.store";
import DistributorSlice from "../store/slices/distributor.slice";
import ShopSlice from "../store/slices/shop.slice";
import { setError, setSuccess } from "../utils/app.utils";
import { useLogin } from "../utils/auth.utils";
import Routes from "./routes";

const ScrollToTop = ({ location, children }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return children;
};

const RoutedScrollToTop = withRouter(ScrollToTop as any);

const Page = ({ component, headerType, footerType }) => {
    const { isLogin } = useLogin();

    const { error, success } = useSelector((state: any): any => state.app);

    const resetError = () => {
        setError(null);
    };

    const resetSuccess = () => {
        setSuccess(null);
    };

    const getRegistrations = async () => {
        if (isLogin) {
            const res = await RegistrationsService.getForCurrentDistributor();
            if (res && res.registrations) {
                store.dispatch(DistributorSlice.actions.setRegistrations(res.registrations));
            }
        } else {
            store.dispatch(DistributorSlice.actions.reset());
        }
    };

    const getProducts = async () => {
        if (isLogin) {
            const productsCategoriesRes = await ProductsService.getProductCategories();
            if (productsCategoriesRes) {
                store.dispatch(ShopSlice.actions.setProductCategories(productsCategoriesRes));
            }

            const productsSubCategoriesRes = await ProductsService.getProductSubCategories();
            if (productsSubCategoriesRes) {
                store.dispatch(ShopSlice.actions.setProductSubCategories(productsSubCategoriesRes));
            }

            const productsRes = await ProductsService.getAll();
            if (productsRes) {
                store.dispatch(ShopSlice.actions.setProducts(productsRes));
            }
        } else {
            store.dispatch(ShopSlice.actions.reset());
        }
    };

    const getOrders = async () => {
        const res = await OrdersService.getOrders();
        if (res && res.orders) {
            store.dispatch(DistributorSlice.actions.setOrders(res.orders));
        }
    };

    const getCountries = async () => {
        const res = await CountriesService.getAll();
        if (res) {
            store.dispatch(ShopSlice.actions.setCountries(res));
        }
    };

    const getNotifications = async () => {
        if (isLogin) {
            const res = await NotificationsService.getAll();
            if (res) {
                store.dispatch(DistributorSlice.actions.setNotifications(res));
            }
        }
    };

    const getLoyaltyData = async () => {
        if (isLogin) {
            const res = await CareCoinsService.getCurrentCareCoinsAmount();
            if (res && res.success) {
                store.dispatch(
                    DistributorSlice.actions.setLoyaltyData({ careCoinsBalance: res.data })
                );
            }
        }
    };

    useEffect(() => {
        getRegistrations();
        getProducts();
        getOrders();
        getCountries();
        getNotifications();
        getLoyaltyData();
    }, [isLogin]);

    const PageComponent = component;

    const Header =
        {
            auth: AuthHeader,
            none: EmptyHeader,
        }[headerType] || MainHeader;

    const FooterComponent =
        {
            none: () => null,
        }[footerType] || Footer;

    return (
        <>
            <Header />
            <PageComponent />
            <FooterComponent />
            {error ? (
                <Modal className="error" onClose={resetError} closeOnBackgroundClick hasCloseIcon>
                    {error}
                </Modal>
            ) : null}
            {success ? (
                <div className="app success" onClick={resetSuccess}>
                    {success}
                </div>
            ) : null}
        </>
    );
};

const AuthenticatedRoute = (props) => {
    const { isLogin } = useLogin();

    return isLogin ? <Route {...props} /> : <Redirect to="/login" />;
};

const PagesNavigation = () => {
    return (
        <Router>
            <RoutedScrollToTop>
                <Switch>
                    {Routes.map((route) => {
                        const RouteComponent = route.auth ? AuthenticatedRoute : Route;

                        return (
                            <RouteComponent
                                key={route.path}
                                exact
                                path={route.path}
                                component={() => (
                                    <Page
                                        component={route.component}
                                        headerType={route.headerType}
                                        footerType={route.footerType}
                                    />
                                )}
                            />
                        );
                    })}
                    <Redirect to="/404" />
                </Switch>
            </RoutedScrollToTop>
        </Router>
    );
};

export default PagesNavigation;
