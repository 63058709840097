import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";

import Button from "../../components/forms/Button";
import PasswordInput from "../../components/forms/PasswordInput";
import { validatePassword } from "../../utils/regex.util";
import { patchPassword } from "../../services/distributors.service";
import "./login.scss";
import Wave from "../../components/style/Wave";

export default () => {
    const history = useHistory();
    const location = useLocation();

    const { token, isNew } = queryString.parse(location.search);

    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const submit = async () => {
        if (!validatePassword(password)) {
            setErrorMsg(
                "Password must contain at least 8 signs, including one number, one capital, one lowercase and one special sign"
            );
            return;
        }
        if (password !== passwordConfirmation) {
            setErrorMsg("The passwords are different");
            return;
        }

        setLoading(true);
        const res = await patchPassword(token, password);
        setLoading(false);

        if (res.success) {
            history.push(`/login${isNew ? "?isNew=true" : ""}`);
        } else {
            setErrorMsg("Request expired");
        }
    };

    return (
        <div id="login">
            <div className="header">
                <div className="content">
                    <img src="/assets/icons/key.svg" alt="Key" />
                    <h1>{isNew ? "Set your password" : "I forgot my password"}</h1>
                </div>
                <div className="background"></div>
                <Wave color="light" />
            </div>

            <div className="form">
                <div className="inputs">
                    <PasswordInput
                        label="New password"
                        placeholder="New password"
                        tooltip="Password must contain at least 8 signs, including one number, one capital, one lowercase and one special sign"
                        onChange={text => setPassword(text)}
                        errorMsg={errorMsg}
                    />
                    <PasswordInput
                        label="Confirm password"
                        placeholder="Confirm password"
                        onChange={text => setPasswordConfirmation(text)}
                    />
                </div>

                <div className="submit-block">
                    <Button loading={loading} text="Confirm" color="dark" onClick={submit} />
                </div>
            </div>
        </div>
    );
};
