import React from "react";

import Stepper from "../Stepper";

const Steps = [
    { label: "My cart", slug: "cart" },
    { label: "Shipping & Billing addresses", slug: "address" },
    { label: "Shipping method", slug: "shipping" },
    { label: "Certificates", slug: "certificates" },
    { label: "Validation", slug: "validation" },
];

export default ({ currentStep }) => {
    return <Stepper steps={Steps} currentStep={currentStep} basePath="/order" />;
};
