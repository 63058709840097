import React, { useState } from "react";
import { useSelector } from "react-redux";

import "./forecasts.scss";
import BackLink from "../../components/BackLink";
import CaretLink from "../../components/CaretLink";
import ForecastsSelectors from "../../selectors/forecasts.selectors";
import IconLink from "../../components/IconLink";
import ConfirmationModal from "../../components/ConfirmationModal";
import { deleteForecast } from "../../services/forecasts.service";
import { store } from "../../store/configure.store";
import DistributorSlice from "../../store/slices/distributor.slice";
import LinkButton from "../../components/LinkButton";

const Cols = [
    "Date",
    "Product",
    "Orders in the next 3 months",
    "Quantity",
    "Current stock",
    "Action",
];

export default () => {
    const forecasts = useSelector((state: any) => ForecastsSelectors.forecastsData(state));

    const [deleteId, setDeleteId] = useState(null);

    const onDeleteForecast = async () => {
        const res = await deleteForecast(deleteId);
        if (res.success) {
            store.dispatch(DistributorSlice.actions.deleteForecast({ id: deleteId }));
        }
    };

    return (
        <div id="forecasts" className="profile">
            <BackLink to="/profile/me" label="Back to my data" />
            <h1>My forecasts</h1>
            <div className="links">
                <CaretLink to="/profile/labels" label="Label and artwork" />
                <CaretLink to="/profile/documents" label="My documents" />
                <CaretLink to="/profile/security" label="Security" />
            </div>
            <table>
                <thead>
                    <tr>
                        {Cols.map((col, index) => (
                            <th key={index}>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {forecasts.map((forecast) => (
                        <tr key={forecast.id}>
                            <td data-label={Cols[0]}>{forecast.date}</td>
                            <td data-label={Cols[1]}>{forecast.productName}</td>
                            <td data-label={Cols[2]}>{forecast.ordersInNext3Months}</td>
                            <td data-label={Cols[3]}>{forecast.quantity}</td>
                            <td data-label={Cols[4]}>{forecast.currentInventoryLevel}</td>
                            <td data-label={Cols[5]}>
                                <div className="actions">
                                    <LinkButton
                                        color="primary"
                                        label="Edit"
                                        to={`/profile/forecasts/edit/${forecast.id}`}
                                    />
                                    <LinkButton
                                        color="primary"
                                        label="Delete"
                                        onClick={() => setDeleteId(forecast.id)}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <IconLink
                icon="plus"
                label="Create a forecast"
                to="/profile/forecasts/edit"
                color="secondary"
            />
            {deleteId && (
                <ConfirmationModal
                    message="Delete this forecast ?"
                    onValidate={() => {
                        onDeleteForecast();
                        setDeleteId(false);
                    }}
                    onCancel={() => setDeleteId(false)}
                />
            )}
        </div>
    );
};
