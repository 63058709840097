import React from "react";

import "./checkInput.scss";

type CheckInputProps = {
    label: string;
    color?: string;
    value?: boolean;
    onChange: (value: boolean) => void;
};

export default (props: CheckInputProps) => {
    return (
        <label className={`check-input ${props.color || ""}`}>
            <input type="checkbox" checked={!!props.value} onChange={(e) => props.onChange(e.target.checked)} />
            <div className="label">{props.label}</div>
        </label>
    );
};
