import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import QualityAssurance from "../../components/QualityAssurance";
import Wave from "../../components/style/Wave";
import meSelectors from "../../selectors/me.selectors";
import { getCurrentLoyaltyStatus } from "../../services/loyaltyStatus.service";
import { getForCurrentDistributor } from "../../services/productHistory.service";
import Contacts from "./Contacts";
import "./home.scss";
import Orders from "./Orders";
import RecentlyViewedProducts from "./RecentlyViewedProducts";
import RelatedProducts from "./RelatedProducts";

const HomeScreen = () => {
    const allProducts = useSelector((state: any) => state.shop.products);
    const distributor = useSelector((state: any) => state.distributor);
    const fullName = useSelector((state: any) => meSelectors.fullName(state));

    const [sortedProducts, setSorteddProducts] = useState([]);
    const [currentLoyaltyStatus, setCurrentLoyaltyStatus] = useState(null);

    const getSortedProducts = (viewedProducts) => {
        const products = viewedProducts
            .sort((a, b) => (a.creationDate < b.creationDate ? 1 : -1))
            .reduce((acc, obj) => {
                if (!acc.find((x) => x.id === obj.product.id)) {
                    acc.push(obj.product);
                }
                return acc;
            }, [])
            .slice(0, 4);
        const randomProducts = allProducts.slice(0, 4 - products.length);
        return products.concat(randomProducts);
    };

    useEffect(() => {
        (async () => {
            const res = await getForCurrentDistributor();
            if (res) {
                const products = getSortedProducts(res.data);
                setSorteddProducts(products);
            }

            const response = await getCurrentLoyaltyStatus();
            setCurrentLoyaltyStatus(response);
        })();
    }, []);

    return (
        <div id="home">
            <div className="header">
                <div className="background"></div>
                <div className="title-block">
                    <div className="title">
                        Hello <span className="name">{fullName}</span>, welcome back!
                    </div>
                    <div className="subtitle">How can we help you today?</div>
                </div>
                <Wave color="white" />
                {distributor.loyaltyProgramGroup ? (
                    <div className="program">
                        {currentLoyaltyStatus?.reference === "BLUE" && (
                            <img
                                src="/assets/images/loyaltyProgram/blue-partner-badge.png"
                                alt="Blue Partner Badge"
                                className="partner-badge"
                            />
                        )}
                        {currentLoyaltyStatus?.reference === "BRONZE" && (
                            <img
                                src="/assets/images/loyaltyProgram/bronze-partner-badge.png"
                                alt="Bronze Partner Badge"
                                className="partner-badge"
                            />
                        )}
                        {currentLoyaltyStatus?.reference === "SILVER" && (
                            <img
                                src="/assets/images/loyaltyProgram/silver-partner-badge.png"
                                alt="Silver Partner Badge"
                                className="partner-badge"
                            />
                        )}
                        {currentLoyaltyStatus?.reference === "GOLD" && (
                            <img
                                src="/assets/images/loyaltyProgram/gold-partner-badge.png"
                                alt="Gold Partner Badge"
                                className="partner-badge"
                            />
                        )}
                        <div
                            className="partner-level-name"
                            style={{
                                color:
                                    currentLoyaltyStatus?.reference === "BLUE"
                                        ? "#5CFDDB"
                                        : currentLoyaltyStatus?.reference === "BRONZE"
                                        ? "#A25D26"
                                        : currentLoyaltyStatus?.reference === "SILVER"
                                        ? "#A8B7B5"
                                        : currentLoyaltyStatus?.reference === "GOLD"
                                        ? "#CD9650"
                                        : null,
                            }}
                        >
                            {currentLoyaltyStatus === null
                                ? "You are not yet a partner"
                                : currentLoyaltyStatus?.displayName}
                        </div>
                        <Link to="/my-womancare/my-loyalty-program">My loyalty program</Link>
                    </div>
                ) : null}
            </div>
            <Orders />
            <Contacts />
            <RecentlyViewedProducts sortedProducts={sortedProducts} />
            <RelatedProducts sortedProducts={sortedProducts} />
            <QualityAssurance />
        </div>
    );
};

export default HomeScreen;
